import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';

export default function AddFaq() {
    const navigate = useNavigate();
    const [quest, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [type, setType] = useState('1');
    const handleAdd = () => {
        if (validateFields()) {
            Addfaq()
        } else {
          console.log('Validation failed');
        }
      };
      const Addfaq = async () => {
        const {status, data, msg} = await sendData('/faqs', JSON.stringify({question:quest,answer,type}));
        if(status){
         navigate('/faq')
        }
      }
      const validateFields = () => {
        if (!quest.trim()) {
          alert('Question cannot be empty.');
          return false;
        }
        if (!answer.trim()) {
            alert('Answer cannot be empty.');
            return false;
          }
        return true;
      };
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>
        <label style={{marginLeft:10}} className="form-label mt-4">Type <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={type} onChange={(e)=>setType(e.target.value)} required name="code" className="form-control"  >

  <option value={1}>User</option>
  <option value={2}>Driver</option>


</select>

</div>
    <label className="form-label mt-4">Question</label>
      <input required type="text" className="form-control"  name="slogan" value={quest} 
      onChange={(e) => {setQuestion(e.target.value)}}
      />
      <label className="form-label mt-4">Answer</label>
      <input required type="text" className="form-control"  name="slogan" value={answer} 
      onChange={(e) => {setAnswer(e.target.value)}}
      />

<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleAdd}>Add</Button>

  </div>
  )
}
