import React, {useState} from 'react'
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

export default function Time() {
    const [from, SetFrom] = useState('10:00');
    const [to, setTo] = useState('15:00');
    const [en, setEn] = useState('');
  return (
    <div style={{display:'flex',alignItems:'center',border:'1px solid #d1d1d1',borderRadius:7,padding:5}}>
    <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',width:'29%'}}>
        <label >From</label>
            <TimePicker 
            
            onChange={SetFrom} 
            value={from} 
            clearIcon={null}
            //clockIcon={null}
            disableClock={true}
            format='HH:mm'
            />
        </div>
        <div style={{flexDirection:'row',display:'flex',marginLeft:'2%',justifyContent:'space-between',width:'29%'}}>
        <label >To</label>
            <TimePicker 
            onChange={setTo} 
            value={to} 
            clearIcon={null}
            //clockIcon={null}
            disableClock={true}
            format='HH:mm'
            />
        </div>
       <div style={{width:'30%',marginLeft:'2%',display:'flex',alignItems:'center',justifyContent:'center'}}>
       <div >
                    <label className="form-label m-0">Enabled/Disabled</label>
        </div>
            <select style={{marginLeft:'3%'}} className='form-select' required name="code" value={en} onChange={(e)=>{setEn(Number(e.target.value))}} >
            <option value="0">Enable</option>
            <option value="1">Disable</option>
            </select>
       </div>

    </div>   
  )
}
