import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import { Alert, Button } from 'react-bootstrap';

export default function ShopperInfo(props) {
   const awsUrl = process.env.REACT_APP_AWS_LINK;

    const [info, setInfo] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [subcitys, setSubCitys] = useState([]);
    const [citys, setCitys] = useState([]);
    const [banks, setBanks] = useState([]);
    const [reason, setReason] = useState('');
    const [isreject, setisreject] = useState(false);
   
    const [name, setName] = useState('');
    const [nameconf, setNameconf] = useState('');
    const [namereason, setNamereason] = useState('');
    
    const [lastname, setLastname] = useState('');
    const [lastnameconf, setLastnameconf] = useState('');
    const [lastnamereason, setLastnamereason] = useState('');
    
    const [phone, setPhone] = useState('');
    const [phoneconf, setPhoneconf] = useState('');
    const [phonereason, setPhonereason] = useState('');
    
    const [cityid, setCityid] = useState();
    const [cityidconf, setCityidconf] = useState('');
    const [cityidreason, setCityidreason] = useState('');
    
    const [emg_phone, setEmg_Phone] = useState('');
    const [emg_phoneconf, setEmg_Phoneconf] = useState('');
    const [emg_phonereason, setEmg_Phonereason] = useState('');
    
    const [emg_name, setEmg_Name] = useState('');
    const [emg_nameconf, setEmg_Nameconf] = useState('');
    const [emg_namereason, setEmg_Namereason] = useState('');
    
    const [bankid, setBankid] = useState();
    const [bankidconf, setBankidconf] = useState('');
    const [bankidreason, setBankidreason] = useState('');
    
    const [account_type, setaccountType] = useState('');
    const [account_typeconf, setaccountTypeconf] = useState('');
    const [account_typereason, setaccountTypereason] = useState('');
    
    const [account_number, setAccontNumber] = useState('');
    const [account_numberconf, setAccontNumberconf] = useState('');
    const [account_numberreason, setAccontNumberreason] = useState('');
    
    const [mtid, setmtid] = useState(null);
    const [mtidconf, setmtidconf] = useState('');
    const [mtidreason, setmtidreason] = useState('');
    
    const [mt_number, setmt_number] = useState('');
    const [mt_numberconf, setmt_numberconf] = useState('');
    const [mt_numberreason, setmt_numberreason] = useState('');
    
    const [dob, setDob] = useState('');
    const [dobconf, setDobconf] = useState('');
    const [dobreason, setDobreason] = useState('');
    
    const [govid_front, setgovid_front] = useState();
    const [imgchanged1, setimgchanged1] = useState(false);
    const [govid_frontconf, setgovid_frontconf] = useState('');
    const [govid_frontreason, setgovid_frontreason] = useState('');
    
    const [govid_back, setgovid_back] = useState('');
    const [imgchanged2, setimgchanged2] = useState(false);

    const [govid_backconf, setgovid_backconf] = useState('');
    const [govid_backreason, setgovid_backreason] = useState('');
    
    const [img, setimg] = useState('');
    const [imgchanged, setimgchanged] = useState(false);
    const [imgconf, setimgconf] = useState('');
    const [imgreason, setimgreason] = useState('');
    
    const [commercial_driver_license_front, setcommercial_driver_license_front] = useState();
    const [imgchanged4, setimgchanged4] = useState(false);
    const [commercial_driver_license_frontconf, setcommercial_driver_license_frontconf] = useState('');
    const [commercial_driver_license_frontreason, setcommercial_driver_license_frontreason] = useState('');
    
    const [commercial_driver_license_back, setcommercial_driver_license_back] = useState('');
    const [imgchanged5, setimgchanged5] = useState(false);
    const [commercial_driver_license_backconf, setcommercial_driver_license_backconf] = useState('');
    const [commercial_driver_license_backreason, setcommercial_driver_license_backreason] = useState('');
    
    const [judicial_record, setjudicial_record] = useState('');
    const [imgchanged3, setimgchanged3] = useState(false);
    const [judicial_recordconf, setjudicial_recordconf] = useState('');
    const [judicial_recordreason, setjudicial_recordreason] = useState('');
    
    const [car_permit, setcar_permit] = useState('');
    const [imgchanged6, setimgchanged6] = useState(false);
    const [car_permitconf, setcar_permitconf] = useState('');
    const [car_permitreason, setcar_permitreason] = useState('');
    
    const [car_registration_front, setcar_registration_front] = useState();
    const [imgchanged7, setimgchanged7] = useState(false);
    const [car_registration_frontconf, setcar_registration_frontconf] = useState('');
    const [car_registration_frontreason, setcar_registration_frontreason] = useState('');
    
    const [car_registration_back, setcar_registration_back] = useState('');
    const [imgchanged8, setimgchanged8] = useState(false);
    const [car_registration_backconf, setcar_registration_backconf] = useState('');
    const [car_registration_backreason, setcar_registration_backreason] = useState('');
    
    const [compulsory_insurance, setcompulsory_insurance] = useState('');
    const [imgchanged9, setimgchanged9] = useState(false);
    const [compulsory_insuranceconf, setcompulsory_insuranceconf] = useState('');
    const [compulsory_insurancereason, setcompulsory_insurancereason] = useState('');

    const GetCities = async ()=>{
        const {status, data, msg} = await fetchData(`/cities`);
        if(status){
            setCitys(data)
         }
    }
    const GetBanks = async ()=>{
        const {status, data, msg} = await fetchData(`/banks`);
        if(status){
            setBanks(data)
      
         }
    }
    const GetShopperDetails = async () => {
        const {status, data, msg} = await fetchData(`/shoppers/details/${props.userid}`);
        if(status){ 
            if(data.application){
                setIsSubmitted(true)
            }else{
                setIsSubmitted(false)
            }
            setInfo(data.data)

            setName(data.data.first_name)
            setNameconf(data.data.first_name_conf)
            setNamereason(data.data.first_name_reason)

            setLastname(data.data.last_name)
            setLastnameconf(data.data.last_name_conf)
            setLastnamereason(data.data.last_name_reason)


            setPhone(data.data.phone_number)
            setPhoneconf(data.data.phone_number_conf)
            setPhonereason(data.data.phone_number_reason)


            setCityid(data.data.cityid)
            setCityidconf(data.data.cityid_conf)
            setCityidreason(data.data.cityid_reason)


            setEmg_Phone(data.data.emergency_phone)
            setEmg_Phoneconf(data.data.emergency_phone_conf)
            setEmg_Phonereason(data.data.emergency_phone_reason)

            setEmg_Name(data.data.emergency_name)
            setEmg_Nameconf(data.data.emergency_name_conf)
            setEmg_Namereason(data.data.emergency_name_reason)


          
            setmtid(data.data.mtid)
            setmtidconf(data.data.mtid_conf)
            setmtidreason(data.data.mtid_reason)
            setmt_number(data.data.mt_number)
            setmt_numberconf(data.data.mt_number_conf)
            setmt_numberreason(data.data.mt_number_reason)

            setBankid(data.data.bankid)
            setBankidconf(data.data.bankid_conf)
            setBankidreason(data.data.bankid_reason)

            setaccountType(data.data.account_type)
            setaccountTypeconf(data.data.account_type_conf)
            setaccountTypereason(data.data.account_type_reason)
            setAccontNumber(data.data.account_number)
            setAccontNumberconf(data.data.account_number_conf)
            setAccontNumberreason(data.data.account_number_reason)



            setDob(data.data.dob)
            setDobconf(data.data.dob_conf)
            setDobreason(data.data.dob_reason)

            setgovid_back(data.data.govid_back)
            setgovid_backconf(data.data.govid_back_conf)
            setgovid_backreason(data.data.govid_back_reason)


            setgovid_front(data.data.govid_front)
            setgovid_frontconf(data.data.govid_front_conf)
            setgovid_frontreason(data.data.govid_front_reason)


            setimg(data.data.img)
            setimgconf(data.data.img_conf)
            setimgreason(data.data.img_reason)

            setcar_permit(data.data.car_permit)
            setcar_permitconf(data.data.car_permit_conf)
            setcar_permitreason(data.data.car_permit_reason)


            setjudicial_record(data.data.judicial_record)
            setjudicial_recordconf(data.data.judicial_record_conf)
            setjudicial_recordreason(data.data.car_permit_reason)


            setcar_registration_back(data.data.car_registration_back)
            setcar_registration_backconf(data.data.car_registration_back_conf)
            setcar_registration_backreason(data.data.car_registration_back_reason)

            setcar_registration_front(data.data.car_registration_front)
            setcar_registration_frontconf(data.data.car_registration_front_conf)
            setcar_registration_frontreason(data.data.car_registration_front_reason)


            setcommercial_driver_license_back(data.data.commercial_driver_license_back)
            setcommercial_driver_license_backconf(data.data.commercial_driver_license_back_conf)
            setcommercial_driver_license_backreason(data.data.commercial_driver_license_back_reason)

            setcommercial_driver_license_front(data.data.commercial_driver_license_front)
            setcommercial_driver_license_frontconf(data.data.commercial_driver_license_front_conf)
            setcommercial_driver_license_frontreason(data.data.commercial_driver_license_front_reason)

            setcompulsory_insurance(data.data.compulsory_insurance)
            setcompulsory_insuranceconf(data.data.compulsory_insurance_conf)
            setcompulsory_insurancereason(data.data.compulsory_insurance_reason)
    
       
     
            setLoading(false)
        }
      }
      useEffect(()=>{

        GetCities()
        GetBanks()
        GetShopperDetails();
    },[]);
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setCityid(selectedValue);
       // GetArea(selectedValue)
      };
      const GetArea= async (x)=>{
        const {status, data, msg} = await fetchData(`/subcities/dropdown/${x}`);
        setSubCitys(data)
       if(data.length>0){
       // setsubcity(data[0].id)
       }
        //console.log(data)
        //setCitys(data);
        }
        const handleimg = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setimg(selectedFile);
            setimgchanged(true)
            }
          };
          const handleimg4 = (event) => {
            const selectedFile = event.target.files[0];  
                if(selectedFile){
            setcommercial_driver_license_front(selectedFile);
            setimgchanged4(true)
                }
          };
          const handleimg5 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setcommercial_driver_license_back(selectedFile);
            setimgchanged5(true)
            }
          };
          const handleimg3 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setjudicial_record(selectedFile);
            setimgchanged3(true)
            }
          };
          const handleimg6 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setcar_permit(selectedFile);
            setimgchanged6(true)
            }
          };
          const handleimg7 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setcar_registration_front(selectedFile);
            setimgchanged7(true)
            }
          };
          const handleimg8 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setcar_registration_back(selectedFile);
            setimgchanged8(true)
            }
          };
          const handleimg1 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setgovid_front(selectedFile);
            setimgchanged1(true)
            }
          };
          const handleimg2 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
            setgovid_back(selectedFile);
            setimgchanged2(true)
            }
          };
          const handleimg9 = (event) => {
            const selectedFile = event.target.files[0];
            if(selectedFile){
                setcompulsory_insurance(selectedFile);
                setimgchanged9(true)
            }
            
          };
          const handleSubmit = async(status) => { 
            setLoading(true)
              try {
                const formData = new FormData();
     
                 formData.append('status', status);
                 formData.append('reason', reason);
                 formData.append('userid', props.userid);

                 formData.append('first_name', name);
                 formData.append('first_name_conf', nameconf);
                 formData.append('first_name_reason', namereason);

                 formData.append('last_name', lastname);
                 formData.append('last_name_conf', lastnameconf);
                 formData.append('last_name_reason', lastnamereason);

                 formData.append('last_name', lastname);
                 formData.append('last_name_conf', lastnameconf);
                 formData.append('last_name_reason', lastnamereason);

                 formData.append('phone_number', phone);
                 formData.append('phone_number_conf', phoneconf);
                 formData.append('phone_number_reason', phonereason);

                 formData.append('cityid', cityid);
                 formData.append('cityid_conf', cityidconf);
                 formData.append('cityid_reason', cityidreason);
                 
                 formData.append('emergency_phone', emg_phone);
                 formData.append('emergency_phone_conf', emg_phoneconf);
                 formData.append('emergency_phone_reason', emg_phonereason);
                 
                 formData.append('emergency_name', emg_name);
                 formData.append('emergency_name_conf', emg_nameconf);
                 formData.append('emergency_name_reason', emg_namereason);
                 
                 formData.append('bankid', bankid);
                 formData.append('bankid_conf', bankidconf);
                 formData.append('bankid_reason', bankidreason);
                 
                 formData.append('account_type', account_type);
                 formData.append('account_type_conf', account_typeconf);
                 formData.append('account_type_reason', account_typereason);
                 
       
                 formData.append('account_number', account_number);
                 formData.append('account_number_conf', account_numberconf);
                 formData.append('account_number_reason', account_numberreason);

                 formData.append('mtid', mtid);
                 formData.append('mtid_conf', mtidconf);
                 formData.append('mtid_reason', mtidreason);
                 
       
                 formData.append('mt_number', mt_number);
                 formData.append('mt_number_conf', mt_numberconf);
                 formData.append('mt_number_reason', mt_numberreason);
                 

                 formData.append('dob', dob);
                 formData.append('dob_conf', dobconf);
                 formData.append('dob_reason', dobreason);
                 
                 formData.append('govid_front', govid_front);
                 formData.append('govid_front_changed', imgchanged1?1:0);
                 formData.append('govid_front_conf', govid_frontconf);
                 formData.append('govid_front_reason', govid_frontreason);

                 formData.append('govid_back', govid_back);
                 formData.append('govid_back_changed', imgchanged2?1:0);
                 formData.append('govid_back_conf', govid_backconf);
                 formData.append('govid_back_reason', govid_backreason);
                 
            
                 formData.append('img', img);
                 formData.append('img_changed', imgchanged?1:0);
                 formData.append('img_conf', imgconf);
                 formData.append('img_reason', imgreason);
                 
         
                 formData.append('commercial_driver_license_front', commercial_driver_license_front);
                 formData.append('commercial_driver_license_front_changed', imgchanged4?1:0);
                 formData.append('commercial_driver_license_front_conf', commercial_driver_license_frontconf);
                 formData.append('commercial_driver_license_front_reason', commercial_driver_license_frontreason);
                 
                 formData.append('commercial_driver_license_back', commercial_driver_license_back);
                 formData.append('commercial_driver_license_back_changed', imgchanged5?1:0);
                 formData.append('commercial_driver_license_back_conf', commercial_driver_license_backconf);
                 formData.append('commercial_driver_license_back_reason', commercial_driver_license_backreason);
                 
                 formData.append('judicial_record', judicial_record);
                 formData.append('judicial_record_changed', imgchanged3?1:0);
                 formData.append('judicial_record_conf', judicial_recordconf);
                 formData.append('judicial_record_reason', judicial_recordreason);
                 
                 formData.append('car_permit', car_permit);
                 formData.append('car_permit_changed', imgchanged6?1:0);
                 formData.append('car_permit_conf', car_permitconf);
                 formData.append('car_permit_reason', car_permitreason);
                 
                           
                 formData.append('car_registration_front', car_registration_front);
                 formData.append('car_registration_front_changed', imgchanged7?1:0);
                 formData.append('car_registration_front_conf', car_registration_frontconf);
                 formData.append('car_registration_front_reason', car_registration_frontreason);
           
                 formData.append('car_registration_back', car_registration_back);
                 formData.append('car_registration_back_changed', imgchanged8?1:0);
                 formData.append('car_registration_back_conf', car_registration_backconf);
                 formData.append('car_registration_back_reason', car_registration_backreason);
           
                 formData.append('compulsory_insurance', compulsory_insurance);
                 formData.append('compulsory_insurance_changed', imgchanged9?1:0);
                 formData.append('compulsory_insurance_conf', compulsory_insuranceconf);
                 formData.append('compulsory_insurance_reason', compulsory_insurancereason);
           
                
           
              const req = await fetch("https://api.trakitrak.com/control/shoppers/decide", {
                method:'POST',
                body: formData,
        })
        if(req.status==200){
            const json = await req.json()
            console.log(json)
            setLoading(false)
            // setLoading(true)
            // navigate(`/store/${storeid}}`, {replace: true});
    
        }else{
            const json = await req.json()
            console.log(json) 
            setLoading(false)

            setShowalert(true)
            setMsg(json.message)
            // setLoading(true)
        }
              } catch (error) {
                
              }
           
           
          };
          const showAlerts = () =>{
            return (
              <>
          {showalert&&(
        <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
            {msg}
        </Alert>
          )}
             
            </>
            )
          }
  return (
    <div>
               {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
        <>
        {isSubmitted?
        <>
            {showAlerts()}
        
         <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setNameconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:nameconf==1?0.5:1}} disabled={nameconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setNameconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:nameconf==2?0.5:1}} disabled={nameconf==2?true:false}>Reject</Button>
      <input required type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />
        {nameconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={namereason} onChange={(e) => setNamereason(e.target.value)} />)}
     <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Last Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setLastnameconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:lastnameconf==1?0.5:1}} disabled={lastnameconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setLastnameconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:lastnameconf==2?0.5:1}} disabled={lastnameconf==2?true:false}>Reject</Button>
      <input required type="text" className="form-control" name="name" value={lastname} onChange={(e) => setLastname(e.target.value)} />
      {lastnameconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={lastnamereason} onChange={(e) => setLastnamereason(e.target.value)} />)}
      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Phone Number <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setPhoneconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:phoneconf==1?0.5:1}} disabled={phoneconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setPhoneconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:phoneconf==2?0.5:1}} disabled={phoneconf==2?true:false}>Reject</Button>
      <input required type="text" className="form-control" name="name" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={phonereason} onChange={(e) => setPhonereason(e.target.value)} />)}
      <div style={{width:'100%',height:40}}/>

      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">City <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setCityidconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:cityidconf==1?0.5:1}} disabled={cityidconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setCityidconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:cityidconf==2?0.5:1}} disabled={cityidconf==2?true:false}>Reject</Button>
      <select className="form-control" value={cityid} onChange={handleSelectChange}>
        <option value="" disabled>Select an option</option>
        {citys.map((option, index) => (
        <option key={index} value={option.id}>{option.name}</option>
        ))}
        </select>
        {cityidconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={cityidreason} onChange={(e) => setCityidreason(e.target.value)} />)}
        <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Emergency Phone Number <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setEmg_Phoneconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:emg_phoneconf==1?0.5:1}} disabled={emg_phoneconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setEmg_Phoneconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:emg_phoneconf==2?0.5:1}} disabled={emg_phoneconf==2?true:false}>Reject</Button>
      <input required type="text" className="form-control" name="name" value={emg_phone} onChange={(e) => setEmg_Phone(e.target.value)} />
      {emg_phoneconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={emg_phonereason} onChange={(e) => setEmg_Phonereason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Emergency Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setEmg_Nameconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:emg_nameconf==1?0.5:1}} disabled={emg_nameconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setEmg_Nameconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:emg_nameconf==2?0.5:1}} disabled={emg_nameconf==2?true:false}>Reject</Button>
    
      <input required type="text" className="form-control" name="name" value={emg_name} onChange={(e) => setEmg_Name(e.target.value)} />
      {emg_nameconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={emg_namereason} onChange={(e) => setEmg_Namereason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
{mtid?
<>
<label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Money Transfer <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setmtidconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:mtidconf==1?0.5:1}} disabled={mtidconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setmtidconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:mtidconf==2?0.5:1}} disabled={mtidconf==2?true:false}>Reject</Button>
      <select className="form-control" value={mtid} onChange={(e)=>{console.log(e.target);setmtid(e.target.value)}}>
        <option value="" disabled>Select an option</option>
        {banks.map((option, index) => (
     <>
     {option.mt?        <option key={index} value={option.id}>{option.name}</option>
:null}
     </>
        ))}
        </select>
        {mtidconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={mtidreason} onChange={(e) => setmtidreason(e.target.value)} />)}

        <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Account Number <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setmt_numberconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:mt_numberconf==1?0.5:1}} disabled={mt_numberconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setmt_numberconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:mt_numberconf==2?0.5:1}} disabled={mt_numberconf==2?true:false}>Reject</Button>
       <input required type="text" className="form-control" name="name" value={mt_number} onChange={(e) => setmt_number(e.target.value)} />
      {mt_numberconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={mt_numberreason} onChange={(e) => setmt_numberreason(e.target.value)} />)}


</>
:
<>
<label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Bank <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setBankidconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:bankidconf==1?0.5:1}} disabled={bankidconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setBankidconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:bankidconf==2?0.5:1}} disabled={bankidconf==2?true:false}>Reject</Button>
      <select className="form-control" value={bankid} onChange={(e)=>setBankid(e.target.value)}>
        <option value="" disabled>Select an option</option>
        {banks.map((option, index) => (
     <>
      {!option.mt?        <option key={index} value={option.id}>{option.name}</option>
      :null}
     </>
        ))}
        </select>
        {bankidconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={bankidreason} onChange={(e) => setBankidreason(e.target.value)} />)}

        <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Account Type <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setaccountTypeconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:account_typeconf==1?0.5:1}} disabled={account_typeconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setaccountTypeconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:account_typeconf==2?0.5:1}} disabled={account_typeconf==2?true:false}>Reject</Button>
       <input required type="text" className="form-control" name="name" value={account_type} onChange={(e) => setaccountType(e.target.value)} />
      {account_typeconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={account_typereason} onChange={(e) => setaccountTypereason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Account Number <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setAccontNumberconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:account_numberconf==1?0.5:1}} disabled={account_numberconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setAccontNumberconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:account_numberconf==2?0.5:1}} disabled={account_numberconf==2?true:false}>Reject</Button>
      <input required type="text" className="form-control" name="name" value={account_number} onChange={(e) => setAccontNumber(e.target.value)} />
      {account_numberconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={account_numberreason} onChange={(e) => setAccontNumberreason(e.target.value)} />)}

</>

}
     
<div style={{width:'100%',height:40}}/>

      <label style={{minWidth:'82%',marginBottom:12,marginTop:10}} className="form-label">Date Of Birth <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setDobconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:dobconf==1?0.5:1}} disabled={dobconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setDobconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:dobconf==2?0.5:1}} disabled={dobconf==2?true:false}>Reject</Button>
      <input required type="date" className="form-control" name="name" value={dob} onChange={(e) => setDob(e.target.value)} />
      {dobconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={dobreason} onChange={(e) => setDobreason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'70%',marginBottom:12,marginTop:10}} className="form-label">Image <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{ window.open(!imgchanged?`${awsUrl}/shoppers/files/`+img:URL.createObjectURL(img), '_blank');}}   style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setimgconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:imgconf==1?0.5:1}} disabled={imgconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setimgconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:imgconf==2?0.5:1}} disabled={imgconf==2?true:false}>Reject</Button>
     <div style={{position:'relative'}}>
        <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg} />
      <img style={{cursor:'pointer',objectFit:'contain',minHeight:200,maxHeight:250}} className="form-control"  src={!imgchanged?`${awsUrl}/shoppers/files/`+img:URL.createObjectURL(img)} />
     </div>
     {imgconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={imgreason} onChange={(e) => setimgreason(e.target.value)} />)}


     <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Goverment Id Front <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged1(true); setgovid_front('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged1?`${awsUrl}/shoppers/files/`+govid_front:URL.createObjectURL(govid_front), '_blank');}}   style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setgovid_frontconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:govid_frontconf==1?0.5:1}} disabled={govid_frontconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setgovid_frontconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:govid_frontconf==2?0.5:1}} disabled={govid_frontconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg1} />
      {imgchanged1?
      <>

         {govid_front.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(govid_front)}
        />
}
      </>
      :
      <>
      {govid_front?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + govid_front}
        />
      }
      </>
      }
      </div>
      {govid_frontconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={govid_frontreason} onChange={(e) => setgovid_frontreason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Goverment Id Back <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged2(true); setgovid_back('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged2?`${awsUrl}/shoppers/files/`+govid_back:URL.createObjectURL(govid_back), '_blank');}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setgovid_backconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:govid_backconf==1?0.5:1}} disabled={govid_backconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setgovid_backconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:govid_backconf==2?0.5:1}} disabled={govid_backconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg2} />
      {imgchanged2?
      <>

         {govid_back.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(govid_back)}
        />
}
      </>
      :
      <>
      {govid_back?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + govid_back}
        />
      }
      </>
      }
     </div>
      {govid_backconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={govid_backreason} onChange={(e) => setgovid_backreason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Judicial Record <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged3(true); setjudicial_record('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged3?`${awsUrl}/shoppers/files/`+judicial_record:URL.createObjectURL(judicial_record), '_blank');}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setjudicial_recordconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:judicial_recordconf==1?0.5:1}} disabled={judicial_recordconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setjudicial_recordconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:judicial_recordconf==2?0.5:1}} disabled={judicial_recordconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg3} />
      {imgchanged3?
      <>

         {judicial_record.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(judicial_record)}
        />
}
      </>
      :
      <>
      {judicial_record?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + judicial_record}
        />
      }
      </>
      }
      </div>
      {judicial_recordconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={judicial_recordreason} onChange={(e) => setjudicial_recordreason(e.target.value)} />)}


      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Driver license Front <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged4(true); setcommercial_driver_license_front('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged4?`${awsUrl}/shoppers/files/`+commercial_driver_license_front:URL.createObjectURL(commercial_driver_license_front), '_blank');}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setcommercial_driver_license_frontconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:commercial_driver_license_frontconf==1?0.5:1}} disabled={commercial_driver_license_frontconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setcommercial_driver_license_frontconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:commercial_driver_license_frontconf==2?0.5:1}} disabled={commercial_driver_license_frontconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg4} />
      {imgchanged4?
      <>

         {commercial_driver_license_front.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(commercial_driver_license_front)}
        />
}
      </>
      :
      <>
      {commercial_driver_license_front?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + commercial_driver_license_front}
        />
      }
      </>
      }
      </div>
      {commercial_driver_license_frontconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={commercial_driver_license_frontreason} onChange={(e) => setcommercial_driver_license_frontreason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Driver license Back <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged5(true); setcommercial_driver_license_back('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged5?`${awsUrl}/shoppers/files/`+commercial_driver_license_back:URL.createObjectURL(commercial_driver_license_back), '_blank');}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setcommercial_driver_license_backconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:commercial_driver_license_backconf==1?0.5:1}} disabled={commercial_driver_license_backconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setcommercial_driver_license_backconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:commercial_driver_license_backconf==2?0.5:1}} disabled={commercial_driver_license_backconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg5} />
      {imgchanged5?
      <>

         {commercial_driver_license_back.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(commercial_driver_license_back)}
        />
}
      </>
      :
      <>
      {commercial_driver_license_back?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + commercial_driver_license_back}
        />
      }
      </>
      }
     </div>
      {commercial_driver_license_backconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={commercial_driver_license_backreason} onChange={(e) => setcommercial_driver_license_backreason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Car Permit <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged6(true); setcar_permit('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged6?`${awsUrl}/shoppers/files/`+car_permit:URL.createObjectURL(car_permit), '_blank');}}   style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setcar_permitconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:car_permitconf==1?0.5:1}} disabled={car_permitconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setcar_permitconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:car_permitconf==2?0.5:1}} disabled={car_permitconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg6} />
      {imgchanged6?
      <>

         {car_permit.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(car_permit)}
        />
}
      </>
      :
      <>
      {car_permit?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + car_permit}
        />
      }
      </>
      }
      </div>
      {car_permitconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={car_permitreason} onChange={(e) => setcar_permitreason(e.target.value)} />)}

      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Car Registration Front <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged7(true); setcar_registration_front('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button  onClick={()=>{ window.open(!imgchanged7?`${awsUrl}/shoppers/files/`+car_registration_front:URL.createObjectURL(car_registration_front), '_blank');}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setcar_registration_frontconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:car_registration_frontconf==1?0.5:1}} disabled={car_registration_frontconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setcar_registration_frontconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:car_registration_frontconf==2?0.5:1}} disabled={car_registration_frontconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg7} />
      {imgchanged7?
      <>

         {car_registration_front.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(car_registration_front)}
        />
}
      </>
      :
      <>
      {car_registration_front?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + car_registration_front}
        />
      }
      </>
      }

      </div>
      {car_registration_frontconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={car_registration_frontreason} onChange={(e) => setcar_registration_frontreason(e.target.value)} />)}


      <div style={{width:'100%',height:40}}/>
      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Car Registration Back <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged8(true); setcar_registration_back('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged8?`${awsUrl}/shoppers/files/`+car_registration_back:URL.createObjectURL(car_registration_back), '_blank');}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setcar_registration_backconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:car_registration_backconf==1?0.5:1}} disabled={car_registration_backconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setcar_registration_backconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:car_registration_backconf==2?0.5:1}} disabled={car_registration_backconf==2?true:false}>Reject</Button>
      
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg8} />
      
      
      {imgchanged8?
      <>

         {car_registration_back.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(car_registration_back)}
        />
}
      </>
      :
      <>
      {car_registration_back?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + car_registration_back}
        />
      }
      </>
      }
     
      </div>
      {car_registration_back==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:25}} name="" value={car_registration_backreason} onChange={(e) => setcar_registration_backreason(e.target.value)} />)}
      <div style={{width:'100%',height:40}}/>

      <label style={{minWidth:'54.5%',marginBottom:12,marginTop:10}} className="form-label">Compulsory Insurance <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      <Button onClick={()=>{setimgchanged9(true); setcompulsory_insurance('')}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >Remove Image</Button>
      <Button onClick={()=>{ window.open(!imgchanged9?`${awsUrl}/shoppers/files/`+compulsory_insurance:URL.createObjectURL(compulsory_insurance), '_blank');}}  style={{marginRight:5,backgroundColor:'#e05b0e',borderColor:'#e05b0e',}}  >View Image</Button>
      <Button onClick={()=>{setcompulsory_insuranceconf(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',opacity:compulsory_insuranceconf==1?0.5:1}} disabled={compulsory_insuranceconf==1?true:false} >Accept</Button>
      <Button onClick={()=>{setcompulsory_insuranceconf(2)}}  style={{backgroundColor:'red',borderColor:'red',opacity:compulsory_insuranceconf==2?0.5:1}} disabled={compulsory_insuranceconf==2?true:false}>Reject</Button>
      <div style={{position:'relative'}}>
      <input required accept="image/*,application/pdf" style={{width:'100%',height:250,position:'absolute',opacity:0,cursor:'pointer', }} type="file" name="exterior" id="exterior"  onChange={handleimg9} />
      {imgchanged9?
      <>

         {compulsory_insurance.name.includes('pdf')?
     <img
     style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
     className="form-control"
     src={require('../pdf.png')}
 />
      :
        <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={ URL.createObjectURL(compulsory_insurance)}
        />
}
      </>
      :
      <>
      {compulsory_insurance?.includes('pdf')?
 <img
 style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
 className="form-control"
 src={require('../pdf.png')}
/>
      :
      <img
            style={{ cursor: 'pointer', objectFit: 'contain', minHeight: 200, maxHeight: 250 }}
            className="form-control"
            src={`${awsUrl}/shoppers/files/` + compulsory_insurance}
        />
      }
      </>
      }
  
      </div>
      {compulsory_insuranceconf==2&&(<input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={compulsory_insurancereason} onChange={(e) => setcompulsory_insurancereason(e.target.value)} />)}

      <div style={{display:'flex',alignItems:'center',marginTop:'10%'}}>
      <Button onClick={()=>{handleSubmit(1)}}  style={{marginRight:5,backgroundColor:'green',borderColor:'green',}}  >Accept</Button>
      <Button onClick={()=>{setisreject(true)}}  style={{backgroundColor:'red',borderColor:'red'}} >Reject</Button>
      <Button onClick={()=>{handleSubmit(3)}}  style={{backgroundColor:'orange',borderColor:'orange',marginLeft:10}} >Open Shopper Edit</Button>

      </div>
   {isreject&&(
    <>
       <input required type="text" className="form-control"  placeholder='Reason' style={{marginTop:5,marginBottom:5}} name="" value={reason} onChange={(e) => setReason(e.target.value)} />

             <Button  onClick={()=>{handleSubmit(2)}}  style={{backgroundColor:'red',borderColor:'red'}} disabled={reason==''?true:false}>Submit</Button>

    </>
   )}

        </>

        :
        <>
        <p>Application not submitted Yet</p>
        </>
        }
     
     
</>
}
    </div>
  )
}
