import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';
import IncentiveDetails from './IncentiveDetails';

export default function Incentives() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [Errors, setErrors] = useState([]);
    const [error, setError] = useState(false);
    const [incentive, setIncentives] = useState([]);
    const today1 = new Date();
    const [currentDate, setCurrentDate] = useState(new Date());
    const defaultEndDate1 = new Date(today1);
    defaultEndDate1.setDate(today1.getDate());
    const [startDate1, setStartDate1] = useState(defaultEndDate1);
    const today = new Date();
  const defaultEndDate = new Date(today);
  defaultEndDate.setDate(today.getDate() + 10);

  const [endDate, setEndDate] = useState(defaultEndDate);
    const getWeekRange = (date) => {
      const startOfWeek = new Date(date);
     
      startOfWeek.setDate(date.getDate() - date.getDay()+3);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
     
      return `${formatDatee(startOfWeek)} - ${formatDatee(endOfWeek)}`;
    };
  
    const formatDatee = (date) => {
      const options = { day: '2-digit', month: 'short' };
      return date.toLocaleDateString('en-US', options);
    };
    const formatDatee1 = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    useEffect(()=>{
      const startOfWeek = new Date(currentDate);
     
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 3);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
   
      GetIncentive(formatDatee1(startOfWeek),formatDatee1(endOfWeek))
      //  setStartDate1(formatDate1(startOfWeek))
      // setEndDate(formatDate1(endOfWeek))
      // setRerender(!true)
    },[currentDate])
    const handlePreviousWeek = () => {
      const previousWeek = new Date(currentDate);
      previousWeek.setDate(currentDate.getDate() - 7);
      setCurrentDate(previousWeek);
    };
  
    const handleNextWeek = () => {
      const nextWeek = new Date(currentDate);
      nextWeek.setDate(currentDate.getDate() + 7);
      setCurrentDate(nextWeek);
    };
   
    const GetIncentive = async (x,y) => {
        const {status, data, msg} = await sendData(`/incentives`,JSON.stringify({from:x,to:y}));
        if(status){ 
            const groupByDate = (data) => {
                return data.reduce((acc, item) => {
                  const date = item.from.split(' ')[0]; // Extract the date part from the 'from' field
                  if (!acc[date]) {
                    acc[date] = [];
                  }
                  acc[date].push(item);
                  return acc;
                }, {});
              };
              
              const groupedData = groupByDate(data.data.multi);
              
              const formattedResponse = Object.keys(groupedData).map(date => ({
                date,
                data: groupedData[date]
              }));
              console.log({formattedResponse})
            setIncentives(formattedResponse)
            setLoading(false)
        }
      }
  return (
    <div style={{marginTop:'5%',marginLeft:'1%',overflow:'scroll',height:'95vh',paddingBottom:50}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>:
<>

         {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
        <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Incentives</p>
        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={()=>navigate(`/addincentive`)}>Add Incentive</Button>

   </div> 
<div style={{marginTop:'5%',backgroundColor:'#f1f1f1',height:'100%'}}>
<div className="container">
    <div className="innerContainer">
      <button onClick={handlePreviousWeek} className="arrowButton">
      <img src={'./arrowdown.png'} alt='trakitrak shoppers logo' 
            className={`arrowleft`}
           style={{height:25,width:25, objectFit:'contain', }} />      </button>
      <div className="divider" />
      <p className="weekText">{getWeekRange(currentDate)}</p>
      <div className="divider" />
      <button onClick={handleNextWeek} className="arrowButton">
      <img src={'./arrowdown.png'} alt='trakitrak shoppers logo' 
            className={`arrowright`}
           style={{height:25,width:25, objectFit:'contain', }} />
      </button>
    </div>
  </div>
     {incentive.length>0?
  <div style={{marginTop:5}}>
<>
{incentive.map((item,i)=>(
    <>
   <p style={{color:'black',marginTop:'1%'}}>{item.date}</p>
   {item.data.map((itemm,k)=>
{
    console.log({itemm})
    const fromDate = new Date(itemm.from);
    const toDate = new Date(itemm.to);
    const differenceInMilliseconds = toDate - fromDate;

 // Convert the difference from milliseconds to hours
 const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
 const options = { hour: 'numeric', minute: 'numeric', hour12: true };
 const formattedFromTime = fromDate.toLocaleTimeString('en-US', options);
 const formattedToTime = toDate.toLocaleTimeString('en-US', options);
 const dateOptions = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
 const formattedFromDate = fromDate.toLocaleDateString('en-US', dateOptions);
 const formattedToDate = toDate.toLocaleDateString('en-US', dateOptions);
   return(
    <>

    {itemm&&(
        <IncentiveDetails item={itemm} formattedFromTime={formattedFromTime} formattedFromDate={formattedFromDate} formattedToDate={formattedToDate} formattedToTime={formattedToTime}  />

    )}
    </>
   )
  }
)}
   </>
))}
</>
    
   {/* {incentive.map((item,i)=>{
     const fromDate = new Date(item.from);
     const toDate = new Date(item.to);
     const differenceInMilliseconds = toDate - fromDate;

  // Convert the difference from milliseconds to hours
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedFromTime = fromDate.toLocaleTimeString('en-US', options);
  const formattedToTime = toDate.toLocaleTimeString('en-US', options);
  const dateOptions = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
  const formattedFromDate = fromDate.toLocaleDateString('en-US', dateOptions);
  const formattedToDate = toDate.toLocaleDateString('en-US', dateOptions);
    return(

<IncentiveDetails/>
    )
   }
   
   
   
    )} */}
      </div>:
      <div>
      <p style={{textAlign:'center',fontSize:22}}>There is no Incentive Yet</p>
      </div>
    
} 
</div>

  
 
</>
}
    </div>
  )
}
