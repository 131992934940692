import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import Editor from "./Editor";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import { stateToHTML } from "draft-js-export-html";
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import ShopperOverview from './ShopperOverview';
import ShopperSessions from './ShopperSessions';
import Shoppermaterial from './Shoppermaterial';
import ShopperFinancial from './ShopperFinancial';
import ShopperOrders from './ShopperOrders';
import ShopperDeposits from './ShopperDeposits';
import ShopperSettings from './ShopperSettings';
import ShopperInfo from './ShopperInfo';
export default function ShopperDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const menuItems = [
      { title: 'Overview', icon: 'icon-overview' },
      { title: 'Personal Information', icon: 'icon-reports' },
      { title: 'Materials', icon: 'icon-reports' },
      { title: 'Sessions', icon: 'icon-sessions' },
      { title: 'Orders', icon: 'icon-orders', highlight: true },
      { title: 'Deposits', icon: 'icon-activity' },
      // { title: 'Booklog', icon: 'icon-booklog' },
      // { title: 'Tasks', icon: 'icon-tasks' },
      { title: 'Financial Information', icon: 'icon-financial' },
      { title: 'Settings', icon: 'icon-settings' }
    ];
    const [isinfo, setIsinfo] = useState(false);
    const [isoverview, setIsOverView] = useState(true);
    const [isOrder, setIsOrder] = useState(false);
    const [isDeposits, setIsDeposits] = useState(false);
    const [isFinancial, setIsFinancial] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [ismaterials, setIsMaterials] = useState(false);
    const [isSessions, setIsSessions] = useState(false);
    const [isDetails, setIsdetails] = useState(false);
    const [isPromotions, setIspromotions] = useState(false);
    const [loading, setLoading] = useState(true);
    const [shopperInfo, setShopperInfo] = useState();
    const GetShopperDetails = async () => {
      const {status, data, msg} = await fetchData(`/shoppers/${id}`);
      if(status){ 
        console.log({shopperDetails:data})
        // filldata(data)
         setShopperInfo(data)
         setLoading(false)
      }
    }
    useEffect(()=>{
      GetShopperDetails();
  },[]);
  return (
    <>
        {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
<div style={{width:'20vw',overflow:'scroll',display:'flex',flexDirection:'column',alignItems:'center',height:'100vh',backgroundColor:'white',paddingTop:'5%'}}>
             <div style={{width:100,height:100,borderRadius:7,display:'flex',position:'relative',justifyContent:'center',alignItems:'center',backgroundColor:'white'}}>
             <img src={shopperInfo.img} style={{width:75,height:75,objectFit:'contain',borderRadius:40}} />
             <span  className="m-0"style={{width:15,position:'absolute',top:20,right:12,height:15,borderRadius:10,backgroundColor:shopperInfo.connected?'green':'red'}}/>
             </div>
             <p className='m-0' style={{fontWeight:'600',textAlign:'center'}}>{shopperInfo.first_name} - {shopperInfo.last_name}</p>
             <p className='m-0' style={{fontSize:16}}>#{shopperInfo.userid}</p>
             <p className='m-0' style={{fontSize:14}}>{shopperInfo.email}</p>
             
             <div style={{width:'100%',height:'5vh'}}/>
             <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
                {menuItems.map((item, index) => (
                  <div onClick={()=>{
                    if(item.title=='Overview'){
                            setIsOverView(true)
                            setIsFinancial(false)
                            setIsOrder(false)
                            setIsSettings(false)
                            setIsSessions(false)
                            setIsMaterials(false)
                            setIsdetails(false)
                            setIspromotions(false)
                            setIsDeposits(false)
                            setIsinfo(false)
                    }
                    if(item.title=='Personal Information'){
                      setIsOverView(false)
                      setIsinfo(true)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setIsSessions(false)
                      setIsMaterials(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsDeposits(false)
              }
                    
                    if(item.title=='Details'){
                      setIsinfo(false)
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setIsSessions(false)
                      setIsMaterials(false)
                      setIsdetails(true)
                      setIspromotions(false)
                      setIsDeposits(false)

              }
                    if(item.title=='Sessions'){
                      setIsinfo(false)
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setIsSessions(true)
                      setIsMaterials(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsDeposits(false)
              }
              if(item.title=='Deposits'){
                setIsinfo(false)
                setIsOverView(false)
                setIsFinancial(false)
                setIsOrder(false)
                setIsSettings(false)
                setIsSessions(false)
                setIsMaterials(false)
                setIsDeposits(true)
                setIsdetails(false)
                setIspromotions(false)
        }
                   if(item.title=='Materials'){
                    setIsinfo(false)
                          setIsOverView(false)
                          setIsFinancial(false)
                          setIsOrder(false)
                          setIsSettings(false)
                          setIsSessions(false)
                          setIsMaterials(true)
                          setIsdetails(false)
                          setIspromotions(false)
                          setIsDeposits(false)
                  }
                    if(item.title=='Orders'){
                      setIsinfo(false)
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(true)
                      setIsSettings(false)
                      setIsSessions(false)
                      setIsMaterials(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsDeposits(false)
                    }
                    if(item.title=='Financial Information'){
                      setIsinfo(false)
                      setIsOverView(false)
                      setIsFinancial(true)
                      setIsOrder(false)
                      setIsSettings(false)
                      setIsSessions(false)
                      setIsMaterials(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsDeposits(false)
                    }
                    if(item.title=='Settings'){
                      setIsinfo(false)
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(true)
                      setIsSessions(false)
                      setIsMaterials(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsDeposits(false)
                    }
                   
                    // 
                  }} key={index} style={{cursor:'pointer',width:'100%',backgroundColor: item.title=='Personal Information'&&isinfo?'#e05b0e':item.title=='Deposits'&&isDeposits?'#e05b0e':item.title=='Promotions'&&isPromotions?'#e05b0e':item.title=='Overview'&&isoverview?'#e05b0e':item.title=='Orders'&&isOrder?'#e05b0e':item.title=='Details'&&isDetails?'#e05b0e':item.title=='Financial Information'&&isFinancial?'#e05b0e':item.title=='Settings'&&isSettings?'#e05b0e':item.title=='Sessions'&&isSessions?'#e05b0e':item.title=='Materials'&&ismaterials?'#e05b0e':'white',display:'flex',alignItems:'center',height:45,}} >
                <div style={{paddingRight:10,paddingLeft:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <svg style={{marginRight:'2%',margin:0}} fill={item.title=='Personal Information'&&isinfo?'white':item.title=='Deposits'&&isDeposits?'white':item.title=='Promotions'&&isPromotions?'white':item.title=='Overview'&&isoverview?'white':item.title=='Orders'&&isOrder?'white':item.title=='Details'&&isDetails?'white':item.title=='Financial Information'&&isFinancial?'white':item.title=='Settings'&&isSettings?'white':item.title=='Sessions'&&isSessions?'white':item.title=='Materials'&&ismaterials?'white':'black'} width="16px" height="16px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z" fill-rule="evenodd"/>
                  </svg>
                  </div>
                    <span className='m-0' style={{color:item.title=='Personal Information'&&isinfo?'white':item.title=='Deposits'&&isDeposits?'white':item.title=='Promotions'&&isPromotions?'white':item.title=='Overview'&&isoverview?'white':item.title=='Orders'&&isOrder?'white':item.title=='Financial Information'&&isFinancial?'white':item.title=='Details'&&isDetails?'white':item.title=='Settings'&&isSettings?'white':item.title=='Sessions'&&isSessions?'white':item.title=='Materials'&&ismaterials?'white':'black',fontWeight:'600',marginRight:10}}>{item.title}</span>
                  </div>
                ))}
              </div>
        </div>
<div style={{width:'80vw',padding:'5% 1% 3% 1%',overflow:'scroll',height:'100vh',backgroundColor:'#f9f9f9'}}>
{isoverview?
<>
<ShopperOverview  shopperInfo={shopperInfo} userid={id}/>
</>
:
isSessions?
<>
<ShopperSessions shopperInfo={shopperInfo} userid={id}/>
</>:
ismaterials?
<>
<Shoppermaterial userid={id}/>
</>:
isFinancial?
<>
<ShopperFinancial userid={id}/>
</>
:
isOrder?
<>
<ShopperOrders userid={id}/>
</>:
isDeposits?
<>
<ShopperDeposits userid={id}/>
</>:
isSettings?
<>
<ShopperSettings shopperInfo={shopperInfo} GetShopperDetails={GetShopperDetails} userid={id}/>
</>:
isinfo?
<>
<ShopperInfo userid={id}/>
</>
:null}
</div>
</div>
</>
}
    </>
  )
}
