import React, { useMemo ,useState,useEffect} from 'react';
import {
   ExpandedState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getExpandedRowModel,
    getFilteredRowModel,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
  import { FaTrash, FaChevronRight, FaChevronDown } from 'react-icons/fa';
  import { fetchData, generateTemplate, sendData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
export default function ProductCat() {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span> Category Id</span>,
          footer: info => info.column.id,
        }),
    
           columnHelper.accessor('name', {
            id: 'name',
            cell: info => <div 
              className="hover-underline"style={{ paddingLeft: `${info.row.depth * 2}rem`,display:'flex',paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>
                 {info.row.getCanExpand() ? (
                    <div  {...{
                      onClick: info.row.getToggleExpandedHandler(),
                      style: { cursor: 'pointer',marginRight:10,justifyContent:'center',display:'flex',alignItems:'center',width:20,height:20,backgroundColor:'white',border:'1px solid #f1f1f1',borderRadius:5},
                    }}
                      
                    >
                      <p className='m-0' style={{fontSize:12}}>{info.row.getIsExpanded() ? '-' : '+'}</p>
                    </div>
                  ) : (
                    ''
                  )}
                
               <p className='m-0'  style={{marginLeft:10}}  
               onClick={()=>{
                if(info.row.depth==0) navigate(`/edit-cat/${info.row.original.id}`)
                if(info.row.depth==1) navigate(`/edit-subcat/${info.row.original.id}`)
                if(info.row.depth==2) navigate(`/edit-subsubcat/${info.row.original.id}`)

              }}
               >{info.getValue()}</p>
               
                </div>,
             header: () =><span>Category Name</span>,
             footer: info => info.column.id,
           }),
          
           columnHelper.accessor('actions', {
            id: 'actions',
            header: 'Actions',
            cell: info => (
                <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
                 
                  <div  style={{display:'flex',alignItems:'center'}} onClick={()=>{
                            if(info.row.depth==0) {setId(info.row.original.id)
                              setType(1)
                            setShow1(true)}
                            if(info.row.depth==1) {setId(info.row.original.id)
                              setType(2)
                            setShow2(true)}
                            if(info.row.depth==2) {setId(info.row.original.id)
                              setType(3)
                            setShow3(true)}
                      // setShow1(true)
                       //setStoreCatId(info.row.original.id)
                       
                  }} className='buttondelete' >
                
                  <FaTrash size={15}  style={{marginRight:5}}/>
                  <p className='m-0'>Deactivate</p>
                  </div>
                </div>
              ),
          }),
           
         
    ]
    const [expanded, setExpanded] = React.useState(false)
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const [storeCatId, setStoreCatId] = useState();
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState();
    const [type, setType] = useState()
    
    const filteredData = useMemo(() => {
      if (searchInput) {
        return data.filter(
          item =>
            item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            (item.subcats && item.subcats.some(sub => sub.name.toLowerCase().includes(searchInput.toLowerCase()))) ||
            (item.subsubs && item.subsubs.some(sub => sub.name.toLowerCase().includes(searchInput.toLowerCase()))) 

        );
      }
      return data;
    }, [searchInput, data]);
    const GetCat = async ()=>{
        const {status, data, msg} = await fetchData(`/categories`);
        if(status){
            setData(data)
             setLoading(false)
         }
    }
    useEffect(()=>{
      GetCat()
    },[])
    const table = useReactTable({
      data: filteredData,
             state: {
          expanded,
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      columns: columns.filter(column => visibleColumns.includes(column.id)),
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      onRowSelectionChange: setRowSelection,
      onExpandedChange: setExpanded,
      getFilteredRowModel: getFilteredRowModel(),
      getSubRows: (row) => row.subcats || row.subsubs,
      enableRowSelection: true,
    });
  
      
      const Deletcat = async() =>{
        const {status, data, msg} = await sendData('/categories/deactivate', JSON.stringify({id, type}));
        if(status){
          await  generateTemplate()
          GetCat()
        }else{
          setLoading(false);
        }
        //  /stores/status/edit
        //   type ya 1(accept) ya 2(reject)
      }
     
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
<Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Deactivate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Deactivate this Category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button  variant="primary" onClick={()=>{   setShow1(false) 
            Deletcat(storeCatId)}} >
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
<Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Deactivate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Deactivate this Sub Category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button  variant="primary" onClick={()=>{   setShow2(false) 
            Deletcat()}} >
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
<Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Deactivate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Deactivate this Sub Sub Category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button  variant="primary" onClick={()=>{   setShow3(false) 
            Deletcat()}} >
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
   <div style={{width:'96%',marginLeft:'1%'}}>
   <input
  type="text"
  className='form-control'
   value={searchInput}
   onChange={e => setSearchInput(e.target.value)}
  placeholder="Search by category name..."
/>
    </div>
  <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <p>Manage Categories </p>
      <div style={{display:'flex',alignItems:'center'}}>
      <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-cat')}>Add Category</Button>
      <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-subcat')}>Add Sub Category</Button>
      <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-subsubcat')}>Add Sub Sub Category</Button>

        </div>
 </div>
<div style={{marginLeft:'1.5%'}} className="table-container">
  <table className="table"> 

    <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
            
          {headerGroup.headers.map(header => (
            <th key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                   {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
            </th>
          ))}
        </tr>
      ))}
    </thead>

    <tbody>
      {table.getRowModel().rows.map(row => (
        <tr  key={row.id}>
       
          {row.getVisibleCells().map(cell => (
            <td className='noborder' key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
 
  </table>
</div>

</div>
}
  </div>
  )
}
