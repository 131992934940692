import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';

export default function AddBrand() {
    const navigate = useNavigate();
    const [name, setName] = useState('');

    const handleAdd = () => {
        if (validateFields()) {
            AddBrand()
        } else {
          console.log('Validation failed');
        }
      };
      const AddBrand = async () => {
        const {status, data, msg} = await sendData('/brands/add', JSON.stringify({name}));
        if(status){
         navigate('/brands')
        }
      }
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>
    <label className="form-label mt-4">Brand Name</label>
      <input required type="text" className="form-control"  name="slogan" value={name} 
      onChange={(e) => {setName(e.target.value)}}
      />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleAdd}>Add</Button>

  </div>
  )
}
