import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';

export default function EditStoreCashier() {
    const navigate =  useNavigate()
    const {id} = useParams();
    const [cashier, setCashier] = useState();
    const EditStoreCashierBYBranchid = async () => {
        const {status, data, msg} = await sendData('/updateCashierNumber', JSON.stringify({branch_id:id,cashier_num:cashier}));
        if(status){
         navigate(-1)
        }
      }

      const GetStoreCashierByBranchid = async ()=>{
        const {status, data, msg} = await fetchData(`/getCashierNumberByBranch/${id}`);
        if(status){
            setCashier(data.cashier_number)
         }
    }
    useEffect(()=>{
        GetStoreCashierByBranchid()
    },[])
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>

       <label className="form-label mt-4">Store Cashier</label>
      <input  min={0} max={50}  type="number" className="form-control"  name="slogan" value={cashier} 
      onChange={(e) => {setCashier(e.target.value?Number(e.target.value):'')}}
      />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={EditStoreCashierBYBranchid}>Save</Button>

  </div>
  )
}
