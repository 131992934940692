import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData,sendData } from '../utils';
import { FaAngleRight, FaClock } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';

export default function Shoppermaterial(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [naylon_s, setNaylon_s] = useState(1);
     const [naylon_m, setNaylon_m] = useState(1);
     const [naylon_l, setNaylon_l] = useState(1);
     const [paper_l, setPaper_l] = useState(1);
    const [bags, setBags] = useState([]);
    const [bagss, setBagss] = useState([]);
    const [show, setShow] = useState(false);
    const [request, setRequest] = useState(false);
    const handleClose = () => {setShow(false)}
    const fetchMaterial= async ()=>{
        const {status, data, msg} = await fetchData(`/shoppers/materials/${props.userid}`);
              if(status){
               setRequest(data.outfits_waiting==1?true:false)
                 setBags(data.bags)
                 setBagss(data.bags)
                 setLoading(false)
              }else{
                console.log({msg})
              }
        }
    useEffect(()=>{
        fetchMaterial();
  },[]);
  const handleQuantityChange = (newQuantity, index) => {
   // Create a new array with the updated item
   const updatedBags = bagss.map((item, i) => {
     if (i === index) {
       // Update the quantity of the item at the given index
       return { ...item, quantity: newQuantity };
     }
     return item;
   });

   // Update the state with the new array
   setBagss(updatedBags);
 };
 const editmaterial = async() =>{
    setLoading(true)
    handleClose()
   const {status, data, msg} = await sendData('/shoppers/materials/edit', JSON.stringify({userid:props.userid,bags:bagss}));
   if(status){
      setLoading(false)
      fetchMaterial()
    handleClose()
   }else{
      handleClose()
   
   }

 }
 const accept = async() =>{
   setLoading(true)
  const {status, data, msg} = await sendData('/shoppers/tshirtaccept', JSON.stringify({userid:props.userid}));
  if(status){
     setLoading(false)
     fetchMaterial()

  }else{

  
  }

}
  return (
    <div >
        {loading?
   
        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<Modal scrollable      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show} onHide={handleClose}>
   <Modal.Header style={{justifyContent:'space-between'}}>
        <Modal.Title>Bags</Modal.Title>
       <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
       <Button onClick={()=>editmaterial()}>Save</Button>
        <Button style={{marginLeft:10}} onClick={handleClose}>Close</Button>
       </div>
       
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'#f1f1f1'}}>
      {bagss.map((item,i)=>(
      <div style={{width:'100%',display:'flex',alignItems:'center',height:100,backgroundColor:'white',marginBottom:'2%'}}> 
          <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
             <div style={{width:75,height:75,backgroundColor:'white',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:7,border:'1px solid #d1d1d1'}}>
                <img src={require('../Bolsas.png')} style={{objectFit:'contain',width:50,height:50}}/>
             </div>
          </div>
          <div style={{width:'50%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p className='m-0' style={{fontSize:18,fontWeight:'600',textTransform:'capitalize'}}>{item.bag_type_size.type.name}</p>
             <p className='m-0' style={{fontSize:16,fontWeight:'400',textTransform:'capitalize'}}>{item.bag_type_size.size.name}</p>
          </div>
          <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
              <input style={{width:'50%',marginLeft:'25%'}} placeholder={item.quantity} className='form-control' onChange={(e) => handleQuantityChange(e.target.value, i)}
 value={item.quantity}/>

          </div>
      </div>
    ))}
      </Modal.Body>


</Modal>
      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <p  style={{fontSize:20,fontWeight:'600',marginLeft:'2%',textTransform:'capitalize'}}>Bags</p>
       <Button onClick={()=>{setShow(true)}} >Add Bags</Button>
      </div>
    {bags.map((item,i)=>(
      <div style={{width:'100%',display:'flex',alignItems:'center',height:100,backgroundColor:'white',marginBottom:'2%'}}> 
          <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
             <div style={{width:75,height:75,backgroundColor:'white',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:7,border:'1px solid #d1d1d1'}}>
                <img src={require('../Bolsas.png')} style={{objectFit:'contain',width:50,height:50}}/>
             </div>
          </div>
          <div style={{width:'50%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p className='m-0' style={{fontSize:18,fontWeight:'600',textTransform:'capitalize'}}>{item.bag_type_size.type.name}</p>
             <p className='m-0' style={{fontSize:16,fontWeight:'400',textTransform:'capitalize'}}>{item.bag_type_size.size.name}</p>
          </div>
          <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p className='m-0' style={{fontSize:18,fontWeight:'600',color:item.quantity>2?'green':'red',textTransform:'capitalize'}}>{item.quantity}</p>

          </div>
      </div>
    ))}
       <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <p  style={{fontSize:20,fontWeight:'600',marginLeft:'2%',textTransform:'capitalize'}}>Outfit</p>
         {request&&(
             <Button onClick={()=>{accept()}} >Accept request</Button>
         )}
      
      </div>
        <div style={{width:'100%',display:'flex',alignItems:'center',height:100,backgroundColor:'white',marginBottom:'2%'}}> 
          <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
             <div style={{width:75,height:75,backgroundColor:'white',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:7,border:'1px solid #d1d1d1'}}>
                <img src={require('../tshirt.png')} style={{objectFit:'contain',width:50,height:50}}/>
             </div>
          </div>
          <div style={{width:'50%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p className='m-0' style={{fontSize:18,fontWeight:'600',textTransform:'capitalize'}}>Outfit</p>
          </div>
          <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>

          </div>
      </div>
              </>
            }
    </div>
  )
}
