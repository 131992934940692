import * as React from "react";
const Shopper = (props) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="20.000000pt" height="20.000000pt" viewBox="0 0 1519.000000 1526.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1526.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M4365 15253 c-464 -22 -895 -108 -1343 -269 -695 -250 -1108 -696
-1236 -1334 -41 -207 -45 -294 -47 -1020 -1 -669 -1 -699 19 -789 26 -114 76
-223 142 -306 l50 -63 -51 -88 c-203 -352 -233 -718 -84 -1032 58 -123 114
-199 216 -297 119 -114 274 -211 431 -270 25 -9 56 -27 67 -39 11 -12 117
-212 235 -446 l215 -425 -459 -460 c-487 -489 -510 -515 -510 -607 0 -44 26
-127 71 -227 l34 -74 -461 -456 c-254 -251 -514 -512 -579 -581 -488 -518
-770 -952 -989 -1522 -73 -192 -95 -311 -78 -440 10 -82 19 -107 119 -316 59
-125 338 -689 620 -1254 388 -778 522 -1038 552 -1071 72 -78 122 -95 407
-135 l253 -36 3 -741 3 -740 24 -47 c45 -91 110 -128 227 -128 113 0 187 42
228 130 20 43 21 64 24 413 2 202 6 367 8 367 2 0 91 -87 197 -193 203 -205
347 -322 529 -430 493 -294 1059 -368 1653 -218 261 66 1054 308 2055 626 638
204 786 253 1214 409 175 64 321 116 323 116 3 0 2 -161 -1 -357 -4 -197 -3
-428 2 -515 8 -135 13 -164 35 -213 47 -102 97 -147 183 -165 109 -23 209 33
261 146 l27 59 4 598 c3 328 6 597 8 597 2 0 126 14 276 30 597 66 490 62
1748 70 1254 7 1237 6 1455 61 297 76 521 239 644 470 44 81 88 222 100 319 6
47 14 100 17 118 l5 32 879 0 c589 0 896 4 929 11 70 15 112 46 141 104 l25
50 3 3455 c2 3125 1 3459 -14 3502 -18 55 -58 93 -112 107 -51 13 -3774 21
-5662 13 l-1515 -7 -45 -25 c-50 -28 -84 -73 -102 -134 -9 -30 -12 -262 -13
-898 0 -771 -2 -857 -16 -853 -8 3 -95 21 -194 40 -187 37 -306 72 -365 108
-122 73 -259 197 -495 446 l-203 215 70 85 c333 410 541 919 637 1561 37 250
81 727 81 882 l0 57 43 5 c23 3 112 10 197 16 585 41 936 138 1241 341 320
215 471 524 419 859 -47 294 -294 666 -563 844 -158 105 -339 170 -542 195
-54 7 -285 11 -576 11 -454 0 -487 1 -494 18 -4 9 -17 60 -30 112 -101 410
-273 742 -519 997 -250 260 -537 423 -912 517 -328 83 -783 126 -1139 109z
m3700 -2298 c230 -67 390 -212 512 -463 65 -134 76 -198 48 -283 -21 -63 -89
-135 -161 -172 -224 -114 -563 -227 -764 -256 -89 -13 -463 -41 -469 -35 -6 6
-159 790 -166 854 -9 78 4 103 77 150 89 57 289 154 379 184 187 63 376 70
544 21z m-1417 -877 c62 -413 82 -659 82 -1000 0 -956 -272 -1746 -740 -2154
-310 -270 -706 -407 -1175 -408 -190 0 -277 10 -440 50 -406 101 -773 375
-1044 780 -84 124 -156 271 -256 519 -84 209 -117 268 -178 320 -45 38 -145
75 -206 75 -110 1 -256 64 -323 139 -54 62 -76 107 -104 219 -41 160 -31 293
30 427 56 121 103 161 221 185 46 10 372 13 1415 15 1457 2 1381 0 1559 52
210 61 441 208 611 390 71 75 317 379 430 531 30 39 62 72 70 72 13 0 22 -39
48 -212z m5562 -3497 l0 -660 -107 51 c-60 27 -207 100 -328 161 l-219 112
-82 0 -82 0 -159 -82 c-87 -45 -233 -117 -325 -162 l-168 -80 0 660 0 659 735
0 735 0 0 -659z m-4996 -1172 c39 -12 147 -38 240 -59 124 -28 174 -44 190
-59 l21 -22 3 -1945 2 -1946 -32 -32 c-30 -29 -109 -58 -758 -274 -1281 -425
-1663 -550 -1742 -566 -157 -32 -363 -16 -508 41 -165 65 -351 214 -447 358
-49 74 -285 475 -678 1155 -164 283 -316 541 -338 572 -57 83 -104 112 -187
116 -92 6 -160 -18 -215 -73 -23 -23 -48 -59 -55 -80 -20 -59 -8 -170 24 -233
32 -64 335 -566 501 -832 183 -293 189 -307 160 -406 -47 -161 -184 -373 -345
-535 -169 -170 -343 -278 -560 -349 -161 -53 -247 -65 -445 -64 -204 1 -315
18 -376 59 -43 28 -88 114 -509 975 -579 1183 -632 1294 -643 1355 l-10 60
103 235 c199 456 245 528 585 916 243 277 899 975 1157 1231 l163 162 507
-422 c279 -232 532 -438 564 -457 74 -45 159 -53 222 -21 67 34 120 96 481
555 189 240 344 436 346 434 4 -4 299 -725 319 -781 21 -55 82 -109 178 -158
112 -57 190 -49 256 26 31 35 64 110 187 421 82 209 156 398 164 422 9 23 18
42 22 42 3 0 107 -139 231 -308 125 -170 245 -325 268 -346 58 -52 114 -76
180 -76 137 0 192 61 356 396 67 138 146 302 174 365 78 174 104 190 244 148z
m2576 -1919 c20 -5 56 -23 81 -39 118 -74 132 -271 26 -368 -71 -66 -84 -68
-427 -68 -336 0 -340 1 -413 64 -96 85 -96 262 2 355 61 58 106 65 416 66 154
0 296 -5 315 -10z m519 -756 c135 -40 200 -209 133 -341 -26 -50 -56 -77 -117
-105 l-50 -23 -550 0 -550 0 -50 23 c-84 39 -116 79 -136 172 -27 125 44 242
169 280 15 5 268 9 563 9 457 1 543 -1 588 -15z"/>
</g>
</svg>
);
export default Shopper;

