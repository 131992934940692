import React, { useMemo ,useState,useEffect} from 'react';
import {
   ExpandedState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getExpandedRowModel,
    getFilteredRowModel,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
  import { FaTrash, FaChevronRight, FaChevronDown } from 'react-icons/fa';
  import { fetchData, sendData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';

export default function OrderFaq() {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
 
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span> Faq Id</span>,
          footer: info => info.column.id,
        }),
        // columnHelper.accessor('parentQuestion', {
        //  id: 'parentQuestion',
        //  cell: info => <p className='m-0' style={{paddingRight:50,minWidth:150}}>{info.getValue()}</p>,
        //   header: () =><p className='m-0' style={{paddingRight:50,minWidth:150}}>Parent Question</p>,
        //   footer: info => info.column.id,
        // }),
        columnHelper.accessor('title', {
            id: 'title',
            cell: info => <div 
            className="hover-underline"style={{ paddingLeft: `${info.row.depth * 3}rem`,display:'flex',paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>
               {info.row.getCanExpand() ? (
                  <div  {...{
                    onClick: info.row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer',marginRight:10,justifyContent:'center',display:'flex',alignItems:'center',width:20,height:20,backgroundColor:'white',border:'1px solid #f1f1f1',borderRadius:5},
                  }}
                    
                  >
                    <p className='m-0' style={{fontSize:12}}>{info.row.getIsExpanded() ? '-' : '+'}</p>
                  </div>
                ) : (
                  ''
                )}
              
             <p className='m-0'  style={{marginLeft:10}}  
             onClick={()=>{
              if(info.row.depth==0) navigate(`/edit-cat/${info.row.original.id}`)
              if(info.row.depth==1) navigate(`/edit-subcat/${info.row.original.id}`)
              if(info.row.depth==2) navigate(`/edit-subsubcat/${info.row.original.id}`)

            }}
             >{info.getValue()}</p>
             
              </div>,
             header: () =><span>Question</span>,
             footer: info => info.column.id,
           }),
        
        columnHelper.accessor('answer', {
            id: 'answer',
            cell: info => <p  style={{minWidth:200,maxWidth:350}}>{info.getValue()}</p>,
             header: () =><span>Answer</span>,
             footer: info => info.column.id,
           }),
          //  columnHelper.accessor('sequence', {
          //   id: 'sequence',
          //   cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          //    header: () =><span>sequence</span>,
          //    footer: info => info.column.id,
          //  }),
           
          
           columnHelper.accessor('actions', {
            id: 'actions',
            header: 'Actions',
            cell: info => (
                <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
                 <button className="view-button" onClick={()=>{
                    navigate(`/order-faq/${info.row.original.id}`)
                  }}>Edit</button>
                  <div  style={{display:'flex',alignItems:'center'}} onClick={()=>{
                            if (window.confirm('Are you sure you want to Delete ?')) {
                                DeleteFaq(info.row.original.id);
                              }
                       
                  }} className='buttondelete' >
                
                  <FaTrash size={15}  style={{marginRight:5}}/>
                  <p className='m-0'>Delete</p>
                  </div>
                </div>
              ),
          }),
           
         
    ]
    const [expanded, setExpanded] = React.useState(false)
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const [storeCatId, setStoreCatId] = useState();
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState([]);
    const [flatData, setflatData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState();
    const [type, setType] = useState()
    

    const GetFaq = async ()=>{
        const {status, data, msg} = await fetchData(`/orderfaqs`);
        if(status){
            setData(data)
            const flatQuestions = data.flatMap(group => group.questions);

// Build a map of questions by their ID for quick lookup
          const questionMap = flatQuestions.reduce((acc, question) => {
              acc[question.id] = question;
              return acc;
          }, {});

          // Assign children to their respective parent question
          flatQuestions.forEach(question => {
              if (question.questionid) {
                  const parent = questionMap[question.questionid];
                  if (!parent.questions) {
                      parent.questions = [];
                  }
                  parent.questions.push(question);
              }
          });

          // Filter to get only top-level questions (those without a parent)
          const nestedQuestions = flatQuestions.filter(question => question.questionid === null);

          console.log({nestedQuestions});
          setflatData(nestedQuestions)
            //console.log({data})
            // const flatDatax = data.flatMap(group => 
            //       group.questions.map(question => ({
            //       sequence: group.sequence,
            //       id: question.id,
            //       title: question.title,
            //       questionid:question.questionid,
            //       parentQuestion: question.questionid!=null ? findQuestionById(question.questionid,data) : '',
            //       answer: question.answer,
            //       created_at: question.created_at,
            //       updated_at: question.updated_at
            //     }))
            //   );
            //   console.log({flatDatax})
            //   setflatData(flatDatax)
             
             setLoading(false)
         }
    }
    useEffect(()=>{
        GetFaq()
    },[])
    
    // const table = useReactTable({
    //         data: flatData,
    //          state: {
    //       expanded,
    //       rowSelection, //pass the row selection state back to the table instance
    //     //   //pagination
    //     },
    //   columns: columns.filter(column => visibleColumns.includes(column.id)),
    //   getCoreRowModel: getCoreRowModel(),
    //   getExpandedRowModel: getExpandedRowModel(),
    //   onRowSelectionChange: setRowSelection,
    //   onExpandedChange: setExpanded,
    //   getFilteredRowModel: getFilteredRowModel(),
    //   //getSubRows: (row) => row.subcats || row.subsubs,
    //   enableRowSelection: true,
    // });
    const table = useReactTable({
      data: flatData, // Make sure this is your nested data
      state: {
          expanded,
          rowSelection, // pass the row selection state back to the table instance
      },
      columns: columns.filter(column => visibleColumns.includes(column.id)),
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      onRowSelectionChange: setRowSelection,
      onExpandedChange: setExpanded,
      getFilteredRowModel: getFilteredRowModel(),
      getSubRows: (row) => row.questions || [], // Assuming 'questions' is the array of nested rows
      enableRowSelection: true,
      enableExpanding: true, // Make sure to enable expanding if needed
  });
    const findQuestionById = (id,dataa) => {
        for (const group of dataa) {
          const question = group.questions.find(q => q.id === id);
          if (question) {
            return question.title;  // Return the title of the found question
          }
        }
        return null;
      };
    const DeleteFaq = async(id) =>{
        const {status, data, msg} = await sendData('/orderfaqs/delete', JSON.stringify({id}));
        if(status){
            GetFaq()
        }else{
          setLoading(false);
        }

      }
     
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>

   
  <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <p>Manage Faq </p>
      <div style={{display:'flex',alignItems:'center'}}>
      <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-order-faq')}>Add Faq</Button>
      {/* <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-subcat')}>Add Sub Category</Button>
      <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-subsubcat')}>Add Sub Sub Category</Button> */}

        </div>
 </div>
<div style={{marginLeft:'1.5%'}} className="table-container">
  <table className="table"> 

    <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
            
          {headerGroup.headers.map(header => (
            <th key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                   {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
            </th>
          ))}
        </tr>
      ))}
    </thead>

    <tbody>
      {table.getRowModel().rows.map(row => (
        <tr  key={row.id}>
       
          {row.getVisibleCells().map(cell => (
            <td className='noborder' key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
 
  </table>
</div>

</div>
}
  </div>
  )
}
