import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';

export default function StoreCatDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const GetStoreCatDetails = async ()=>{
        const {status, data, msg} = await fetchData(`/storecategories/${id}`);
        if(status){
            setName(data.name);
            setLoading(false)
         }
    }
    const updateStorecat = async () => {
        setLoading(true)
        const {status, data, msg} = await sendData('/storecategories/edit', JSON.stringify({name,id}));
        if(status){
            navigate('/store-cat')
        }
      }
    useEffect(()=>{
        GetStoreCatDetails()
    },[])
    const handleEdit = () => {
        if (validateFields()) {
            updateStorecat()
        } else {
          console.log('Validation failed');
        }
      };
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>
           {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<label className="form-label mt-4">Bank Name</label>
        <input required type="text" className="form-control"  name="slogan" value={name} 
        onChange={(e) => {setName(e.target.value)}}
        />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleEdit}>Save</Button>

</>
}

      

        
    </div>
  )
}
