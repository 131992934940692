import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, generateTemplate, sendData } from '../utils';
import { Button } from 'react-bootstrap';
import { Alert,Modal } from 'react-bootstrap';

export default function AddSubCat() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [data, setData] = useState([]);
    const [catid, setCatid] = useState();
    const [loading, setLoading] = useState(true)
    const [words, setWords] = useState([]);
    const [currentInput, setCurrentInput] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [showw, setShoww] = useState(false);
    const handleClosee =()=>{setShoww(false)}
  
  
    // Function to remove a tag
    const removeTagg = (index) => {
      setWords(words.filter((_, i) => i !== index));
    };
    const handleAdd = () => {
        if (validateFields()) {
            AddCatt()
        } else {
          console.log('Validation failed');
        }
      };
      const AddCatt = async () => {
        setLoading(true)
        const {status, data, msg} = await sendData('/categories/add', JSON.stringify({name,type:2,catid:catid,tags:words}));
        if(status){
          await  generateTemplate()
            setLoading(false)
         navigate('/product-cat')
        }
      }
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
      const GetCat = async ()=>{
        const {status, data, msg} = await fetchData(`/categories/dropdown`);
        if(status){
            setData(data)
            setCatid(data[0].id)
            setLoading(false)
         }
    }
    useEffect(()=>{
      GetCat()
    },[])
    const handleInputChange = (event) => {
      setCurrentInput(event.target.value);
    };
  
    // Function to add the current input to the array of words
const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        setWords([...words, currentInput]);
        setCurrentInput(''); // Clear the input field
      }
    };
  return (
    <div style={{marginTop:'5%'}}>
           {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<label style={{marginLeft:10}} className="form-label mt-4">Category <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={catid} onChange={(e)=>setCatid(e.target.value)} required name="code" className="form-control"  >
{data.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}

</select>

</div>
<div style={{marginLeft:10}} className="d-flex w-75 mt-4 justify-content-between align-items-center">
            <label className="form-label">Tags </label>   
           </div>
           <input style={{marginLeft:10}}   type="text" value={currentInput} onChange={handleInputChange}  onKeyDown={handleKeyDown} className="form-control w-75"  placeholder="Sub Category tags"/>
           {words.length > 0 && (
            //  <div className="form-control w-75"  style={{display:'flex',marginTop:10,flexDirection:'row',alignItems:'center'}}>
            <div className="form-control w-75" style={{
              display: 'flex',
              marginTop: 10,
              flexWrap: 'wrap', // Enable wrapping
              flexDirection: 'row',
              alignItems: 'center',
              gap: '15px' // This will ensure spacing between tags, removing the need for marginLeft condition in each tag
          }}>
            <div style={{display:'flex',cursor:'pointer',backgroundColor:'#DC143C',padding:7,borderRadius:5,alignItems:'center'}} 
            onClick={()=>{
             
              setShoww(true)
            }}>
              <p style={{margin:0,marginRight:5,color:'white'}}>Clear</p>
             <svg style={{cursor:'pointer'}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="22.000000pt" height="22.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M504 5110 c-226 -33 -411 -197 -481 -425 -17 -57 -18 -148 -18 -2125
0 -1977 1 -2068 18 -2125 62 -202 210 -350 412 -412 57 -17 148 -18 2125 -18
1977 0 2068 1 2125 18 202 62 350 210 412 412 17 57 18 148 18 2125 0 1977 -1
2068 -18 2125 -62 200 -203 343 -407 412 -53 17 -140 18 -2095 19 -1122 1
-2063 -2 -2091 -6z m1330 -1552 c35 -10 95 -65 384 -352 l342 -340 343 340
c355 354 368 364 448 364 111 0 219 -108 219 -219 0 -80 -10 -93 -364 -448
l-340 -343 340 -343 c354 -355 364 -368 364 -448 0 -111 -108 -219 -219 -219
-80 0 -93 10 -448 364 l-343 340 -342 -340 c-356 -354 -369 -364 -449 -364
              -111 0 -219 108 -219 219 0 80 10 93 364 449 l340 342 -340 343 c-354 355
              -364 368 -364 448 0 108 107 217 214 219 16 0 47 -5 70 -12z"/>
              </g>
              </svg>
            </div>
              {words.map((word, index) => (
              <div style={{marginLeft:index==0?0:15,paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,position:'relative',borderRadius:5,backgroundColor:'#f1f1f1',display:'flex',alignItems:'center'}}>
                <p key={index} style={{marginTop:0,marginBottom:0,color:'black'}}>{word}</p>
                <div onClick={() => removeTagg(index)} style={{backgroundColor:'gray',cursor:"pointer",position:'absolute',top:-6,right:-6,padding:3,width:20,height:20,borderRadius:10,justifyContent:'center',alignItems:'center',display:'flex',color:'white'}}>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="white" stroke="none">
            <path d="M1017 4072 c-110 -110 -177 -185 -177 -197 0 -13 230 -250 662 -682
            l663 -663 -663 -663 c-428 -428 -662 -669 -662 -682 0 -27 348 -375 375 -375
            13 0 246 226 682 662 l663 663 663 -663 c486 -486 668 -662 685 -662 33 0 372
            337 372 370 0 19 -146 171 -662 687 l-663 663 663 663 c436 436 662 669 662
            682 0 27 -348 375 -375 375 -13 0 -254 -234 -682 -662 l-663 -663 -663 663
            c-433 433 -669 662 -682 662 -13 0 -86 -66 -198 -178z"/>
            </g>
            </svg>
                  </div>
               </div>
              ))}
          </div>
      )}
<div style={{margin:'5px 10px 10px 10px'}} >
<label className="form-label mt-4">Sub Category Name</label>
        <input required type="text" className="form-control"  name="slogan" value={name} 
        onChange={(e) => {setName(e.target.value)}}
        />
                <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleAdd}>Add Sub Category</Button>
                <Modal show={showw} onHide={handleClosee}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to clear?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosee}>
          Cancel
        </Button>
        <Button variant="danger" onClick={()=>{setWords([])
        setShoww(false)
        }}>
          Clear
        </Button>
      </Modal.Footer>
    </Modal>
        </div>
</>
}

  

    </div>
  )
}
