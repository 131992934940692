import React,{useEffect,useState} from 'react'
import { fetchData, sendData } from '../../utils';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import { FaRegTrashAlt, FaTrash } from 'react-icons/fa';


export default function Promotions() {
  const navigate =  useNavigate()
  const columnHelper = createColumnHelper()
  const columns = [
    columnHelper.accessor('id', {
       id: 'id',
       cell: info => <i onClick={()=>navigate(`/promotion/${info.getValue()}`)} style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
        header: () =><p className='m-0' style={{minWidth:200}}>Promotion Id</p>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => {
        // Assuming 'row.to' contains the expiration date as a string
        const expirationDate = new Date(row.to);
        const currentDate = new Date();
        return currentDate > expirationDate ? 'Expired' : 'Active';
      }, {
        id: 'isExpired',
        cell: info => <p style={{paddingRight:50,color:info.getValue()=='Expired'?'red':'green'}}>{info.getValue()}</p>,
        header: () => <p className='m-0' style={{minWidth:200}}>Is Expired</p>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info => <i onClick={()=>navigate(`/promotion/${info.row.original.id}`)} style={{paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()==0?'Deactivated':'Active'}</i>,
         header: () =><p className='m-0' style={{minWidth:200}}>Promotion Status</p>,
         footer: info => info.column.id,
       }),
         columnHelper.accessor('name', {
          id: 'name',
          cell: info => <i onClick={()=>navigate(`/promotion/${info.row.original.id}`)} style={{paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()}</i>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Name (1)</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('namex', {
          id: 'namex',
          cell: info => <i onClick={()=>navigate(`/promotion/${info.row.original.id}`)} style={{paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()}</i>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Name (2)</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('des', {
          id: 'des',
          cell: info => <p onClick={()=>navigate(`/promotion/${info.row.original.id}`)} style={{minWidth:500,paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()}</p>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Description</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('typ', {
          id: 'typ',
          cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Type</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('from', {
          id: 'from',
          cell: info => <p style={{minWidth:200}}>{String(info.getValue()).substring(0,10)}</p>,
           header: () => <p style={{minWidth:200}}>From</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('to', {
          id: 'to',
          cell: info => <p style={{minWidth:200}}>{String(info.getValue()).substring(0,10)}</p>,
           header: () => <p style={{minWidth:200}}>To</p>,
           footer: info => info.column.id,
         }),
     
         columnHelper.accessor('perc', {
          id: 'perc',
          cell: info => <i style={{paddingRight:50}}>{info.getValue()}%</i>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Percentage</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('prixmax', {
          id: 'prixmax',
          cell: info => <i style={{paddingRight:50}}>{info.getValue()}$</i>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Max Price</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('color', {
          id: 'color',
          cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
           header: () =><p className='m-0' style={{minWidth:300}}>Promotion logo Background color</p>,
           footer: info => info.column.id,
         }),
        
         columnHelper.accessor('color2', {
          id: 'color2',
          cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><p className='m-0' style={{minWidth:300}}>Promotion Banner Background color</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('img', {
          id: 'img',
           cell: info => <div style={{borderRadius:7,backgroundColor:info.row.original.color,width:50,height:50,display:'flex',alignItems:'center',justifyContent:'center'}}> <img src={info.getValue()} style={{width:40,height:40,objectFit:'contain'}} /></div>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Logo</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('img2', {
          id: 'img2',
           cell: info => <div style={{borderRadius:7,backgroundColor:info.row.original.color2,width:120,height:50,display:'flex',alignItems:'center',justifyContent:'center'}}> <img src={info.getValue()} style={{width:'100%',height:'100%',borderRadius:7,objectFit:'contain'}} /></div>,
           header: () =><p className='m-0' style={{minWidth:200}}>Promotion Banner</p>,
           footer: info => info.column.id,
         }),
        
         columnHelper.accessor('actions', {
          id: 'actions',
          header: 'Actions',
          cell: info => (
              <div className="action-buttons" style={{display:'flex',alignItems:'center',paddingRight:50}}>
                <button style={{minWidth:125}} className="view-button" onClick={()=>{
                  navigate(`/promotion-products/${info.row.original.id}`)
                }}>Add Products</button>
                <button className="view-button" onClick={()=>{
                  navigate(`/promotion-branches/${info.row.original.id}`)
                }}>Branches</button>
        
                <div  style={{display:'flex',alignItems:'center'}} onClick={()=>{
                  DeactivatePromotions(info.row.original.id,info.row.original.status)
                    // setShow1(true)
                    // setBankid(info.row.original.id)
                }} className='buttondelete' >
              
                <FaTrash size={15}  style={{marginRight:5}}/>
                <p className='m-0'>{info.row.original.status==1?'Deactivate':'Activate'}</p>
                </div>
              
              </div>
            ),
        }),
         
       
  ]
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow1(true);
  const [bankid, setBankid] = useState();
  const [data, setData] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
  const [rowSelection, setRowSelection] = useState({});
  const [loading, setLoading] = useState(true);
  const table = useReactTable({
      data,
      columns: columns.filter(column => visibleColumns.includes(column.id)),
      getCoreRowModel: getCoreRowModel(),
      onRowSelectionChange: setRowSelection,
      //onPaginationChange: setPagination, 
      enableRowSelection:true, //hoist up the row selection state to your own scope
      state: {
        rowSelection, //pass the row selection state back to the table instance
      //   //pagination
      },
    });
    const GetPromotions = async ()=>{
        const {status, data, msg} = await fetchData(`/promotions`);
        if(status){
             setData(data)
             setLoading(false)
         }
    }
    useEffect(()=>{
        GetPromotions()
    },[])
    const DeactivatePromotions = async (id,statuss) => {
      setLoading(true)
      const {status, data, msg} = await sendData('/promotions/status', JSON.stringify({
       id:id,
       status:statuss==1?0:1
      }));
      if(status){
        GetPromotions()
      }
    }
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
      {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
    <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Promotions</p>
        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={()=>navigate('/add-promotion')}>Add Promotion</Button>

   </div>
<div style={{marginLeft:'1.5%'}} className="table-container">
    <table className="table"> 

      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                {/* <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th> */}
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            {/* <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td> */}
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
 {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Deactivate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Deactivate this Bank?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button  variant="primary" onClick={()=>{   setShow1(false) 
            DeleteBank(bankid)}} >
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal> */}
</div>
}
    </div>
  )
}
