import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Alert, Button } from 'react-bootstrap';
import Select, { components, createFilter } from "react-select"
import makeAnimated from "react-select/animated";

export default function AddMainPromotion(props) {
    const {id} =useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [branches, setBranches] = useState([]);
    const [short_name, setName] = useState('');
    const [long_name, setNamex] = useState('');
    const [from, setFrom] = useState('');
    const [branchid, setBranchid] = useState();
    const [to, setTo] = useState('');
    const [file, setFile] = useState('');
    const [file2, setFile2] = useState('');
    const [file3, setFile3] = useState('');
    const [color, setColor] = useState('#000000');
    const [color2, setColor2] = useState('#000000');
    const [typ, setTyp] = useState('');
    const [perc, setPerc] = useState('');
    const [description, setDes] = useState('');
    const [prixmax, setPrixmax] = useState('');
    const [Errors, setErrors] = useState([]);
    const [error, setError] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [image_align, setImageAlign] = useState();
    const [priority, setPriority] = useState(1);
    const [prom, setProms] = useState([]);
    const [selectedPromIds, setSelectedPromIds] = useState([]);
   useEffect(()=>{
    GetStoreBranches()
   },[])
   const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
    const GetStoreBranches = async () => {
        const {status, data, msg} = await fetchData(`/stores/${id}/branches`);
        if(status){ 
            setBranches(data)
            setLoading(false)
        }
      }
    const handleCheckBoxChange = (promid, isChecked) => {
      if (isChecked) {
       setSelectedPromIds([...selectedPromIds, promid]);
      } else {
       setSelectedPromIds(selectedPromIds.filter(id => id !== promid));
      }
    };
  
   
    const AddBank = async () => {
   
       const {status, data, msg} = await sendData('/promotions/add', JSON.stringify({
           name,
           namex,
           from,
           to,
           file,
       }));
       if(status){
        navigate('/banks')
       }
     }
   
     const validateFields = () => {
       if (!name.trim()) {
         alert('Name cannot be empty.');
         return false;
       }
       return true;
     };
     const handleSelectChange = (event) => {
       const selectedValue = event.target.value;
       setBranchid(selectedValue)
       GetPromotionByBranch(selectedValue)
     };
     const handleSelectChange1 = (event) => {
      const selectedValue = event.target.value;
      setImageAlign(selectedValue)
    };
     const isFormValid = () => {
       let x=[]
   
    // Validate string fields
    if (!short_name) x.push('Short Name is required');
    if (!long_name) x.push('Long Name is required');
    if (!description) x.push('Description is required');
    if (!from) x.push('from is required');
    if (!to) x.push('to is required');
    if (!file) x.push('Logo is required');
    if (!file2) x.push('Banner is required');
    if (!file3) x.push('Second Banner is required');
    if (!color) x.push('Color is required');
    if (!image_align) x.push('Image Alignment is required');
    if (!priority) x.push('Priority is required');
    if (!branchid) x.push('Branch is required');
    if (!selectedPromIds) x.push('Promotion is required');   
   
    if (x.length > 0) {
      console.log('Validation Errors:', x);
      setErrors(x)
      return false;
    }
   if(x.length==0){
    setErrors([])
    return true;
   }
    // If all validations pass, return true
   
   
   };
   const handleSubmit = async() => {
   setLoading(true)
    setErrors([])
   
   
    if (isFormValid()) {
   
          setError(false)
      try {
   
       
        const formData = new FormData();
         formData.append('short_name', short_name);
         formData.append('long_name', long_name);
         formData.append('description', description);
         formData.append('from', from);
         formData.append('to', to);
         formData.append('image', file);
         formData.append('banner', file2);
         formData.append('second_banner', file3);
         formData.append('color', color);
         formData.append('image_align', image_align);
         formData.append('priority', priority);
         formData.append('branch_id', branchid);
         formData.append('promotion_ids', selectedPromIds);
     
         
        
        
       
   
      const req = await fetch("https://api.trakitrak.com/control/addBranchPromotion", {
        method:'POST',
        body: formData,
   })
   if(req.status==200){
    const json = await req.json()
    console.log(json)
    navigate(-1)
    //navigate(`/store/${str}`, {replace: true});
    setLoading(false)
   
   
   }else{
    const json = await req.json()
    console.log(json) 
    setShowalert(true)
    setMsg(json.message)
     setLoading(false)
   }
      } catch (error) {
        
      }
   
    } else {
     setLoading(false)
        setError(true)
   
    }
   };
   const GetPromotionByBranch = async (x) => {
    if(!x){
      setProms([])
    }else{
      const {status, data, msg} = await fetchData(`/getPromotionsByBranch/${x}`);
      if(status){ 
        setProms(data[0].promotions)
      }
    }
   
  }
     return (
       <div style={{marginTop:'1%',marginLeft:'1%',overflow:'scroll',height:'95vh',paddingBottom:50}}>
       {loading?
   <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
   <div className="spinner-border" role="status">
    <span className="visually-hidden">Loading...</span>
   </div>
   </div>:
   <>
       {showAlerts()}
            {error && (
        <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
            <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <strong className="ms-1">{Errors[0]}</strong>
            </div>
            <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
            </button>
        </div>
        )}
           <label className="form-label mt-4">Short Name</label>
   <input  className="form-control" type="text" id="name" value={short_name} onChange={e => setName(e.target.value)} />
   
   <label className="form-label mt-4">Long Name</label>
   <input  className="form-control" type="text" id="namex" value={long_name} onChange={e => setNamex(e.target.value)} />
   <label className="form-label mt-4">Description</label>
   <input  className="form-control" type="text" id="namex" value={description} onChange={e => setDes(e.target.value)} />
   
   
   <label className="form-label mt-4">From (Date)</label>
   <input  className="form-control"  type="datetime-local" id="from" value={from} onChange={e => setFrom(e.target.value)} />
   
   <label className="form-label mt-4" htmlFor="to">To (Date)</label>
   <input  className="form-control"  type="datetime-local" id="to" value={to}   min={from} onChange={e => setTo(e.target.value)} />
   
   
   
   <label className="form-label mt-4">Logo <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
   <div>
   <div className="bg-primary rounded d-flex p-2">
   <svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
   </svg>
   <div style={{ width: '95%', color: 'white' }}>
     <p>We're going to generate a black and white version of the logo. For this reason, it must meet some requirements:</p>
     <ol>
       <li>It must be in PNG format.</li>
       <li>It must have a transparent background.</li>
       <li>Its width must be at least 1,000 px.</li>
     </ol>
   </div>
   </div>
   
   <div className="d-flex mt-3 rounded my-shadow w-75 m-auto" style={{ position: 'relative' }}>
   <div className="camera-div">
     <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="logo">
       <input required name="logo" id="logo" type="file" accept="image/*" className="d-none" onChange={e => setFile(e.target.files[0])} />
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
         
         <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
       </svg>
     </label>
   </div>
   
   <div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#b2b2b2', width: '50%',  borderTopRightRadius: '0 !important', borderBottomRightRadius: '0 !important' }}>
   
   {file ?(
   
   <div style={{justifyContent:'center',backgroundColor:color,alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
     <img style={{width:'70%',height:'70%',objectFit:'contain',filter: 'brightness(1000%)'}} src={ URL.createObjectURL(file)} alt="Logo Preview" />
   </div>
   ):
   <svg xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">
   
   <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
   </svg>
   }
    
   
   </div>
   
   <div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#f8f8f9', width: '50%',  borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0 !important' }}>
   {file ?(
   <div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
    <img style={{width:'70%',height:'70%',objectFit:'contain',filter: 'brightness(0%)'}} src={URL.createObjectURL(file)} alt="Logo Preview" />
   </div>
   ):
     <svg style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">
       <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
     </svg>
   }
   </div>
   
   </div>
   <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
   <Button style={{marginRight:20}} onClick={()=>{ window.open(URL.createObjectURL(file), '_blank');}}>
     View Image
   </Button>
   </div>
   </div>
   <label className="form-label mt-4" htmlFor="color2">Logo Background Color</label>
   <input  className="form-control" type="color" id="color" value={color} onChange={e => setColor(e.target.value)} />
   
   
   
   
   <label className="form-label mt-5">Interior Banner<span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
   <div>
   <div className="bg-primary rounded d-flex p-2">
   <svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
   </svg>
   <div style={{ width: '95%', color: 'white' }}>
     <p>The banner needs to match the following conditions:</p>
     <ol>
       <li>The width must be at least 1296px.</li>
       <li>The height must be at least 540px.</li>
     </ol>
   </div>
   </div>
   
   <div className="mt-3 logodiv22" style={{ width: '90%',marginLeft:'5%',  backgroundColor: '#b2b2b2', position: 'relative' }}>
   <div id="banner_cameradiv" className="camera-div" style={{zIndex:10}}>
     <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="banner">
       <input required name="banner" id="banner" type="file" accept="image/*" className="d-none" onChange={e => {setFile2(e.target.files[0])}} />
       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
         <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
       </svg>
     </label>
   </div>
   
   <div className="rounded d-flex justify-content-center align-items-center bannerdiv" style={{ backgroundColor: color2, width: '100%', height: '100%' }}>
   {file2 &&(
   <div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%',}}>
    <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(100%)'}} src={ URL.createObjectURL(file2)} alt="Logo Preview" />
   </div>
   )}
   </div>
   </div>
   
   
   <div style={{display:'flex',marginTop:10,alignItems:'center',justifyContent:'flex-end'}}>
   <Button style={{marginRight:20}} onClick={()=>{ window.open(URL.createObjectURL(file2), '_blank');}}>
     View Image
   </Button>
   </div>
   
   </div>
   
   
   <label className="form-label mt-4" htmlFor="typ">Image align</label>
   <select className="form-control" value={image_align} onChange={handleSelectChange1}>
   <option value="" >Select an option</option>
   <option  value={'1'}>Vertical</option>
   <option  value={'2'}>Horizontal</option>
   </select>
   
   <label className="form-label mt-5">Exterior {image_align==1?'Vertical':'Horizontal'} Banner<span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
   <div>
   <div className="bg-primary rounded d-flex p-2">
   <svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
   </svg>
   <div style={{ width: '95%', color: 'white' }}>
     <p>The banner needs to match the following conditions:</p>
     <ol>
     <li>The width must be at least {image_align==1?'1296px':'400px'}.</li>
    <li>The height must be at least {image_align==1?'540px':'600px'}.</li>
     </ol>
   </div>
   </div>
   
   <div className={image_align==1?"mt-3 logodiv32":"mt-3 logodiv22"} style={{ width: '90%',marginLeft:'5%',  backgroundColor: '#b2b2b2', position: 'relative' }}>
   <div  >
     <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="banner">
     <input required name="logo" id="logo" type="file" accept="image/*"  onChange={e => {setFile3(e.target.files[0])}} />

       {/* <input  type="file" accept="image/*" style={{zIndex:-2,display:'none'}} onChange={e => setFile3(e.target.files[0])} /> */}
       {/* <svg  xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
         <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
       </svg> */}
     </label>
   </div>
   
   <div className="rounded d-flex justify-content-center align-items-center bannerdiv" style={{ backgroundColor: 'gray', width: '100%', height: '100%',zIndex:100 }}>
   {file3 &&(
   <div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%',}}>
    <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(100%)'}} src={URL.createObjectURL(file3)} alt="Logo Preview" />
   </div>
   )}
   </div>
   </div>
   
   
   <div style={{display:'flex',marginTop:10,alignItems:'center',justifyContent:'flex-end'}}>
   <Button style={{marginRight:20}} onClick={()=>{ window.open(URL.createObjectURL(file3), '_blank');}}>
     View Image
   </Button>
   </div>
   
   </div>
   <label className="form-label mt-4">Priority</label>
   <input  className="form-control" type="number"  id="name" value={priority} onChange={e => setPriority(e.target.value)} />
   
 
   
   <label className="form-label mt-4" htmlFor="typ">Branch</label>
   <select className="form-control" value={branchid} onChange={handleSelectChange}>
   <option value="" >Select an option</option>
   {branches.map((item,i)=>(
       <option  value={item.id}>{item.name}</option>
   ))}
   
   </select>
   
   
   <label className="form-label mt-4" htmlFor="typ">Promotions</label>
   {prom.map(prom => (
           <div key={prom.id} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
             <input
               type="checkbox"
               checked={selectedPromIds.includes(prom.id)}
               onChange={(e) => handleCheckBoxChange(prom.id, e.target.checked)}
             />
             <label style={{marginLeft:'1%'}}>{prom.name} {prom.namex}</label>
           </div>
         ))}
   
   
   
   <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleSubmit}>Add</Button>
   </>
   }
       </div>
     )
   }
