import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';

export default function EditBrand() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [nameorig, setNameOrig] = useState('');
    const [priority, setPriority] = useState(1);
    
    const HandleSave = () => {
        if (validateFields()) {
            EditBrand()
        } else {
          console.log('Validation failed');
        }
      };
      const EditBrand = async () => {
        const {status, data, msg} = await sendData('/brands/edit', JSON.stringify({
          name,
          id,
          namechanged:name!=nameorig?1:0,
          priority
        }));
        if(status){
         navigate('/brands')
        }
      }
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
      const GetBrand = async ()=>{
        const {status, data, msg} = await fetchData(`/brands/${id}`);
        if(status){
            setName(data.name)
            setNameOrig(data.name)
            setPriority(data?.priority)
            // setData(data)
            //  setLoading(false)
         }
    }
    useEffect(()=>{
      GetBrand()
    },[])

  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>
    <label className="form-label mt-4">Brand Name</label>
      <input required type="text" className="form-control"  name="slogan" value={name} 
      onChange={(e) => {setName(e.target.value)}}
      />
      <label className="form-label mt-4">Priority</label>
      <input min={1} required type="number" className="form-control"  name="slogan" value={priority} 
      onChange={(e) => {setPriority(e.target.value)}}
      />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={HandleSave}>Save</Button>

  </div>
  )
}
