import React, {useState} from 'react'
import { Button } from 'react-bootstrap'

import { useNavigate, Link } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { sendData } from '../utils';
const Login = () => {
  const [showalert, setShowalert] = useState(false);
const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const login = async () => {
    const {status, data, msg} = await sendData('/login', JSON.stringify({email, password}), navigate);
    if(status){
      localStorage.setItem("token", data.token);
      localStorage.setItem("userid", data.userid);
      navigate('/home', {replace: true});
    }else{
      setShowalert(true)
      setMsg(msg)
    }
  }
  const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'warning'} variant={'warning'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
 

  return (
 <div style={{backgroundColor:'white'}}>
 {showAlerts()}
    <div  className='login-container'>  
    <div  className='first-div'>
          <img src={'./control.png'} alt='trakitrak shoppers logo'  style={{height:'15%',width:'40%', objectFit:'contain' }} />
    <div style={{ width: '99%', height: 3, background: 'linear-gradient(to right, #ffffff, #e05b0e)', alignSelf: 'center',marginTop:'2%'}}></div>
    <div style={{ width: '99%', height: 250, background: 'linear-gradient(to right, #ffffff, #e05b0e)', alignSelf: 'center',marginTop:'2%'}}>
    <img src={'./bannerrr.jpg'}  style={{height:'100%',width:'100%', objectFit:'cover' }} />
    </div>
  </div>
  <div  className='first-div1'>
          <img src={'./control.png'} alt='trakitrak shoppers logo'  style={{height:'50%',width:'100%', objectFit:'contain' }} />
  
  </div>
      <div  className='login-small'>
      <div  style={{width:'100%',height:400,border:'none',display:'flex',justifyContent:'center'}}
      className="card" 
      >

        <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}}>
            <div className="mt-3s" style={{width: '90%'}}>
                <label>Email</label>
                <input onChange={(e) => setEmail(e.target.value)} className="form-control w-100" type="email" />
            </div>

            <div className="mt-3" style={{width: '90%'}}>
                <label>Password</label>
                <input onChange={(e) => setPassword(e.target.value)} className="form-control w-100" type="password" />
            </div>
           
            <div  className='loginbtn' onClick={login}>Log in</div>
        </div>

    </div>
      </div>
    </div>
 </div>
  
  )
}

export default Login