import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData,sendData } from '../utils';
import { FaAngleRight, FaClock } from 'react-icons/fa';
import { SlArrowRight } from "react-icons/sl";
import { Button } from 'react-bootstrap';
export default function Deposits() {
    const navigate = useNavigate()

    const [filter, setFilter] = useState(1);
    const [deposits, setDeposits] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(35);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([])
    const GetDeposits = async() =>{
        //setLoading(true)
        const {status, data, msg} = await fetchData('/shoppers/deposits/pending');
        if(status){
          setDeposits(data)
          setLoading(false)
       
        }
      }
      useEffect(()=>{
        GetDeposits()
      },[])
  return (
    <div style={{backgroundColor:'#f1f1f1',paddingTop:'5%',flex:1,height:'100vh'}}>
    {loading?

    <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div >
    {deposits.length==0?
    
    <div style={{width:'100%',backgroundColor:'#f1f1f1',marginTop:'40%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
    <p>No Pending Deposits Yet</p>
    <p>Recent Deposits Will Appear here</p>
    </div>:
    
    <>
    {deposits.map((item,i)=>(
  <div onClick={()=>navigate(`/deposit-details/${item.id}`)}  style={{width:'100%',cursor:'pointer',display:'flex',alignItems:'center',height:100,backgroundColor:'white',marginBottom:'2%'}}> 

      <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
         <p>${item.total}</p>
      </div>
       <div style={{width:'60%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <p className='m-0' style={{color:'black',fontWeight:'600'}}>Paid at {item.paid_at}</p>
        <p className='m-0' style={{color:'#a1a1a1',fontWeight:'400'}}>Payments of {item.orders_count} pickings and deliveries</p>
      </div>
      <div style={{width:'15%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <p className='m-0'>{item.number}</p>
      <SlArrowRight style={{marginLeft:10}} />

      </div>
  </div>
))}
    </>
    }

</div>
}
      
    </div>
  )
}
