
import * as React from "react";
const Order = (props) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="20.000000pt" height="20.000000pt" viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M1166 2544 c-128 -31 -258 -129 -318 -240 -32 -59 -58 -152 -58 -206
l0 -38 70 0 69 0 6 38 c22 131 82 217 190 272 62 32 75 35 155 35 80 0 93 -3
155 -35 108 -55 168 -141 190 -272 l6 -38 69 0 70 0 0 38 c0 157 -116 330
-272 406 -114 56 -215 68 -332 40z"/>
<path d="M474 2031 c-41 -25 -72 -65 -84 -110 -16 -58 -122 -1447 -117 -1531
2 -43 14 -100 27 -133 27 -70 108 -162 177 -200 105 -57 111 -57 800 -57 424
0 649 4 685 11 126 26 255 133 298 246 13 33 25 90 27 133 5 85 -101 1474
-117 1532 -13 48 -55 97 -97 114 -22 10 -75 14 -162 14 l-130 0 -3 -180 c-3
-169 -4 -181 -25 -201 -13 -14 -34 -22 -53 -22 -19 0 -40 8 -53 22 -21 20 -22
32 -25 201 l-3 180 -339 0 -339 0 -3 -180 c-3 -169 -4 -181 -25 -201 -13 -14
-34 -22 -53 -22 -19 0 -40 8 -53 22 -21 20 -22 32 -25 201 l-3 180 -137 0
c-117 0 -142 -3 -168 -19z m1145 -747 c43 -54 37 -62 -198 -298 -265 -264
-235 -256 -389 -103 -56 56 -105 112 -108 125 -10 39 5 72 38 87 45 18 71 6
151 -73 l67 -67 178 178 c155 155 181 177 209 177 22 0 38 -8 52 -26z"/>
</g>
</svg>
);
export default Order;