import React,{useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { FaClock, FaShoppingCart, FaStarHalfAlt,FaRegStar, FaTruck,FaStar } from 'react-icons/fa';

export default function SessionDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [sessiondetails, setSessiondetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const GetShopperSessions = async() =>{
        const {status, data, msg} = await sendData('/shoppers/sessionorders', JSON.stringify({sessionid:id}));
        if(status){
         console.log({data})
         setSessiondetails(data)
         setLoading(false)
        }else{
       
        }
      }
    useEffect(()=>{
        GetShopperSessions();
  },[]);
  const renderRatingStars = (x) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div  key={i} >
            {x>=i?<FaStar color='orange' />: <FaRegStar />}
         
       </div>
      );
    }
    return stars;
  };
  return (
    <div style={{paddingTop:'5%',backgroundColor:'#f1f1f1'}}>
           {loading?
   
   <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%',height:'90vh',overflow:'scroll'}}>
<div style={{width:'100%',backgroundColor:'white',height:80,display:'flex',alignItems:'center'}}>
    <div style={{width:'25%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <p className='m-0'>{sessiondetails.length}</p>
                        <p className='m-0'>{sessiondetails.length>1?'Orders':'Order'}</p>
    </div>
    <div style={{width:'75%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <p className='m-0'>{String(sessiondetails[0].connect_date).slice(11,16)} - {String(sessiondetails[0].disconnect_date).slice(11,16)}</p>
                        <div style={{display:'flex',alignItems:'center'}}>
                        <FaClock style={{marginRight:10}} size={15} />
                        <p className='m-0'>Duration: {sessiondetails[0].duration} min</p>
                        </div>
                    </div>
</div>
<p style={{marginTop:'2%',marginBottom:'2%',marginLeft:'3%'}}>Orders placed.</p>
<div style={{width:'100%',minHeight:100,backgroundColor:'white',}}>
{sessiondetails.map((session,i)=>(
<div onClick={()=>navigate(`/order-summary/${session.driverid}/${session.id}`)} style={{width:'100%',cursor:'pointer',height:100,display:'flex',padding:5,alignItems:'center',marginBottom:'2%'}}>
    <div style={{width:'8%',display:'flex',alignItems:'center',justifyContent:'center'}}>
    <div style={{width:70,height:70,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:5,backgroundColor:session.color}}>
            <img src={session.img} style={{width:60,height:60,objectFit:'contain'}}/>
    </div>
     </div>   
    <div style={{display:'flex',width:'77%',marginLeft:10,flexDirection:'column',justifyContent:'center'}}>
        <p className='m-0'>{session.storename}</p>
         <div style={{display:'flex'}}>
         {renderRatingStars(session.rating)}
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
        <div style={{display:'flex',alignItems:'center',marginRight:10}}>
        <FaShoppingCart style={{marginRight:10}}/>
        <p className='m-0'>{session.count} {session.count>1?'Products':'Product'}</p>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
        <FaTruck style={{marginRight:10}}/>
        <p className='m-0'>{session.branchname}</p>
        </div>
        </div>

    </div>
    <div style={{width:'15%'}}>
        <p>{session.order_number}</p>
    </div>
</div>
))}
</div>   
</div>
}
    </div>
  )
}
