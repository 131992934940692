import React,{useState,useEffect, useRef} from 'react'
import { fetchData, sendData } from '../utils';
import { Alert, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import ProdItem from './ProdItem';
import Modal from 'react-bootstrap/Modal';

export default function Products() {
    const navigate = useNavigate()
    const fileInputRef = useRef(null);

    const [totalentries, setTotalentries] = useState(0);
    const [validentries, setValidentries] = useState(0);
    const [invalidentries, setInvalidentries] = useState(0);
    const [preimporterror, setPreImportError] = useState([]);
    const [emptyexcel, setEmptyexcel] = useState(false);
    const [modalLoading2, setModalLoading2] = useState(false);
const [search, setSearch] = useState('');
const [barcode, setBarcode] = useState('');
const [cat, setCat] = useState('');
const [statuss, setStatus] = useState('ALL');
const [page, setPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [count, setCount] = useState(0);
const [limit, setLimit] = useState(0);
const [sameav, setSameav] = useState(false);
const [prodinfo, setProdInfo] = useState();
const [branchesav, setBranchesav] = useState([]);
const [excel, setexcel] = useState(null);
const [excel1, setexcel1] = useState(null);
const [excel2, setexcel2] = useState(null);
const [branchname, setBranchName] = useState('');
const [brandsearch, setBrandsearch] = useState('');
const [modalInput, setModalInput] = useState(null);
const [modalInput1, setModalInput1] = useState(null);
const [modalInput2, setModalInput2] = useState(null);
const [show, setShow] = useState(false);
const [show1, setShow1] = useState(false);
const [show2, setShow2] = useState(false);
const [show3, setShow3] = useState(false);
const [modal, setClose] = useState(false);
const [modal1, setClose1] = useState(false);
const [loadermodal, setLoadermodal] = useState(false);
const [modalImgPrev, setModalImgPrev] = useState(null);
const [modal2, setClose2] = useState(false);
const [exportmdodal, setExportmdodal] = useState(false);
const [processmodal, setProcessmodal] = useState(false);
const [processloader, setProcessloader] = useState(false);
const [processloader1, setProcessloader1] = useState(false);
const [processapproved, setprocessapproved] = useState(false);
const [bulkupdate, setBulkUpdate] = useState(false);
const [branchess, setBranchess] = useState([]);
const [loading, setLoading] = useState(true);
const [showalert, setShowalert] = useState(false);
const [msg, setMsg] = useState('');
const [modalLoading, setModalLoading] = useState(true);
const handleClosemodal = () => setClose(false);
const handleClosemodal1 = () => setClose1(false);
const handleClosemodal2 = () => setClose2(false);
const handleClose = () => setShow(false);
const handleClose1 = () => setShow1(false);
const handleClose2 = () => setShow2(false);
const handleClose3 = () => setShow3(false);
const handleClose4 = () => setLoadermodal(false);
const handleProcess = () => setProcessmodal(false);
const handleProcessapproved = () => setBulkUpdate(false);
const handlebulkupdate = () => setprocessapproved(false);
const [products, setProducts] = useState([]);
const [modalData, setModalData] = useState(null);
const [debouncedValue, setDebouncedValue] = useState(search);


  const handlePasswordCheck = async() => {
    const password = prompt('Please enter the password:');
    const {status, data, msg} = await sendData('/excel/password', JSON.stringify({password}));
        if(status){
          if(data.isValid){
            Export()
          }else{
            alert('Password incorrect!');
          }
        }

    
  };
const setModalState = (state) => {
    setModalLoading(state);
}
const setModalInfo = (state) => {
    setModalData(state);
}
const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
  const fetchprods = async () => {
    setLoading(true)
    const {status, data, msg} = await sendData('/products', JSON.stringify({search,page,barcode,catsearch:cat,brandsearch:brandsearch}));
    if(status){
        setLoading(false);
        let totalPagesx = Math.ceil(data.count / data.limit);
        if(totalPagesx == 0) totalPagesx = 1;
        setProducts(data.products)
        setTotalPages(totalPagesx);
        setLimit(data.limit);
        setCount(data.count);
    }else{
      setShowalert(true)
      setMsg(msg)
      setLoading(false);
    }
  }
//   const Export = async() => {
//     setLoadermodal(true)
 
//     try {
    
//     const req = await fetch("https://api.trakitrak.com/control/excel/exportadminproducts", {
//       method:'GET',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json'
//     },
// })
// console.log(req.status)
// if(req.status==200){
//   const blob = await req.blob()
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement("a");
//   a.style.display = "none";
//   a.href = url;
//   // the filename you want
//   a.download = `All_Products.xlsx`;
//   document.body.appendChild(a);
//   a.click();
//   window.URL.revokeObjectURL(url);
//   setLoadermodal(false)
// }else{
//   const json = await req.json()
//   console.log(json) 
//   setLoadermodal(false)
// }
//     } catch (error) {
//       setLoadermodal(false)
// }



// };
const Export = async() => {
  setLoadermodal(true);

  try {
      const req = await fetch("https://api.trakitrak.com/control/excel/exportadminproducts", {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
      });

      console.log(req.status);
      if (req.status === 200) {
        const blob = await req.blob();  // Get the file content as a blob
        const url = window.URL.createObjectURL(blob);  // Create a temporary URL for the blob
        const a = document.createElement("a");  // Create a link element
        a.style.display = "none";
        a.href = url;
        a.download = `admin_products.zip`;  // Specify the file name
        document.body.appendChild(a);  // Append the link to the document
        a.click();  // Programmatically click the link to trigger the download
        document.body.removeChild(a);  // Remove the link from the document
        window.URL.revokeObjectURL(url);  // Revoke the temporary URL
        setLoadermodal(false);
    } else {
          const json = await req.json();
          console.log(json);
          setLoadermodal(false);
      }
  } catch (error) {
      setLoadermodal(false);
  }
};
  useEffect(() => {
    setPage(1)
    const handler = setTimeout(() => {
      setDebouncedValue(search);

    }, 500); // 500 ms delay

    return () => clearTimeout(handler);
  }, [search]);
  useEffect(() => {
    setPage(1)
    const handler = setTimeout(() => {
      setDebouncedValue(barcode);
    }, 500); // 500 ms delay

    return () => clearTimeout(handler);
  }, [barcode]);
  useEffect(() => {
    setPage(1)
    const handler = setTimeout(() => {
      setDebouncedValue(cat);
    }, 500); // 500 ms delay

    return () => clearTimeout(handler);
  }, [cat]);
  useEffect(() => {
    setPage(1)
    const handler = setTimeout(() => {
      setDebouncedValue(brandsearch);
    }, 500); // 500 ms delay

    return () => clearTimeout(handler);
  }, [brandsearch]);
  // useEffect(() => {
   
  //     fetchprods();
    
  // }, [debouncedValue]);
  useEffect(()=>{
    fetchprods();
},[page,debouncedValue]);

// useEffect(()=>{
//   setPage(1)
//   fetchprods();
// },[cat,barcode]);
const HandlePreImport = async() => {
  // setProcessloader(true)
  setLoadermodal(true)
    try {
       let userid =localStorage.getItem('userid')
       let storid =localStorage.getItem('storeid')
      const formData = new FormData();
      formData.append('userid', userid);
      formData.append('file', excel);
    const req = await fetch("https://api.trakitrak.com/control/excel/preimportproducts", {
      method:'POST',
      body: formData,
})
if(req.status==200){
  const json = await req.json()
    console.log(json)


    setEmptyexcel(json.isEmpty)
    setPreImportError(json.errors)
    setTotalentries(Number(json.valid)+Number(json.invalid))
    setValidentries(json.valid)
    setInvalidentries(json.invalid)
    setLoadermodal(false)
    setLoadermodal(false)
    setProcessmodal(true)
    setProcessloader(false)

}else{
  const json = await req.json()
  console.log(json) 

  setLoadermodal(false)
  setexcel(null)
  setModalInput(null)
  setProcessloader(false)
  setShowalert(true)
  alert(json.message)
  setMsg('Invalid Excel')
  setShow(false)
  // setLoader(false)
}
    } catch (error) {
      setShow(false)
      setLoadermodal(false)
      setexcel(null)
      setModalInput(null)
      setProcessloader(false)
    }
 
  
};
const handleSubmit = async() => {
  setLoadermodal(true)
//setProcessloader1(true)


    try {

       let userid =localStorage.getItem('userid')
      const formData = new FormData();
      formData.append('userid', userid);
      formData.append('file', excel);
      console.log({formData})
    const req = await fetch("https://api.trakitrak.com/control/excel/importproducts", {
      method:'POST',
      body: formData,
})
if(req.status==200){
  const json = await req.json()
  console.log(json)
setShow(false)
setProcessmodal(false)
setprocessapproved(true)
setexcel(null)
setModalInput(null)
setLoadermodal(false)

  // navigate('/stores/added', {replace: true});

}else{
  const json = await req.json()
  console.log(json) 
  setShow(false)
  setexcel(null)
  setProcessmodal(false)

  setModalInput(null)
  setLoadermodal(false)
  // setShowalert(true)
  // setMsg(json.message)
  // setLoader(false)
}
    } catch (error) {
      setexcel(null)
      setModalInput(null)
      setShow(false)
      setLoadermodal(false)
      setProcessmodal(false)
    }
 
  
};
  return (
    <>
        <main   className="products-container">
      
          <svg style={{marginLeft:'5%',marginTop:'1.2%',cursor:'pointer'}} width={25} height={25} onClick={()=>{
          setLoading(true)
          fetchprods()
        }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0">
            </g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 3V8M21 8H16M21 8L18 5.29168C16.4077 3.86656 14.3051 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.2832 21 19.8675 18.008 20.777 14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </path> </g></svg>
      <div className="prodfilter" style={{ marginRight:'2%',marginLeft:'2%',marginTop:'1.5%',width:'96%'}}>
      <div className="d-flex align-items-center" style={{width:'70%',}}>
      <div style={{cursor:'pointer',marginRight:'1.5%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={brandsearch}
          onChange={(e) => {

  setBrandsearch(e.target.value)

          }}
          type="text"
          id="search-product"
          placeholder="Search brand"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {brandsearch!=''?   <svg onClick={()=>{setBrandsearch('');setPage(1)}} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
   <div style={{cursor:'pointer',marginLeft:'0%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={search}
          onChange={(e) => {

  setSearch(e.target.value)

          }}
          type="text"
          id="search-product"
          placeholder="Search Product"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {search!=''?   <svg onClick={()=>{setSearch('');setPage(1)}} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
         <div style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={barcode}
          onChange={(e) => {
          setBarcode(e.target.value)
          }}
          type="text"
          id="search-product"
          placeholder="Search  barcode"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {barcode!=''?   <svg onClick={()=>{setBarcode('');setPage(1)}} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
         <div style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={cat}
          onChange={(e) => {
          setCat(e.target.value)
          }}
          type="text"
          id="search-product"
          placeholder="Search  Category"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {cat!=''?   <svg onClick={()=>{setCat('');setPage(1)}} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
      

<div >
 
 
</div>


           
            </div>
           
        <div className="d-flex align-items-center addprod">
        
        <a  onClick={handlePasswordCheck}
        //href={`https://api.trakitrak.com/control/excel/exportadminproducts`} download 
        data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-danger buttonx me-2" style={{ fontSize: '13px' }}>
            Export
          </a>
      
          <a onClick={()=>{setShow(true)}} data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-danger buttonx me-2" style={{ fontSize: '13px' }}>
            Import
          </a>
          <a onClick={()=>navigate('/products-add')} className="btn btn-danger buttonx" style={{ fontSize: '13px' }}>
            Add product
          </a>
        
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between' style={{ marginRight:'2%',marginLeft:'2%',marginTop:'1.5%',width:'96%'}}>
        <div>
          <p>All Products</p>
        </div>
       
<div  style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
<div  style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
<p className="mx-2" style={{ fontSize: '12px',margin:0 }}>
            {page === 1 ? 0 : (page - 1) * limit}-{page * limit <= count ? page * limit : count} of {count}
          </p>
          </div>
          <div className="pagination-container py-2 border d-flex h-100">
          <svg  onClick={() => {
                if (page > 1) setPage(page - 1);
              }} style={{ opacity: page > 1 ? '1' : '0.25' }} className="border-end" fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
  <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
</svg>
       
         

<svg  onClick={() => {
                if (page < totalPages) setPage(page + 1);
              }}   style={{ opacity: page < totalPages ? '1' : '0.25' }} fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)">
  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
  </g>
</svg>
          </div>
</div>
      </div>
{showAlerts()}

      <div className={`main-products-container py-3 px-4 ${loading ? 'd-flex justify-content-center align-items-center main-products-containerx' : ''}`}>
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
        <>
        {   products.map((product) => (

            <ProdItem
            fetch={fetchprods}
            key={product.id} // Make sure to provide a unique key
            setModalState={setModalState}
            setModalInfo={setModalInfo}
            setModalImgPrev={setModalImgPrev}
            product={product}
            />
))
}
        </>
        
            
      
        )}
       
      </div>
        </main>




        <Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal1"  // Custom class for centering horizontally
     show={show} onHide={handleClose}>
    
    <div className="modal-content" style={{minHeight:'75vh',backgroundColor:'#fafbff'}}>
                        <div className="modal-header" style={{ display: "block" }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Upload spreadsheet</h5>
                                <div className="d-flex align-items-center">
                                    <div>
                                      <button onClick={()=>{setShow(false)
                                      setexcel(null)
                                      setModalInput(null)
                                      }} type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button disabled={processloader&&excel?true:excel&&!processloader?false:true} onClick={HandlePreImport} type="submit" className="btn buttonx-outline">Upload</button></div>
                                </div>
                            </div>
                        </div>
                        <div className={"modal-body" + (modalLoading2 ? ' d-flex justify-content-center align-items-center' : '')} style={{ backgroundColor: "#fafbff",width:'90%',marginLeft:'5%' }}>
                        {processloader?
                        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'28%'}}>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      </div> : (
                                <div>
                                    
                                    <div className="d-flex">
                                        <div className="d-flex justify-content-center" >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">1</p>
                                            </div>
                                        </div>
                                        <p style={{ margin:0,marginLeft:10,marginTop:3 }}>Download the spreadsheet template and fill in the required information.</p>
                                    </div>

                                    <div className="d-flex justify-content-center mt-1"  >
                                        <a href={`https://api.trakitrak.com/merchant/excel/download?a=123456789`} download className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "red", cursor: "pointer",marginTop:50,textDecoration:'none' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            <p className="text-white ms-1 m-0" style={{ fontSize: "14px" }}>Download template</p>
                                        </a>
                                    </div>

                                    <div className="d-flex mt-4">
                                        <div className="d-flex justify-content-center" >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">2</p>
                                            </div>
                                        </div>
                                        <p style={{ margin:0,marginLeft:10,marginTop:3 }}>Upload your spreadsheet here</p>
                                    </div>

                                    <div className="d-flex flex-column justify-content-center mt-2 py-4" style={{ backgroundColor: "white",height:'30vh' ,borderRadius:10,marginBottom:10 }}>

                                        {modalInput && (
                                          <div style={{display:'flex',justifyContent:'space-between',height:70,alignItems:'center',borderBottom:'1px solid red'}}>
                                       
                                            <div style={{marginLeft:15,}} className="d-flex  align-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                                                </svg>
                                                <p className='m-0' style={{fontSize:17,fontWeight:'bold'}}>{modalInput}</p>
                                            </div>

                                            <div onClick={()=>{
                                              setModalInput(null)
                                              setexcel(null)
                                              if (fileInputRef.current) {
                                                fileInputRef.current.value = null;
                                            }
                                            }} style={{marginRight:34,cursor:'pointer'}}>
<svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <line x1="18" y1="6" x2="6" y2="18" />
  <line x1="6" y1="6" x2="18" y2="18" />
</svg>
                                            </div>
                                    
                                            </div>
                                        )}

                                        <label htmlFor="file" style={{display:'flex',justifyContent:modalInput?'flex-end':'center',width:'100%',height:50,alignItems:'center' }}>
                                            <input
                                            ref={fileInputRef}
                                            onChange={(e) => {
                                            
                                                const file = e.target.files[0];
                                                   console.log({excel})
                                                   console.log({file})
                                                   setexcel(e.target.files[0])
                                                   console.log({excel})
                                                if (file) {
                                                  console.log({file})
                                             
                                                    setModalInput(file.name)
                                                } else setModalInput(null);
                                            }} id="file" name="file" type="file" accept=".xlsx" style={{ display: "none" }} />
                                         
                                            <div className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "#f1f1f1",width:120,height:40, cursor: "pointer" }}>
                                                <p className='m-0'  style={{ fontSize: "14px",color:'black',fontWeight:'bolder', }}>{excel?'Replace file':'Select a file'}</p>
                                            </div>
                                        </label>

                                    </div>
                                    <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8568477-adding-single-products-in-bulk' style={{textAlign:'start',fontSize:13.7,marginTop:10,color:'#007aff'}}>Need help importing products?</a>

                                </div>
                            )}
                        </div>
                    </div>
        
            
       

</Modal>
{/* bulk Process */}
<Modal   
centered 
scrollable={true}  // This centers the modal vertically
 dialogClassName="custom-modal1" 
 // Custom class for centering horizontally
     show={processmodal}
     onHide={handleProcess}>
    
                    {/* <div className="modal-content" style={{backgroundColor:'#fafbff'}}>
                    <div className="modal-header" style={{ display: "block",backgroundColor:'white' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>{emptyexcel||preimporterror.length>0?'Bulk upload process failed':'Bulk upload process completed'}</h5>
                                <div className="d-flex align-items-center">
                                {emptyexcel||preimporterror.length>0?
                                    <div>
                                      <button onClick={()=>
                                      {
                                        setProcessmodal(false)
                                      }
                                     
                                      } type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>:null}
                                    <div className="ms-2">
                                      <button  type="submit" className="btn buttonx-outline">
                                      {emptyexcel||preimporterror.length>0?'Upload another template':'Ready'}</button></div>
                                </div>
                            </div>
                        </div>
                        <div  style={{backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column',overflowY:'auto' }}>
                          
                                <div >
                                    <div className="d-flex mt-4">
                                        <p>Bulk upload process summary</p>
                                    </div>
                                  <div style={{backgroundColor:'white',width:'100%',marginTop:10,border:'1px solid #d1d1d1',borderRadius:10}}>
                                      <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                        <p>Total entries</p>
                                        <p>{totalentries}</p>
                                      </div>
                                      <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                      <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                        <p>Valid entries</p>
                                        <p>{validentries}</p>
                                      </div>
                                      <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                      <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                        <p>Invalid entries</p>
                                        <p>{invalidentries}</p>
                                      </div>
                                  </div> 
                               

                                  {emptyexcel||preimporterror.length>0?<p style={{marginTop:10}}>Erros:</p>:null}
                                  {emptyexcel?
                                  <ul>
                                    <li>You have uploaded an empty excel</li>
                                  </ul>
                                  :
                                  preimporterror.length>0?
                                  <table className="table" >
                                  <thead>
                                    <tr style={{height:64}}>
                                      <th scope="col">Row</th>
                                      <th scope="col">Column</th>
                                      <th scope="col">Error Type</th>
                                    </tr>
                                  </thead>
                                  <tbody >
                                 {preimporterror.map((err,i) => {
        
                                               return(
                                                <tr key={i}    >
                                                 <td>{err.row}</td>
                                                 <td>{err.column}</td>
                                                 <td>{err.reason}</td>
                                              </tr>
                                               )
                                     
                                })}
                                  </tbody>
                                </table>
                                :null
                                  }

                                </div>
                           
                        </div>
                    </div>    */}
        <Modal.Header>
        <div className="modal-header" style={{ display: "block",backgroundColor:'white', width: '100%' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>{emptyexcel||preimporterror.length>0?'Bulk upload process failed':'Confirm bulk process'}</h5>
                                <div className="d-flex align-items-center">
                              
                                    <div>
                                      <button onClick={()=>
                                      {
                                        setShow(false)
                                        setexcel(null)
                                        setModalInput(null)
                                        setProcessmodal(false)
                                        if (fileInputRef.current) {
                                          fileInputRef.current.value = null;
                                      }
                                      }
                                     
                                      } type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button onClick={()=>{
                                        if(emptyexcel||preimporterror.length>0){
                                              setProcessmodal(false)
                                         
                                              setexcel(null)
                                              setModalInput(null)
                                              if (fileInputRef.current) {
                                                fileInputRef.current.value = null;
                                            }

                                        }else{
                                          handleSubmit()
                                        }
                                      }}  type="submit" className="btn buttonx-outline">
                                      {emptyexcel||preimporterror.length>0?'Upload another template':'Confirm'}</button></div>
                                </div>
                            </div>
                        </div>
      </Modal.Header>
 
      <Modal.Body style={{backgroundColor: "#fafbff",height:'70vh'}}>
      {processloader1?
                        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'10%'}}>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      </div>:
      <div  style={{backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column',overflowY:'auto' }}>
                          
                          <div >
                              <div className="d-flex mt-4">
                                  <p>Bulk upload process summary</p>
                              </div>
                            <div style={{backgroundColor:'white',width:'100%',marginTop:10,border:'1px solid #d1d1d1',borderRadius:10}}>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Total entries</p>
                                  <p>{totalentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Valid entries</p>
                                  <p>{validentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Invalid entries</p>
                                  <p>{invalidentries}</p>
                                </div>
                            </div> 
                         

                            {emptyexcel||preimporterror.length>0?<p style={{marginTop:10}}>Erros:</p>:null}
                            {emptyexcel?
                            <ul>
                              <li>You have uploaded an empty excel</li>
                            </ul>
                            :
                            preimporterror.length>0?
                            <table className="table" >
                            <thead>
                              <tr style={{height:64}}>
                                <th scope="col">Row</th>
                                <th scope="col">Column</th>
                                <th scope="col">Error Type</th>
                              </tr>
                            </thead>
                            <tbody >
                           {preimporterror.map((err,i) => {
  
                                         return(
                                          <tr key={i}    >
                                           <td>{err.row}</td>
                                           <td>{err.column}</td>
                                           <td>{err.reason}</td>
                                        </tr>
                                         )
                               
                          })}
                            </tbody>
                          </table>
                          :null
                            }

                          </div>
                     
                  </div>
}
      </Modal.Body>

</Modal>
{/* bulk Process */}

{/* bulk Process  approved*/}
<Modal   
centered 
scrollable={true}  // This centers the modal vertically
 dialogClassName="custom-modal1" 
 // Custom class for centering horizontally
     show={processapproved}
     onHide={handleProcessapproved}>
    
                  
        <Modal.Header>
        <div className="modal-header" style={{ display: "block",backgroundColor:'white', width: '100%' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Bulk upload process completed</h5>
                                <div className="d-flex align-items-center">
                              
                            
                                    <div className="ms-2">
                                      <button onClick={()=>{
                                        fetchprods()
                                        setprocessapproved(false)
                                      setShow3(false)

                                      }}  type="submit" className="btn buttonx-outline">
                                     Ready</button></div>
                                </div>
                            </div>
                        </div>
      </Modal.Header>
 
      <Modal.Body style={{backgroundColor: "#fafbff",height:'70vh'}}>
    
      <div  style={{backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column',overflowY:'auto' }}>
                          
                          <div >
                              <div className="d-flex mt-4">
                                  <p>Bulk upload process summary</p>
                              </div>
                            <div style={{backgroundColor:'white',width:'100%',marginTop:10,border:'1px solid #d1d1d1',borderRadius:10}}>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Total entries</p>
                                  <p>{totalentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Valid entries</p>
                                  <p>{validentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Invalid entries</p>
                                  <p>{invalidentries}</p>
                                </div>
                            </div> 
                         

                           

                          </div>
                     
                  </div>

      </Modal.Body>

</Modal>

{/* bulk Process approved */}

<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal2"// Custom class for centering horizontally
     show={loadermodal} onHide={handleClose4}>
    
                    <div className="modal-content" style={{height:173,backgroundColor:'#fafbff'}}>
                  <div style={{display:'flex',height:'100%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                  <Spinner animation="border" style={{marginBottom:20}} role="status">
                      <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p style={{fontSize:16,color:'#4a4a4a',width:132,height:65,fontWeight:'900',textAlign:'center'}}>This Process may take a moment</p>
                  </div>
                    </div>
        
            
       

</Modal>
    </>
  )
}
