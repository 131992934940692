import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';
export default function EarningPlan() {
    const [plan, setPlan] = useState([]);
    const [loading, setLoading] = useState(true);
    const GetEarning = async ()=>{
        const {status, data, msg} = await fetchData(`/shoppersearningplan`);
        if(status){
            setPlan(data)
            setLoading(false)
         }
    }
    useEffect(()=>{
        GetEarning()
    },[])
    function updateValue(item, newValue) {
        const updatevaluee = plan.map(p => {
            if (p.id === item.id) { // Assuming each item has a unique 'id' field
                return {...p, value: newValue};
            }
            return p;
        });
        setPlan(updatevaluee); // Assuming 'setParking' is your state updater function
    }
    const EditEarning = async () => {
        setLoading(true)
        const {status, data, msg} = await sendData('/shoppersearningplan/edit', JSON.stringify({items:plan}));
        if(status){
        GetEarning()
        }
      }
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
{loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%',marginLeft:10}}>

{plan.map((item,i)=>(
<>
<label className="form-label mt-4">{item.key}</label>
        <input min={0} type="number" className="form-control"  name="slogan" value={item.value} 
       onChange={(e)=>updateValue(item, e.target.value)}

        />
</>
))}


       


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={EditEarning}>Save</Button>

</div>
}
    </div>
  )
}
