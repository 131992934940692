import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';
export default function EditCity() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const handleAdd = () => {
        if (validateFields()) {
            AddCatt()
        } else {
          console.log('Validation failed');
        }
      };
      const AddCatt = async () => {
        const {status, data, msg} = await sendData('/cities/edit', JSON.stringify({name,id,type:1}));
        if(status){
         navigate('/cities')
        }
      }
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
      const GetCat = async ()=>{
        const {status, data, msg} = await fetchData(`/cities/${id}/1`);
        if(status){
            setName(data.name)
            
            // setData(data)
            //  setLoading(false)
         }
    }
    useEffect(()=>{
      GetCat()
    },[])
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>
      <label className="form-label mt-4">City Name</label>
        <input required type="text" className="form-control"  name="slogan" value={name} 
        onChange={(e) => {setName(e.target.value)}}
        />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleAdd}>Save</Button>

    </div>
  )
}
