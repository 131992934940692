import * as React from "react";
const Product = (props) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="20.000000pt" height="20.000000pt" viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M150 2405 l0 -155 450 0 450 0 0 155 0 155 -450 0 -450 0 0 -155z"/>
<path d="M127 1845 l-127 -255 0 -142 0 -141 78 75 c83 81 163 128 250 147
119 25 208 9 345 -62 94 -49 103 -51 176 -52 76 0 81 2 214 67 109 54 137 71
137 88 0 11 -57 135 -127 275 l-128 255 -345 0 -345 0 -128 -255z"/>
<path d="M1270 1795 c0 -3 18 -41 40 -85 l40 -80 0 -375 c0 -317 2 -375 14
-375 8 0 20 -2 28 -5 7 -3 25 12 40 34 50 70 185 141 270 141 l28 0 0 -75 c0
-71 -1 -75 -22 -75 -30 0 -13 -13 47 -35 65 -24 181 -138 218 -215 62 -127 71
-290 24 -430 l-23 -70 218 0 218 0 0 609 c0 601 0 610 20 616 33 10 79 55 100
94 28 55 34 104 20 163 -17 69 -85 139 -152 156 -33 9 -207 12 -587 12 -298 0
-541 -2 -541 -5z"/>
<path d="M357 1380 c-73 -19 -123 -54 -240 -168 l-117 -116 0 -473 0 -473 438
0 439 0 -23 68 c-30 89 -39 179 -25 262 6 37 13 75 17 83 3 8 -15 -4 -39 -28
-149 -142 -380 -100 -479 88 -18 34 -23 58 -23 127 0 76 3 91 29 138 33 60 76
101 139 134 60 33 194 33 254 0 105 -56 163 -141 171 -255 4 -63 6 -68 19 -51
57 80 165 154 246 169 l37 7 0 240 c0 188 -3 239 -12 235 -7 -3 -59 -27 -116
-52 -172 -79 -287 -75 -467 15 -107 54 -177 67 -248 50z"/>
<path d="M533 879 c-30 -15 -48 -33 -62 -63 -26 -53 -26 -79 0 -132 21 -45 81
-84 129 -84 13 0 43 9 66 21 45 21 84 81 84 129 0 48 -39 108 -84 129 -53 26
-80 26 -133 0z"/>
<path d="M1188 736 c-126 -35 -210 -166 -211 -331 -1 -217 144 -399 318 -398
33 0 75 7 94 16 32 13 39 13 90 -5 83 -29 181 -6 257 60 44 38 93 114 115 179
72 214 -27 446 -206 483 -59 12 -110 3 -179 -32 l-38 -20 -57 26 c-72 32 -124
39 -183 22z"/>
</g>
</svg>
);
export default Product;