import React,{useState,useRef} from 'react'
import Map, { GeolocateControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FaStar, FaShoppingCart, FaTruck, FaPercentage, FaClock, FaHeartbeat, FaInfoCircle, FaDollarSign, FaUber, FaUser } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';

export default function ShopperOverview(props) {
    const mapRef = useRef()
    const [zoom, setZoom] = useState(false);
    const [viewport, setViewport] = useState({
      latitude: 33.888630,
      longitude: 35.495480,
      zoom: 16,
    });
    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false)}
    // setViewport({
    //   latitude: data.branch.lat,
    //   longitude: data.branch.longg,
    //   zoom: 16,
    // });
  return (
     <div style={{paddingBottom:20,paddingRight:30,paddingLeft:50}}>
      <Modal scrollable      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show} onHide={handleClose}>
   <Modal.Header style={{justifyContent:'space-between'}}>
        <Modal.Title>Location</Modal.Title>

        <Button style={{marginLeft:10}} onClick={handleClose}>Close</Button>
    
       
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'#f1f1f1'}}>
        {props.shopperInfo.connected?
        <>
              {viewport.latitude && viewport.longitude && (
<Map
ref={mapRef}
style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
initialViewState={viewport}
//viewState={viewport}
onMouseOut={()=>setZoom(false)}
//onMove={evt => setViewport(evt.viewState)}

onClick={()=>setZoom(true)}
onTouchEnd={()=>setZoom(false)}

scrollZoom={zoom}  // Disable scroll zooming
mapStyle="mapbox://styles/mapbox/streets-v11"
>
<Marker

  longitude={viewport.longitude}
  latitude={viewport.latitude}
/>
{/* <GeolocateControl
positionOptions={{ enableHighAccuracy: true }}
trackUserLocation={true}
/> */}
</Map>
)} 
    
        </>:
        <>
        <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <p>Shopper not connected</p>
        </div>
        </>}
  </Modal.Body>


</Modal>
          <h4 className='m-0'>Overview</h4>
          <p className='m-0' style={{color:'#a1a1a1'}}>Shopper's main information and data</p>

          <p style={{marginLeft:20,marginTop:20}}>Shopper statistics</p>
         <div style={{display:'flex',width:'100%',height:80,alignItems:'center'}}>
         {[0,1,2,3,4].map((card, index) => (
                <div style={{width:'20%',justifyContent:'space-around',marginRight:'1%',alignItems:'center',display:'flex',height:70,backgroundColor:'white',borderRadius:9,border:'1px solid #f1f1f1'}}>
                 {index==0?
                 <>
                <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <FaStar color='#e05b0e' size={20}/>
                </div>
                  <div style={{marginLeft:10,width:'75%'}}>
                    <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.avg_rating}</h3>
                    <p style={{fontSize:12,color:'#c9c9c9'}} className='m-0'>Of 5 Stars</p>
                  </div>
                 </>
                 :index==1?
                 <>
                <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <FaShoppingCart color='#e05b0e' size={20}/>
                </div>
                  <div style={{marginLeft:10,width:'75%'}}>
                    <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.picked_count}</h3>
                    <p style={{fontSize:12,color:'#c9c9c9'}} className='m-0'>Pickings</p>
                  </div>
                 </>
                 
                 :index==2?
                 <>
                <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <FaTruck color='#e05b0e' size={20}/>
                </div>
                  <div style={{marginLeft:10,width:'75%'}}>
                    <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.delivered_count}</h3>
                    <p style={{fontSize:12,color:'#c9c9c9'}} className='m-0'>Deliveries</p>
                  </div>
                 </>
                 :
                 index==3?
                 <>
                  <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <FaStar color='#e05b0e' size={20}/>
                </div>
                  <div style={{marginLeft:10,width:'75%',}}>
                    <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.accept}%</h3>
                    <p style={{fontSize:12,color:'#c9c9c9',}} className='m-0'>Accepted orders</p>
                  </div>
                 </>
                 :
                 index==4?
                 <>
                  <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <FaStar color='#e05b0e' size={20}/>
                </div>
                  <div style={{marginLeft:10,width:'75%',}}>
                    <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.pickspeed}</h3>
                    <p style={{fontSize:12,color:'#c9c9c9',}} className='m-0'>Mins per product</p>
                  </div>
                 </>
                 :
                 null
                 }
                </div>
              ))} 
          </div>
          <div style={{display:'flex',width:'100%',height:80,alignItems:'center',}}>
         {[0,1,2,3,4].map((card, index) => (
                <div style={{width:'20%',justifyContent:'space-around',marginRight:'1%',alignItems:'center',display:'flex',height:70,backgroundColor:'white',borderRadius:9,border:'1px solid #f1f1f1'}}>
                {index==0?
                <>
               <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
               <FaInfoCircle color='#e05b0e' size={20}/>
               
               </div>
                 <div style={{marginLeft:10,width:'75%'}}>
                   <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.full_rate}%</h3>
                   <p style={{fontSize:12,color:'#c9c9c9'}} className='m-0'>Fullfilment rate</p>
                 </div>
                </>
                :index==1?
                <>
               <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
               <FaPercentage color='#e05b0e' size={20}/>
               </div>
                 <div style={{marginLeft:10,width:'75%'}}>
                   <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.found_rate}%</h3>
                   <p style={{fontSize:12,color:'#c9c9c9'}} className='m-0'>Found rate</p>
                 </div>
                </>
                
                :index==2?
                <>
               <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
               <FaPercentage color='#e05b0e' size={20}/>
               </div>
                 <div style={{marginLeft:10,width:'75%'}}>
                   <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.cancel_rate}%</h3>
                   <p style={{fontSize:12,color:'#c9c9c9'}} className='m-0'>Cancel rate</p>
                 </div>
                </>
                :
                index==3?
                <>
                 <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                 <FaPercentage color='#e05b0e' size={20}/>
               </div>
                 <div style={{marginLeft:10,width:'75%',}}>
                   <h3 className='m-0' style={{fontSize:16,color:'black'}}>{props.shopperInfo.points}</h3>
                   <p style={{fontSize:12,color:'#c9c9c9',}} className='m-0'>Points Earned</p>
                 </div>
                </>
                :
                index==4?
                <>
                 <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                 <FaDollarSign color='#e05b0e' size={20}/>
               </div>
                 <div style={{marginLeft:10,width:'75%',}}>
                   <h3 className='m-0' style={{fontSize:16,color:'black'}}>${Number(props.shopperInfo.cash_accumulator).toFixed(2)}</h3>
                   <p style={{fontSize:12,color:'#c9c9c9',}} className='m-0'>Cash accumulator</p>
                 </div>
                </>
                :
                null
                }
               </div>
              ))} 
          </div>

          <div style={{backgroundColor:'white',marginTop:'5%',width:'100%',minHeight:100,borderRadius:9,border:'1px solid #f1f1f1'}}>
          <div style={{width:'100%',height:40,marginLeft:10,alignItems:'center',display:'flex'}}>
          <FaUser color='#e05b0e' style={{marginRight:10}} size={20}/>
          <p className='m-0'>Main Information</p> 
          </div>
          <div style={{width:'90%',marginLeft:'5%',height:1,backgroundColor:'#f1f1f1'}}/>
          <div style={{width:'97%',height:40,justifyContent:'space-between',marginLeft:10,alignItems:'center',display:'flex'}}>
          <p className='m-0'>Shopper ID</p> 
          <p className='m-0'>#{props.shopperInfo.userid}</p>
          </div>
          <div style={{width:'90%',marginLeft:'5%',height:1,backgroundColor:'#f1f1f1'}}/>
          <div style={{width:'97%',height:40,justifyContent:'space-between',marginLeft:10,alignItems:'center',display:'flex'}}>
          <p className='m-0'>Status</p> 
          <div style={{display:'flex',alignItems:'center'}}>
          {props.shopperInfo.connected?<div  style={{width:15,marginRight:5,height:15,borderRadius:10,backgroundColor:'green'}}/>:<div  style={{width:15,marginRight:5,height:15,borderRadius:10,backgroundColor:'red'}}/>}
          <p className='m-0' style={{marginLeft:10}}> {props.shopperInfo.connected?' Available':' Unavailable'}</p>
          </div>
          </div>
          <div style={{width:'90%',marginLeft:'5%',height:1,backgroundColor:'#f1f1f1'}}/>
          <div style={{width:'97%',height:40,justifyContent:'space-between',marginLeft:10,alignItems:'center',display:'flex'}}>
          <p className='m-0'>City</p> 
          <p className='m-0'>{props.shopperInfo.cityname?props.shopperInfo.cityname:'City Name'}</p>
          </div>
          <div style={{width:'90%',marginLeft:'5%',height:1,backgroundColor:'#f1f1f1'}}/>
          <div style={{width:'97%',height:40,justifyContent:'space-between',marginLeft:10,alignItems:'center',display:'flex'}}>
          <p className='m-0'>Mean of transport</p> 
          <p className='m-0'>{props.shopperInfo.drivertyp}</p>
          </div>
          <div style={{width:'90%',marginLeft:'5%',height:1,backgroundColor:'#f1f1f1'}}/>
          <div style={{width:'97%',height:40,justifyContent:'space-between',marginLeft:10,alignItems:'center',display:'flex'}}>
          <p className='m-0'>Type of contract</p> 
          <p className='m-0'>{props.shopperInfo.type==1?'Contractors':'Store Driver'}</p>
          </div>
        
          <div style={{width:'90%',marginLeft:'5%',height:1,backgroundColor:'#f1f1f1'}}/>
          <div style={{width:'97%',height:40,justifyContent:'space-between',marginLeft:10,alignItems:'center',display:'flex'}}>
          <p className='m-0'>Commissions plan</p> 
          <p className='m-0'>TrakiTrak Commission Plan</p>
          </div>
          <div style={{width:'90%',marginLeft:'5%',height:1,backgroundColor:'#f1f1f1'}}/>
          <div style={{width:'97%',height:40,justifyContent:'space-between',marginLeft:10,alignItems:'center',display:'flex'}}>
          <p className='m-0'>Location</p> 
          <a onClick={()=>{
             setViewport({
      latitude: props.shopperInfo.lat,
      longitude: props.shopperInfo.longg,
      zoom: 16,
    })
            
            setShow(true)
            }} className="link-hover-effect" >View On Map</a>

          </div>
        

          </div>
          
     </div>
//     <div style={{paddingBottom:20}}>
//         <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:100,justifyContent:'space-between'}}>
//         <label style={{marginRight:10}} className="form-label m-0">Connected: </label>
//         <span  className="m-0"style={{width:10,height:10,borderRadius:5,backgroundColor:props.shopperInfo.connected?'green':'red'}}/>
//         </div>
//         <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:65,justifyContent:'space-between',marginTop:15}}>
//         <label style={{marginRight:10}} className="form-label m-0">Active: </label>
//         <span  className="m-0"style={{width:10,height:10,borderRadius:5,backgroundColor:props.shopperInfo.active?'green':'red'}}/>
// </div>
// <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:90,justifyContent:'space-between',marginTop:15}}>
//         <label style={{marginRight:10}} className="form-label m-0">Approved: </label>
//         <span  className="m-0"style={{width:10,height:10,borderRadius:5,backgroundColor:props.shopperInfo.approved?'green':'red'}}/>
// </div>
//       <hr></hr>
//       <label className="form-label mt-1">Vehicle Type</label>
//      <input required type="text" className="form-control" min={0} max={100} name="slogan" value={props.shopperInfo.drivertyp} 
// />
// <label className="form-label mt-4">Last update</label>
//      <input required  className="form-control" min={0} max={100} name="slogan" value={String(props.shopperInfo.last_update)} 
// />
// <label className="form-label mt-4">Cash accumulator</label>
//      <input required  className="form-control" min={0} max={100} name="slogan" value={`$ ${props.shopperInfo.cash_accumulator}`} 
// />
// <label className="form-label mt-4">Points Earned</label>
//      <input required  className="form-control" min={0} max={100} name="slogan" value={props.shopperInfo.points} 

// />
// <label className="form-label mt-4">Number Of Cancellation</label>
//      <input required  className="form-control" min={0} max={100} name="slogan" value={props.shopperInfo.cancelled} 
// />
// <label className="form-label mt-4">Address</label>
//      <input required  className="form-control" min={0} max={100} name="slogan" value={props.shopperInfo.address} 
// />
// <label className="form-label mt-4">Address on Map</label>

// <Map
// ref={mapRef}
// style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
// mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
// initialViewState={{
//     latitude: props.shopperInfo.lat,
//     longitude: props.shopperInfo.longg,
//     zoom: 16,
//   }}
// //viewState={viewport}

// // onMouseOut={()=>setZoom(false)}
// // // onMove={evt => setViewport(evt.viewState)}
// // onClick={()=>setZoom(true)}
// // onTouchEnd={()=>setZoom(false)}
// scrollZoom={zoom}  
// mapStyle="mapbox://styles/mapbox/streets-v11"
// >
// <Marker

//   longitude={props.shopperInfo.longg}
//   latitude={props.shopperInfo.lat}
// />
// <GeolocateControl
// positionOptions={{ enableHighAccuracy: true }}
// trackUserLocation={true}
// />
// </Map>

//     </div>
  )
}
