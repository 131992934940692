import React, { useMemo, useState, useRef, useEffect } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
import { fetchData, sendData } from '../utils';
import { Alert, Modal ,Button} from 'react-bootstrap';
import Map, { GeolocateControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { DateRangePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
export default function Orders(props) { 
  const mapRef = useRef()
  const navigate = useNavigate()
  const [ordernumber, setOrdernumber] = useState(false);
  const [custname, setCustname] = useState(false);
  const [shoppername, setShoppername] = useState(false);
  const [storename, setstorename] = useState(false);
  const [acttype, setacttype] = useState(false);
  const [isdate, setIsdate] = useState(false);
  const [cities, setCities] = useState([]);
  const [subCitys, setSubCitys] = useState([]);
  const [orderidd, setOrderidd] = useState()
  






    const divRef = useRef(null)
    const divRef2 = useRef(null)
  
    

    const [showcalendar, setShowcalendar] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
 });
   const [showfilter, setshowfilter] = useState(true);
   const [isall, setIsall] = useState(true);
   const [isnew, setIsnew] = useState(false);

   const [delivered, setDelivered] = useState(false);
   const [delayed, setIsdelayed] = useState(false);
   const [missing, setMissing] = useState(false);
   const [cancelled, setCancelled] = useState(false);
   const [pendingcancelled, setispendingcancelled] = useState(false);
   const [inProgress, setInProgress] = useState(false);
    const columnHelper = createColumnHelper()
    const [viewport, setViewport] = useState({
      latitude: 33.888630,
      longitude: 35.495480,
      zoom: 16,
    });
    const [zoom, setZoom] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const handleCloseMap = () => {setShowMap(false)}
    const columns = [
      
      columnHelper.accessor('id', {
        id: 'id',
        cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
         header: () =><span>OrderId</span>,
         footer: info => info.column.id,
       }),
       columnHelper.accessor('number', {
        id: 'number',
        header: () =><p className='m-0' style={{minWidth:200}}>Order No.</p>,
        cell: info => <p  onClick={()=>{
          if(info.row.original.driverid!=null){
            navigate(`/order-summary/${info.row.original.driverid}/${info.row.original.id}`)
          }else{
            navigate(`/store/${info.row.original.storid}`)
          }
        }} className='m-0' style={{minWidth:200,cursor:'pointer'}}>{info.getValue()}</p>,
        footer: info => info.column.id,
       }),
         columnHelper.accessor('storid', {
          id: 'storid',
          cell: info => <p onClick={()=>(navigate(`/store/${info.getValue()}`))} className='m-0' style={{minWidth:100,cursor:'pointer'}}>{info.getValue()}</p>,
           header: () =><p className='m-0' style={{minWidth:100}}>Store Id</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('storename', {
          id: 'storename',
          cell: info => <p onClick={()=>(navigate(`/store/${info.row.original.storid}`))} className='m-0' style={{minWidth:100,cursor:'pointer'}}>{info.getValue()}</p>,
           header: () =><p className='m-0' style={{minWidth:100}}>Store Name</p>,
           footer: info => info.column.id,
         }),
         
         columnHelper.accessor('storecatname', {
            id: 'storecatname',
            cell: info => <p className='m-0' style={{minWidth:150}}>{info.getValue()}</p>,
            header: () =><p className='m-0' style={{minWidth:150}}>Store Category</p>,
           footer: info => info.column.id,
         }),
       
         columnHelper.accessor('Activity_Type', {
          id: 'Activity_Type',
          header: () =><p className='m-0' style={{minWidth:150}}>Activity Type</p>,
          cell: info => <p className='m-0' style={{minWidth:150}}>{info.row.original.beginn==1?'Immediate':'Schedule'}</p>,
          footer: info => info.column.id,
         }),
         columnHelper.accessor('branchid', {
          id: 'branchid',
          header: () =><span>Branch Id</span>,
          cell: info => <p onClick={()=>(navigate(`/store/branches/${info.getValue()}`))} className='m-0' style={{minWidth:100,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('branchname', {
          id: 'branchname',
          header: () =><span>Branch Name</span>,
          cell: info => <p onClick={()=>(navigate(`/store/branches/${info.row.original.branchid}`))} className='m-0' style={{minWidth:180,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('cityname', {
          id: 'cityname',
          header: () =><span>City Name</span>,
          cell: info => <p className='m-0' style={{minWidth:180}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('subcityname', {
          id: 'subcityname',
          header: () =><span>Sub City Name</span>,
          cell: info => <p className='m-0' style={{minWidth:180}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         

         columnHelper.accessor('productstotal', {
          id: 'productstotal',
          header: () =><span>Products Total</span>,
          cell: info => <p className='m-0' style={{minWidth:150}}>${info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('basetime', {
          id: 'basetime',
          header: () =><span>Base Time</span>,
          cell: info => <p className='m-0' style={{minWidth:150}}>{info.getValue()} Mins</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('begintime', {
          id: 'begintime',
          header: () =><p className='m-0' style={{minWidth:200}}>Begin Time</p>,
          cell: info => <p className='m-0' style={{minWidth:200}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
        
         columnHelper.accessor('driverid', {
          id: 'driverid',
          header: () =><p className='m-0' style={{minWidth:100}}>Shopper Id</p>,
          cell: info => <p onClick={()=>(navigate(`/shopper/${info.getValue()}`))} className='m-0' style={{minWidth:100,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('shopper_first_name', {
          id: 'shopper_first_name',
          header: () =><p className='m-0' style={{minWidth:150}}>Shopper First Name</p>,
          cell: info => <p onClick={()=>(navigate(`/shopper/${info.row.original.driverid}`))} className='m-0' style={{minWidth:150,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('shopper_last_name', {
          id: 'shopper_last_name',
          header: () =><p className='m-0' style={{minWidth:150}}>Shopper Last Name</p>,
          cell: info => <p onClick={()=>(navigate(`/shopper/${info.row.original.driverid}`))} className='m-0' style={{minWidth:150,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('shopper_phone', {
          id: 'shopper_phone',
          header: () =><p className='m-0' style={{minWidth:250}}>Shopper Phone Number</p>,
          cell: info => <p onClick={()=>(navigate(`/shopper/${info.row.original.driverid}`))} className='m-0' style={{minWidth:200,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('earnings.shoppers_earnings', {
          id: 'shoppers_earnings',
          header: () =><p className='m-0' style={{minWidth:200}}>Shopper Earning</p>,
          cell: info => <p className='m-0' style={{minWidth:200}}>${info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('drivertyp', {
          id: 'drivertyp',
          header: () =><p className='m-0' style={{minWidth:150}}>Vehicle Type</p>,
          cell: info => <p className='m-0' style={{minWidth:100}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),

         columnHelper.accessor('userid', {
          id: 'userid',
          header: () =><span>User Id</span>,
          cell: info => <p onClick={()=>(navigate(`/user/detail/${info.getValue()}`))} className='m-0' style={{minWidth:100,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('customer_first_name', {
          id: 'customer_first_name',
          header: () =><p className='m-0' style={{minWidth:175}}>Customer First Name</p>,
          cell: info => <p onClick={()=>(navigate(`/user/detail/${info.row.original.userid}`))}className='m-0' style={{minWidth:175,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('customer_last_name', {
          id: 'customer_last_name',
          header: () =><p className='m-0' style={{minWidth:175}}>Customer Last Name</p>,
          cell: info => <p onClick={()=>(navigate(`/user/detail/${info.row.original.userid}`))} className='m-0' style={{minWidth:175,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('address', {
          id: 'address',
          header: () =><p className='m-0' style={{minWidth:175}}>Customer Address</p>,
          cell: info => {
            const { latitude, longitude } = info.row.original; // Assuming the coordinates are available in the row's original data
        
            const openGoogleMaps = () => {
              const url = `https://www.google.com/maps?q=${info.row.original.lat},${info.row.original.longg}`;
              window.open(url, '_blank'); // Opens in a new tab
            };
        
            return (
              <p
                onClick={openGoogleMaps}
                className='m-0'
                style={{ minWidth: 175, cursor: 'pointer' }}
              >
                {info.getValue()}
              </p>
            );
          }, 
                    footer: info => info.column.id,
         }),
         columnHelper.accessor('customer_phone', {
          id: 'customer_phone',
          header: () =><p className='m-0' style={{minWidth:220}}>Customer Phone Number</p>,
          cell: info => <p onClick={()=>(navigate(`/user/detail/${info.row.original.userid}`))} className='m-0' style={{minWidth:175,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('email', {
          id: 'email',
          header: () =><p className='m-0' style={{minWidth:175}}>Customer Email</p>,
          cell: info => <p onClick={()=>(navigate(`/user/detail/${info.row.original.userid}`))} className='m-0' style={{minWidth:175,cursor:'pointer'}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('conf', {
          id: 'conf',
          header: () =><p className='m-0' style={{minWidth:150}}>Order Status</p>,
          cell: info => <p className='m-0' style={{minWidth:150}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('delivery_fee', {
          id: 'delivery_fee',
          header: () =><span>Delivery Cost</span>,
          cell: info => <i style={{paddingRight:100}}>${info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('delivered_at', {
          id: 'delivered_at',
          header: () =><span>Delivery Date</span>,
          cell: info => <i style={{paddingRight:100}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('delivered_at', {
          id: 'delivered_at',
          header: () =><span>Delivery Time</span>,
          cell: info => <i style={{paddingRight:100}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
        
         columnHelper.accessor('accept_date', {
          id: 'accept_date',
          header: () =><span>Order Accept Time</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('store_at', {
          id: 'store_at',
          header: () =><span>Arrived to store Time</span>,
          cell: info => <i style={{paddingRight:250}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('pick_start', {
          id: 'pick_start',
          header: () =><span>Pick start Time</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('pick_end', {
          id: 'pick_end',
          header: () =><span>Pick End Time</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('delivery_start', {
          id: 'delivery_start',
          header: () =><span>Delivery Start Time</span>,
          cell: info => <i style={{paddingRight:200}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('at_client', {
          id: 'at_client',
          header: () =><span>Arrived at Client Time</span>,
          cell: info => <i style={{paddingRight:250}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('delivered_at', {
          id: 'delivered_at',
          header: () =><p className='m-0' style={{minWidth:200}}>Delivered at</p>,
          cell: info => <p className='m-0' style={{minWidth:200}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('cancel_date', {
          id: 'cancel_date',
          header: () =><p className='m-0' style={{minWidth:200}}>Cancelled at</p>,
          cell: info => <p className='m-0' style={{minWidth:200}}>{info.getValue()}</p>,
           footer: info => info.column.id,
         }),
        //  columnHelper.accessor(pendingcancelled?`cancel_type`:` cancel_reason`, {
          //id: pendingcancelled?`cancel_type`:` cancel_reason`,
          columnHelper.accessor(`cancel_reason`, {
          id:'cancel_reason',
          header: () =><p className='m-0' style={{minWidth:250}}>Cancel Reason</p>,
          cell: info => <p className='m-0' style={{minWidth:250}}>{info.row.original.conf!='Cancelled'?null:info.getValue()==0?'Cancelled By User':info.getValue()==1?'Driver Choice':info.getValue()==2?'All Products Out Of Stock':info.getValue()==3?'Store Closed':info.getValue()==4?'Client Request During picking':info.getValue()==5?'Accident':info.getValue()==6?'Client Not Found':info.getValue()==7?'Client Request During delivery':info.getValue()==8?'Admin Cancellation':''}</p>,
           footer: info => info.column.id,
         }),
        //  return {
        //   ...rest,
        //   earnings: earnings_many[0] || null,
        //   bags: bags_many[0] || null,
        //   cart: cart5s[0] || null,
        // };
         columnHelper.accessor('cart.products_count', {
          id: 'products_count',
          header: () =><span>Ordered Quantity</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('cart.pick_count', {
          id: 'pick_count',
          header: () =><span>Picked Quantity</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),  
         columnHelper.accessor('cart.rejected_count', {
          id: 'rejected_count',
          header: () =><span>Rejected Quantity</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('cart.replaced_count', {
          id: 'replaced_count',
          header: () =><span>Replacement Quantity</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('cart.total_weight', {
          id: 'total_weight',
          header: () =><p className='m-0' style={{minWidth:150}}>Total Weight</p>,
          cell: info => <p className='m-0' style={{minWidth:150}}>{info.getValue()} Kg</p>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('bags.total_bags', {
          id: 'total_bags',
          header: () =><span>Bag Used</span>,
          cell: info => <i style={{paddingRight:150}}>{info.getValue()}</i>,
           footer: info => info.column.id,
         }),
    ]
    if(pendingcancelled){
      columns.push(
        columnHelper.accessor('actions', {
          id: 'actions',
          header: 'Actions',
          cell: info => (
              <div className="action-buttons" style={{display:'flex',alignItems:'center',minWidth:400}}>
                 <button  className="delete-button" onClick={() =>{
                  if (window.confirm('Are you sure you want to Reject this order?')) {
                  Reject(info.row.original.id);
                } }}>Reject</button>
                  <button  className="delete-button" 
                  onClick={() =>{
                    setShowDriver1(true)
                    setOrderid(info.row.original.id)
                    setType(info.row.original.cancel_type)
                    setOrderNumber(info.row.original.number);GetDrivers()}}>Assign</button>

                    {info.row.original.conf=='Picked'||info.row.original.conf=='Delivering'?null:
                                  <button  className="delete-button" onClick={() =>{if (window.confirm('Are you sure you want to Re-Assign this order?')) {
                                    reAssignOrder(info.row.original.cancel_type, info.row.original.id);
                                  } }}>Re-Assign</button>
                  }

                <button  className="view-button"   onClick={() => {
                  if (window.confirm('Are you sure you want to accept this order?')) {
                    AcceptOrder(info.row.original.cancel_type, info.row.original.id);
                  }
                }}>Accept</button>
              </div>
            ),
        }),
      )
      }
      if(delayed){
        columns.push(
          columnHelper.accessor('actions', {
            id: 'actions',
            header: 'Actions',
            cell: info => (
                <div className="action-buttons" style={{display:'flex',alignItems:'center',minWidth:400}}>
                  
                    <button  className="delete-button" 
                    onClick={() =>{
                      setShowDriver(true)
                      setOrderid(info.row.original.id)
                      setType(info.row.original.cancel_type)
                      setOrderNumber(info.row.original.number);GetDrivers()}}>Assign</button>
                  <button  className="delete-button" onClick={() =>{if (window.confirm('Are you sure you want to Re-Assign this order?')) {
                    reAssignOrderDelayed(info.row.original.cancel_type, info.row.original.id);
                  } }}>Re-Assign</button>
                    <button  className="delete-button" 
                     onClick={()=>{
                      setOrderidd(info.row.original.id)
                      handleCancelClick()
                    }}
          //           onClick={() =>{
          //    if (window.confirm("Are you sure you want to cancel?")) {
          //     // Second confirmation for payment option
          //     if (window.confirm("Do you want to proceed with payment?")) {
          //         console.log("User chose to proceed with payment.");
          //         cancelorder( info.row.original.id,true);
          //     } else {
          //         console.log("User chose to proceed without payment.");
          //         cancelorder( info.row.original.id,false);
          //     }
          // } else {
          //     console.log("User chose not to cancel.");
          //     // Handle the cancellation logic here
          // } }}
          >Cancel
          </button>
                 
                </div>
              ),
          }),
        )
        }
     if(inProgress){
      columns.push(
        columnHelper.accessor('actions', {
          id: 'actions',
          header: 'Actions',
          cell: info => (
              <div className="action-buttons" style={{display:'flex',alignItems:'center',minWidth:400}}>
          
          <button  className="delete-button" 
           onClick={()=>{
            setOrderidd(info.row.original.id)
            handleCancelClick()
          }}
          // onClick={() =>{
          //    if (window.confirm("Are you sure you want to cancel?")) {
          //     // Second confirmation for payment option
          //     if (window.confirm("Do you want to proceed with payment?")) {
          //         console.log("User chose to proceed with payment.");
          //         cancelorder( info.row.original.id,true);
          //     } else {
          //         console.log("User chose to proceed without payment.");
          //         cancelorder( info.row.original.id,false);
          //     }
          // } else {
          //     console.log("User chose not to cancel.");
          //     // Handle the cancellation logic here
          // } }}
          >Cancel
          </button>
                
                {/* <button  className="delete-button" 
                  onClick={() =>{
                    setShowDriver1(true)
                    setOrderid(info.row.original.id)
                    setType(info.row.original.cancel_type)
                    setOrderNumber(info.row.original.number);GetDrivers()}}>Assign</button> */}
                      {/* <button  className="delete-button" onClick={() =>{if (window.confirm('Are you sure you want to Re-Assign this order?')) {
                                    reAssignOrder(info.row.original.cancel_type, info.row.original.id);
                                  } }}>Re-Assign {info.row.original.conf}</button> */}
                    {info.row.original.conf=='Picked'||info.row.original.conf=='Delivering'?null:
                                  <button  className="delete-button" onClick={() =>{if (window.confirm('Are you sure you want to Re-Assign this order?')) {
                                    reAssignOrder(info.row.original.cancel_type, info.row.original.id);
                                  } }}>Re-Assign</button>
                  }
              </div>
            ),
        }),
      )
     }
     if(missing){
      columns.push(
        columnHelper.accessor('actions', {
          id: 'actions',
          header: 'Actions',
          cell: info => (
              <div className="action-buttons" style={{display:'flex',alignItems:'center',minWidth:400}}>
                 <button  className="delete-button" 
                    onClick={() =>{
                      setShowDriver(true)
                      setOrderid(info.row.original.id)
                      setType(info.row.original.cancel_type)
                      setOrderNumber(info.row.original.number);GetDrivers()}}>Assign</button>
            <button  className="delete-button" 
            onClick={()=>{
              setOrderidd(info.row.original.id)
              handleCancelClick()
            }}
          //   onClick={() =>{
          //    if (window.confirm("Are you sure you want to cancel?")) {
          //     // Second confirmation for payment option
          //     if (window.confirm("Do you want to proceed with payment?")) {
          //         console.log("User chose to proceed with payment.");
          //         cancelorder( info.row.original.id,true);
          //     } else {
          //         console.log("User chose to proceed without payment.");
          //         cancelorder( info.row.original.id,false);
          //     }
          // } else {
          //     console.log("User chose not to cancel.");
          //     // Handle the cancellation logic here
          // } }}
          >Cancel
          </button>

               
              </div>
            ),
        }),
      )
     }
     if(isall){
      columns.push(
        columnHelper.accessor('actions', {
          id: 'actions',
          header: 'Actions',
          cell: info => (
              <div className="action-buttons" style={{display:'flex',alignItems:'center',minWidth:400}}>
          {info.row.original.conf=='Delivered'||info.row.original.conf=='Cancelled'?
          null
          :
          <>
          
          <button  className="delete-button" 
            onClick={()=>{
              setOrderidd(info.row.original.id)
              handleCancelClick()
            }}
          // onClick={() =>{
          //    if (window.confirm("Are you sure you want to cancel?")) {
          //     // Second confirmation for payment option
          //     if (window.confirm("Do you want to proceed with payment?")) {
          //         console.log("User chose to proceed with payment.");
          //         cancelorder( info.row.original.id,true);
          //     } else {
          //         console.log("User chose to proceed without payment.");
          //         cancelorder( info.row.original.id,false);
          //     }
          // } else {
          //     console.log("User chose not to cancel.");
          //     // Handle the cancellation logic here
          // } }}
          >Cancel
          </button>
           <button  className="delete-button" 
           onClick={() =>{
             setShowDriver(true)
             setOrderid(info.row.original.id)
             setType(info.row.original.cancel_type)
             setOrderNumber(info.row.original.number);GetDrivers()}}>Assign</button>
             
             {info.row.original.conf=='Picked'||info.row.original.conf=='Delivering'?null:
                           <button  className="delete-button" onClick={() =>{if (window.confirm('Are you sure you want to Re-Assign this order?')) {
                             reAssignOrder(info.row.original.cancel_type, info.row.original.id);
                           } }}>Re-Assign</button>
           }
           </>
          }
               
               
              </div>
            ),
        }),
      )
     }
     const [filterdata, setFilterdata] = useState({
      id: '',
      branchid: '',
      storid: '',
      storecatname: "",
      storename: "",
      productstotal: '',
      conf: "",
      init_total: '',
      created_at: "",
      beginn: '',
      begintime: "",
      number: "",
      cancel_date: "",
      cancel_reason: '',
      shift_end: '',
      basetime: '',
      branchname: "",
      delivered_at: '',
      driver_conf: "",
      driver_cancel_date: "",
      cancel_type: '',
      driverid: '',
      shopper_first_name: "",
      shopper_last_name: "",
      drivertyp: 0,
      shopper_phone: "",
      userid: '',
      customer_first_name: "",
      customer_last_name: "",
      address: "",
      customer_phone: "",
      email: "",
      delivery_fee: '',
      accept_date: "",
      store_at: '',
      pick_start: '',
      pick_end: '',
      delivery_start: '',
      at_client: '',
      shoppers_earnings: "",
      total_bags: '',
      cityname: "",
      subcityname: '',
      products_count: '',
      pick_count: '',
      rejected_count: '',
      replaced_count: '',
      total_weight: ""
    });
    const [debouncedValue, setDebouncedValue] = useState(filterdata);

      const [all_count, setall_count] = useState(0);
      const [newcount, setNewcount] = useState(0);
      const [delivered_count, setdelivered_count] = useState(0);
      const [pending_cancellations_count, setpending_cancellations_count] = useState(0);
      const [cancelled_count, setcancelled_count] = useState(0);
      const [progress_count, setprogress_count] = useState(0);
      const [missing_count, setmissing_count] = useState(0);
      const [delayed_count, setdelayed_count] = useState(0);
      const [penidng_count, setPendingcount] = useState(0);
      const [activity_type, setactivity_type] = useState(3);
      const [customer_name, setcustomer_name] = useState('');
      const [shopper_name, setShopper_name] = useState('');
      const [store_name, setStore_name] = useState('');
      const [search, setsearch] = useState('');
      const [from, setFrom] = useState('');
      const [to, setTo] = useState('');
      const [timeoutId, setTimeoutId] = useState(null);

    
     
  
  const [loader, setLoader] = useState(true);
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(0);
  const [limitt, setLimitt] = useState(10);
  const [statuss, setStatus] = useState('0');

  const [showDriver1, setShowDriver1] = useState(false);


    const [showDriver, setShowDriver] = useState(false);
    const [loaderr, setloaderr] = useState(false);
    const [type, setType] = useState();
    const [orderid, setOrderid] = useState();
    const [orderNumber, setOrderNumber] = useState(false);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [visibleColumns1, setVisibleColumns1] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [data, setData] = React.useState(() => [])
    const [avDriver, SetAvDriver] = useState([]);
    const [reason, setReason] = useState(8);
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
  
    const handleCancelClick = () => setShowFirstModal(true);
  
    const handleFirstModalClose = (proceed) => {
      setShowFirstModal(false);
      if (proceed) setShowSecondModal(true);
    };
  
    const handleSecondModalClose = (proceedWithPayment) => {
      setShowSecondModal(false);
      if (proceedWithPayment !== null) {
        cancelorder(orderidd, proceedWithPayment);
      }
    };
    const GetDrivers = async ()=>{
      setloaderr(true)
      const {status, data, msg} = await fetchData(`/shoppers/available`);
      if(status){
        SetAvDriver(data)
        setloaderr(false)
          // setData(data)
          // setLoading(false)
       }else{
        setloaderr(false)
       }
  }
    const fetchOrders = async () => {
      setLoader(true)
      const {status, data, msg} = await sendData('/orders', JSON.stringify({
        status:statuss,
        from,
        to,
        search:`${filterdata.number?'LB-':''}${filterdata.number}`,
        customer_name:filterdata.customer_first_name,
        shopper_name:filterdata.shopper_first_name,
        store_name:filterdata.storename,
        activity_type:filterdata.Activity_Type,
        id:filterdata.id,
        branchid:filterdata.branchid,
        storid:filterdata.storid,
        storecatname:filterdata.storecatname,
        storename:filterdata.storename,
        productstotal:filterdata.productstotal,
        init_total:filterdata.init_total,
        begintime:filterdata.beginn,
        cancel_date:filterdata.cancel_date,
        cancel_reason:filterdata.cancel_reason,
        basetime:filterdata.basetime,
        branchname:filterdata.branchname,
        cancel_type:filterdata.cancel_type,
        driverid:filterdata.driverid,
        shopper_last_name:filterdata.shopper_last_name,
        drivertyp:filterdata.drivertyp,
        shopper_phone:filterdata.shopper_phone,
        userid:filterdata.userid,
        customer_last_name:filterdata.customer_last_name,
        address:filterdata.address,
        customer_phone:filterdata.customer_phone,
        email:filterdata.email,
        delivery_fee:filterdata.delivery_fee,
        accept_date:filterdata.accept_date,
        store_at:filterdata.store_at,
        pick_start:filterdata.pick_start,
        pick_end:filterdata.pick_end,
        delivery_start:filterdata.delivery_start,
        at_client:filterdata.at_client,
        shoppers_earnings:filterdata.shoppers_earnings,
        total_bags:filterdata.total_bags,
        cityname:filterdata.cityname,
        subcityname:filterdata.subcityname,
        products_count:filterdata.products_count,
        pick_count:filterdata.pick_count,
        rejected_count:filterdata.rejected_count,
        replaced_count:filterdata.replaced_count,
        total_weight:filterdata.total_weight,

















        




        page,
        limit:limitt
      }));
      if(status){
        const transformed = data.orders.map(item => {
          const { earnings_many, bags_many, cart5s, ...rest } = item;
          return {
            ...rest,
            earnings: earnings_many[0] || null,
            bags: bags_many[0] || null,
            cart: cart5s[0] || null,
          };
        });
        setData(transformed);
        let totalPagesx = Math.ceil(data.count / data.limit);
        if(totalPagesx == 0) totalPagesx = 1;
        setTotalPages(totalPagesx);
        setCount(data.count);
        setLoading(false)
        setLoader(false)
        setall_count(data.all_count)
        setNewcount(data.new_order_count)
        setdelivered_count(data.delivered_count)
        setpending_cancellations_count(data.pending_cancellations_count)
        setcancelled_count(data.cancelled_count)
        setprogress_count(data.progress_count)
        setmissing_count(data.missing_count)
        setdelayed_count(data.delayed_count)
        setPendingcount(data.pending_count)
   

   


        // setFirstFetch(true)
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
        // setFirstFetch(true)
      }
   
    }
    useEffect(() => {
      // Call fetchOrders initially
      fetchOrders();
  
      // Set up the interval to call fetchOrders every 60 seconds
      const interval = setInterval(() => {
        fetchOrders();
      }, 60000); // 60000 ms = 60 seconds
  
      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }, [statuss, page, limitt,debouncedValue]); 
    useEffect(() => {
      setPage(1)
      const handler = setTimeout(() => {
        setDebouncedValue(filterdata);
      }, 500); // 500 ms delay
  
      return () => clearTimeout(handler);
    }, [filterdata]);
  
    // Fetch data using debounced value
    // useEffect(() => {
    //   if (debouncedValue) {
    //     fetchOrders();
    //   }
    // }, [debouncedValue]);
    const cancelorder = async (checkoutbranchid,boolean) => {
      setLoader(true)
      const {status, data, msg} = await sendData('/orders/cancel', JSON.stringify({checkoutbranchid,pay:boolean}));
      if(status){
       fetchOrders()
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
   
      }
   
    }

    const AcceptOrder = async (type,checkoutbranchid) => {
      setLoader(true)
      const {status, data, msg} = await sendData('/orders/acceptcancel', JSON.stringify({type,checkoutbranchid,reassign:false}));
      if(status){
       fetchOrders()
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
   
      }
   
    }
    const assignOrder = async (type,checkoutbranchid,driverid) => {
      setLoader(true)
      const {status, data, msg} = await sendData('/orders/acceptcancel', JSON.stringify({type,checkoutbranchid,reassign:true,driverid:driverid}));
      if(status){
       fetchOrders()
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
   
      }
   
    }
    const reAssignOrder = async (type,checkoutbranchid) => {
      setLoader(true)
      const {status, data, msg} = await sendData('/orders/acceptcancel', JSON.stringify({type,checkoutbranchid,reassign:true}));
      if(status){
       fetchOrders()
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
   
      }
   
    }
    const reAssignOrderDelayed = async (type,checkoutbranchid) => {
      setLoader(true)
      const {status, data, msg} = await sendData('/orders/reassigndelayed', JSON.stringify({checkoutbranchid,}));
      if(status){
       fetchOrders()
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
   
      }
   
    }
    const assignOrderDelayed = async (type,checkoutbranchid,driverid) => {
      setLoader(true)
      setLoading(true)
      setShowDriver(false)
      const {status, data, msg} = await sendData('/orders/reassigndelayed', JSON.stringify({checkoutbranchid,driverid:driverid}));
      if(status){
       fetchOrders()
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
   
      }
   
    }
    
    const Reject = async (checkoutbranchid) => {
      setLoader(true)
      const {status, data, msg} = await sendData('/orders/rejectcancel', JSON.stringify({checkoutbranchid}));
      if(status){
       fetchOrders()
      }else{
        setLoader(false)
        setShowalert(true)
        setMsg(msg)
        setData([]);
        setLoading(false);
   
      }
   
    }
  const rerender = React.useReducer(() => ({}), {})[1]
  const handleSearch = () => {
    // Implement your search logic here
    fetchOrders()
  };

  const handleReset = () => {
    setsearch('');
    setcustomer_name('');
    setactivity_type('');
    setFrom('');
    setTo('')
  };
  const onClickClear = () => {
    setSelectedDateRange({
         startDate: new Date(),
         endDate: new Date(),
         key: "selection"
    });
    setFrom('')
    setTo('')
    setShowcalendar(false)
};
const onClickDone = () => {
 console.log(selectedDateRange.startDate.toISOString().substring(0,10))
 console.log(selectedDateRange.endDate.toISOString().substring(0,10))
 const startDate = new Date(selectedDateRange.startDate);
const endDate = new Date(selectedDateRange.endDate);

// Add one day to the start date
startDate.setDate(startDate.getDate() + 1);

// Subtract one day from the end date
endDate.setDate(endDate.getDate() + 1);
 setFrom(startDate.toISOString().substring(0,10))
 setTo(endDate.toISOString().substring(0,10))
  setShowcalendar(false)
};
const handleSelect = ranges => {
  setSelectedDateRange(ranges.selection);
  console.log(ranges.selection);
};
  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShow(false); // Step 4: Implement the Click Outside Logic
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Step 3: Remove Event Listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);
  const table = useReactTable({
    data,
    columns: columns.filter(column => visibleColumns.includes(column.id)),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection:true, //hoist up the row selection state to your own scope
    state: {
      rowSelection, //pass the row selection state back to the table instance
    },
  });
function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
useEffect(() => {
    
  

    // setSelectedRowIds([])
    let b =[]
    for (let i = 0; i < Object.keys(rowSelection).length; i++) {
     
        console.log(data[Object.keys(rowSelection)[i]])
    
         b.push(data[Object.keys(rowSelection)[i]])
         
         setSelectedRowIds(removeDuplicates(b, "Id"))
    }


    //setSelectedRowIds(b)
  }, [rowSelection, data]);
  const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
  const handleInputChange = (key, value) => {
    setFilterdata(prevData => ({
      ...prevData,
      [key]: value
    }));
   
  };
  useEffect(()=>{
    GetCities()
  },[])
  const GetCities = async ()=>{
    const {status, data, msg} = await fetchData(`/cities`);
    if(status){
      setCities(data)
        // setLoading(false)
     }
}
const getArea= async (x)=>{
  const {status, data, msg} = await fetchData(`/subcities/dropdown/${x}`);
  setSubCitys(data)
//  if(data.length>0){
//   setFilterdata((sdata[0].id))
//  }
  //console.log(data)
  //setCitys(data);
  }
  return (
    <div style={{overflowY:'scroll',height:'100vh'}} className="p-2">
        {/* <a  onClick={()=>{
          setLoading(true)
          fetchOrders()
        }}
        //href={`https://api.trakitrak.com/control/excel/exportadminproducts`} download 
        data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-danger buttonx me-2" style={{ fontSize: '13px',marginLeft:'5%' }}>
            Refresh
          </a> */}
          <svg style={{marginLeft:'5%',marginTop:'0.6%',cursor:'pointer'}} width={25} height={25} onClick={()=>{
          setLoading(true)
          fetchOrders()
        }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0">
            </g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 3V8M21 8H16M21 8L18 5.29168C16.4077 3.86656 14.3051 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.2832 21 19.8675 18.008 20.777 14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </path> </g></svg>
            {showAlerts()}
   
{loading?
  <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<Modal scrollable      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={showMap} onHide={handleCloseMap}>
   <Modal.Header style={{justifyContent:'space-between'}}>
        <Modal.Title>Location</Modal.Title>

        <Button style={{marginLeft:10}} onClick={handleCloseMap}>Close</Button>
    
       
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'#f1f1f1'}}>
        {viewport.latitude && viewport.longitude && (
<Map
ref={mapRef}
style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
initialViewState={viewport}
//viewState={viewport}
onMouseOut={()=>setZoom(false)}
//onMove={evt => setViewport(evt.viewState)}

onClick={()=>setZoom(true)}
onTouchEnd={()=>setZoom(false)}

scrollZoom={zoom}  // Disable scroll zooming
mapStyle="mapbox://styles/mapbox/streets-v11"
>
<Marker

  longitude={viewport.longitude}
  latitude={viewport.latitude}
/>
{/* <GeolocateControl
positionOptions={{ enableHighAccuracy: true }}
trackUserLocation={true}
/> */}
</Map>
)} 
    
   
  </Modal.Body>


</Modal>
<Modal  show={showDriver} onHide={()=>{setShowDriver(false)}} centered  dialogClassName="custom-modal" scrollable >
        <Modal.Header closeButton>{orderNumber}</Modal.Header>
        <Modal.Body style={{backgroundColor:'#f1f1f1'}}>
          {loaderr?
          <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'28%'}}>
          <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        </div>
          :
          <>
          {avDriver.length==0?
           <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
           <p>There is no Shoppers  connected</p>
          </div>
          :
          <>
       
          {avDriver.map((item,i)=>(
           <div onClick={()=>{
            if (window.confirm(`Are you sure you want to Assign this order to ${item.user.name} ${item.user.lastname}?`)) {
              assignOrderDelayed(type, orderid,item.userid);
            }
           }} className='driveritem'  style={{display:'flex',backgroundColor:'white',borderRadius:7,padding:10,cursor:'pointer',alignItems:'center'}}>
            <div style={{width:'10%'}}>
            <img src={item.img} style={{width:70,height:70,objectFit:'contain',borderRadius:40}} />

            </div>
            <div style={{marginLeft:10,width:'70%'}}>
            <p className='m-0'>Driver Id: {item.userid}</p>
            <p className='m-0'>Driver Name: {item.user.name} {item.user.lastname} </p>
            <p className='m-0'>Driver Phone Number: {item.phone}</p>
            <p className='m-0'>vehicle Type: {item.drivertyp}</p>
            <p className='m-0'>Average rating: {Number(item.ratings_avg_rating).toFixed(2)}</p>
            </div>
            <div style={{width:'20%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <a onClick={()=>{
                           setViewport({
                    latitude:item.lat,
                    longitude: item.longg,
                    zoom: 16,
                  })
                          
                          setShowMap(true)
            }} className="link-hover-effect" >View On Map</a>
            </div>
     
            
           </div>
          ))}
             </>}
          </>
          }
        </Modal.Body>
</Modal>
<Modal  show={showDriver1} onHide={()=>{setShowDriver1(false)}} centered  dialogClassName="custom-modal" scrollable >
        <Modal.Header closeButton>{orderNumber}</Modal.Header>
        <Modal.Body style={{backgroundColor:'#f1f1f1'}}>
          {loaderr?
          <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'28%'}}>
          <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        </div>
          :
          <>
          {avDriver.length==0?
           <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
           <p>There is no Shoppers  connected</p>
          </div>
          :
          <>
       
          {avDriver.map((item,i)=>(
           <div onClick={()=>{
            if (window.confirm(`Are you sure you want to Assign this order to ${item.user.name} ${item.user.lastname}?`)) {
              assignOrder(type, orderid,item.userid);
            }
           }} className='driveritem'  style={{display:'flex',backgroundColor:'white',borderRadius:7,padding:10,cursor:'pointer',alignItems:'center'}}>
            <div style={{width:'10%'}}>
            <img src={item.img} style={{width:70,height:70,objectFit:'contain',borderRadius:40}} />

            </div>
            <div style={{marginLeft:10,width:'70%'}}>
            <p className='m-0'>Driver Id: {item.userid}</p>
            <p className='m-0'>Driver Name: {item.user.name} {item.user.lastname} </p>
            <p className='m-0'>Driver Phone Number: {item.phone}</p>
            <p className='m-0'>vehicle Type: {item.drivertyp}</p>
            <p className='m-0'>Average rating: {Number(item.ratings_avg_rating).toFixed(2)}</p>
            </div>
            <div style={{width:'20%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <a onClick={()=>{
                           setViewport({
                    latitude:item.lat,
                    longitude: item.longg,
                    zoom: 16,
                  })
                          
                          setShowMap(true)
            }} className="link-hover-effect" >View On Map</a>
            </div>
     
            
           </div>
          ))}
             </>}
          </>
          }
        </Modal.Body>
</Modal>
 {/* First Confirmation Modal */}
 <Modal show={showFirstModal} onHide={() => handleFirstModalClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
        <Modal.Footer>
        <p>Cancel Reason</p>
          <select className='form-control'  value={reason} onChange={(e) => setReason(e.target.value)}>
            <option value="6">User Requested</option>
            <option value="7">User Not Found</option>
            <option value="8">Admin Cancellation</option>
        
          </select>
          <Button variant="secondary" onClick={() => handleFirstModalClose(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => {
            if(reason==8){
              handleFirstModalClose(true)
            }else if(reason==6){
              AcceptOrder(6, orderidd)
            }
            else if(reason==7){
              AcceptOrder(7, orderidd)
            }

            }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* Second Confirmation Modal */}
      <Modal show={showSecondModal} onHide={() => handleSecondModalClose(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Proceed with Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to proceed with payment?</Modal.Body>
        <Modal.Footer>
          
          <Button variant="secondary" onClick={() => handleSecondModalClose(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleSecondModalClose(true)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
<div onClick={()=>{setshowfilter(!showfilter)}} style={{display:'flex',marginLeft:'1%',paddingTop:'3%',cursor:'pointer',alignItems:'center',}}>
            {/* {!showfilter?
<svg style={{marginRight:10}} width="20px" height="20px" viewBox="0 -4.5 20 20" version="1.1">
    
    <title>arrow_down [#338]</title>
    <desc>Created with Sketch.</desc>
    <defs>

</defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#000000">
            <g id="icons" transform="translate(56.000000, 160.000000)">
                <path d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583" id="arrow_down-[#338]">

</path>
            </g>
        </g>
    </g>
</svg>
:
<svg style={{marginRight:10}} width="20px" height="20px" viewBox="0 -4.5 20 20" version="1.1">
    
    <title>arrow_up [#337]</title>
    <desc>Created with Sketch.</desc>
    <defs>

</defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Dribbble-Light-Preview" transform="translate(-260.000000, -6684.000000)" fill="#000000">
            <g id="icons" transform="translate(56.000000, 160.000000)">
                <path d="M223.707692,6534.63378 L223.707692,6534.63378 C224.097436,6534.22888 224.097436,6533.57338 223.707692,6533.16951 L215.444127,6524.60657 C214.66364,6523.79781 213.397472,6523.79781 212.616986,6524.60657 L204.29246,6533.23165 C203.906714,6533.6324 203.901717,6534.27962 204.282467,6534.68555 C204.671211,6535.10081 205.31179,6535.10495 205.70653,6534.69695 L213.323521,6526.80297 C213.714264,6526.39807 214.346848,6526.39807 214.737591,6526.80297 L222.294621,6534.63378 C222.684365,6535.03868 223.317949,6535.03868 223.707692,6534.63378" id="arrow_up-[#337]">

</path>
            </g>
        </g>
    </g>
</svg>
} */}
<p className='m-0'   style={{color:'#e05b0e',marginLeft:'1%',fontSize:25,cursor:'pointer'}}>Filter</p>
<div ref={divRef2} style={{display:'flex',position:'relative',justifyContent:'space-between',alignItems:'center'}}>
  
<button style={{marginRight:20}} onClick={()=>setShow1(!show1)} id="ember5628" class="btn o__secondary o__in-right-list js__displayed-attributes-toggle ember-view" role="button" type="button">  
  <svg class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"></path><path d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"></path><path d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"></path></svg>
  <svg class="interface-icon o__standard o__standard__small-down-arrow" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.79491 10.1807C7.18432 10.6065 7.81568 10.6064 8.20509 10.1807L11.2942 6.80291C11.7339 6.32211 11.4225 5.5 10.8006 5.5L4.1994 5.5C3.57755 5.5 3.26612 6.32211 3.70584 6.80291L6.79491 10.1807Z"></path></svg>
</button>


</div>
{show1&&
<div className='dropdownn1'>
{columns.map(column => (
 <>
  {column.id=='conf'||column.id=='actions'||column.id=='delivered_at'?null:
  <div style={{marginLeft:10}} key={column.id}>
      <label style={{display:'flex',alignItems:'center'}}>

        <input
        style={{marginRight:5}}
          type="checkbox"
          checked={visibleColumns1.includes(column.id)}
          onChange={e => {
            const isChecked = e.target.checked;
            setVisibleColumns1(current =>
              isChecked
                ? [...current, column.id]
                : current.filter(v => v !== column.id)
            );
            if(!isChecked){
              setFilterdata(prevData => ({
                ...prevData,
                [column.id]: ''
              }));
            }
          }}
        />
        <svg style={{margin:0}} class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"></path><path d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"></path><path d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"></path></svg>
            

      <p style={{marginLeft:7,marginTop:0,marginBottom:0}}>{column.id}</p>
      </label>
    </div>}
    </>
  ))}
  <div style={{marginLeft:10}}>
      <label style={{display:'flex',alignItems:'center'}}>

        <input
        style={{marginRight:5}}
          type="checkbox"
          checked={visibleColumns1.includes('Delivery_Date')}
          onChange={e => {
            const isChecked = e.target.checked;
            setVisibleColumns1(current =>
              isChecked
                ? [...current, 'Delivery_Date']
                : current.filter(v => v !== 'Delivery_Date')
            );
          }}
        />
        <svg style={{margin:0}} class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"></path><path d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"></path><path d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"></path></svg>
            

      <p style={{marginLeft:7,marginTop:0,marginBottom:0}}>Delivery_Date</p>
      </label>
    </div>
    </div>}
   
</div>
<div style={{ display: 'flex', flexWrap: 'wrap' }}>
     {visibleColumns1.map((inputKey) => (
       <div style={{display:'flex',flexDirection:'column'}}>
        <label>{inputKey}</label>
        <div className="filterItem" key={inputKey}>

          {inputKey=='Activity_Type'?
            <select  value={data[inputKey]} onChange={(e) => handleInputChange(inputKey, e.target.value)}>
            <option value="1">Immediate</option>
            <option value="2">Scheduled</option>
            <option value="0">Any</option>
        
          </select>
          :inputKey=='drivertyp'?
          <select  value={data[inputKey]} onChange={(e) => handleInputChange(inputKey, e.target.value)}>
          <option value="1">Car</option>
          <option value="2">Moto</option>
          <option value="0">Any</option>
      
        </select>
        :
        inputKey=='cityname'?
        <select  value={data[inputKey]} onChange={(e) =>{ handleInputChange(inputKey, e.target.value);getArea(e.target.value)}}>
      <option value="">Any</option>
  {cities.map((item,i)=>(
    <option value={item.name}>{item.name}</option>

  ))}
        </select>:
           inputKey=='subcityname'?
           <select  value={data[inputKey]} onChange={(e) => handleInputChange(inputKey, e.target.value)}>
         <option value="">Any</option>
     {subCitys.map((item,i)=>(
       <option value={item.name}>{item.name}</option>
   
     ))}
           </select>
        :
        inputKey=='cancel_type'?
        <select  value={data[inputKey]} onChange={(e) => handleInputChange(inputKey, e.target.value)}>
        <option value="0">User from app</option>
        <option value="1">By Choice</option>
        <option value="2">Out Of Stock</option>
        <option value="3">Store Closed</option>
        <option value="4">Clienyt Request</option>
        <option value="5">Accident</option>
        <option value="6">Client Not Found</option>
        <option value="7">Client Request in delivery</option>
        <option value="8">Admin Cancel</option>
        <option value="">Any</option>
     
    
      </select>
           :inputKey=='at_client'?
           <select  value={data[inputKey]} onChange={(e) => handleInputChange(inputKey, e.target.value)}>
           <option value="1">Yes</option>
           <option value="2">No</option>
           <option value="0">Any</option>
       
         </select>

          :inputKey=='cancel_date'||inputKey=='delivered_at'||inputKey=='delivery_start'||inputKey=='pick_end'||inputKey=='pick_start'||inputKey=='store_at'||inputKey=='accept_date'?
          <input
           type='date'
           placeholder={'Search '+inputKey}
           value={data[inputKey]}
           onChange={(e) => handleInputChange(inputKey, e.target.value)}
         />
         :
          inputKey=='begintime'?
          <input
           type='date'
           placeholder={'Search '+inputKey}
           value={data[inputKey]}
           onChange={(e) => handleInputChange(inputKey, e.target.value)}
         />
         :
         inputKey=='Delivery_Date'?
        <>
         <div  onClick={()=>setShowcalendar(true)} style={{cursor:'pointer',marginLeft:'2%',minWidth:'35%',width:'auto',padding:'0 5px 0 10px',display:'flex',alignItems:'center',height:40}} className="form-control">

<svg style={{marginRight:10}} xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  width="18.000000pt" height="18.000000pt" viewBox="0 0 512 512">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#616161" stroke="none">
<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
-146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
-310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
-456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
-1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
</g>
</svg>

<p className='m-0' style={{marginLeft:15,paddingRight:50}}>{from&&to?from+' - '+to:'Date'}</p>
  
</div>
{showcalendar&&(
  <div className="custom-date-range-picker">
     
  <DateRangePicker

                           onChange={handleSelect}
                           showSelectionPreview={false}
                           moveRangeOnFirstSelection={false}
                           showMonthAndYearPickers={true}
                          //  months={2}
                          showDateDisplay={false}
                           ranges={[selectedDateRange]}
                           direction="horizontal"
                      />
                    <div style={{}}>
                           <button
                                className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
                                onClick={onClickDone}
                                
                           >
                                Done
                           </button>
                           <button
                                className="btn btn-transparent text-danger rounded-0 px-4"
                                onClick={onClickClear}
                           >
                                Clear
                           </button>
                      </div>    
  </div>
)}
        </>
          :
           <input
           type="text"
           placeholder={'Search '+inputKey}
           value={data[inputKey]}
           onChange={(e) => handleInputChange(inputKey, e.target.value)}
         />
          }
         
        </div>
        </div>
      ))}
      </div>
{/* <Button onClick={()=>{console.log(filterdata)}}>Save</Button> */}
{/* {showfilter?
<div className="filterBar">
  <div style={{display:'flex',alignItems:'center'}}>
  <div className="filterItem">
      
      </div>
      <div className="filterItem">
        <input
          type="text"
          value={search}
          onChange={(e) => setsearch(e.target.value)}
          placeholder="Order Number"
        />
      </div>
      
      <div className="filterItem">
        <input
          type="text"
          value={customer_name}
          onChange={(e) => setcustomer_name(e.target.value)}
          placeholder="Customer Name"
        />
      </div>
      <div className="filterItem">
        <input
          type="text"
          value={shopper_name}
          onChange={(e) => setShopper_name(e.target.value)}
          placeholder="Shopper Name"
        />
      </div>
      <div className="filterItem">
        <input
          type="text"
          value={store_name}
          onChange={(e) => setStore_name(e.target.value)}
          placeholder="Store Name"
        />
      </div>
      <div className="filterItem">
      <select value={activity_type} onChange={(e) => setactivity_type(e.target.value)}>
          <option value="1">Immediate</option>
          <option value="2">Scheduled</option>
          <option value="0">Any</option>
      
        </select>
        
      </div>
      <div  onClick={()=>setShowcalendar(true)} style={{cursor:'pointer',marginLeft:'2%',minWidth:'25%',width:'auto',padding:'0 5px 0 10px',display:'flex',alignItems:'center',height:40}} className="form-control">

<svg style={{marginRight:10}} xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  width="18.000000pt" height="18.000000pt" viewBox="0 0 512 512">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#616161" stroke="none">
<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
-146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
-310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
-456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
-1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
</g>
</svg>

<p className='m-0' style={{marginLeft:15}}>{from&&to?from+' - '+to:'Date'}</p>
  
</div>
{showcalendar&&(
  <div className="custom-date-range-picker">
     
  <DateRangePicker

                           onChange={handleSelect}
                           showSelectionPreview={false}
                           moveRangeOnFirstSelection={false}
                           showMonthAndYearPickers={true}
                          //  months={2}
                          showDateDisplay={false}
                           ranges={[selectedDateRange]}
                           direction="horizontal"
                      />
                    <div style={{}}>
                           <button
                                className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
                                onClick={onClickDone}
                                
                           >
                                Done
                           </button>
                           <button
                                className="btn btn-transparent text-danger rounded-0 px-4"
                                onClick={onClickClear}
                           >
                                Clear
                           </button>
                      </div>    
  </div>
)}
  </div>

  
  
    

    </div>:null} */}





      <div className="orderStatusContainer">
            <div 
            onClick={()=>{
              setPage(1)
              setIsall(true)
              setispendingcancelled(false)
              setDelivered(false)
              setCancelled(false)
              setInProgress(false)
              setMissing(false)
              setIsdelayed(false)
              setIsnew(false)
              setStatus(0)
              }}className="statusItem allOrders" style={{minWidth:props.isopen?130:150,backgroundColor:isall?'#e05b0e':'#f1f1f1',color:isall?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?14:16}}>All Orders</p>
              <p className='m-0' style={{fontSize:props.isopen?14:16}} >{all_count}</p>
            </div>
            <div 
            onClick={()=>{
              setPage(1)
              setIsall(false)
              setIsnew(true)
              setispendingcancelled(false)
              setDelivered(false)
              setCancelled(false)
              setInProgress(false)
              setMissing(false)
              setIsdelayed(false)
              setStatus('new_order')
              }}className="statusItem" style={{minWidth:props.isopen?100:130,backgroundColor:isnew?'#e05b0e':'#f1f1f1',color:isnew?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?12:14}}>New Orders</p>
              <p className='m-0' style={{fontSize:props.isopen?12:14}} >{newcount}</p>
            </div>
            <div 
            onClick={()=>{
              setPage(1)
              setIsall(false)
              setDelivered(true)
              setCancelled(false)
              setInProgress(false)
              setMissing(false)
              setIsnew(false)
              setispendingcancelled(false)
              setIsdelayed(false)
              setStatus('Delivered')
              }}
              className="statusItem" style={{minWidth:props.isopen?130:140,backgroundColor:delivered?'#e05b0e':'#f1f1f1',color:delivered?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?14:16}}>Delivered</p>
            <p className='m-0' style={{fontSize:props.isopen?14:16}} >{delivered_count}</p>
            </div>
            <div onClick={()=>{ 
              setPage(1)
              setIsall(false)
              setDelivered(false)
              setCancelled(false)
              setispendingcancelled(true)
              setInProgress(false)
              setMissing(false)
              setIsnew(false)
              setIsdelayed(false)
              setStatus('Pending Cancellations')
              }} className="statusItem" style={{minWidth:props.isopen?120:160,backgroundColor:pendingcancelled?'#e05b0e':'#f1f1f1',color:pendingcancelled?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?14:16}}>Pending Cancellations</p>
            <p className='m-0' style={{fontSize:props.isopen?14:16}} >{pending_cancellations_count}</p>
            </div>
            <div onClick={()=>{
               setPage(1)
              setIsall(false)
              setispendingcancelled(false)
              setDelivered(false)
              setCancelled(true)
              setInProgress(false)
              setMissing(false)
              setIsnew(false)
              setIsdelayed(false)
            
              setStatus('Cancelled')
              }} className="statusItem" style={{minWidth:props.isopen?140:160,backgroundColor:cancelled?'#e05b0e':'#f1f1f1',color:cancelled?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?14:16}}>Cancelled</p>
            <p className='m-0' style={{fontSize:props.isopen?14:16}} >{cancelled_count}</p>
            </div>
            <div 
            onClick={()=>{
              setPage(1)
              setIsall(false)
              setDelivered(false)
              setCancelled(false)
              setispendingcancelled(false)
              setInProgress(false)
              setMissing(false)
              setIsnew(false)
              setIsdelayed(true)
              setStatus('Delayed')
              }}
              className="statusItem" style={{minWidth:props.isopen?130:160,backgroundColor:delayed?'#e05b0e':'#f1f1f1',color:delayed?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?14:16}}>Delayed</p>
            <p className='m-0' style={{fontSize:props.isopen?14:16}} >{delayed_count}</p>
            </div>
            <div onClick={()=>{
               setPage(1)
              setIsall(false)
              setDelivered(false)
              setCancelled(false)
              setInProgress(true)
              setispendingcancelled(false)
              setMissing(false)
              setIsnew(false)
              setIsdelayed(false)
              setStatus('Progress')
              }} className="statusItem" style={{minWidth:props.isopen?130:160,backgroundColor:inProgress?'#e05b0e':'#f1f1f1',color:inProgress?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?14:16}}>In Progress</p>
            <p className='m-0' style={{fontSize:props.isopen?14:16}} >{progress_count}</p>
            </div>
            <div onClick={()=>{
               setPage(1)
              setIsall(false)
              setDelivered(false)
              setCancelled(false)
              setInProgress(false)
              setispendingcancelled(false)
              setMissing(true)
              setIsnew(false)
              setIsdelayed(false)
              setStatus('Pending')
              }} className="statusItem" style={{minWidth:props.isopen?130:160,backgroundColor:missing?'#e05b0e':'#f1f1f1',color:missing?'white':'black'}}>
              <p className='m-0' style={{fontSize:props.isopen?14:16}}>Pending</p>
            <p className='m-0' style={{fontSize:props.isopen?14:16}} >{penidng_count}</p>
            </div>
      </div>

    <div ref={divRef} style={{display:'flex',position:'relative',justifyContent:'space-between',alignItems:'center',zIndex:show1?-1:10}}>
    <p className='m-0' style={{marginLeft:5}}>{count} Orders</p>
    <a  
        href={`https://api.trakitrak.com/control/excel/exportOrdersToExcel`} download 
        data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-danger buttonx me-2 exportbuttons" style={{ fontSize: '13px',marginLeft:'77%' }}>
            Export
          </a>
<button style={{marginRight:20}} onClick={()=>setShow(!show)} id="ember5628" class="btn o__secondary o__in-right-list js__displayed-attributes-toggle ember-view" role="button" type="button">  
  <svg class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"></path><path d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"></path><path d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"></path></svg>
  <svg class="interface-icon o__standard o__standard__small-down-arrow" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.79491 10.1807C7.18432 10.6065 7.81568 10.6064 8.20509 10.1807L11.2942 6.80291C11.7339 6.32211 11.4225 5.5 10.8006 5.5L4.1994 5.5C3.57755 5.5 3.26612 6.32211 3.70584 6.80291L6.79491 10.1807Z"></path></svg>
</button>

{show&&
<div className='dropdownn'>
{columns.map(column => (
    <div style={{marginLeft:10}} key={column.id}>
      <label style={{display:'flex',alignItems:'center'}}>

        <input
        style={{marginRight:5}}
          type="checkbox"
          checked={visibleColumns.includes(column.id)}
          onChange={e => {
            const isChecked = e.target.checked;
            setVisibleColumns(current =>
              isChecked
                ? [...current, column.id]
                : current.filter(v => v !== column.id)
            );
          }}
        />
        {column.id=='Customer_Email'?<svg style={{marginLeft:7,margin:0}} fill="#000000" width="16px" height="16px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z" fill-rule="evenodd"/>
            </svg>:
        column.id=='Rejected_Quantity'?
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M729 2532 c-69 -36 -106 -88 -119 -167 -5 -33 -10 -240 -10 -461 l0
-401 113 -6 c132 -7 226 -31 336 -87 84 -42 217 -143 245 -185 l16 -25 423 0
c412 0 424 -1 445 -20 14 -14 22 -33 22 -55 0 -22 -8 -41 -22 -55 -21 -19 -33
-20 -391 -20 -348 0 -369 -1 -362 -18 28 -66 56 -157 66 -214 l12 -68 327 0
c314 0 327 -1 348 -20 14 -14 22 -33 22 -55 0 -22 -8 -41 -22 -55 -21 -19 -34
-20 -348 -20 l-326 0 -18 -89 c-38 -188 -142 -360 -291 -479 l-40 -32 611 0
610 0 55 28 c69 35 106 87 119 167 6 36 10 394 10 888 l0 827 -303 0 c-291 0
-304 1 -325 20 -22 21 -22 25 -22 325 l0 305 -564 0 -564 0 -53 -28z m1449
-902 c30 -28 30 -82 0 -110 -21 -20 -32 -20 -598 -20 -566 0 -577 0 -598 20
-14 14 -22 33 -22 55 0 22 8 41 22 55 21 20 32 20 598 20 566 0 577 0 598 -20z"/>
<path d="M2050 2285 l0 -225 232 0 233 0 -225 225 c-124 124 -228 225 -232
225 -5 0 -8 -101 -8 -225z"/>
<path d="M545 1335 c-206 -45 -375 -176 -464 -357 -54 -111 -71 -182 -71 -303
0 -122 16 -192 72 -305 32 -67 58 -102 122 -166 142 -141 280 -199 476 -199
196 0 334 58 476 199 133 133 194 281 194 471 0 122 -16 192 -72 305 -57 119
-177 238 -298 297 -138 68 -296 89 -435 58z m41 -457 l94 -92 94 92 c83 82 97
92 129 92 44 0 77 -31 77 -72 0 -23 -20 -49 -94 -126 l-94 -97 94 -97 c74 -77
94 -103 94 -125 0 -42 -33 -73 -77 -73 -32 0 -46 10 -129 92 l-94 92 -94 -92
c-83 -82 -97 -92 -129 -92 -44 0 -77 31 -77 73 0 22 20 48 94 125 l94 97 -94
97 c-74 77 -94 103 -94 126 0 41 33 72 77 72 32 0 46 -10 129 -92z"/>
</g>
       </svg>:
        column.id=='Replacement_Quantity'?
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="16px" height="16px"viewBox="0 0 256.000000 256.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M1410 2542 c-52 -25 -70 -70 -70 -172 l0 -86 -93 -12 c-324 -41 -640
       -224 -835 -485 -125 -167 -195 -323 -234 -516 -24 -124 -19 -152 32 -169 40
       -14 75 8 146 92 168 198 409 323 684 357 88 10 249 6 285 -7 12 -5 15 -25 15
       -92 0 -110 23 -163 79 -186 76 -30 90 -20 364 252 140 139 258 265 269 287 25
       47 29 131 9 190 -11 31 -68 95 -245 274 -127 128 -251 247 -275 262 -52 33
       -82 36 -131 11z"/>
       <path d="M2298 1456 c-15 -6 -57 -47 -95 -91 -90 -105 -193 -183 -328 -250
       -120 -59 -221 -89 -360 -106 -83 -10 -245 -6 -280 7 -12 5 -15 25 -15 93 0 62
       -5 95 -18 120 -38 75 -108 93 -184 48 -58 -34 -490 -472 -511 -519 -28 -59
       -32 -119 -12 -179 16 -49 40 -77 259 -297 132 -134 258 -252 278 -263 45 -23
       67 -24 114 -4 50 21 73 77 74 180 l0 81 93 12 c408 52 777 316 962 690 48 97
       98 251 116 356 10 62 10 70 -8 93 -25 34 -53 43 -85 29z"/>
       </g>
       </svg>:
        column.id=='Picked_Quantity'?<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M257 2370 c-38 -30 -52 -80 -33 -123 20 -49 59 -67 148 -67 l73 0
       147 -737 c165 -825 155 -792 253 -842 l49 -26 546 -3 c300 -2 565 0 589 3 96
       14 134 108 72 177 l-29 33 -565 3 -565 2 -11 51 c-6 27 -11 59 -11 69 0 20 12
       20 573 20 532 0 575 2 612 19 57 26 111 90 129 153 31 107 176 779 176 813 0
       46 -21 87 -57 111 -27 18 -62 19 -845 22 -764 2 -817 4 -822 20 -2 9 -12 55
       -21 102 -20 97 -32 124 -72 161 -53 50 -84 59 -201 59 -94 0 -113 -3 -135 -20z
       m1395 -767 c117 -117 128 -130 128 -163 0 -39 -18 -63 -57 -74 -28 -9 -57 6
       -110 57 l-43 41 0 -134 c0 -124 -2 -135 -22 -157 -12 -13 -33 -23 -49 -23 -74
       0 -89 32 -89 186 l0 117 -45 -42 c-53 -49 -93 -54 -130 -16 -48 47 -38 68 103
       208 104 102 134 127 157 127 23 0 53 -25 157 -127z"/>
       <path d="M940 525 c-69 -32 -110 -94 -110 -169 0 -110 75 -186 185 -186 183 0
       252 234 99 339 -42 29 -127 37 -174 16z"/>
       <path d="M1740 525 c-69 -32 -110 -94 -110 -169 0 -110 75 -186 185 -186 183
       0 252 234 99 339 -42 29 -127 37 -174 16z"/>
       </g>
       </svg>:
       column.id=='Total_Weight'?
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
       preserveAspectRatio="xMidYMid meet">
      
      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M1191 2549 c-158 -31 -309 -170 -352 -324 -20 -71 -17 -198 5 -265
      19 -55 75 -152 95 -164 34 -21 -7 -26 -234 -26 l-244 0 -35 -31 c-29 -25 -37
      -42 -50 -102 -37 -169 -266 -1476 -266 -1515 0 -34 7 -49 34 -80 l34 -37 1102
      0 1102 0 34 37 c27 31 34 46 34 80 0 39 -229 1346 -266 1515 -13 60 -21 77
      -50 102 l-35 31 -244 0 c-227 0 -268 5 -234 26 20 12 76 109 95 164 22 67 25
      194 5 265 -62 222 -302 369 -530 324z m177 -268 c72 -37 112 -102 112 -181 0
      -113 -87 -200 -200 -200 -214 0 -277 282 -85 383 42 23 127 21 173 -2z m308
      -1146 c54 -17 81 -36 108 -77 15 -23 16 -31 4 -55 -19 -40 -69 -44 -100 -8
      -32 38 -70 55 -118 55 -85 0 -140 -47 -160 -135 -42 -186 97 -320 250 -242 40
      19 45 26 48 60 l3 37 -50 0 c-38 0 -56 5 -71 20 -24 24 -24 29 -4 58 14 20 23
      22 118 22 66 0 106 -4 114 -12 8 -8 12 -48 12 -113 0 -112 -2 -116 -69 -150
      -184 -93 -390 -38 -452 123 -31 81 -31 198 0 268 59 134 212 196 367 149z
      m-822 -11 c13 -12 16 -39 16 -125 0 -60 3 -109 8 -108 4 0 58 57 121 125 110
      121 114 124 155 124 43 0 66 -13 66 -38 0 -8 -38 -53 -84 -101 l-85 -86 100
      -153 c54 -84 99 -159 99 -166 0 -25 -28 -37 -76 -34 l-48 3 -71 123 c-40 68
      -76 127 -82 133 -6 6 -26 -7 -56 -40 -47 -50 -47 -51 -47 -120 0 -50 -4 -74
      -16 -85 -20 -21 -53 -20 -76 1 -17 15 -18 37 -18 271 0 224 2 258 17 274 20
      22 56 23 77 2z"/>
      </g>
      </svg>:
      column.id=='Bag_Used'?
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet">
     
     <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
     fill="#000000" stroke="none">
     <path d="M1154 2545 c-107 -23 -191 -71 -279 -160 -123 -122 -174 -248 -175
     -427 l0 -78 -184 0 c-102 0 -187 -4 -190 -9 -9 -13 -147 -1851 -141 -1862 8
     -12 2182 -12 2190 0 6 11 -133 1849 -141 1862 -3 5 -88 9 -190 9 l-184 0 0 78
     c0 101 -21 193 -61 278 -46 96 -168 219 -262 263 -121 58 -256 74 -383 46z
     m276 -174 c165 -64 257 -199 268 -393 l5 -98 -422 0 -421 0 0 73 c0 213 118
     378 310 432 72 20 188 14 260 -14z m-730 -776 c0 -99 2 -107 25 -130 32 -33
     78 -33 110 0 23 23 25 31 25 130 0 58 3 105 7 105 3 0 22 -12 41 -26 103 -80
     90 -254 -25 -325 -57 -36 -154 -34 -210 3 -111 73 -124 237 -25 318 20 16 40
     30 44 30 5 0 8 -47 8 -105z m1000 0 c0 -99 2 -107 25 -130 32 -33 78 -33 110
     0 23 23 25 31 25 130 0 58 3 105 7 105 3 0 22 -12 41 -26 103 -80 90 -254 -25
     -325 -57 -36 -154 -34 -210 3 -111 73 -124 237 -25 318 20 16 40 30 44 30 5 0
     8 -47 8 -105z"/>
     </g>
     </svg>:
     column.id=='Ordered_Quantity'?
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="26px" height="26px">
<g><path  fill="#000000" d="M 34.5,-0.5 C 79.8333,-0.5 125.167,-0.5 170.5,-0.5C 175.234,1.73934 178.401,5.40601 180,10.5C 180.5,33.1642 180.667,55.8309 180.5,78.5C 175.821,78.3342 171.155,78.5008 166.5,79C 164.667,79.5 163.5,80.6667 163,82.5C 162.5,107.498 162.333,132.498 162.5,157.5C 156.158,157.666 149.825,157.5 143.5,157C 147.062,153.942 150.062,150.442 152.5,146.5C 151.788,144.954 150.622,143.954 149,143.5C 143.878,147.62 139.044,152.12 134.5,157C 128.858,158.276 123.692,157.442 119,154.5C 117.739,154.759 116.739,155.426 116,156.5C 115.548,182.699 115.381,209.032 115.5,235.5C 79.8333,235.5 44.1667,235.5 8.5,235.5C 7.01307,234.196 5.3464,233.03 3.5,232C 2.30342,229.985 0.970083,228.151 -0.5,226.5C -0.5,162.833 -0.5,99.1667 -0.5,35.5C 28.7722,40.5615 40.4389,28.5615 34.5,-0.5 Z M 147.5,64.5 C 151,64.4954 152.5,66.1621 152,69.5C 144.833,76.6667 137.667,83.8333 130.5,91C 129.167,91.6667 127.833,91.6667 126.5,91C 123,87.5 119.5,84 116,80.5C 115.277,77.8751 116.277,76.2084 119,75.5C 122.118,77.6149 124.952,80.1149 127.5,83C 134.829,77.8486 141.496,71.6819 147.5,64.5 Z M 29.5,74.5 C 48.8362,74.3334 68.1695,74.5 87.5,75C 90.8251,76.6144 91.1585,78.6144 88.5,81C 68.8333,81.6667 49.1667,81.6667 29.5,81C 26.9571,78.8483 26.9571,76.6816 29.5,74.5 Z M 148.5,103.5 C 151.114,104.264 152.281,105.93 152,108.5C 144.319,116.348 136.486,124.015 128.5,131.5C 124.182,127.682 120.015,123.682 116,119.5C 115.259,116.888 116.259,115.222 119,114.5C 122.313,116.979 125.48,119.645 128.5,122.5C 135.214,116.117 141.88,109.784 148.5,103.5 Z M 28.5,114.5 C 48.5028,114.333 68.5028,114.5 88.5,115C 90.7811,116.851 90.7811,118.518 88.5,120C 68.5,120.667 48.5,120.667 28.5,120C 27.2475,118.172 27.2475,116.339 28.5,114.5 Z M 29.5,153.5 C 48.8362,153.333 68.1695,153.5 87.5,154C 91.5,156 91.5,158 87.5,160C 68.1667,160.667 48.8333,160.667 29.5,160C 26.9571,157.848 26.9571,155.682 29.5,153.5 Z"/></g>
<g><path  fill="#000000" d="M 26.5,4.5 C 33.1673,24.8414 25.834,32.508 4.5,27.5C 11.8548,19.8128 19.1881,12.1461 26.5,4.5 Z"/></g>
<g><path  fill="#000000" d="M 255.5,85.5 C 255.5,109.5 255.5,133.5 255.5,157.5C 226.833,157.5 198.167,157.5 169.5,157.5C 169.5,133.5 169.5,109.5 169.5,85.5C 179.5,85.5 189.5,85.5 199.5,85.5C 199.334,92.8409 199.5,100.174 200,107.5C 200.739,108.574 201.739,109.241 203,109.5C 209.333,108.167 215.667,108.167 222,109.5C 223.261,109.241 224.261,108.574 225,107.5C 225.5,100.174 225.666,92.8409 225.5,85.5C 235.5,85.5 245.5,85.5 255.5,85.5 Z"/></g>
<g><path  fill="#000000" d="M 206.5,85.5 C 210.833,85.5 215.167,85.5 219.5,85.5C 219.827,90.544 219.494,95.544 218.5,100.5C 217.707,100.931 216.873,101.265 216,101.5C 212.971,101.184 209.971,100.85 207,100.5C 206.501,95.5111 206.334,90.5111 206.5,85.5 Z"/></g>
<g><path  fill="#000000" d="M 209.5,235.5 C 180.5,235.5 151.5,235.5 122.5,235.5C 122.5,211.833 122.5,188.167 122.5,164.5C 132.5,164.5 142.5,164.5 152.5,164.5C 152.334,171.508 152.5,178.508 153,185.5C 154.688,189.026 156.855,189.359 159.5,186.5C 160.5,187.167 161.5,187.833 162.5,188.5C 164.611,185.956 166.778,185.956 169,188.5C 170.085,187.707 171.252,187.04 172.5,186.5C 174.371,187.767 176.204,187.767 178,186.5C 179.429,179.281 179.929,171.947 179.5,164.5C 189.5,164.5 199.5,164.5 209.5,164.5C 209.5,188.167 209.5,211.833 209.5,235.5 Z"/></g>
<g><path  fill="#000000" d="M 159.5,164.5 C 163.833,164.5 168.167,164.5 172.5,164.5C 172.827,169.544 172.494,174.544 171.5,179.5C 170.833,179.833 170.167,180.167 169.5,180.5C 166.455,179.802 163.455,179.468 160.5,179.5C 159.506,174.544 159.173,169.544 159.5,164.5 Z"/></g>
<g><path  fill="#000000" d="M 216.5,164.5 C 217.5,164.5 218.5,164.5 219.5,164.5C 219.827,169.544 219.494,174.544 218.5,179.5C 217.914,179.709 217.414,180.043 217,180.5C 216.501,175.177 216.334,169.844 216.5,164.5 Z"/></g>
<g><path fill="#000000" d="M 255.5,164.5 C 255.5,188.167 255.5,211.833 255.5,235.5C 242.167,235.5 228.833,235.5 215.5,235.5C 215.333,219.83 215.5,204.163 216,188.5C 217.085,187.707 218.252,187.04 219.5,186.5C 221.371,187.767 223.204,187.767 225,186.5C 225.5,179.174 225.666,171.841 225.5,164.5C 235.5,164.5 245.5,164.5 255.5,164.5 Z"/></g>
</svg>
  
            
             : <svg style={{margin:0}} class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"></path><path d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"></path><path d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"></path></svg>
            }

      <p style={{marginLeft:7,marginTop:0,marginBottom:0}}>{column.id}</p>
      </label>
    </div>
  ))}
    </div>}

</div>
{loader?
  <div style={{width:'100%',height:'50%',justifyContent:'center',alignItems:'center',display:'flex'}}>
  <div className="spinner-border" role="status">
   <span className="visually-hidden">Loading...</span>
  </div>
  </div>
:
<>
{data.length>0?
<>
<div className="table-container">
    <table className="table"> 

      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td>
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
  <div className="pagination">
  <button onClick={() => {setPage(page-1)}}  disabled={page === 1}>Previous</button>
  <button onClick={() => {setPage(page+1)}} disabled={page  === Math.ceil(count/limitt)||Math.ceil(count/limitt)==0}>Next</button>
  <span>
    Page 
    <strong>
      {' '+page} of {Math.ceil(count/limitt)}
    </strong>
  </span>
  <select value={limitt} onChange={(e) => {
      setPage(1)
      setLimitt(Number(e.target.value))
      }}>
    {[10, 20, 30, 40, 50,100].map(pageSize => (
      <option key={pageSize} value={pageSize}>
        Show {pageSize}
      </option>
    ))}
  </select>
</div>
</>
 :
 <div style={{width:'100%',height:'80%',justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
  <p>No {missing?'Pending':delayed?'Delayed':pendingcancelled?'Pending Cancellation':inProgress?'In Progress':cancelled?'Cancelled':delivered?'Delivered':''} Orders</p>
  </div>}
 
</>

}


</>
}

  </div>
  )
}
