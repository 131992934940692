import {toast, Slide} from 'react-toastify'
const apiUrl = process.env.REACT_APP_API_URL;

export const generateURI = (path) => {
//  return `https://api.trakitrak.com/control${path}`;
return `${apiUrl}${path}`;
}

export const sendData = async (uri, body, navigate, method='POST') => {
    try{
        //
        const req = await fetch(`${generateURI(uri)}`,
            {
                method, 
                headers: {
                    'Content-Type': 'application/json',
                },
                body
            }
        );
        if(req.status === 200){
            const res = await req.json();
            return {status: true, data: res}
        } else {
            if(req.status === 498){
                localStorage.removeItem('token');
                navigate('/login', {replace: true});
                return;
            }
            const res = await req.json();
            return {status: false, msg: res.message}
        }
    } catch(err){
        return { status: false, msg: "Server error" };
    }
} 


export const fetchData = async (uri, navigate) => {
    try{
        const req = await fetch(`${generateURI(uri)}`);
        if(req.status === 200 || req.status === 201){
            const res = await req.json();
            return {status: true, data: res}
        } else {
            if(req.status === 498){
                localStorage.removeItem('token');
                navigate('/login', {replace: true});
                return;
            }
        }
    } catch(err){
        return { status: false, msg: "Server error" };
    }
} 
export const generateTemplate = async (uri, navigate) => {
    try{
        const req = await fetch(`${generateURI('/excel/generatetemplate')}`);
        if(req.status === 200 || req.status === 201){
            const res = await req.json();
            return {status: true, data: res}
        } else {
            if(req.status === 498){
                localStorage.removeItem('token');
                navigate('/login', {replace: true});
                return;
            }
        }
    } catch(err){
        return { status: false, msg: "Server error" };
    }
} 


export const showToast = (content, type) => {
    toast(content, {
        type: type,
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme:'light',
        transition: Slide
    })
}