import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import UserAddresses from './UserAddresses';
import UserOrders from './UserOrders';
import UserSettings from './UserSettings';

export default function UserDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState();
    const [isoverview, setIsOverView] = useState(true);
    const [isOrder, setIsOrder] = useState(false);
    const [isAddress, setIsaddress] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
   
    const menuItems = [
      { title: 'Overview', icon: 'icon-overview' },
    //   { title: 'Personal Information', icon: 'icon-reports' },
    //   { title: 'Materials', icon: 'icon-reports' },
    //   { title: 'Sessions', icon: 'icon-sessions' },
      { title: 'Orders', icon: 'icon-orders', highlight: true },
      { title: 'Addresses', icon: 'icon-activity' },
      // { title: 'Booklog', icon: 'icon-booklog' },
      // { title: 'Tasks', icon: 'icon-tasks' },
    //   { title: 'Financial Information', icon: 'icon-financial' },
      { title: 'Settings', icon: 'icon-settings' }
    ];
    const GetUserDetails = async () => {
        const {status, data, msg} = await fetchData(`/users/${id}`);
        if(status){ 

           setUserInfo(data)
           setLoading(false)
        }
      }
      useEffect(()=>{
        GetUserDetails();
    },[]);
  return (
    <>  
        {loading?
        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
        <div className="spinner-border" role="status">
         <span className="visually-hidden">Loading...</span>
        </div>
        </div>
        :
        <>
<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
<div style={{width:'20vw',overflow:'scroll',display:'flex',flexDirection:'column',alignItems:'center',height:'100vh',backgroundColor:'white',paddingTop:'5%'}}>
             <div style={{width:100,height:100,borderRadius:7,display:'flex',position:'relative',justifyContent:'center',alignItems:'center',backgroundColor:'white'}}>
             <img  style={{width:75,height:75,objectFit:'contain',backgroundColor:'gray',borderRadius:40}} />
             </div>
             <p className='m-0' style={{fontWeight:'600',textAlign:'center'}}>{userInfo.name} - {userInfo.profile.lastname}</p>
             <p className='m-0' style={{fontSize:16}}>#{userInfo.profile.userid}</p>
             <p className='m-0' style={{fontSize:14}}>{userInfo.email}</p>
             
             <div style={{width:'100%',height:'5vh'}}/>
             <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
                {menuItems.map((item, index) => (
                  <div onClick={()=>{
                    if(item.title=='Overview'){
                            setIsOverView(true)
                            setIsaddress(false)
                            setIsOrder(false)
                            setIsSettings(false)
                         
                    }
                    if(item.title=='Orders'){
                        setIsOverView(false)
                        setIsaddress(false)
                        setIsOrder(true)
                        setIsSettings(false)

                    }
                
                    if(item.title=='Addresses'){
                        setIsOverView(false)
                        setIsaddress(true)
                        setIsOrder(false)
                        setIsSettings(false)
                    }
                    
                    if(item.title=='Settings'){
                        setIsOverView(false)
                        setIsaddress(false)
                        setIsOrder(false)
                        setIsSettings(true)
                    }
                   
                    // 
                  }} key={index} style={{cursor:'pointer',width:'100%',backgroundColor:item.title=='Addresses'&&isAddress?'#e05b0e':item.title=='Overview'&&isoverview?'#e05b0e':item.title=='Orders'&&isOrder?'#e05b0e':item.title=='Settings'&&isSettings?'#e05b0e':'white',display:'flex',alignItems:'center',height:45,}} >
                <div style={{paddingRight:10,paddingLeft:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <svg style={{marginRight:'2%',margin:0}} fill={item.title=='Overview'&&isoverview?'white':item.title=='Orders'&&isOrder?'white':item.title=='Addresses'&&isAddress?'white':item.title=='Settings'&&isSettings?'white':'black'} width="16px" height="16px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z" fill-rule="evenodd"/>
                  </svg>
                  </div>
                    <span className='m-0' style={{color:item.title=='Overview'&&isoverview?'white':item.title=='Orders'&&isOrder?'white':item.title=='Addresses'&&isAddress?'white':item.title=='Settings'&&isSettings?'white':'black',fontWeight:'600',marginRight:10}}>{item.title}</span>
                  </div>
                ))}
              </div>
        </div>
<div style={{width:'80vw',padding:'5% 1% 3% 1%',overflow:'scroll',height:'100vh',backgroundColor:'#f9f9f9'}}>
{isAddress?

<UserAddresses userId={userInfo.id} />
:
isOrder?
<>
<UserOrders userId={userInfo.id} />
</>:
isSettings?
<UserSettings GetUserDetails={GetUserDetails} blocked={userInfo.blocked} userId={userInfo.id} />
:
null}

</div>
</div>
      </>
}
    </>
  )
}
