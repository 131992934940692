import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData,sendData } from '../utils';
import { FaAngleRight, FaClock } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';

export default function UserSettings(props) {
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(false);
    const [connected, setConnected] = useState(false);
    const [blocked, setBlocked] = useState(props.blocked);
    const [devices, setDevices] = useState([]);

    const handleCheckboxChange = (id) => {
      const updatedDevices = devices.map((device) =>
        device.id === id ? { ...device, isBlocked: !device.isBlocked } : device
      );
      setDevices(updatedDevices);
    };
   
    // {
    //   id: 1,
    //   deviceId: 'abc123',
    //   deviceBrandName: 'Samsung',
    //   deviceOS: 'Android',
    //   isBlocked: false,
    // },
    // {
    //   id: 2,
    //   deviceId: 'def456',
    //   deviceBrandName: 'Apple',
    //   deviceOS: 'iOS',
    //   isBlocked: true,
    // },
    // {
    //   id: 3,
    //   deviceId: 'ghi789',
    //   deviceBrandName: 'Google',
    //   deviceOS: 'Android',
    //   isBlocked: false,
    // }
//     const fetchSettings= async ()=>{
//         const {status, data, msg} = await fetchData(`/shoppers/settings/${props.userid}`);
//               if(status){
//             setConnected(data.connected)
//             setActive(data.active)
//             setBlocked(data.user.blocked)
//             setLoading(false)
//               }else{
//                 console.log({msg})
//               }
//         }
    useEffect(()=>{
      GetUserDdevices()
  },[]);
  const GetUserDdevices = async() =>{
   
   const {status, data, msg} = await fetchData(`/deviceids/${props.userId}`);
   if(status){
    setDevices(data)
    // props.GetUserDetails()
    setLoading(false)

   }else{
      
   
   }

 }
const handleBlockById = async(device_id) =>{
  setLoading(true)

 const {status, data, msg} = await sendData('/deviceids/block', JSON.stringify({device_id}));
 if(status){
  props.GetUserDetails()
  setLoading(false)

 }else{
    
 
 }

}
  const handleSave = async() =>{
    setLoading(true)
   const {status, data, msg} = await sendData('/users/block', JSON.stringify({userid:props.userId,blocked:blocked?1:0}));
   if(status){
    props.GetUserDetails()
    setLoading(false)

   }else{
      
   
   }

 }
  return (
    <div style={{paddingTop:'5%'}}>
    {loading?

<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<label className="form-label mt-5" style={{ fontFamily: 'Poppins-SemiBold' }}>User Status</label>





<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">
<input name="sanitary" className="form-check-input" type="checkbox" id="flexCheckDefault3"  value={blocked} checked={blocked}  onChange={() => setBlocked(!blocked)}/>
<label className="form-check-label" htmlFor="flexCheckDefault3">
Blocked 
</label>
</div>
</div>
<Button style={{marginTop:'2%'}} onClick={handleSave}>Save</Button>
{devices.map((device) => (
        <div className='form-control' style={{marginTop:10,justifyContent:'space-between',alignItems:'center',display:'flex'}}  key={device.id}>
          <label>
            <input style={{marginRight:5}}
              type="checkbox"
              checked={device.isBlocked?true:false}
              onChange={() => handleCheckboxChange(device.id)}
            />
            {device.device_id} - {device.brand} - {device.os}
          </label>
          <Button  onClick={()=>{handleBlockById(device.device_id)}}>Save</Button>
        </div>
      ))}


</>}

</div>
  )
}
