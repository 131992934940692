import React, {useEffect, useState,useRef} from 'react'
import Tippy from '@tippyjs/react/headless';
import {fetchData} from "../utils"
import Select, { components, createFilter } from "react-select"
import makeAnimated from "react-select/animated";
import { Alert,Modal,Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import validbarcode from "barcode-validator";

// import bwipjs from 'bwip-js';

// const isValidBarcode = (code, types) => {
//   return types.some(type => {
//     try {
//       // Generate the barcode with bwip-js to check if it's valid for the given type
//       bwipjs.toBuffer({
//         bcid: type,       // Barcode type
//         text: code,       // Barcode text
//         scale: 3,         // 3x scaling factor
//         height: 10,       // Bar height, in millimeters
//         includetext: false, // Do not include text below the barcode
//       });
//       return true; // Valid for this type
//     } catch (error) {
//       return false; // Invalid for this type, continue checking
//     }
//   });
// };

// // Usage example:
// const barcode = '123456789012';
// const barcodeTypes = ['ean13', 'code128', 'upc']; // Array of barcode types to validate against

// if (isValidBarcode(barcode, barcodeTypes)) {
//   console.log('Barcode is valid for one of the specified types.');
// } else {
//   console.log('Barcode is invalid for all specified types.');
// }
// auspost - Australia Post 4 State Customer Code
// azteccode - Aztec Code
// aztecrune - Compact Aztec Code
// bc412 - BC412
// channelcode - Channel Code
// codablockf - Codablock F
// code11 - Code 11
// code128 - Code 128
// code16k - Code 16K
// code2of5 - Industrial 2 of 5
// code32 - Italian Pharmacode
// code39 - Code 39
// code49 - Code 49
// code93 - Code 93
// codeone - Code One
// coop2of5 - COOP 2 of 5
// daft - Custom 4 state symbology
// databarexpanded - GS1 DataBar Expanded
// databarexpandedcomposite - GS1 DataBar Expanded Composite
// databarexpandedstacked - GS1 DataBar Expanded Stacked
// databarexpandedstackedcomposite - GS1 DataBar Expanded Stacked Composite
// databarlimited - GS1 DataBar Limited
// databarlimitedcomposite - GS1 DataBar Limited Composite
// databaromni - GS1 DataBar Omnidirectional
// databaromnicomposite - GS1 DataBar Omnidirectional Composite
// databarstacked - GS1 DataBar Stacked
// databarstackedcomposite - GS1 DataBar Stacked Composite
// databarstackedomni - GS1 DataBar Stacked Omnidirectional
// databarstackedomnicomposite - GS1 DataBar Stacked Omnidirectional Composite
// databartruncated - GS1 DataBar Truncated
// databartruncatedcomposite - GS1 DataBar Truncated Composite
// datalogic2of5 - Datalogic 2 of 5
// datamatrix - Data Matrix
// datamatrixrectangular - Data Matrix Rectangular
// dotcode - DotCode
// ean13 - EAN-13
// ean13composite - EAN-13 Composite
// ean14 - GS1-14
// ean2 - EAN-2 (2 digit addon)
// ean5 - EAN-5 (5 digit addon)
// ean8 - EAN-8
// ean8composite - EAN-8 Composite
// flattermarken - Flattermarken
// gs1-128 - GS1-128
// gs1-128composite - GS1-128 Composite
// gs1-cc - GS1 Composite 2D Component
// gs1datamatrix - GS1 Data Matrix
// gs1datamatrixrectangular - GS1 Data Matrix Rectangular
// gs1dotcode - GS1 DotCode
// gs1northamericancoupon - GS1 North American Coupon
// gs1qrcode - GS1 QR Code
// hanxin - Han Xin Code
// hibcazteccode - HIBC Aztec Code
// hibccodablockf - HIBC Codablock F
// hibccode128 - HIBC Code 128
// hibccode39 - HIBC Code 39
// hibcdatamatrix - HIBC Data Matrix
// hibcdatamatrixrectangular - HIBC Data Matrix Rectangular
// hibcmicropdf417 - HIBC MicroPDF417
// hibcpdf417 - HIBC PDF417
// hibcqrcode - HIBC QR Code
// iata2of5 - IATA 2 of 5
// identcode - Deutsche Post Identcode
// industrial2of5 - Industrial 2 of 5
// interleaved2of5 - Interleaved 2 of 5
// isbn - ISBN
// ismn - ISMN
// issn - ISSN
// itf14 - ITF-14
// japanpost - Japan Post 4 State Customer Code
// kix - Royal Dutch TPG Post KIX
// leitcode - Deutsche Post Leitcode
// mailmark - Royal Mail Mailmark
// matrix2of5 - Matrix 2 of 5
// maxicode - MaxiCode
// micropdf417 - MicroPDF417
// microqrcode - Micro QR Code
// msi - MSI Modified Plessey
// onecode - USPS Intelligent Mail
// pdf417 - PDF417
// pdf417compact - Compact PDF417
// pharmacode - Pharmaceutical Binary Code
// pharmacode2 - Two-track Pharmacode
// planet - USPS PLANET
// plessey - Plessey UK
// posicode - PosiCode
// postnet - USPS POSTNET
// pzn - Pharma Zentral Nummer (PZN)
// qrcode - QR Code
// rationalizedCodabar - Codabar
// raw - Custom 1D symbology
// royalmail - Royal Mail 4 State Customer Code
// sscc18 - SSCC-18
// symbol - Miscellaneous symbols
// telepen - Telepen
// telepennumeric - Telepen Numeric
// ultracode - Ultracode
// upca - UPC-A
// upcacomposite - UPC-A Composite
// upce - UPC-E
// upcecomposite - UPC-E Composite
const animatedComponents = makeAnimated();
export default function AddProductForm(props) {
  const navigate = useNavigate()
  const [words, setWords] = useState([]);
  const [currentInput, setCurrentInput] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showw, setShoww] = useState(false);
  const handleClosee =()=>{setShoww(false)}
  const awsUrl = process.env.REACT_APP_AWS_LINK;


  // Function to remove a tag
  const removeTagg = (index) => {
    setWords(words.filter((_, i) => i !== index));
  };




  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [ImageSize, setImageSize] = useState('');
  const [ImageName, setImageName] = useState('');
  const [ImagesName, setImagesName] = useState([]);
  const [ImagesSize, setImagesSize] = useState([]);
  const [cats, setCats] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({value: null, label: 'Select Brand'});
  const [newBrand, setNewBrand] = useState(false);
  const [newBrandName, setNewBrandName] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [format, setFormat] = useState('');
  const [selectedCat, setSelectedCat] = useState({value: null, label: 'Category (required)'});
  const [barcode, setBarcode] = useState('');
  const [shoppermsg, setShoppermsg] = useState('');
  const [featured, setFeatured] = useState('0');
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [unitmeasure, setUnitMeasure] = useState('unit');
  const [unitmeasure2, setUnitMeasure2] = useState(null);
  const [prixunit1, setPrixunit1] = useState('');
  const [prixbulk1, setPrixbulk1] = useState('');
  const [prixpack1, setPrixpack1] = useState('');
  const [unit, setUnit] = useState('');
  const [weightpack1, setWeightpack1] = useState('');


  const [ErrorImg, setErrorImg] = useState('');
  const [Errors, setErrors] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
 

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const maxSizeMB = 1; // Maximum file size allowed in MB

    // Check if a file is selected
    if (file) {
        // Convert file size to MB
        const fileSizeMB = file.size / (1024 * 1024);

        // Check if the file size is less than 1 MB
        if (fileSizeMB < maxSizeMB) {
            // File size is within the limit, you can proceed
            if (ImageName==file.name) {
              // If the image name exists, set image to null
             //setImage(null);
             // setErrorImg('Image with this name already exists.');
              return;
          }
            setImageName(file.name)
            setImageSize(fileSizeMB)
            setImage(file);
            setErrorImg('')
        } else {
            // File size exceeds the limit, show an error message or handle accordingly
            setErrorImg('Photo is larger than 1 MB. Please compress the image or upload a different file.')
            setImage(null);

        }
    }
};
const handleOtherImageChange = (e, otherimg) => {
  const file = e.target.files[0];
  const maxSizeMB = 1; // Maximum file size allowed in MB

  // Check if a file is selected
  if (file) {
      // Convert file size to MB
      const fileSizeMB = file.size / (1024 * 1024);

      // Check if the file size is less than 1 MB
      if (fileSizeMB < maxSizeMB) {
        if (ImagesName.includes(file.name)) {
          // If the image name exists, set image to null
         //setImage(null);
          setErrorImg('Image with this name already exists.');
          return;
      }
       let b =[...ImagesName]
       b.push(file.name)
       setImagesName(b)
       let c =[...ImagesSize]
       c.push(fileSizeMB)
       setImagesSize(c)

        setErrorImg('')
          // File size is within the limit, update otherImages state
          setOtherImages(otherImages.map((oi) => {
              if (oi.key === otherimg.key) {
                  return { ...oi, value: file };
              } else {
                  return oi;
              }
          
          }));
      } else {
        setErrorImg('Photo is larger than 1 MB. Please compress the image or upload a different file.')

          // File size exceeds the limit, show an error message or handle accordingly
      }
  }
};
  const addTag = (checked, type) => {
      if(checked && !tags.includes(type)){
        setTags([...tags, type]);
      } else if(!checked) {
        setTags(tags.filter(i => i !== type));
      }
}
const getDropDowns = async () => {

  const {status, data, msg} = await fetchData(`/products/createdropdown`);

  if(status){ 
    console.log({data})
    if(data.brands.length > 0){
      // setSelectedBrand({label: data.brands[0].name, value: data.brands[0].id})
      setBrands(data.brands.map(i => ({label: i.name, value: i.id})));
    }
    if(data.categories.length > 0){
     // setSelectedCat({label: data.categories[0].subsubs[0].name, value: data.categories[0].subsubs[0].value})
      setCats(data.categories.map(cat => {
        const mainlabel = cat['prodcat']['name'] + ' > ' + cat['name'];
        return {
          label: mainlabel,
          options: cat.subsubs.map(subsub => ({label: subsub.name, value: subsub.id, mainlabel }))
        }
      }));

    }
    
  }
}
function containsOnlyNumbers(str) {
  // Regular expression to match only digits
  const regex = /^[0-9]+$/;
  
  // Test the string against the regular expression
  return regex.test(str);
}
const isFormValid = () => {
  let x=[]
        // Validate string fields
        if (!name) x.push('Name is required');
        if(newBrand){
          if (!newBrandName) x.push('Brand is required');
        }else{
          if (!selectedBrand.value) x.push('Brand is required');
        }
        if (!format) x.push('Format is required');
        if (!selectedCat.value) x.push('Category is required');
        if (!barcode) x.push('Barcode is required');

        if(!containsOnlyNumbers(barcode)) x.push('Barcode Should be only numbers'); 
        if(!validbarcode(barcode)){x.push('Invalid Barcode'); }
    
        if(unitmeasure=='unit'&&unitmeasure2==null){
          if (!prixunit1) x.push('Price is required'); 
          if (!unit) x.push('Weight is required'); 
          // if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
        }
        if(unitmeasure=='weight'&&unitmeasure2=='bulk'){
          if (!prixbulk1) x.push('Price is required'); 
          // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
        }
        if(unitmeasure=='weight'&&unitmeasure2=='package'){
          if (!prixpack1) x.push('Price is required'); 
          if (!weightpack1) x.push('Weight per package is required');
          // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
          // if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 

        }
        if(unitmeasure=='weight'&&unitmeasure2=='both'){
          if (!prixbulk1) x.push('Price is required'); 
          if (!prixpack1) x.push('Price is required'); 
          if (!weightpack1) x.push('Weight per package is required');
          // if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
          // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');
          // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
 
        }
        if(unitmeasure=='both'&&unitmeasure2=='weight'){
          if (!prixbulk1) x.push('Price is required'); 
          if (!prixunit1) x.push('Price is required'); 
          if (!unit) x.push('Weight per unit is required');
          // if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
          // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');

        }
        if(unitmeasure=='both'&&unitmeasure2=='package'){
          if (!prixunit1) x.push('Price is required'); 
          if (!prixpack1) x.push('Price is required'); 
          if (!weightpack1) x.push('Weight per package is required');
          if (!unit) x.push('Weight per unit is required');
          // if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          // if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
          // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
          // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
        }
        if(unitmeasure=='both'&&unitmeasure2=='both'){
          if (!prixunit1) x.push('Price is required'); 
          if (!prixbulk1) x.push('Price is required'); 
          if (!prixpack1) x.push('Price is required'); 
          if (!unit) x.push('Weight per unit is required');
          if (!weightpack1) x.push('Weight per package is required');
          // if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          // if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
          // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
          // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
          // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
        }
        if(unitmeasure=='both'&&!unitmeasure2){
          x.push('Please choose a unit measure 2');
        }
        if(unitmeasure=='weight'&&!unitmeasure2){
          x.push('Please choose a unit measure 2');
        }
        if(containsOnlyNumbers(format)) x.push(`Format can't be only numbers`); 
      

      



        // Validate images
         if (!image) x.push('Image is required');
         for (let i = 0; i < otherImages.length; i++) {
          if (!otherImages[i].value) x.push(`Image ${i+2} is required`);
          
         }
  
        if (x.length > 0) {
          console.log('Validation Errors:', x);
          setErrors(x)
          return false;
        }
      if(x.length==0){
        setErrors([])
        return true;
      }
        // If all validations pass, return true
  
   
      };
const handleSubmit = async() => {
        setErrors([])

  
        if (isFormValid()) {
          setLoader(true)
          setError(false)

          try {
             let userid =localStorage.getItem('userid')
            const formData = new FormData();
             formData.append('userid', userid);
             formData.append('name', name);
             if(selectedBrand.value){
              formData.append('brandid', selectedBrand.value);
             }
             formData.append('newbrand', newBrand);
             formData.append('brandx', newBrandName);
             formData.append('description', description);
             formData.append('format', format);
             formData.append('categoryid', selectedCat.value);
             formData.append('barcode', barcode);
             formData.append('shopper_msg', shoppermsg);
             formData.append('unitmeasure', unitmeasure);
             if(unitmeasure2){
              formData.append('unitmeasure2', unitmeasure2);
             }
             formData.append('img1', image);


             formData.append('frozen', tags.includes("Frozen")?1:0);
             formData.append('alcoholic', tags.includes("Alcohol")?1:0);
             formData.append('fragile', tags.includes("Fragile")?1:0);
             formData.append('tobacco', tags.includes("Tobacco")?1:0);


             formData.append('prixbulk1', prixbulk1);
             formData.append('prixpack1', prixpack1);
             formData.append('prixunit1', prixunit1);
             formData.append('weightpack1', weightpack1);
             formData.append('unit', unit);
             formData.append('tags', JSON.stringify(words));
            
             if(otherImages.length>0){
              for (let i = 0; i < otherImages.length; i++) {
                formData.append(`img${i+2}`, otherImages[i].value);
                
              }
             }
       
          const req = await fetch("https://api.trakitrak.com/control/products/add", {
            method:'POST',
            body: formData,
    })
    if(req.status==200){
        const json = await req.json()
        console.log(json)
        setLoader(false)
        navigate('/products')
       


    }else{
        const json = await req.json()
        console.log(json) 
        setShowalert(true)
        setMsg(json.message)
        setLoader(false)
    }
          } catch (error) {
            setLoader(false)
          }
       
        } else {
          setLoader(false)
            setError(true)
            //alert('hi')
            props.scrollToTop();
        }
      };   
const handleInputChange = (event) => {
        setCurrentInput(event.target.value);
      };
    
      // Function to add the current input to the array of words
const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          setWords([...words, currentInput]);
          setCurrentInput(''); // Clear the input field
        }
      };    
const filterConfig = {
        ignoreCase: true,
        ignoreAccents: false,
        trim:true,
        matchFrom: 'any',
        stringify: (option) =>{
          return `${option.label} ${option.data.mainlabel}`;
        }
      };   
const groupStyles = {
    // border: `2px dotted blue`,
    color: '#8b8b93',
    backgroundColor: 'white',
    padding: '5px 0px 0px 8px',
    display: 'flex',
  };
const GroupHeading = (props) => (
    <div style={groupStyles}>
      <p style={{fontSize:17}}>{props.data.label} </p>
      {/* <components.GroupHeading {...props} /> */}
    </div>
  );
useEffect(() => {
    getDropDowns();
  },[])
const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
return (
    <div className="products-container" style={{paddingTop:'5%'}}>
        {showAlerts()}
            {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
     {loader?
     <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

     <div className="spinner-border" role="status">
     <span className="visually-hidden">Loading...</span>
   </div>
   </div>
     :
       <div className="d-flex flex-column">
           <h3  className="text-center">Add Product</h3>
            <div className="d-flex my-4 justify-content-center">
                <div className="preview-container my-shadowx rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" className="bi bi-card-image" viewBox="0 0 16 16">
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                    </svg>
                    <div className="preview-text">
                    <p>Brand · Product name</p>
                    <p style={{ fontSize: '14px' }}>$2.990</p>
                    <p style={{ fontSize: '14px' }}>1 grs bag</p>
                    </div>
                </div>

                <div className="preview-container my-shadowx rounded mx-5" style={{ transform: 'scale(1.1)' }}>
                  {image?
                  <div style={{ width: '100px', height: '50%',borderRadius:10,justifyContent:'center',alignItems:'center',display:'flex'  }}>

                 <img id="img-preview" alt='product preview' src={URL.createObjectURL(image)} style={{ width: '70px', height: '70px',borderRadius:10  }} />
</div>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" id="no-prod" className="bi bi-card-image" viewBox="0 0 16 16">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                </svg>
                }
                 
                    <div className="preview-text">
                    <p id="name" style={{color: (newBrand && newBrandName) || (selectedBrand.value && !newBrand) || name ? 'black' : '#dcdcdf'}}><span id="brandtext"></span><span id="nametext">{newBrand && newBrandName ? `${newBrandName} · ` : selectedBrand.value && !newBrand ? `${selectedBrand.label} · ` : 'Name'}{name ? name : ''}</span></p>
                    <p id="pricetext" style={{ fontSize: '14px', color: prixunit1 || prixbulk1 || prixpack1 ? 'black' : '#dcdcdf' }}>{prixunit1 ? `${prixunit1}$` : prixbulk1 ? `${prixbulk1}$` : prixpack1 ? `${prixpack1}` : 'Price' }</p>
                    <p id="format" style={{ fontSize: '14px', color: format ? 'black' : '#dcdcdf' }}>{format ? format:'Format'}</p>
                    </div>
                </div>

                                <div className="preview-container my-shadowx rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" className="bi bi-card-image" viewBox="0 0 16 16">
                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                    </svg>
                                    <div className="preview-text">
                                    <p>Brand · Product name</p>
                                    <p style={{ fontSize: '14px' }}>$2.990</p>
                                    <p style={{ fontSize: '14px' }}>1 grs bag</p>
                                    </div>
                                </div>
                </div>
          
            <div  style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                          <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                            <label className="form-label">Brand</label>
                            <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{background: '#f1f1f1', color: 'black', maxWidth: 300}}>
                                <strong className="text-black" style={{fontFamily: 'Poppins-SemiBold', fontSize: '12px'}}>IMPORTANT:</strong>
                                <p style={{fontSize: '13px'}}>This is the manufacturer's brand. If the product is produced by your store, you do not need to enter a brand.</p>
                                <p style={{fontSize: '13px', marginTop: '5px'}}>If the brand you are looking for does not exist, you can create one. Remember to take into consideration the following tips</p>
                                <ul className="myul">
                                  <li>Use capital letters only for brand names or at the beginning of the name.</li>
                                </ul>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                            </Tippy>
                            </div>
                        
                            <div className="w-75">
                    <div className="rounded border" style={{ backgroundColor: 'white', padding: '10px 0' }}>

                    <Select
                    isDisabled={newBrand}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti={false}
                    options={brands}
                    onChange={(e) => setSelectedBrand(e)}
                    value={selectedBrand}
                    blurInputOnSelect={true}
                    styles={{
                      container: (styles) => ({...styles, border: 0}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none'}),
                    }}
                    />

                    </div>
                    </div>

                    <div className="w-75 form-check mt-2">
                        <input checked={newBrand} onChange={(e) => setNewBrand(e.target.checked)} className="form-check-input brand-check" type="checkbox" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                        Add new brand instead
                        </label>
                    </div>

                    {newBrand &&
                    <div className="mt-2 w-75 new-brand" >
                        <input value={newBrandName} onChange={(e) => setNewBrandName(e.target.value) } type="text" name="brandx" placeholder="Enter new brand name" className="form-control" />
                    </div>
                    }

                    <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Name <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
                        </label>
                        <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                            <div className="p-2 rounded my-shadow" style={{ background: '#f1f1f1', color: 'black' }}>
                              <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>
                                IMPORTANT:
                              </strong>
                              <ul className="myul">
                                <li>Use capital letters only at the start of the name.</li>
                                <li style={{ marginTop: '5px' }}>Do not include the product's brand in its name.</li>
                                <li style={{ marginTop: '5px' }}>The product's format (packaging goes in a different field).</li>
                              </ul>
                              <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>
                                EXAMPLES:
                              </strong>

                              <div className="d-flex align-items-center p-1">
                                <div style={{ width: '10%' }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                </div>
                                <p className="ms-2" style={{ width: '90%' }}>Red eggs</p>
                              </div>

                              <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: '#ffdbdb' }}>
                                <div style={{ width: '10%' }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                  </svg>
                                </div>
                                <p className="ms-2" style={{ width: '90%' }}>Red EGGS(12 eggs) SuperChicken</p>
                              </div>
                            </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                            </Tippy>
                    </div>
                    <input value={name} onChange={(e) => setName(e.target.value)} name="name" id="name-input" type="text" className="form-control w-75" />

                  




                            <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                        <label className="form-label">Description</label>
                        <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                              <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                              <ul className="myul">
                                  <li>Avoid using subjective adjectives about the product's characteristics.</li>
                                  <li style={{ marginTop: '5px' }}>Be brief and objective.</li>
                                  <li style={{ marginTop: '5px' }}>Use simple and formal language.</li>
                                  <li style={{ marginTop: '5px' }}>HTML tags are not permitted.</li>
                              </ul>
                              <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>EXAMPLES:</strong>
                              <div className="d-flex align-items-center p-1">
                                  <div style={{ width: '10%' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                      </svg>
                                  </div>
                                  <p className="ms-2" style={{ width: '90%' }}>Sugar-free soda, lime flavor.</p>
                              </div>
                              <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: '#ffdbdb' }}>
                                  <div style={{ width: '10%' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                      </svg>
                                  </div>
                                  <p className="ms-2" style={{ width: '90%' }}>The best sugar-free soda. It will quench your thirst with its refreshing natural lime flavor.</p>
                              </div>
                          </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                        </Tippy>
                        </div>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} name="description" type="text" className="form-control w-75"></textarea>

                <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                    <label className="form-label">Format <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                                  <p>Brief description of the product's presentation</p>
                                  <ul className="myul">
                                    <li>Make sure you include the product's net content and/or measurements.</li>
                                  </ul>
                                  <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>EXAMPLES:</strong>
                                  <div className="d-flex align-items-center p-1">
                                    <div style={{ width: '10%' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                      </svg>
                                    </div>
                                    <p className="ms-2" style={{ width: '90%' }}>1.5 liter bottle</p>
                                  </div>
                                  <div className="d-flex align-items-center p-1">
                                    <div style={{ width: '10%' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                      </svg>
                                    </div>
                                    <p className="ms-2" style={{ width: '90%' }}>In bulk</p>
                                  </div>
                                  <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: '#ffdbdb' }}>
                                    <div style={{ width: '10%' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                      </svg>
                                    </div>
                                    <p className="ms-2" style={{ width: '90%' }}>Unit</p>
                                  </div>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                </div>
                <input value={format} onChange={(e) => setFormat(e.target.value)} id="format-input" type="text" className="form-control w-75" name="format"/>

                <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                    <label className="form-label">Category <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p>The customer will be able to find your product in this category. It is very important that you classify your product in the corresponding related aisle.</p>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                        </Tippy>
                </div>
                <div className="w-75">
                <div className="rounded border" style={{ backgroundColor: 'white', padding: '10px 0' }}>
                    <Select
                    closeMenuOnSelect={false}
                    components={{GroupHeading}}
                    filterOption={createFilter(filterConfig)}
                    isMulti={false}
                    options={cats}
                    onChange={(e) => setSelectedCat(e)}
                    value={selectedCat}
                    blurInputOnSelect={true}
                    //styles={colourStyles}
                    styles={{
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? 'white' : isFocused ? '#f1f1f1' : 'white',
                        color: isSelected ? 'black' : 'black',
                        fontFamily: isSelected ? 'Poppins-SemiBold' : '500',
                        height: 55,
                        margin:0,
                       
                        // margin: '0 15px 15px 15px',
                        display: 'flex',
                        alignItems: 'center', // Center vertically
                        borderBottom:'1px solid #f3f3f3',
                      
                        // justifyContent: 'center', // Center horizontally
                      }),
                      container: (styles) => ({...styles, border: 0,color:'black'}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none',}),
                      groupHeading: (base) => ({
                        ...base,
                        flex: '1 1',
                        color: 'white',
                        fontSize: 15,
                        margin: 100,

                      })
                    }}
                    />
                </div>
                 </div>
                 <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
            
                 <label className="form-label">Barcode <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>  
                      
                </div>
               
                <input value={barcode} onChange={(e) => setBarcode(e.target.value)} name="sku" id="sku-input" type='number' className="form-control w-75" />
                <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
            <label className="form-label">Shopper message </label>   
           </div>
           <textarea value={shoppermsg} onChange={(e) => setShoppermsg(e.target.value)} name="description" type="text" className="form-control w-75"></textarea>

    
           <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
            <label className="form-label">Tags </label>   
           </div>
           <input  type="text" value={currentInput} onChange={handleInputChange}  onKeyDown={handleKeyDown} className="form-control w-75"  placeholder="Product tags"/>
           {words.length > 0 && (
            //  <div className="form-control w-75"  style={{display:'flex',marginTop:10,flexDirection:'row',alignItems:'center'}}>
            <div className="form-control w-75" style={{
              display: 'flex',
              marginTop: 10,
              flexWrap: 'wrap', // Enable wrapping
              flexDirection: 'row',
              alignItems: 'center',
              gap: '15px' // This will ensure spacing between tags, removing the need for marginLeft condition in each tag
          }}>
            <div style={{display:'flex',cursor:'pointer',backgroundColor:'#DC143C',padding:7,borderRadius:5,alignItems:'center'}} 
            onClick={()=>{
             
              setShoww(true)
            }}>
              <p style={{margin:0,marginRight:5,color:'white'}}>Clear</p>
             <svg style={{cursor:'pointer'}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="22.000000pt" height="22.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M504 5110 c-226 -33 -411 -197 -481 -425 -17 -57 -18 -148 -18 -2125
0 -1977 1 -2068 18 -2125 62 -202 210 -350 412 -412 57 -17 148 -18 2125 -18
1977 0 2068 1 2125 18 202 62 350 210 412 412 17 57 18 148 18 2125 0 1977 -1
2068 -18 2125 -62 200 -203 343 -407 412 -53 17 -140 18 -2095 19 -1122 1
-2063 -2 -2091 -6z m1330 -1552 c35 -10 95 -65 384 -352 l342 -340 343 340
c355 354 368 364 448 364 111 0 219 -108 219 -219 0 -80 -10 -93 -364 -448
l-340 -343 340 -343 c354 -355 364 -368 364 -448 0 -111 -108 -219 -219 -219
-80 0 -93 10 -448 364 l-343 340 -342 -340 c-356 -354 -369 -364 -449 -364
              -111 0 -219 108 -219 219 0 80 10 93 364 449 l340 342 -340 343 c-354 355
              -364 368 -364 448 0 108 107 217 214 219 16 0 47 -5 70 -12z"/>
              </g>
              </svg>
            </div>
              {words.map((word, index) => (
              <div style={{marginLeft:index==0?0:15,paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,position:'relative',borderRadius:5,backgroundColor:'#f1f1f1',display:'flex',alignItems:'center'}}>
                <p key={index} style={{marginTop:0,marginBottom:0,color:'black'}}>{word}</p>
                <div onClick={() => removeTagg(index)} style={{backgroundColor:'gray',cursor:"pointer",position:'absolute',top:-6,right:-6,padding:3,width:20,height:20,borderRadius:10,justifyContent:'center',alignItems:'center',display:'flex',color:'white'}}>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="white" stroke="none">
            <path d="M1017 4072 c-110 -110 -177 -185 -177 -197 0 -13 230 -250 662 -682
            l663 -663 -663 -663 c-428 -428 -662 -669 -662 -682 0 -27 348 -375 375 -375
            13 0 246 226 682 662 l663 663 663 -663 c486 -486 668 -662 685 -662 33 0 372
            337 372 370 0 19 -146 171 -662 687 l-663 663 663 663 c436 436 662 669 662
            682 0 27 -348 375 -375 375 -13 0 -254 -234 -682 -662 l-663 -663 -663 663
            c-433 433 -669 662 -682 662 -13 0 -86 -66 -198 -178z"/>
            </g>
            </svg>
                  </div>
               </div>
              ))}
          </div>
      )}
      



                  <label className="form-label mt-4 w-75">Images</label>
                    
                    <div id="main-img-div" className="rounded border p-2 w-75">
                              
                              <div className="border-bottom pb-2">
                                <label htmlFor="img1" className="w-100 label-input">
                                  <input
                                    onChange={handleImageChange}
                                   
                                    required
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    type="file"
                                    name="img1"
                                    id="img1"
                                    //onChange={handleImg1Change}
                                />
                                 {ErrorImg?
                                <Alert dismissible key={'danger'} variant={'danger'}>
                                 <div style={{display:'flex',alignItems:'center'}}>
                                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#ff0000" stroke="none">
<path d="M1555 5115 c-5 -2 -23 -6 -40 -10 -23 -5 -210 -185 -753 -728 -398
-397 -731 -735 -740 -752 -16 -28 -17 -113 -17 -1055 0 -564 4 -1038 8 -1055
6 -21 219 -241 730 -753 397 -398 736 -731 752 -740 27 -16 116 -17 1045 -20
558 -2 1031 0 1050 3 30 5 139 110 769 738 425 424 739 746 747 764 12 27 14
201 14 1052 0 685 -3 1029 -10 1048 -14 38 -1455 1481 -1497 1499 -27 12 -197
14 -1040 13 -554 0 -1012 -2 -1018 -4z m1276 -1078 c19 -12 43 -40 54 -62 l19
-40 -66 -883 c-73 -969 -66 -922 -138 -969 -29 -20 -46 -23 -140 -23 -94 0
-111 3 -140 23 -72 48 -66 4 -136 930 -35 463 -64 865 -64 892 0 59 25 106 72
135 30 18 51 20 269 20 229 0 237 -1 270 -23z m-165 -2394 c67 -23 154 -110
177 -177 40 -117 14 -231 -72 -317 -72 -71 -132 -94 -235 -87 -82 6 -133 28
-189 85 -67 66 -82 105 -82 213 0 78 4 102 22 135 38 71 112 134 183 156 45
13 145 9 196 -8z"/>
</g>
</svg>
                       <p style={{marginLeft:10}}>{ErrorImg}</p>
                                 </div>
                              </Alert>:null}
                             
                                  <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                        <div>
                                          <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                            {!image ?
                                            <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                             : 
                                             <img alt='img1' className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} src={URL.createObjectURL(image)}></img>
                                             }
                                          </div>
                                        </div>

                                        <div className="d-flex flex-column justify-content-center ms-2">
                                          <p className='m-0' >{ImageName?ImageName:'Image 1'}</p>
                                          <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>{ImageSize?Number(ImageSize*1000).toFixed(0)+'KB':'Choose...'}</span>
                                        </div>
                                  </div>
                                </label>
                              </div>

                              {otherImages.map((otherimg,i) => {
                                return (
                                  <div className={`${i < 3 ? 'border-bottom pb-2' : ''}`}>
                                  <label htmlFor={"img" + i+2} className="w-100 label-input">
                                    <input
                                       onChange={e => handleOtherImageChange(e, otherimg)}
                                      // onChange={e => setOtherImages(otherImages.map((oi) => {
                                      //   if(oi.key == otherimg.key) return {...oi, value: e.target.files[0]}
                                      //   else return oi;
                                      // }))}
                                      required
                                      accept="image/*"
                                      style={{ display: 'none' }}
                                      type="file"
                                      name={"img" + i+2}
                                      id={"img" + i+2}
                                      
                                  />
                                    <div className="d-flex align-items-center justify-content-between" style={{ cursor: 'pointer' }}>
                                      <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>

                                          <div>
                                            <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                              {!otherimg.value ?
                                              <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                               : 
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} src={URL.createObjectURL(otherimg.value)}></img>
                                               }
                                            </div>
                                          </div>
  
                                          <div className="d-flex flex-column justify-content-center ms-2">
                                            <p className='m-0'>{ImagesName[i]?ImagesName[i]:`Image ${i+2}`}</p>
                                            <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>{ImagesSize[i]?Number(ImagesSize[i]*1000).toFixed(0)+"KB":`Choose...`}</span>
                                          </div>

                                      </div>

                                          <div onClick={() => setOtherImages(otherImages.filter(x => x.key !== otherimg.key))} class="close-img">
                                              <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                                          </div>
                                    </div>
                                  </label>
                                </div>
                                )
                                })}
                              

                            
                            { otherImages.length < 4 &&

                            <div onClick={() => {
                              setOtherImages([...otherImages, {key: Math.random(), value: null,}]);
                            }} 
                            id="add-img" 
                            className="p-2 mt-2 justify-content-between align-items-center" 
                            style={{ display: 'flex', cursor: 'pointer' }}
                            >
                                <div className="d-flex align-items-center">
                                  <svg  className="img-preview" style={{ fill: 'black',marginRight:5}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                </svg>
                                <p className='m-0' >Add another image</p>
                                </div>
                                <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                <title>plus-circle</title>
                                <desc>Created with Sketch Beta.</desc>
                                <defs></defs>
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
                                <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
                                    <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup"></path>
                                </g>
                                </g>
                            </g>
                            </svg>
                            </div>
                            }

                            </div>
                            <div className="mt-1 w-75">
                            <p style={{textAlign:'left',color:'#8e8e93',fontSize:13}}>Learn <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8479767-what-to-consider-when-creating-a-non-variant-products' style={{fontSize:13,color:'#007aff'}}>Best practices for uploading images.</a> You can arrange them any way you want them to show in the app.</p>
                            </div>
                    
            </div>
                 
           {/* unit measure  */}
                    <div style={{display:'flex',alignSelf:'center'}} className="unitmeasuresmaindiv mt-4" id="unitmeasuremaindiv">

                
                <div
                 onClick={() => {
                  setUnitMeasure('unit');
                  setUnitMeasure2(null);
                }}
                className="measurediv unitdiv" style={{opacity: unitmeasure == 'unit' ? 1 : 0.5,  transform: `scale(${unitmeasure == 'unit' ? 1.04 : 1})`}}>
                    <div className="measure-first">
                      <input
                      checked={unitmeasure == 'unit'}
                          id="unit-check"
                          style={{ marginLeft: '10px' }}
                          value="unit"
                          type="radio"
                          name="unitmeasure"
                      />
                      <div className="measure-text">
                          <p>By unit</p>
                          <p>Example: soda can</p>
                      </div>
                    </div>
                    <div className="measure-second">
                      <img src={`${awsUrl}/quotes/pepsi.png`} alt="soda-can" style={{ width: '100%', top: '10px' }} />
                    </div>
                </div>

                <div style={{ flex: '0.1' }}></div>

                <div 
                  onClick={() => {
                  setUnitMeasure('weight');
                  setUnitMeasure2(null);
                }}
                className="measurediv bulkdiv" style={{opacity: unitmeasure == 'weight' ? 1 : 0.5, transform: `scale(${unitmeasure == 'weight' ? 1.04 : 1})`}}>
                    <div className="measure-first">
                    <input
                        checked={unitmeasure == 'weight'}
                        id="weight-check"
                        type="radio"
                        name="unitmeasure"
                        value="weight"
                        style={{ marginLeft: '10px' }}
                    />
                    <div className="measure-text" style={{ color: 'black' }}>
                        <p>By Weight</p>
                        <p>Example: Lemon</p>
                    </div>
                    </div>
                    <div className="measure-second">
                    <img src={`${awsUrl}/quotes/lemon.png`} alt="lemon" style={{ width: '100%', top: '10px' }} />
                    </div>
                </div>
                
                </div>

                {/* UNIT AND WEIGHT INPUTS */}
                {unitmeasure == 'unit'?
                  <div id="unit-inputs" className="mt-3 w-75 m-auto">
                  <label className="form-label w-100">Price per unit</label>
                  <input value={prixunit1} onChange={e => setPrixunit1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

                  <label className="form-label w-100 mt-3">Weight per unit<span style={{color:'gray', fontSize:'13px'}}> (in grams)</span>                    
                <span style={{marginLeft:5}}>
                <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p>This value won't appear to the customer, however it is required for our internal systems.</p>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                </span>
                    </label>
                  <input value={unit} onChange={e => setUnit(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
                </div>
                :
                unitmeasure == 'weight'?
                <div className="mt-3 w-75 m-auto">
                <div className='d-flex'>
                <div className="form-check">
                    <input checked={unitmeasure2 == 'bulk'} onClick={() => setUnitMeasure2('bulk')} value="bulk" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
                    <label className="form-check-label">weight                  
                 <span style={{marginLeft:5}}>
                 <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p style={{fontSize: 14}}>In this case, products are sold only by kilograms such as 1 KG Tomato.</p>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                 </span>
                    </label>
                </div>
  
                <div className="form-check ms-4">
                    <input checked={unitmeasure2 == 'package'} onClick={() => setUnitMeasure2('package')} value="package" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
                    <label className="form-check-label">package                     
                   <span style={{marginLeft:5}}>
                   <Tippy
                  
                  placement='left'
                  theme='tomato'
                  render={attrs => (
                    <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                        <p style={{fontSize: 14}}>Some packed products like meat have different weights. For this type of product, you must include the approximate weight per unit.</p>
                    </div>

                  )}
                  >
                    <i id="brand-tip">
                      <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                    </i>
          </Tippy>
                   </span>
                    </label>
                </div>
                {/* <i className="ms-1" id="package-tip">
                  <svg stroke="red" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="2.5"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" stroke-width="2.5" stroke-linecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                </i>   */}
  
  
                <div className="form-check ms-4">
                    <input checked={unitmeasure2 == 'both'} onClick={() => setUnitMeasure2('both')} value="both" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
                    <label className="form-check-label">weight &amp; package
                    
                    <span>                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p style={{fontSize: 14}}>some products may be requested by the
customer either by bulk (1 kg of apples) or by units (5 apples). Check this option if the product can be ordered by weight or unit.</p>
                              <ul style={{marginTop: 5}}>
                                <li  style={{fontSize: 14}}>If that is the case, do not forget to include the products approximate weight per unit.</li>
                              </ul>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy></span>
                    </label>
                </div>


                </div>

                {/* inputs unitmeasure2 */}
                <div className='mt-3'>
                  {unitmeasure2 == 'bulk' ?
                  (
                    <>
                      <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                      <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
                    </>
                  )
                  : unitmeasure2 == 'package' ?
                    (
                      <>
                        <label className="form-label w-100">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                        <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />
      
                        <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
                        <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
                      </>
                    )
                  : unitmeasure2 == 'both' ? (
                    <>
                    <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                    <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
                  
                    <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                    <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />
  
                    <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
                    <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
                    </>
                    ) : null
                  }
                </div>
                {/* inputs unitmeasure2 */}

                </div>
                :
                null
                }
              
              
                {/* UNIT AND WEIGHT INPUTS */}

           {/* unit measure  */}
           <div style={{display:'flex',alignSelf:'center',justifyContent:'center',alignItems:'center',width:'100%'}}  id="unitmeasuremaindiv">
          <div
          onClick={() => {
              setUnitMeasure('both');
              setUnitMeasure2(null);
          }}
            style={{opacity: unitmeasure == 'both' ? 1 : 0.5,  transform: `scale(${unitmeasure == 'both' ? 1.04 : 1})`}} className="weightunitdiv" id="weightunitdiv">
                    <div className="measure-first">
                        <input checked={unitmeasure == 'both'} id="weightunit-check" style={{marginLeft:10}} type="radio" name="unitmeasure"
                            value="both"/>
                        <div className="measure-text">
                            <p>By unit and weight</p>
                            <p>Example: pieces of meat</p>
                        </div>
                    </div>
                    <div className="measure-second">
                        <img src={`${awsUrl}/quotes/meat.png`} alt="meat" style={{top:-30}}/>
                    </div>
                </div>
          </div>

          {unitmeasure == 'both' && (
            <>
            <div id="unit-inputs" className="mt-3 w-75 m-auto">
              <label className="form-label w-100">Price per unit</label>
              <input value={prixunit1} onChange={e => setPrixunit1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

              <label className="form-label w-100 mt-3">Weight per unit<span style={{color:'gray', fontSize:'13px'}}> (in grams)</span>
              <span style={{marginLeft:5}}>
              <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p>This value won't appear to the customer, however it is required for our internal systems.</p>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                    </span>
              </label>
              
              <input value={unit} onChange={e => setUnit(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
            </div>


            <div className="mt-3 w-75 m-auto">
            <div className='d-flex'>
                <div className="form-check">
                    <input checked={unitmeasure2 == 'bulk'} onClick={() => setUnitMeasure2('bulk')} value="bulk" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
                    <label className="form-check-label">weight                  
                    <span style={{marginLeft:5}}>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p style={{fontSize: 14}}>In this case, products are sold only by kilograms such as 1 KG Tomato.</p>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                    </span>
                    </label>
                </div>
  
                <div className="form-check ms-4">
                    <input checked={unitmeasure2 == 'package'} onClick={() => setUnitMeasure2('package')} value="package" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
                    <label className="form-check-label">package                    
                
                    <span style={{marginLeft:5}}>    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p style={{fontSize: 14}}>Some packed products like meat have different weights. For this type of product, you must include the approximate weight per unit.</p>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy></span>
                    </label>
                </div>
                {/* <i className="ms-1" id="package-tip">
                  <svg stroke="red" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="2.5"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" stroke-width="2.5" stroke-linecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                </i>   */}
  
  
                <div className="form-check ms-4">
                    <input checked={unitmeasure2 == 'both'} onClick={() => setUnitMeasure2('both')} value="both" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
                    <label className="form-check-label">weight &amp; package
                    
                    <span>                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p style={{fontSize: 14}}>some products may be requested by the
customer either by bulk (1 kg of apples) or by units (5 apples). Check this option if the product can be ordered by weight or unit.</p>
                              <ul style={{marginTop: 5}}>
                                <li  style={{fontSize: 14}}>If that is the case, do not forget to include the products approximate weight per unit.</li>
                              </ul>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy></span>
                    </label>
                </div>


                </div>

                {/* inputs unitmeasure2 */}
                <div className='mt-3'>
                  {unitmeasure2 == 'bulk' ?
                  (
                    <>
                      <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                      <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
                    </>
                  )
                  : unitmeasure2 == 'package' ?
                    (
                      <>
                        <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                        <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />
      
                        <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
                        <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
                      </>
                    )
                  : unitmeasure2 == 'both' ? (
                    <>
                    <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                    <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
                  
                    <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                    <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />
  
                    <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
                    <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
                    </>
                    ) : null
                  }
                </div>
                {/* inputs unitmeasure2 */}

                </div>


            </>

          )}



           {/* product tags */}
           <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" mt-5 w-75">
           <div className="d-flex w-100 mt-4 justify-content-between align-items-center">
                    <label className="form-label">   Product tags <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span></label>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                                  <p>This information is optional, but it can be important for the Shopper to know if there’s any product that needs to be treated with special care.
</p>
                              
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                </div>
                </div>
           <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" w-75">
         
      <div className="row ms-0 me-0" >
        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Frozen"), 'Frozen')} className="tag-container rounded mt-4 bg-primary d-flex align-items-center px-3" style={{ height: '100px' }}>
            <input checked={tags.includes("Frozen")} onChange={(e) => addTag(e.target.checked, 'Frozen')} 
            value="Frozen" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Frozen</h6>
              <p>Example: Ice</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Alcohol"), 'Alcohol')} className="bg-danger mt-4 d-flex align-items-center px-3 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(121 55 89 / 80%) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input checked={tags.includes("Alcohol")} onChange={(e) => addTag(e.target.checked, 'Alcohol')}  value="Alcohol" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Alcohol</h6>
              <p>Example: Beer</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Fragile"), 'Fragile')} className="d-flex align-items-center px-3 mt-4 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(48 206 62) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input checked={tags.includes("Fragile")} onChange={(e) => addTag(e.target.checked, 'Fragile')}  value="Fragile" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Fragile</h6>
              <p>Example: Cake</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Tobacco"), 'Tobacco')} className="tag-container rounded d-flex align-items-center px-3 mt-4" style={{ height: '100px', background: 'linear-gradient(to right, rgb(206 48 160) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input checked={tags.includes("Tobacco")} onChange={(e) => addTag(e.target.checked, 'Tobacco')}  value="Tobacco" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Tobacco</h6>
              <p>Example: Cigarette</p>
            </div>
          </div>
        </div>
      </div>
    </div>

           {/* product tags */}
        

            <div className="my-5 w-75" style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'80%'}}>
                <input onClick={()=>{handleSubmit()}} type="submit" value="ADD PRODUCT" className="btn btn-primary buttonx w-100" />
            </div>
         {/* </div> */}
       
       </div>
         }
         <Modal show={showw} onHide={handleClosee}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to clear?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosee}>
          Cancel
        </Button>
        <Button variant="danger" onClick={()=>{setWords([])
        setShoww(false)
        }}>
          Clear
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  )
}
