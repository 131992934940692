import React,{useState,useEffect,useRef} from 'react'
import { fetchData } from '../utils';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getRowSelectionRowModel
} from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
export default function AddSettelments() {
  const navigate =  useNavigate()
  const divRef = useRef(null); // Step 1: Use a Ref
  const [selectedParking, setParkingSelected] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRowIds1, setSelectedRowIds1] = useState([]);
  const [selectedRowIds2, setSelectedRowIds2] = useState([]);
  const [selectedRowIds3, setSelectedRowIds3] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [Errors, setErrors] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [bank, setBank] = useState();
  const [bankid, setBankid] = useState();
  const [filter, setFilter] = useState('1');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [errorr, setErrorr] = useState('');
  const [tax, setTax] = useState(11);
  const [commission, setCommission] = useState(5);
  const [fixed, setFixed] = useState(0);
  const [fixed1, setFixed1] = useState(0);
  const [fixed2, setFixed2] = useState(0);
  const [fixed3, setFixed3] = useState(0);
  const [userid2, setUserid2] = useState();
  const [des, setDes] = useState('');
  
  

  const handleStartDateChange = (e) => {
    setFrom(e.target.value);
    setErrorr(''); // Reset error message
  };

    const handleEndDateChange = (e) => {
    if (from && e.target.value < from) {
      setErrorr('End date cannot be less than start date.');
      setTo('');
    } else {
      setTo(e.target.value);
      setErrorr(''); // Reset error message
    }
      };
    const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    };

  const columnHelper = createColumnHelper()
  const columns = [
    columnHelper.accessor('id', {
       id: 'id',
       cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
        header: () =><span>Order ID</span>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor('order_number', {
          id: 'order_number',
          cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
           header: () =><span>Order Number</span>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('totalx', {
          id: 'totalx',
          cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>${info.getValue()}</i>,
           header: () =><span>Total</span>,
           footer: info => info.column.id,
         }),
      columnHelper.accessor('storename', {
          id: 'storename',
          cell: info => <i 
          //onClick={()=>(navigate(`/shopper/${info.row.original.userid}`))}
           style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
           header: () =><span>Store Name</span>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('img', {
          id: 'img',
           cell: info => <div style={{borderRadius:7,backgroundColor:info.row.original.color,width:50,height:50,display:'flex',alignItems:'center',justifyContent:'center'}}> <img src={info.getValue()} style={{width:40,height:40,objectFit:'contain'}} /></div>,
           header: () =><span>Store Logo</span>,
           footer: info => info.column.id,
         }),
         columnHelper.accessor('branchname', {
          id: 'branchname',
          cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
           header: () =><span>Branch Name</span>,
           footer: info => info.column.id,
         }),
       
         
         
         
        
 
  ]
    const [data, setStores] = useState([]);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true)
    const [storeid, setStoreid] = useState()
    const [branchid, setBranchid] = useState()
    const [orders, setOrders] = useState([])
    const [billsettings, setbillSettings] = useState([])
    const [billId, setBillId] = useState()
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    useEffect(()=>{
        setLoading(false)
        fetchStores();
    },[filter]);
    const fetchStores = async () => {
        setLoading(true)
        const {status, data, msg} = await fetchData(`/stores/paymentdropdown/${filter}`);
        if(status){
            setLoading(false);
            setStores(data)
            setStoreid(data[0].id)
            fetchbranches(data[0].id)
        
        }else{
          setLoading(false);
        }
      }
      const fetchbranches = async (x) => {
        const {status, data, msg} = await fetchData(`/branchesdropdown/${x}`);
        if(status){
            setBranches(data)
        
        }else{
          setLoading(false);
        }
      }
      const fetchunpaidOrder = async (x) => {
        const {status, data, msg} = await fetchData(`/branches/unpaid/${x}/${storeid}`);
        if(status){
          setOrders(data.orders)
          setUserid2(data.orders[0]?.userid2)
          setbillSettings(data.billsettings)
        
        }else{
          setLoading(false);
        }
      }
      const table = useReactTable({
        data:orders,
        columns: columns.filter(column => visibleColumns.includes(column.id)),
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        //onPaginationChange: setPagination, 
        enableRowSelection:true, //hoist up the row selection state to your own scope
        state: {
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      });

      function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};
    
        for (var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for (i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      }
      useEffect(() => {
    
  

        // setSelectedRowIds([])
   setTimeout(() => {
    if(orders.length>0){
      let b =[]
      let b1 =[]
      let b2 =[]
      let b3 =[]
      for (let i = 0; i < Object.keys(rowSelection).length; i++) {
        let total = Number(orders[Object.keys(rowSelection)[i]].totalx)
          console.log({total})
         
          if(total<=10){
            console.log('here1')
            b1.push(orders[Object.keys(rowSelection)[i]])
          }
          if(total>10.01&&total<=20){
            console.log('here2')
            b2.push(orders[Object.keys(rowSelection)[i]])
          }
          if(total>20.01){
            console.log('here3')
            b3.push(orders[Object.keys(rowSelection)[i]])
          }

           b.push(orders[Object.keys(rowSelection)[i]])
           
           setSelectedRowIds(removeDuplicates(b, "Id"))
           setSelectedRowIds1(removeDuplicates(b1, "Id"))
           setSelectedRowIds2(removeDuplicates(b2, "Id"))
           setSelectedRowIds3(removeDuplicates(b3, "Id"))
      }
  
  
      setSelectedRowIds(b)
      setSelectedRowIds1(b1)
      setSelectedRowIds2(b2)
      setSelectedRowIds3(b3)
     }else{
      setSelectedRowIds([])
      setSelectedRowIds1([])
      setSelectedRowIds2([])
      setSelectedRowIds3([])
     }
    // console.log(selectedRowIds2)
   }, 10);
      }, [rowSelection, orders]);
      const isFormValid = () => {
        let x=[]
              // Validate string fields
              
              if (!billId) x.push('Payment Method is required');
              if (!from) x.push('Start Date is required');
              if (!to) x.push('End Date is required');
              if (tax<0||tax>100) x.push('Tax Should be between 0 and 100');
              if (commission<0||commission>100) x.push('Commission Should be between 0 and 100');

         
      
      
      
              // Validate images
               if (!selectedFile) x.push('Receip is required');
               
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };
      const handleSubmit = async() => {
              setErrors([])
        
              if (isFormValid()) {
                setLoader(true)
                setError(false)
      
                try {
                  let fixedd = Number(Number(Number(Number(fixed1)*Number(selectedRowIds1.length)).toFixed(2))+Number(Number(Number(fixed2)*Number(selectedRowIds2.length)).toFixed(2))+Number(Number(Number(fixed3)*Number(selectedRowIds3.length)).toFixed(2)))

                  const formData = new FormData();
                 
                  
                   formData.append('from',from);
                   formData.append('to',to);
                   formData.append('orders',JSON.stringify(selectedRowIds.map(o => o.id)));
                   formData.append('total', Number(selectedRowIds.reduce((sum, item) => sum + item.totalx, 0)).toFixed(2));
                   formData.append('commission', commission/100);
                   formData.append('tax', tax/100);
                  formData.append('file', selectedFile);
                  formData.append('userid2', userid2);
                  formData.append('storid', storeid);
                  formData.append('des', des);
                  formData.append('billsettingid', billId);
                  formData.append('fixed_commission', fixedd);
                  
         
                  
           
                 

                 
             
                const req = await fetch("https://api.trakitrak.com/control/payments/merchants/pay", {
                  method:'POST',
                  body: formData,
          })
          if(req.status==200){
              const json = await req.json()
              console.log(json)
              // setLoader(false)
               navigate('/settelments')
             
      
      
          }else{
              const json = await req.json()
              console.log(json) 
            //   setShowalert(true)
            //   setMsg(json.message)
              setLoader(false)
          }
                } catch (error) {
                  setLoader(false)
                }
             
              } else {
                setLoader(false)
                  setError(true)
                  //alert('hi')
                //   props.scrollToTop();
              }
            }; 
            const isFormValid1 = () => {
              let x=[]
                    // Validate string fields
                    
                    // if (!billId) x.push('Payment Method is required');
                    if (!from) x.push('Start Date is required');
                    if (!to) x.push('End Date is required');
                    if (tax<0||tax>100) x.push('Tax Should be between 0 and 100');
                    // if (commission<0||commission>100) x.push('Commission Should be between 0 and 100');
      
               
            
            
            
                    // Validate images
                     if (!selectedFile) x.push('Receip is required');
                     
              
                    if (x.length > 0) {
                      console.log('Validation Errors:', x);
                      setErrors(x)
                      return false;
                    }
                  if(x.length==0){
                    setErrors([])
                    return true;
                  }
                    // If all validations pass, return true
              
               
                  };
            const handleSubmit1 = async() => {
                    setErrors([])
            
              
                    if (isFormValid1()) {
                      setLoader(true)
                      setError(false)
                      try {
                        let fixedd = Number(Number(Number(Number(fixed1)*Number(selectedRowIds1.length)).toFixed(2))+Number(Number(Number(fixed2)*Number(selectedRowIds2.length)).toFixed(2))+Number(Number(Number(fixed3)*Number(selectedRowIds3.length)).toFixed(2)))

                        const formData = new FormData();
                       
                        
                         formData.append('from',from);
                         formData.append('to',to);
                         formData.append('orders',JSON.stringify(selectedRowIds.map(o => o.id)));
                         formData.append('total', Number(selectedRowIds.reduce((sum, item) => sum + item.totalx, 0)).toFixed(2));
                        //  formData.append('commission', commission/100);
                         formData.append('tax', tax/100);
                        formData.append('file', selectedFile);
                        formData.append('userid2', userid2);
                        formData.append('storid', storeid);
                        formData.append('des', des);
                        // formData.append('billsettingid', billId);
                        formData.append('fixed_commission', fixedd);
                        
               
                        
                 
                       
      
                       
                   
                      const req = await fetch("https://api.trakitrak.com/control/payments/merchants/pay2", {
                        method:'POST',
                        body: formData,
                })
                if(req.status==200){
                    const json = await req.json()
                    console.log(json)
                    // setLoader(false)
                     navigate('/settelments')
                   
            
            
                }else{
                    const json = await req.json()
                    console.log(json) 
                  //   setShowalert(true)
                  //   setMsg(json.message)
                    setLoader(false)
                }
                      } catch (error) {
                        setLoader(false)
                      }
                   
                    } else {
                      setLoader(false)
                        setError(true)
                        //alert('hi')
                      //   props.scrollToTop();
                    }
                  }; 
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>        
        {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
<label style={{marginLeft:10}} className="form-label mt-4">Type</label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={filter}  onChange={(e)=>{setFilter(Number(e.target.value));fetchbranches((Number(e.target.value)))}} required name="code" className="form-control"  >
<option value={1}>Fullfilment</option>
<option value={2}>Independent</option>
</select>
</div>
<label style={{marginLeft:10}} className="form-label mt-4">Store</label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={storeid}  onChange={(e)=>{setStoreid(Number(e.target.value));fetchbranches((Number(e.target.value)))}} required name="code" className="form-control"  >
<option value={0}>Select a Store</option>
{data.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}
</select>
</div>
<label style={{marginLeft:10}} className="form-label mt-4">Branch</label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={branchid}  onChange={(e)=>{setBranchid(Number(e.target.value));fetchunpaidOrder(Number(e.target.value))}} required name="code" className="form-control"  >
<option value={0}>Select a Branch</option>
{branches.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}
</select>
</div>
{orders.length>0&&(
  <>
    <div className="table-container">
    <table className="table"> 

      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td>
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
 <div>
      <div style={{ marginLeft: '1%', width: '95%' }} className="d-flex mt-4 justify-content-between align-items-center">
        <label className="form-label">
          From
        </label>
      </div>
      <input value={from} onChange={handleStartDateChange} name="fromDate" id="from-input" type="date" style={{ width: '95%', marginLeft: '1%' }} className="form-control" />

      <div style={{ marginLeft: '1%', width: '95%' }} className="d-flex mt-4 justify-content-between align-items-center">
        <label className="form-label">
          To
        </label>
      </div>
      <input value={to} onChange={handleEndDateChange} name="toDate" id="to-input" type="date" style={{ width: '95%', marginLeft: '1%' }} className="form-control" />

      {errorr && <div style={{ color: 'red', marginLeft: '1%' }}>{errorr}</div>}
    </div>
    <div onClick={()=>console.log(selectedRowIds)} style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Total
                        </label>
                       
  </div>
  <input disabled value={"$ "+Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0))}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />
  <div  style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Tax %
                        </label>
                       
  </div>
  <input  value={tax} onChange={(e)=>setTax(e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />
 {filter==1&&(
<>
<div  style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
   <label className="form-label">
   Commission %
   </label>
  
</div>
<input  value={commission} onChange={(e)=>setCommission(e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />

</>
 )}
  <div  style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Fixed Commission 0$ - 10$
                        </label>                
  </div>
  <input  value={fixed1} onChange={(e)=>setFixed1(e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />
  
  <div  style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Fixed Commission 10.01$ - 20$
                        </label>                
  </div>
  <input  value={fixed2} onChange={(e)=>setFixed2(e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />
  
  <div  style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Fixed Commission 20.01$ +
                        </label>                
  </div>
  <input  value={fixed3} onChange={(e)=>setFixed3(e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />
  


  <label style={{margin:10}} className="form-label">Receipt</label>
  <input type="file" ref={fileInputRef} className='form-input' onChange={handleFileChange} accept="application/pdf,image/*" />
  <div style={{width:'80%',height:380,marginLeft:'10%',marginTop:'5%',backgroundColor:'rgba(0, 0, 0, 0.05)',borderRadius:10,alignSelf:'center'}}>
    <h1 style={{textAlign:'center',color:'green'}}>Payment summary</h1>
    {filter==1&&(
      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
      <p>Total</p>    
      <p>{"$ "+Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)}</p>
   
</div>
    )}
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Tax</p>    
{filter==1?
                <p>{filter==1?'-':''}{Number(Number(tax/100)*Number((commission/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)))+Number(Number(fixed)*Number(selectedRowIds.length))).toFixed(2)}</p>
:
<p>{Number(Number(tax/100)*Number(Number(fixed)*Number(selectedRowIds.length))).toFixed(2)}</p>
}             
    </div>
   {filter==1&&(
     <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
     <p>Commission</p>    
     <p>-{Number((commission/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)).toFixed(2)}</p>
  
</div>
   )}
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Fixed 0$ - 10$</p>    
                <p>{filter==1?'-':''}{Number(Number(fixed1)*Number(selectedRowIds1.length)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Fixed 10.1$ - 20$</p>    
                <p>{filter==1?'-':''}{Number(Number(fixed2)*Number(selectedRowIds2.length)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Fixed 20.1$ +</p>    
                <p>{filter==1?'-':''}{Number(Number(fixed3)*Number(selectedRowIds3.length)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
      <p>Paid</p>    
      <p>{"$ "+Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)}</p>
   
</div>
    {/* <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>SubTotal</p>    
{filter==1?
                <p>${Number(Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0))-(Number(Number(tax/100)*Number((commission/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)))+Number(Number(fixed)*Number(selectedRowIds.length)))+(commission/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0))+Number(Number(fixed1)*Number(selectedRowIds1.length))+Number(Number(fixed2)*Number(selectedRowIds2.length))+Number(Number(fixed3)*Number(selectedRowIds3.length)))).toFixed(2)} </p>
:
<p>${Number((Number(Number(tax/100)*Number(Number(fixed)*Number(selectedRowIds.length)))+Number(Number(fixed)*Number(selectedRowIds.length)))).toFixed(2)} </p>
}
    </div> */}
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p style={{fontSize:22}}>Pending to pay</p>    

                <p style={{fontSize:22}}>${Number(Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0))-Number(Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0))-(Number(Number(tax/100)*Number((commission/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)))+Number(Number(fixed)*Number(selectedRowIds.length)))+(commission/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0))+Number(Number(fixed1)*Number(selectedRowIds1.length))+Number(Number(fixed2)*Number(selectedRowIds2.length))+Number(Number(fixed3)*Number(selectedRowIds3.length)))).toFixed(2)).toFixed(2)} </p>

    </div>

    
    
</div>
{filter==1&&(
  <div style={{marginTop:10,marginLeft:10}}> 
  <p style={{margin:10}}>Payment Method</p>
    {billsettings.map((item,i)=>(
      <div onClick={()=>setBillId(item.id)} style={{backgroundColor:'white',cursor:'pointer',marginBottom:15,display:'flex',alignItems:'center'}}>
           <div style={{width:'5%'}}>
       <input type='checkbox' checked={item.id==billId} onChange={()=>setBillId(item.id)}/>
          </div>
        <div style={{width:'30%'}}>
        <p>Name: {item.bankaccount.name}</p>
        <p>Email: {item.bankaccount.email}</p>
        </div>
        <div style={{width:'30%'}}>
        <p>Bank Name: {item.bankaccount.bank.name}</p>
        <p>Account Type: {item.bankaccount.acctyp}</p>
        <p>Account Number: {item.bankaccount.accnum}</p>
        </div>
        <div style={{width:'35%'}}>
        <p>{item.billinfo.busname}</p>
        <p>{item.billinfo.comprat}</p>
        <p>{item.billinfo.activit}</p>
        <p>{item.billinfo.street}</p>
        <p>{item.billinfo.numsuite}</p>
        <p>{item.billinfo.city}</p>
        </div>
  
      </div>
    ))}
  </div>  
)}
<div style={{width:'100%',height:100,display:'flex',justifyContent:'center',alignItems:'center'}}>
<Button style={{width:300,marginTop:'5%'}}onClick={filter==1? handleSubmit:handleSubmit1} >Pay</Button>

</div>
  </>
)}

</div>
}

    </div>
  )
}
