 import React,{useState,useEffect} from 'react'
import { fetchData, sendData } from '../utils';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Alert, ModalTitle } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function StoreOrder(props) {
    const navigate = useNavigate()
    const [orderStatus, setOrderStatus] = useState({
      pending: false,
      inProgress: false,
      delivered: false,
      canceled: false
    });
    const handleShow = () => setShowModal(true);
    const handleStatusChange = (status) => {
      setOrderStatus((prevStatus) => ({
        ...prevStatus,
        [status]: !prevStatus[status]
      }));
    };
    const handleDownload = () => {
      // Implement your download logic here
      console.log(orderStatus);
    };
    const [fromexcel, setFromexcel] = useState('');
    const [toexcel, setToexcel] = useState('');
    const [branchname, setBranchName] = useState('');
      const [loading, setLoading] = useState(true);
      const [showModal, setShowModal] = useState(false);
      const [showcalendar, setShowcalendar] = useState(false);
      const [showcalendar1, setShowcalendar1] = useState(false);
      const [prodinfo, setProdinfo] = useState();
      const [showalert, setShowalert] = useState(false);
      const [msg, setMsg] = useState(false);
      const [modalData, setModalData] = useState(null);
      const [page, setPage] = useState(1);
      const [totalPages, setTotalPages] = useState(1);
      const [count, setCount] = useState(0);
      const [limit, setLimit] = useState(0);
      const [search, setSearch] = useState('');
      const [branchid, setBranchid] = useState('0');
      const [from, setFrom] = useState('');
      const [to, setTo] = useState('');
      const [statuss, setStatus] = useState('Waiting');
      const [orders, setOrders] = useState([]);
      const [firstFetch, setFirstFetch] = useState(false);
      const [branches, setBranches] = useState(props.branches);
      const [show, setShow] = useState(false);
      const [show1, setShow1] = useState(false);
      const [show2, setShow2] = useState(false);
      const [orderloading, setOrderloading] = useState(true);
      const [orderinfo, setOrderinfo] = useState();
      const [orderdatee,setorderdatee] = useState();
      const [found,setFound] = useState([]);
      const [notfound,setNotFound] = useState([]);
      const handleClose = () => setShow(false);
      const handleClose1 = () => setShow1(false);
      const handleClose2 = () => setShow2(false);
      const handleClose3 = () => {setShowModal(false)
      setFromexcel('')
      setToexcel('')
      };
      function convertTo12HourFormat(time24) {
        // Assuming the input is a string in "HH:MM" format
  
        const [hours, minutes] = time24.split(':');
        
        // Create a Date object. The date doesn't matter since we're only interested in time.
        // Here, we're setting the date to "January 1, 1970" with the provided hours and minutes.
        const date = new Date(1970, 0, 1, hours, minutes);
        
        // Convert to 12-hour time format with AM/PM. Specify the locale as 'en-US' and use options to get the desired format.
        const time12Hour = date.toLocaleTimeString('en-US', {
            hour: 'numeric', // "numeric" for the hour, which could be 1 through 12.
            minute: '2-digit', // "2-digit" for the minute, ensuring two digits are always displayed.
            hour12: true // Ensure we're using the 12-hour format.
        });
        
        return time12Hour;
    }
      const [dateRange, setDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
      const [dateRange1, setDateRange1] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
      const [open1, setOpen1] = useState(false);
      const handleClosedrop1 = () => {
        setOpen1(false);
      };
      const [selection, setSelection] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      });
    
   
  
          const fetchOrders = async () => {

            setLoading(true)

            const {status, data, msg} = await sendData('/stores/orders', JSON.stringify({status:statuss,from,to, branchid, storeid: props.storeid,search,page}));
            if(status){
              setOrders(data.orders);
              let totalPagesx = Math.ceil(data.count / data.limit);
              if(totalPagesx == 0) totalPagesx = 1;
              setTotalPages(totalPagesx);
              setLimit(data.limit);
              setCount(data.count);
              setLoading(false)
              setFirstFetch(true)
            }else{
              setShowalert(true)
              setMsg(msg)
              setOrders([]);
              setLoading(false);
              setFirstFetch(true)
            }
         
          }
          const fetchOrderInfo = async (id) => {
            setOrderloading(true)
            const {status, data, msg} = await sendData(`/stores/orders/${id}`, JSON.stringify({status:statuss,from,to, branchid, storeid:props.storeid,search,page}));
            if(status){
              const foundItems = data.cart5s.filter(item => item.CartProdStatus !== "rejected");
              const notFoundItems = data.cart5s.filter(item => item.CartProdStatus == "rejected");
              setFound(foundItems)
              setNotFound(notFoundItems)
               setOrderinfo(data);
            
              setOrderloading(false)
               
     
            }else{
              setShowalert(true)
              setMsg(msg)
              setOrderinfo();
              setOrderloading(false);
       
            }
          }
    
      useEffect(()=>{
            //  fetchBranch()
            },[])
            useEffect(()=>{
              fetchOrders();
          },[branchid, from, to,search, statuss, page])
          const checkColor = state => {
            switch (state) {
              case 'WAITING':
              case 'PENDING':
                return '#df5a15';
              case 'REJECTED':
                return 'red';
              case 'FOUND':
                return 'green';
              case 'REVIEW':
                return '#B58B00';
              default:
                return '#B58B00';
            }
          };
          const createQuantityString = (productType, product) => {
            return productType == 'unit'? `${product.quan} unidad${product.quan > 1 ? 'es':''}` : productType == 'bulk'?`${product.gram} g`:`${product.quan} unidad${product.quan > 1 ? 'es':''}/${product.gram} g`;
        }
      
        useEffect(()=>{
      if(orderinfo){
        const orderTime = orderinfo.created_at;
        const dateObj = new Date(orderinfo.beginn === 'immediate' ? orderinfo.created_at : orderinfo.begintime);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
  
        let orderDate = `${year}-${month}-${day}`;
        if (orderinfo.beginn === 'immediate') orderDate += ` ${orderinfo.begintime}`;
        setorderdatee(orderDate)
      }
        },[orderinfo])
        const showAlerts = () =>{
          return (
            <>
        {showalert&&(
  <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
          {msg}
  </Alert>
        )}
           
          </>
          )
        }
        const [selectedDateRange, setSelectedDateRange] = useState({
          startDate: new Date(),
          endDate: new Date(),
          key: "selection"
     });
     const [selectedDateRange1, setSelectedDateRange1] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
  });
  
  
    
  
     const handleSelect = ranges => {
          setSelectedDateRange(ranges.selection);
          console.log(ranges.selection);
     };
     const handleSelect1 = ranges => {
      setSelectedDateRange1(ranges.selection);
      console.log(ranges.selection);
  };
  const onClickClear1 = () => {
    setSelectedDateRange1({
         startDate: new Date(),
         endDate: new Date(),
         key: "selection"
    });
    setFromexcel('')
    setToexcel('')
    setShowcalendar1(false)
  };
  const onClickDone1 = () => {
    const startDate22 = new Date(selectedDateRange1.startDate.toISOString());
  const endDate22 = new Date(selectedDateRange1.endDate.toISOString());
  
  const differenceInTime22 = endDate22.getTime() - startDate22.getTime();
  const differenceInDays22 = differenceInTime22 / (1000 * 3600 * 24);
  
    if (differenceInDays22 > 30) {
      // More than 30 days selected, show an alert
      setFromexcel('')
      setToexcel('')
      alert('The maximum date range is 30 days. Please select a shorter range.');
      setShowcalendar1(false)
      setSelectedDateRange1({startDate:new Date(),endDate:new Date(), key: "selection"})
  
    } 
    else{
  
  
  
  const startDate = new Date(selectedDateRange1.startDate);
  const endDate = new Date(selectedDateRange1.endDate);
  
  // Add one day to the start date
  startDate.setDate(startDate.getDate() + 1);
  
  // Subtract one day from the end date
  endDate.setDate(endDate.getDate() + 1);
  setFromexcel(startDate.toISOString().substring(0,10))
  setToexcel(endDate.toISOString().substring(0,10))
  setShowcalendar1(false)
  }
  };
     const onClickClear = () => {
      setSelectedDateRange({
           startDate: new Date(),
           endDate: new Date(),
           key: "selection"
      });
      setFrom('')
      setTo('')
      setShowcalendar(false)
  };
  const onClickDone = () => {
   console.log(selectedDateRange.startDate.toISOString().substring(0,10))
   console.log(selectedDateRange.endDate.toISOString().substring(0,10))
   const startDate = new Date(selectedDateRange.startDate);
  const endDate = new Date(selectedDateRange.endDate);
  
  // Add one day to the start date
  startDate.setDate(startDate.getDate() + 1);
  
  // Subtract one day from the end date
  endDate.setDate(endDate.getDate() + 1);
   setFrom(startDate.toISOString().substring(0,10))
   setTo(endDate.toISOString().substring(0,10))
    setShowcalendar(false)
  };
  const getOrderStatusArray = (status) => {
    const statusArray = [];
    if (status.pending) statusArray.push("Waiting");
    if (status.inProgress){
      statusArray.push("Tostore");
      statusArray.push("Delivering");
      statusArray.push("Picked");
      statusArray.push("Picking");
      statusArray.push("Handled");
    } 
    if (status.delivered) statusArray.push("Delivered");
    if (status.canceled) statusArray.push("Cancelled");
    return statusArray;
  };
  
  const DownloadOrder = async() => {
         try {
          const statusArray = getOrderStatusArray(orderStatus);
           
       console.log(JSON.stringify({
        statuses:statusArray,
        from:fromexcel,
         to:toexcel,
         storeid:props.storeid,
     
     }))
     
         const req = await fetch("https://api.trakitrak.com/control/stores/excel/export/orders", {
           method:'POST',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json'
         },
           body: JSON.stringify({
            statuses:statusArray,
            from:fromexcel,
             to:toexcel,
             storeid:props.storeid,
           }),
     })
     console.log(req.status)
     if(req.status==200){
       const blob = await req.blob()
       const url = window.URL.createObjectURL(blob);
       const a = document.createElement("a");
       a.style.display = "none";
       a.href = url;
       // the filename you want
       a.download = `TrakiTrak - ${localStorage.getItem('storename')} - Order ${fromexcel}${fromexcel?' - ':''}${toexcel}.xlsx`;
       document.body.appendChild(a);
       a.click();
       window.URL.revokeObjectURL(url);
       setShowModal(false)
       setFromexcel('')
       setToexcel('')
       setSelectedDateRange1({startDate:new Date(),endDate:new Date(), key: "selection"})
  
       setOrderStatus(
        { 
           pending: false,
          inProgress: false,
          delivered: false,
          canceled: false
        }
       )
  
     
   
     
       // navigate('/stores/added', {replace: true});
     
     }else{
       const json = await req.json()
       console.log(json) 
       setShowModal(false)
      //  setShow2(false)
       // setShowalert(true)
       // setMsg(json.message)
       // setLoader(false)
     }
         } catch (error) {
           setShow2(false)
           setShowModal(false)
         
     }
    
    
     
   };
   function addMinutesToDate(dateStr, minutes) {
    // Parse the date string into a Date object
    const date = new Date(dateStr);
  
    // Add minutes (converted to milliseconds)
    date.setTime(date.getTime() + minutes * 60000);
  
    // Format the date back into the desired format
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are 0-based
    const day = (`0${date.getDate()}`).slice(-2);
    const hours = (`0${date.getHours()}`).slice(-2);
    const mins = (`0${date.getMinutes()}`).slice(-2);
    const secs = (`0${date.getSeconds()}`).slice(-2);
  
    return `${year}-${month}-${day} ${hours}:${mins}:${secs}`;
  }
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }
  return (
    <div >
        
    <Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show2} onHide={handleClose2}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h5 style={{margin:0,marginLeft:10}}>{prodinfo?.typ=='virtual'?'Requested':prodinfo?.prod?.brand?.name} {prodinfo?.typ=='virtual'?'Requested':prodinfo?.prod?.name}</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button onClick={()=>{setShow2(false)}} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn">Close</button>
                                      </div>
            </div>


        </div>

      
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'90%',marginBottom:'5%'}}>
        {/* first div */}
        <div style={{width:'40%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Product</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
            <img src={prodinfo?.typ=='virtual'?'null':prodinfo?.prod?.img} className='modal2' />
               
             
                
              
            </div>
        </div>
        {/* second div */}
        {/* <div style={{width:'60%',height:'80vh', overflowY: 'auto'}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>GENERAL INFORMATION</p>
        <div style={{width:'98%',paddingBottom:15,marginLeft:'1%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{prodinfo &&prodinfo?.prod.name}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
                Name
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{prodinfo && prodinfo?.prod.brand?.name ? prodinfo?.prod.brand?.name : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Brand
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.barcode ? modalData.barcode : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Barcode
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

              <>
         
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.sku ? modalData.sku : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              SKU
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.format ? modalData.format : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Format
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.dess ? modalData.dess : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Description
              </p>
            </div>
            </>
   
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{prodinfo && modalData.prodcat ? modalData.prodcat.name : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Category
              </p>
            </div>
           
     
        </div>
      
          
        </div> */}
    </div>


</div>


</Modal>
    <Modal     
     centered  // This centers the modal vertically
     dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show1} onHide={handleClose1}>
        <div >
            <div className="modal-content" style={{ height: '80vh', }}>
              <div className="modal-header">
                <h4 style={{ fontFamily: 'Poppins-SemiBold' }}>Product</h4>
                <div>
                  <button onClick={()=>setShow1(false)} data-bs-dismiss="modal" className="btn btn-primary">
                    Close
                  </button>
                </div>
              </div>
             
              {prodinfo&&(
                <div className='generalinfo' style={{marginLeft:'5%'}} >
                  {prodinfo?.added?
                  <>
                    {prodinfo?.prodbarcode?
                     <div style={{display:'flex',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
                
                     <img     className="product-image-order" src={prodinfo?.prodbarcode?.productimg}/>
                       <div style={{display:'flex',flexDirection:'column',marginLeft:10}}>
                       <h6 style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.prodbarcode?.prodbrand?.name} {prodinfo?.prodbarcode?.name}</h6>
                     <h6 style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.prodbarcode?.formatt}</h6>
                       </div>
               </div>
                    :
                    <div style={{display:'flex',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
                
                    <img     className="product-image-order" src={`https://trakitrak-prod.s3.eu-west-1.amazonaws.com/shoppers/added_products/${prodinfo?.added_img}`}/>
                      <div style={{display:'flex',flexDirection:'column',marginLeft:10}}>
                      <h6 style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.prodname}</h6>
                    <h6 style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{'No Format'}</h6>
                      </div>
              </div>
                    }
                  </>
                :
                <div style={{display:'flex',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
                {prodinfo?.typ=='virtual'?
               <svg viewBox="0 0 512 512" width={25} height={25} xmlns="http://www.w3.org/2000/svg"><path d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z"/></svg>
              : 
              <img     className="product-image-order" src={prodinfo?.typ=='virtual'?'':prodinfo?.prod?.img}/>

              }
                  <div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:10}}>
                  <h6 style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.typ=='virtual'?'':prodinfo?.prod?.brand?.name} {prodinfo?.typ=='virtual'?prodinfo?.prodname:prodinfo?.prod?.name}</h6>
                <h6 style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.typ=='virtual'?'Requested':prodinfo?.prod.formatt}</h6>
                  </div>
          </div>
                }
             
          <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>
          <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
              <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>Quantity requested</p>
              <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.typ=='virtual'?'Requested':prodinfo?.quan?prodinfo.quan :prodinfo.gram+" g" }</p>
          </div>
          <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>

          <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
              <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>Price per unit</p>
              <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.typ=='virtual'?'Requested':prodinfo.totalx?prodinfo.totalx:'-'}</p>
          </div>
          <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>

          <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
              <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>Barcode</p>
              <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.typ=='virtual'?'Requested':prodinfo ?.prod?.barcode?prodinfo.prod.barcode : '-'}</p>
          </div>
          <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>

          <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
              <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>SKU</p>
              <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{prodinfo?.typ=='virtual'?'Requested':prodinfo?.prod?.sku}</p>
          </div>
              </div>
              )}
        
            </div>
        </div>
      </Modal>

    <Modal     
     centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show} onHide={handleClose}>
  <div >
      <div className="modal-content" style={{ height: '80vh', }}>
        <div className="modal-header">
          <p className='m-0' style={{ fontWeight:'500' }}>{orderinfo && orderinfo.number}</p>
          <div>
            <button onClick={()=>setShow(false)} data-bs-dismiss="modal" className="btn btn-primary">
              Close
            </button>
          </div>
        </div>
        <div
          className={`modal-body ${orderloading ? 'd-flex justify-content-center align-items-center' : ''}`}
          style={{ backgroundColor: 'rgb(242, 242, 242)',overflowY: 'auto'  }}
        >
          {orderloading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            orderinfo?
            <>
                  <div style={{width:'90%',marginLeft:'10%'}}>
                    {orderinfo?.conf=='Delivered'&&(
                      <div className='information-item-cell'>
                          <svg style={{marginRight:10}}  version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M155 4721 c-45 -11 -69 -27 -103 -65 -40 -46 -56 -101 -48 -161 9
-60 80 -137 141 -154 25 -7 160 -11 360 -11 l320 0 272 -1264 272 -1264 -79
-153 c-95 -186 -119 -261 -101 -319 6 -22 26 -57 44 -77 47 -53 97 -72 192
-73 l80 -1 -50 -18 c-187 -63 -299 -248 -266 -437 16 -87 49 -151 113 -215
219 -220 594 -103 658 205 39 191 -82 392 -273 451 -35 12 170 14 1268 14
1072 0 1303 -3 1270 -13 -111 -35 -200 -112 -248 -214 -111 -237 42 -516 301
-552 306 -42 538 272 409 553 -45 99 -144 185 -244 212 l-48 13 75 1 c98 1
143 15 190 57 79 71 84 195 11 278 -9 10 -34 28 -56 40 l-40 21 -1437 3 -1436
2 34 79 c39 92 42 130 14 246 -10 44 -18 81 -17 82 1 0 720 85 1597 187 877
102 1617 191 1644 197 59 14 120 70 135 126 7 25 11 311 11 858 0 776 -1 822
-19 860 -22 48 -54 79 -103 100 -33 13 -242 15 -1902 15 -1767 0 -1865 1
-1870 18 -3 9 -10 46 -16 82 -15 87 -47 188 -72 222 -11 16 -38 38 -61 51 -41
22 -48 22 -467 24 -234 1 -438 -2 -455 -6z"/>
</g>
</svg>
<p  className='m-0'style={{marginLeft:10,fontWeight:'700',color:'white'}}>This order was delivered by the {orderinfo && orderinfo.cartdriverx.driver.name} {orderinfo && orderinfo.cartdriverx.driver.lastname}.</p>
                      </div>
                    ) }

<p style={{marginTop:'2%'}}>General information</p>
<div className='generalinfo' >
   <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
      <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>City</p>
      <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{orderinfo.branch.city ? orderinfo.branch.city.name : ''}</p>
   </div>
   <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>
   <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
      <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>Branch</p>
      <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{orderinfo.branch.name}</p>
   </div>
   <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>
   <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
      <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>{statuss=='Cancelled'?'Created':'Date'}</p>
      <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{orderinfo.created_at.substring(0,10)+" "+orderinfo.created_at.substring(11,16)}</p>
   </div>
   {statuss=='Delivered'||statuss=='Cancelled'?
   <>
   <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>
   <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
      <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>{statuss=='Cancelled'?'Canceled':'Delivery Time'}</p>
      <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>{statuss=='Delivered'?orderinfo.cartdriverx?.delivered_at.substring(0,10)+" "+orderinfo.cartdriverx?.delivered_at.substring(11,16):statuss=="Cancelled"?orderinfo.cancel_date.substring(0,10)+" "+orderinfo.cancel_date.substring(11,16):orderdatee}</p>
   </div>
   </>:
   null}

   {orderinfo&&orderinfo?.conf=='Delivered'?
   <>
   <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>
   <div style={{display:'flex',justifyContent:'space-between',height:64,padding:'0  10px 0 10px',alignItems:'center'}}>
      <p style={{fontWeight:'bold',fontSize:16,color:'black'}}>Total amount</p>
      <p style={{fontWeight:'bold',fontSize:14,color:'gray'}}>${`${orderinfo.productstotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
   </div>
   <div style={{width:'95%',marginLeft:'5%',height:1,backgroundColor:'#d1d1d1'}}/>

   <div style={{display:'flex',height:64,padding:'0  10px 0 10px',alignItems:'center',marginTop:10}}>
   <img src={orderinfo?orderinfo.receipt.receiptimg:null}
                          className="product-image-order m-0"
                        />
<div style={{display:'flex',flexDirection:'column',marginLeft:10,}}>
<p className='m-0' style={{fontWeight:'bold',fontSize:16,color:'black'}}>Receipt</p>
      <p className='m-0' style={{fontWeight:'bold',fontSize:16,color:'black'}}>{orderinfo?orderinfo.receipt.img:null}</p>
</div>
   </div>
   </>
   :null}

</div>
{statuss=='Cancelled'?
<>
<p style={{marginTop:'2%'}}>Reason for cancellation</p>
<div className='productinfo' >
  <p style={{padding:10}}>Cancel reason Goes here</p>
</div>

</>
:null}
<p style={{marginTop:'2%'}}>Found Products</p>
<div className='productinfo' >
<div >
            {found &&
              found.map((product, index) => {
                 const isAdded = Boolean(product.added);
                
                 const brandname = product.prod && product.prod?.brand ? product.prod?.brand?.name : 
                 product.prodbarcode && product.prodbarcode?.prodbrand ? product.prodbarcode?.prodbrand?.name:
                 product.alternative && product.alternative?.prodbarcode?.prodbrand ? product.alternative?.prodbarcode?.prodbrand?.name:

                 null;
                 const sku = product.prod && product.prod?.sku ? product.prod?.sku : null;
                 const format = product.prod && product.prod?.formatt ? product.prod?.formatt :
                  product.prodbarcode && product.prodbarcode?.formatt ? product.prodbarcode?.formatt:
                  product.alternative && product.alternative?.prodbarcode?.formatt ? product.alternative?.prodbarcode?.formatt:
                  null;
                 const productName = product.typ == 'virtual' || isAdded ? product.prodname : 
                 product.prod ? product.prod?.name : 
                 product.prodbarcode && product.prodbarcode?.name ? product.prodbarcode?.name:
                 product.alternative && product.alternative?product.alternative.prodname:
                 '';
                 const unitmeasure = product.unitmeasure;
                 const unitmeasure2 = product.unitmeasure2;
                 let productType = 'unit';

                 if (product.quan === 0 && product.gram === 0) {
                   productType = 'suggested';
                 } else if (unitmeasure === 'weight' && unitmeasure2 === 'bulk') {
                   productType = 'bulk';
               } else if (
                  product.quan !== 0 &&
                   product.gram === 0 &&
                   product.prod &&
                   JSON.stringify(product.prod.weightpack1) === 'null'
                 ) {
                  productType = 'unit';
                 } 
                else if (
                  product.quan === 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) === 'null'
                ) {
                  productType = 'bulk';
                } else if (
                  product.quan !== 0 &&
                  product.gram === 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                } else if (
                  product.quan === 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                } else if (
                  product.quan !== 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                }
            
                const pSTATUS = product.CartProdStatus.toUpperCase();
                 const productStatus = pSTATUS == 'FOUND' ? 'Found' : pSTATUS == 'REJECTED' ? 'Rejected' : pSTATUS == 'REPLACED' ? 'Replaced' : pSTATUS == 'review' ? 'Review' : 'Pending'
                 let replacedBy = null;
                 if(pSTATUS =="REPLACED") {
                     replacedBy = JSON.parse(product.replacedBy)
                //     if(replacedBy.quan == 0 && replacedBy.gram != 0)replacedBy.productType = "bulk";
                //     else if(replacedBy.quan != 0 && replacedBy.gram == 0) replacedBy.productType = "unit"
                //     else replacedBy.productType = "package";
                 }
                let productreplaceimg = null;
                // if(pSTATUS == 'REPLACED' && replacedBy.productimage) productreplaceimg = replacedBy.productimage;
                // else productreplaceimg = 'https://es.shofershop.app/images/prod/medium/noimage.png';
                 const productQuanString = createQuantityString(productType, product);

                return (
                  <>
                  <div onClick={()=>{
                     setProdinfo(product)
                   setShow1(true)
                    }}  style={{paddingLeft:10,paddingTop:10,cursor:'pointer'}}  key={index}>
                    <div className={`d-flex align-items-center ${pSTATUS === 'REPLACED' ? 'fade-product' : ''}`}>
                      <div className="product-image-order-div">
                      
                        {/* {(product.prod && product.prod?.img)||( product.prodbarcode && product.prodbarcode?.productimg)||(product.alternative && product.alternative?.prodbarcode?.productimg)
                        ? */}
                       {product.typ=='virtual'?
                        <svg viewBox="0 0 512 512" width={35} height={35} style={{margin:5}} xmlns="http://www.w3.org/2000/svg"><path d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z"/></svg>
                        :
                        <img
                        src={product.prod && product.prod?.img ? product.prod?.img :
                          product.prodbarcode && product.prodbarcode?.productimg ? product.prodbarcode?.productimg :
                          product.alternative && product.alternative?.prodbarcode?.productimg ? product.alternative?.prodbarcode?.productimg :
                            product.added_img?
                            `https://trakitrak-prod.s3.eu-west-1.amazonaws.com/shoppers/added_products/${product.added_img}`:
                            null
                        
                      }
                        className="product-image-order"
                      />
                        }
                      

       
                        
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%',alignItems:'center',}}>

               
                      <div className="d-flex flex-column justify-content-between productx-container" style={{maxWidth:'85%'}}>
                        <p className="product-name-order">{brandname?brandname+" . ":null}{productName}</p>
                        <p className="product-quantity-order">{format}
                          {/* {`${productQuanString}${sku ? `, sku: ${sku}` : ''}${brandname ? `, marca: ${brandname}${format ? `, format: ${format}` : ''}` : ''}`} */}
                          </p>
                        {/* <p className="product-status" style={{ backgroundColor: checkColor(pSTATUS) }}>
                          {productStatus}
                        </p> */}
                      </div>
                      <div style={{width:'10%',display:'flex' ,flexDirection:'row',justifyContent:'center',alignItems:'center',}}>
                        <p style={{fontSize:13,marginRight:10}}>{product.typ=='virtual'?'Req.':product.quan==0?product.gram+" g ":product.quan+" Un "}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom:15}} version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c1c1c1" stroke="none">
<path d="M1455 4786 c-67 -29 -105 -105 -90 -183 6 -34 117 -149 1008 -1040 l1002 -1003 -1002 -1002 c-1089 -1090 -1026 -1021 -1008 -1115 9 -49 69 -109 118 -118 95 -18 18 -88 1189 1083 959 960 1077 1081 1083 1115 18 95 87 18 -1073 1181 -625 625 -1080 1074 -1099 1082 -40 17 -89 17 -128 0z"/>
</g>
</svg>
                      </div>

                      </div>
                    </div>

                    
                  </div>
                  {found.length==index+1?<div style={{height:5}}></div>:
                  <div style={{width:'90%',marginTop:10,height:1,backgroundColor:'#d1d1d1',marginLeft:'10%'}}/>
                }
                  </>
                  
                );
              })}
</div>
</div>
{notfound.length>0&&(<p style={{marginTop:'2%'}}>Not Found Products</p>)}
<div className='productinfo' >
<div >
            {notfound &&
              notfound.map((product, index) => {
                 const isAdded = Boolean(product.added);
                
                 const brandname = product.prod && product.prod?.brand ? product.prod?.brand?.name : 
                 product.prodbarcode && product.prodbarcode?.prodbrand ? product.prodbarcode?.prodbrand?.name:
                 product.alternative && product.alternative?.prodbarcode?.prodbrand ? product.alternative?.prodbarcode?.prodbrand?.name:

                 null;
                 const sku = product.prod && product.prod?.sku ? product.prod?.sku : null;
                 const format = product.prod && product.prod?.formatt ? product.prod?.formatt :
                  product.prodbarcode && product.prodbarcode?.formatt ? product.prodbarcode?.formatt:
                  product.alternative && product.alternative?.prodbarcode?.formatt ? product.alternative?.prodbarcode?.formatt:
                  null;
                 const productName = product.typ == 'virtual' || isAdded ? product.prodname : 
                 product.prod ? product.prod?.name : 
                 product.prodbarcode && product.prodbarcode?.name ? product.prodbarcode?.name:
                 product.alternative && product.alternative?product.alternative.prodname:
                 '';
                 const unitmeasure = product.unitmeasure;
                 const unitmeasure2 = product.unitmeasure2;
                 let productType = 'unit';

                 if (product.quan === 0 && product.gram === 0) {
                   productType = 'suggested';
                 } else if (unitmeasure === 'weight' && unitmeasure2 === 'bulk') {
                   productType = 'bulk';
               } else if (
                  product.quan !== 0 &&
                   product.gram === 0 &&
                   product.prod &&
                   JSON.stringify(product.prod.weightpack1) === 'null'
                 ) {
                  productType = 'unit';
                 } 
                else if (
                  product.quan === 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) === 'null'
                ) {
                  productType = 'bulk';
                } else if (
                  product.quan !== 0 &&
                  product.gram === 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                } else if (
                  product.quan === 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                } else if (
                  product.quan !== 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                }
            
                const pSTATUS = product.CartProdStatus.toUpperCase();
                 const productStatus = pSTATUS == 'FOUND' ? 'Found' : pSTATUS == 'REJECTED' ? 'Rejected' : pSTATUS == 'REPLACED' ? 'Replaced' : pSTATUS == 'review' ? 'Review' : 'Pending'
                 let replacedBy = null;
                 if(pSTATUS =="REPLACED") {
                     replacedBy = JSON.parse(product.replacedBy)
                //     if(replacedBy.quan == 0 && replacedBy.gram != 0)replacedBy.productType = "bulk";
                //     else if(replacedBy.quan != 0 && replacedBy.gram == 0) replacedBy.productType = "unit"
                //     else replacedBy.productType = "package";
                 }
                let productreplaceimg = null;
                // if(pSTATUS == 'REPLACED' && replacedBy.productimage) productreplaceimg = replacedBy.productimage;
                // else productreplaceimg = 'https://es.shofershop.app/images/prod/medium/noimage.png';
                 const productQuanString = createQuantityString(productType, product);

                return (
                  <>
                  <div onClick={()=>{
              
                      setProdinfo(product)
                     setShow1(true)
                    }}  style={{paddingLeft:10,paddingTop:10,cursor:'pointer'}}  key={index}>
                    <div className={`d-flex align-items-center ${pSTATUS === 'REPLACED' ? 'fade-product' : ''}`}>
                      <div className="product-image-order-div">
                        {product.typ=='virtual'?
                        <svg viewBox="0 0 512 512" width={35} height={35} style={{marginTop:5,marginLeft:5}} xmlns="http://www.w3.org/2000/svg"><path d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z"/></svg>
                        :
                        <img
                        src={product.prod && product.prod?.img ? product.prod?.img :
                          product.prodbarcode && product.prodbarcode?.productimg ? product.prodbarcode?.productimg :
                          product.alternative && product.alternative?.prodbarcode?.productimg ? product.alternative?.prodbarcode?.productimg :
                            product.added_img?
                            `https://trakitrak-prod.s3.eu-west-1.amazonaws.com/shoppers/added_products/${product.added_img}`:
                            null
                        
                      }
                        className="product-image-order"
                      />
                        }
                      
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%',alignItems:'center',}}>

               
                      <div className="d-flex flex-column justify-content-between productx-container" style={{maxWidth:'85%'}}>
                        <p className="product-name-order">{brandname?brandname+" . ":null}{productName}</p>
                        <p className="product-quantity-order">{format}
                          {/* {`${productQuanString}${sku ? `, sku: ${sku}` : ''}${brandname ? `, marca: ${brandname}${format ? `, format: ${format}` : ''}` : ''}`} */}
                          </p>
                        {/* <p className="product-status" style={{ backgroundColor: checkColor(pSTATUS) }}>
                          {productStatus}
                        </p> */}
                      </div>
                      <div style={{width:'10%',display:'flex' ,flexDirection:'row',justifyContent:'center',alignItems:'center',}}>
                        <p style={{fontSize:13,marginRight:10}}>{product.typ=='virtual'?'Req.':product.quan==0?product.gram+" g ":product.quan+" Un "}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom:15}} version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c1c1c1" stroke="none">
<path d="M1455 4786 c-67 -29 -105 -105 -90 -183 6 -34 117 -149 1008 -1040 l1002 -1003 -1002 -1002 c-1089 -1090 -1026 -1021 -1008 -1115 9 -49 69 -109 118 -118 95 -18 18 -88 1189 1083 959 960 1077 1081 1083 1115 18 95 87 18 -1073 1181 -625 625 -1080 1074 -1099 1082 -40 17 -89 17 -128 0z"/>
</g>
</svg>
                      </div>

                      </div>
                    </div>

                    {/* {pSTATUS === 'REPLACED' ? (
                      <div className="replace-title">
                        <p>Replaced by:</p>
                      </div>
                    ) : null}

                    {pSTATUS === 'REPLACED' ? (
                      <div className="d-flex align-items-center mb-2">
                        <div className="product-image-container d-flex align-items-center justify-content-center">
                          <img src={productreplaceimg} className="product-image" />
                        </div>
                        <div className="d-flex flex-column productx-container">
                          <p className="product-name">{replacedBy.productname}</p>
                          <p className="product-quantity">{createQuantityString(replacedBy.productType, replacedBy)}</p>
                        </div>
                      </div>
                    ) : null} */}
                  </div>
                  {notfound.length==index+1?<div style={{height:5}}></div>:
                  <div style={{width:'90%',marginTop:10,height:1,backgroundColor:'#d1d1d1',marginLeft:'10%'}}/>
                }
                  </>
                  
                );
              })}
</div>
</div>
{/* <div className='productinfo' >
<div >
            {orderinfo.cart5s &&
              orderinfo.cart5s.map((product, index) => {
                 const isAdded = Boolean(product.added);
                 const brandname = product.prod && product.prod.brand ? product.prod.brand.name : null;
                 const sku = product.prod && product.prod.sku ? product.prod.sku : null;
                 const format = product.prod && product.prod.formatt ? product.prod.formatt : null;
                 const productName = product.typ == 'virtual' || isAdded ? product.prodname : product.prod ? product.prod.name : '';
                 const unitmeasure = product.unitmeasure;
                 const unitmeasure2 = product.unitmeasure2;
                 let productType = 'unit';

                 if (product.quan === 0 && product.gram === 0) {
                   productType = 'suggested';
                 } else if (unitmeasure === 'weight' && unitmeasure2 === 'bulk') {
                   productType = 'bulk';
               } else if (
                  product.quan !== 0 &&
                   product.gram === 0 &&
                   product.prod &&
                   JSON.stringify(product.prod.weightpack1) === 'null'
                 ) {
                  productType = 'unit';
                 } 
                else if (
                  product.quan === 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) === 'null'
                ) {
                  productType = 'bulk';
                } else if (
                  product.quan !== 0 &&
                  product.gram === 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                } else if (
                  product.quan === 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                } else if (
                  product.quan !== 0 &&
                  product.gram !== 0 &&
                  product.prod &&
                  JSON.stringify(product.prod.weightpack1) !== 'null'
                ) {
                  productType = 'package';
                }
            
                const pSTATUS = product.CartProdStatus.toUpperCase();
                 const productStatus = pSTATUS == 'FOUND' ? 'Found' : pSTATUS == 'REJECTED' ? 'Rejected' : pSTATUS == 'REPLACED' ? 'Replaced' : pSTATUS == 'review' ? 'Review' : 'Pending'
                 let replacedBy = null;
                 if(pSTATUS =="REPLACED") {
                     replacedBy = JSON.parse(product.replacedBy)
                //     if(replacedBy.quan == 0 && replacedBy.gram != 0)replacedBy.productType = "bulk";
                //     else if(replacedBy.quan != 0 && replacedBy.gram == 0) replacedBy.productType = "unit"
                //     else replacedBy.productType = "package";
                 }
                let productreplaceimg = null;
                // if(pSTATUS == 'REPLACED' && replacedBy.productimage) productreplaceimg = replacedBy.productimage;
                // else productreplaceimg = 'https://es.shofershop.app/images/prod/medium/noimage.png';
                 const productQuanString = createQuantityString(productType, product);

                return (
                  <>
                  <div onClick={()=>{
                    setProdinfo(product)
                  setShow1(true)
                    }}  style={{paddingLeft:10,paddingTop:10,cursor:'pointer'}}  key={index}>
                    <div className={`d-flex align-items-center ${pSTATUS === 'REPLACED' ? 'fade-product' : ''}`}>
                      <div className="product-image-order-div">
                        <img
                        src={product.prod && product.prod.img ? product.prod.img :null}
                         // src={`https://es.shofershop.app/images/prod/medium/${product.prod && product.prod.img ? product.prod.img : 'noimage.png'}`}
                          className="product-image-order"
                        />
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%',alignItems:'center',}}>

               
                      <div className="d-flex flex-column justify-content-between productx-container" style={{maxWidth:'85%'}}>
                        <p className="product-name-order">{brandname?brandname+" . ":null}{productName}</p>
                        <p className="product-quantity-order">{format}
                          </p>
                       
                      </div>
                      <div style={{width:'10%',display:'flex' ,flexDirection:'row',justifyContent:'center',alignItems:'center',}}>
                        <p style={{fontSize:13,marginRight:10}}>{product.quan==0?product.gram+" g ":product.quan+" Un "}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c1c1c1" stroke="none">
<path d="M1455 4786 c-67 -29 -105 -105 -90 -183 6 -34 117 -149 1008 -1040 l1002 -1003 -1002 -1002 c-1089 -1090 -1026 -1021 -1008 -1115 9 -49 69 -109 118 -118 95 -18 18 -88 1189 1083 959 960 1077 1081 1083 1115 18 95 87 18 -1073 1181 -625 625 -1080 1074 -1099 1082 -40 17 -89 17 -128 0z"/>
</g>
</svg>
                      </div>

                      </div>
                    </div>

                    
                  </div>
                  {orderinfo.cart5s.length==index+1?<div style={{height:5}}></div>:
                  <div style={{width:'90%',marginTop:10,height:1,backgroundColor:'#d1d1d1',marginLeft:'10%'}}/>
                }
                  </>
                  
                );
              })}
          </div>
</div> */}

                  </div>
    
           
          </>:null
          )}

  
          
        </div>
      </div>
    </div>




</Modal>



<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={showModal} onHide={handleClose3}>
    <Modal.Header>
        <Modal.Title>Export order to XLSX</Modal.Title>
        <div className="modal-header-buttons">
        <Button  variant="outline-secondary" onClick={handleClose3}>Close</Button>
          <Button disabled={!orderStatus.canceled&&!orderStatus.delivered&&!orderStatus.inProgress&&!orderStatus.pending} style={{marginLeft:15}} onClick={DownloadOrder} variant="outline-secondary" >Download</Button>
          
        </div>
      </Modal.Header>

      <Modal.Body style={{backgroundColor:'#fafbff'}}>
        <>
        <h5 style={{textAlign:'left',fontSize:18,marginLeft:'10%'}}>All orders</h5>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'#fafbff'}}>
          <div className='border' style={{width:'80%',borderRadius:5,height:'80%',backgroundColor:'white',borderColor:'',borderr:10,padding:10}}>
           
          <Form>
  <Form.Group style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
      <Form.Check 
        type="checkbox"
        label="Pending"
        checked={orderStatus.pending}
        onChange={() => handleStatusChange('pending')}
        className="custom-form-check"
      />
    </div>
    <div style={{ width: '103%', marginLeft: '-1.5%', height: '1px', backgroundColor: '#d9d9d9' }} />

    <div style={{ display: 'flex', alignItems: 'center', height: '50px' }}>
      <Form.Check 
        type="checkbox"
        label="In progress"
        checked={orderStatus.inProgress}
        onChange={() => handleStatusChange('inProgress')}
        className="custom-form-check"
      />
    </div>
    <div style={{ width: '103%', marginLeft: '-1.5%', height: '1px', backgroundColor: '#d9d9d9' }} />

    <div style={{ display: 'flex', alignItems: 'center', height: '50px' }}>
      <Form.Check 
        type="checkbox"
        label="Delivered"
        checked={orderStatus.delivered}
        onChange={() => handleStatusChange('delivered')}
        className="custom-form-check"
      />
    </div>
    <div style={{ width: '103%', marginLeft: '-1.5%', height: '1px', backgroundColor: '#d9d9d9' }} />

    <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
      <Form.Check 
      style={{marginTop:'2%'}}
        type="checkbox"
        label="Canceled"
        checked={orderStatus.canceled}
        onChange={() => handleStatusChange('canceled')}
        className="custom-form-check"
      />
    </div>
  </Form.Group>
</Form>
          </div>
        </div>
       {orderStatus.canceled||orderStatus.delivered?
        <>
       
        <h5 style={{textAlign:'left',fontSize:18,marginLeft:'10%',marginTop:'3%'}}>Order date</h5>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'#fafbff'}}>
          <div className='border' style={{width:'80%',borderRadius:5,height:'80%',backgroundColor:'white',borderColor:'',borderr:10,padding:10}}>
           <p style={{color:'#b7b7c2'}}>Date range</p>
           <p  onClick={()=>setShowcalendar1(true)} style={{color:'black',cursor:'pointer'}}>{fromexcel&&toexcel?fromexcel+' - '+toexcel:'Select a date range'}</p>
           {showcalendar1&&(
  <div className="custom-date-range-picker">
     
  <DateRangePicker

                           onChange={handleSelect1}
                           showSelectionPreview={false}
                           moveRangeOnFirstSelection={false}
                           showMonthAndYearPickers={true}
                           showDateDisplay={false}
                           ranges={[selectedDateRange1]}
                           direction="horizontal"
                      />
                    <div style={{}}>
                           <button
                                className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
                                onClick={onClickDone1}
                           >
                                Done
                           </button>
                           <button
                                className="btn btn-transparent text-danger rounded-0 px-4"
                                onClick={onClickClear1}
                           >
                                Clear
                           </button>
                      </div>    
  </div>
)}
          </div>
        
        </div> 
        <p style={{color:'#b7b7c2',marginLeft:'10%',marginTop:'1%',fontSize:14}}>You can download a maximum range of 30 days.</p> 
        </>
        :null}
           </>
        </Modal.Body>


</Modal>

<main className="products-container">

      <div className="border-bottom px-3 py-3 ordertab" style={{ height: '65px',width:'96%',marginLeft:'2%' }}>
      <div className="d-flex align-items-center" style={{width:'70%'}}>
      <div style={{cursor:'pointer',width:'auto',minWidth:'40%',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">

       <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
        <input
          onInput={(e) => setSearch(e.target.value)}
          value={search}
          type="text"
          id="search-order"
          name="search"
          placeholder="Search by order number"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}

          // className="form-control input-order"
        />
        {search!=''?   <svg onClick={()=>setSearch('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
</div>
<div  style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 5px 0 5px',display:'flex',alignItems:'center',height:40}} className="form-control">

<svg xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  width="18.000000pt" height="18.000000pt" viewBox="0 0 512 512">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#616161" stroke="none">
<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
-146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
-310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
-456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
-1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
</g>
</svg>
<select value={branchid === '0' ? 'All Branches' : branchname}
  onChange={(e) => {
    if(e.target.value==0){
      setBranchid(0);
      setBranchName('All Branches');
    }else{
      const selectedBranchName = e.target.value;
      const selectedBranch = branches.find(branch => branch.name === selectedBranchName);
      setBranchid(selectedBranch.id);
      setBranchName(selectedBranch.name);
    }
  
  }} style={{marginLeft: '5%',width:branchid==0?120:150 ,border:'none',outline:'none'}}>
  <option value="0">All Branches</option>
  {branches.map((branch) => (
      <option key={branch.id}>{branch.name}</option>
  ))}
</select>

  
</div>

<div  onClick={()=>setShowcalendar(true)} style={{cursor:'pointer',marginLeft:'2%',minWidth:'45%',width:'auto',padding:'0 5px 0 10px',display:'flex',alignItems:'center',height:40}} className="form-control">

<svg style={{marginRight:10}} xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  width="18.000000pt" height="18.000000pt" viewBox="0 0 512 512">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#616161" stroke="none">
<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
-146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
-310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
-456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
-1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
</g>
</svg>

<p className='m-0' style={{marginLeft:15}}>{from&&to?from+' - '+to:'Date'}</p>
  
</div>
{showcalendar&&(
  <div className="custom-date-range-picker">
     
  <DateRangePicker

                           onChange={handleSelect}
                           showSelectionPreview={false}
                           moveRangeOnFirstSelection={false}
                           showMonthAndYearPickers={true}
                          //  months={2}
                          showDateDisplay={false}
                           ranges={[selectedDateRange]}
                           direction="horizontal"
                      />
                    <div style={{}}>
                           <button
                                className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
                                onClick={onClickDone}
                                
                           >
                                Done
                           </button>
                           <button
                                className="btn btn-transparent text-danger rounded-0 px-4"
                                onClick={onClickClear}
                           >
                                Clear
                           </button>
                      </div>    
  </div>
)}
        
          </div>
        <div className="d-flex align-items-center">
          <a onClick={()=>setShowModal(true)} className="btn btn-danger buttonx" style={{ fontSize: '13px' }}>
          <svg style={{marginRight:5}} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="white" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
          </svg>
           Download
          </a>
         
        </div>

      </div>
      <div  style={{width:'96%',marginLeft:'2%'}}className="border-bottom px-3 py-3 d-flex justify-content-between">
        <p className='m-0'>All Orders</p>
      <div style={{display:'flex',alignItems:'center'}}>
      <p  id="pagination-text" className="mx-2 m-0" style={{ fontSize: '12px' }}>
            {page == 1 ? 0 : (page - 1) * limit}-{page * limit <= count ? page * limit : count} of {count}
          </p>
          <div className="pagination-container py-2 border d-flex h-100">
          <svg  onClick={() => {
                if (page > 1) setPage(page - 1);
              }} style={{ opacity: page > 1 ? '1' : '0.25' }} className="border-end" fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
  <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
</svg>
         
          
         <svg  onClick={() => {
                if (page < totalPages) setPage(page + 1);
              }}   style={{ opacity: page < totalPages ? '1' : '0.25' }} fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)">
  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
  </g>
</svg>
          </div>
      </div>
      </div>

      {showAlerts()}
      <div className={`main-orders-container py-3 px-4 ${loading ? 'd-flex justify-content-center align-items-center main-orders-containerx' : ''}`}>
        {loading ? (

          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>

        ) : (
          
          <div className='orderhome'>
                <div className="orderstatus">
              <div onClick={()=>{
           setOrders([])
           setFound([])
           setNotFound([])
            setOrderinfo();
                setStatus('Waiting')
                setPage(1)
            }} style={{width:'100%',height:70,borderTopLeftRadius:8,borderBottomLeftRadius:8,display:'flex',alignItems:'center',cursor:'pointer',backgroundColor:statuss=='Waiting'?'#e9e9e9':null,justifyContent:'center'}}>
              <svg style={{marginLeft:'5%'}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="12pt" height="12pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#e05b0e" stroke="none">
<path d="M2370 5113 c-379 -36 -661 -116 -980 -278 -378 -193 -717 -497 -965
-865 -104 -156 -232 -419 -294 -605 -49 -150 -89 -321 -113 -490 -17 -118 -17
-512 0 -630 42 -295 120 -553 242 -800 137 -280 272 -468 494 -691 221 -220
412 -357 681 -489 188 -92 309 -137 500 -185 500 -126 1002 -102 1490 71 149
53 407 182 540 271 299 199 573 480 769 788 72 113 188 353 235 486 235 662
194 1372 -115 1993 -124 250 -263 447 -458 648 -216 224 -428 378 -711 518
-296 146 -572 225 -900 255 -102 9 -333 11 -415 3z m289 -1086 c24 -12 57 -42
75 -66 l31 -43 3 -686 2 -687 524 -525 c448 -450 524 -531 534 -566 18 -63 14
-105 -14 -163 -45 -90 -139 -133 -239 -110 -48 11 -67 29 -618 578 -319 317
-577 582 -588 603 -19 37 -19 62 -17 797 l3 759 30 43 c61 86 182 115 274 66z"/>
</g>
</svg>
                <p style={{margin:0,marginLeft:5}}>Pending</p>
              </div>
              <div style={{width:'1%',height:'100%',backgroundColor:'#e9e9e9'}}/>

              <div onClick={()=>{
                setOrders([])
                setFound([])
                setNotFound([])
                 setOrderinfo();
                setStatus('Progress')
               setPage(1)
            }} style={{width:'100%',justifyContent:'center',backgroundColor:statuss=='Progress'?'#e9e9e9':null,height:70,display:'flex',alignItems:'center',cursor:'pointer'}}>
              <svg style={{marginLeft:'5%'}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="12pt" height="12pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#e05b0e" stroke="none">
<path d="M155 4721 c-45 -11 -69 -27 -103 -65 -40 -46 -56 -101 -48 -161 9
-60 80 -137 141 -154 25 -7 160 -11 360 -11 l320 0 272 -1264 272 -1264 -79
-153 c-95 -186 -119 -261 -101 -319 6 -22 26 -57 44 -77 47 -53 97 -72 192
-73 l80 -1 -50 -18 c-187 -63 -299 -248 -266 -437 16 -87 49 -151 113 -215
219 -220 594 -103 658 205 39 191 -82 392 -273 451 -35 12 170 14 1268 14
1072 0 1303 -3 1270 -13 -111 -35 -200 -112 -248 -214 -111 -237 42 -516 301
-552 306 -42 538 272 409 553 -45 99 -144 185 -244 212 l-48 13 75 1 c98 1
143 15 190 57 79 71 84 195 11 278 -9 10 -34 28 -56 40 l-40 21 -1437 3 -1436
2 34 79 c39 92 42 130 14 246 -10 44 -18 81 -17 82 1 0 720 85 1597 187 877
102 1617 191 1644 197 59 14 120 70 135 126 7 25 11 311 11 858 0 776 -1 822
-19 860 -22 48 -54 79 -103 100 -33 13 -242 15 -1902 15 -1767 0 -1865 1
-1870 18 -3 9 -10 46 -16 82 -15 87 -47 188 -72 222 -11 16 -38 38 -61 51 -41
22 -48 22 -467 24 -234 1 -438 -2 -455 -6z"/>
</g>
</svg>
                <p style={{margin:0,marginLeft:5,fontWeight:'500'}}>In Progress</p>
              </div>
              <div style={{width:'1%',height:'100%',backgroundColor:'#e9e9e9'}}/>
               <div onClick={()=>{
           setOrders([])
           setFound([])
           setNotFound([])
            setOrderinfo();
                setStatus('Delivered')
                 setPage(1)
              }} style={{justifyContent:'center',backgroundColor:statuss=='Delivered'?'#e9e9e9':null,width:'100%',height:70,display:'flex',alignItems:'center',cursor:'pointer'}}>
               <svg style={{marginLeft:'5%'}}version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#e05b0e" stroke="none">
<path d="M2435 4739 c-502 -63 -885 -475 -885 -954 l0 -103 -187 -4 c-179 -3
-191 -4 -245 -30 -116 -54 -197 -158 -218 -278 -17 -102 -192 -2609 -186
-2669 12 -122 114 -255 234 -303 l57 -23 1555 0 1555 0 57 23 c120 48 222 181
234 303 6 60 -169 2566 -186 2668 -6 35 -20 82 -32 104 -33 67 -112 141 -181
173 -60 28 -68 29 -249 32 l-188 4 0 81 c-1 242 -110 497 -290 678 -172 173
-368 269 -610 299 -96 11 -135 11 -235 -1z m300 -335 c291 -74 515 -357 515
-651 l0 -73 -690 0 -690 0 0 73 c0 41 9 108 19 149 96 374 472 597 846 502z"/>
</g>
</svg>
                <p style={{margin:0,marginLeft:5,fontWeight:'500'}}>Delivered</p>
              </div>
              <div style={{width:'1%',height:'100%',backgroundColor:'#e9e9e9'}}/>
               <div onClick={()=>{
              setOrders([])
              setFound([])
              setNotFound([])
               setOrderinfo()
              setStatus('Cancelled')
              setPage(1)
          
              }} style={{justifyContent:'center',backgroundColor:statuss=='Cancelled'?'#e9e9e9':null,width:'100%',height:70,borderTopRightRadius:8,borderBottomRightRadius:8,display:'flex',alignItems:'center',cursor:'pointer'}}>
                      <svg style={{marginLeft:'5%'}} version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#e05b0e" stroke="none">
        <path d="M2370 5113 c-379 -36 -661 -116 -980 -278 -378 -193 -717 -497 -965
        -865 -104 -156 -232 -419 -294 -605 -49 -150 -89 -321 -113 -490 -17 -118 -17
        -512 0 -630 42 -295 120 -553 242 -800 137 -280 272 -468 494 -691 221 -220
        412 -357 681 -489 188 -92 309 -137 500 -185 500 -126 1002 -102 1490 71 149
        53 407 182 540 271 299 199 573 480 769 788 72 113 188 353 235 486 235 662
        194 1372 -115 1993 -124 250 -263 447 -458 648 -216 224 -428 378 -711 518
        -296 146 -572 225 -900 255 -102 9 -333 11 -415 3z m1423 -2373 c21 -14 50
        -43 65 -64 24 -35 27 -49 27 -116 0 -67 -3 -81 -27 -116 -15 -21 -44 -50 -65
        -64 l-37 -25 -1196 0 -1196 0 -37 25 c-21 14 -50 43 -65 64 -24 35 -27 49 -27
        117 0 70 3 81 30 120 17 23 50 53 74 66 l45 23 1186 -2 1186 -3 37 -25z"/>
        </g>
        </svg>
                <p style={{margin:0,marginLeft:5,fontWeight:'500'}}>Cancelled</p>
              </div>
            
          </div>
            {orders.length>0?
            <>
         
          {orders.length==0?
           <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
           <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No search results found</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Please try again with a different search term</p>
        </div>:
            <table className="table" >
            <thead>
              <tr style={{height:64}}>
                <th scope="col">Order ID</th>
                <th scope="col">{statuss=='Delivered'?'Delivered':statuss=='Cancelled'?'Created':'Schedule'}</th>
                <th scope="col">{statuss=='Delivered'?'Branch':statuss=='Cancelled'?'Cancelled':'Branch'}</th>
                {statuss=='Cancelled'?null:  <th scope="col">Total amount</th>}
                <th scope="col"></th>
              
              </tr>
            </thead>
            <tbody >
              {orders.map(order => {
                             
                              
                             
         
                         const orderTime = order.created_at;
                         const dateObj = new Date(order.beginn === 'immediate' ? order.created_at : order.begintime);
                         const year = dateObj.getFullYear();
                         const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                         const day = String(dateObj.getDate()).padStart(2, '0');
             
                         let orderDate = `${year}-${month}-${day}`;
                         if (order.beginn === 'immediate') orderDate += ` ${order.begintime}`;
                         return(
                          <tr key={order.id} 
                          onClick={() => { 
                            setShow(true); 
                             fetchOrderInfo(order.id);
                         }}
                           style={{cursor:'pointer'}}   >
                              <td>
                              <div style={{display: 'flex', flexDirection: 'column', }}>
                                <span style={{fontWeight:'500',color:'black'}}>{order.number}</span>
                                <span style={{fontSize:13,color:'#919191'}}>{order.products_count} {order.products_count==1?'Product':'Products'}</span>
                              </div>
                            </td>
                          <td>
                          <div style={{display: 'flex', flexDirection: 'column' }}>
                                <span style={{fontWeight:'500',color:'black'}}>{statuss=='Delivered'?formatDate(order.cartdriver?order.cartdriver.delivered_at?String(order.cartdriver.delivered_at).substring(0,10):String(order.cartdriver.cancel_date).substring(0,10):order.created_at?.substring(0,10)):statuss=='Cancelled'?formatDate(order.created_at?.substring(0,10)):order.beginn?formatDate(addMinutesToDate(order.begintime, order.basetime)?.substring(0,10)):!order.beginn?formatDate(order.begintime?.substring(0,10)):null}</span>
                                <span style={{fontSize:13,color:'#919191'}}>{statuss=='Delivered'?convertTo12HourFormat(order.cartdriver?order.cartdriver.delivered_at?String(order.cartdriver.delivered_at).substring(11,16):String(order.cartdriver.cancel_date).substring(11,16):order.created_at?.substring(11,16)):statuss=='Cancelled'?convertTo12HourFormat(order.created_at?.substring(11,16)):
                                order.beginn&&order.number?
                                convertTo12HourFormat(addMinutesToDate(order.begintime, order.basetime)?.substring(11,16))
                                :
                                !order.beginn&&order.number?
                                convertTo12HourFormat(order.begintime?.substring(11, 16) )+" - "+ convertTo12HourFormat(order.shift_end?.substring(11, 16) )
                                :null}</span>
                          </div>
                          </td> 
                          {statuss=='Cancelled'?
                           <td>
                           <div style={{display: 'flex', flexDirection: 'column' }}>
                                 <span style={{fontWeight:'500',color:'black'}}>{statuss=='Cancelled'?formatDate(String(order.cancel_date).substring(0,11)): formatDate(orderDate)}</span>
                                 <span style={{fontSize:13,color:'#919191'}}>{statuss=='Cancelled'?convertTo12HourFormat(String(order.cancel_date).substring(11,16)):convertTo12HourFormat(String(order.created_at).substring(11,16))}</span>
                               </div>
                           </td>
                           :
                               <td>{order.branch.name}</td>
                        }
                          {statuss=='Cancelled'?null: <td>${`${order.productstotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>}
                         
                          <td>
                          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
          
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c1c1c1" stroke="none">
          <path d="M1455 4786 c-67 -29 -105 -105 -90 -183 6 -34 117 -149 1008 -1040 l1002 -1003 -1002 -1002 c-1089 -1090 -1026 -1021 -1008 -1115 9 -49 69 -109 118 -118 95 -18 18 -88 1189 1083 959 960 1077 1081 1083 1115 18 95 87 18 -1073 1181 -625 625 -1080 1074 -1099 1082 -40 17 -89 17 -128 0z"/>
          </g>
          </svg>
                            </td>
                        </tr>
                         )
               
          })}
            </tbody>
          </table>
        }
            </>
            :
            <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No results</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Try Filtering  by another term</p>
         </div>
            }
         
      
     
               </div>
        )}
      </div>
    </main>

    </div>
  )
}

