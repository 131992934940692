import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'

export default function PromotionBranches() {
    const navigate = useNavigate();
    const {id} = useParams();
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i onClick={()=>navigate(`/promotion/${info.getValue()}`)} style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
          header: () =><p className='m-0' style={{minWidth:200}}>Branch Id</p>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('storeid', {
            id: 'storeid',
            cell: info => <i onClick={()=>navigate(`/promotion/${info.getValue()}`)} style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>Store Id</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('storename', {
            id: 'storename',
            cell: info => <i onClick={()=>navigate(`/promotion/${info.row.original.id}`)} style={{paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>Store Name</p>,
             footer: info => info.column.id,
           }), 
           columnHelper.accessor('name', {
            id: 'name',
            cell: info => <i onClick={()=>navigate(`/promotion/${info.row.original.id}`)} style={{paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>Branch Name</p>,
             footer: info => info.column.id,
           }),
          
       
            
              columnHelper.accessor('actions', {
                id: 'actions',
                header: 'Actions',
                cell: info => (
                    <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
                      <button className="view-button" onClick={()=>{
                        navigate(`/promotion-productsbranch/${id}/${info.row.original.id}`)
                      }}>Products</button>
                      {/* <div  style={{display:'flex',alignItems:'center'}} onClick={()=>{
                        DeactivatePromotions(info.row.original.id,info.row.original.status)
                          // setShow1(true)
                          // setBankid(info.row.original.id)
                      }} className='buttondelete' >
                    
                      <FaTrash size={15}  style={{marginRight:5}}/>
                      <p className='m-0'>{info.row.original.status==1?'Deactivate':'Activate'}</p>
                      </div> */}
                    
                    </div>
                  ),
              }),
        
         
    ]
    const [data, setData] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);
    const table = useReactTable({
        data,
        columns: columns.filter(column => visibleColumns.includes(column.id)),
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        //onPaginationChange: setPagination, 
        enableRowSelection:true, //hoist up the row selection state to your own scope
        state: {
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      });
    const GetPromotionsBranches = async ()=>{
        const {status, data, msg} = await fetchData(`/promotions/${id}/branches`);
        if(status){
            setData(data.branches)
            setLoading(false)
         }
    }
    useEffect(()=>{
        GetPromotionsBranches()
    },[])
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
      {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
  
<div style={{marginLeft:'1.5%'}} className="table-container">
    <table className="table"> 

      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
               
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
        
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>

</div>
}
    </div>
  )
}
