import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';



export default function IncentiveDetails(props) {
  const navigate = useNavigate()
const {item,formattedFromTime, formattedFromDate, formattedToDate, formattedToTime} = props
console.log({item})
const [extend, setExtend] = useState(false);
const toggleDirection = (e) => {
  e.stopPropagation(); 
  setExtend(!extend);
};
  return (



        <div 
        onClick={()=>navigate(`/editincentive/${item.id}`)} 
        style={{width:'100%',minHeight:extend?200:100,backgroundColor:'white',cursor:'pointer',borderRadius:10,marginTop:10,alignItems:'center',alignSelf:'center',display:'flex',flexDirection:'row'}}>
        
   <div style={{display:'flex',flexDirection:'column',marginLeft:10,justifyContent:'center'}}>
    <div style={{flexDirection:'row',display:'flex',alignItems:'center',marginTop:'2%'}}>
            <div style={{width:window.innerWidth*0.72,height:10}}/>
              <div>
              <h6 className='mt-0' style={{color:'white',backgroundColor:item.type==1?'#6f65eb':'#ff4444',maxWidth:85,padding:10,paddingRight:20,paddingLeft:20,borderRadius:10}}>{item.type==1?'$ ':''}{item.type==1?item.profit:item.multiplication_rate}{item.type==1?'':'x'}</h6>
              <p style={{textAlign:'center'}}>{item.type==1?'Guarante':'Multiplier'}</p>
              </div>

    </div>
                  
         {/* <h6 className='mt-0' >{item.name}</h6> */}
         {/* <h6 className='mt-0'>{item.description}</h6> */}
         <h6 style={{color:'gray'}} className='mt-0'>
  Complete <span style={{ fontWeight: 'bold', color: 'black' }}>{item.order_numbers}</span> Orders between 
  <span style={{ fontWeight: 'bold', color: 'black' }}> {formattedFromTime}</span> and 
  <span style={{ fontWeight: 'bold', color: 'black' }}> {formattedToTime}</span> 
  {/* on 
  <span style={{ fontWeight: 'bold', color: 'black' }}> {formattedFromDate}</span> */}
</h6>
{/* <p style={{color:'gray',fontSize:16,fontWeight:'500',top:20}}>An Order Accepted Within 1 KM</p> */}

         {item.stores.map((itemm,i)=>(
            <>
            {itemm.branches.map((item1,i)=>(
                     <h6 className='mt-0' style={{
                      fontSize:14,
                      color:'blue',
                      width:'99%',
                      fontWeight:'500'
                     }}>{itemm.name} {item1}</h6>

            ))}
            </>
         ))}
          
           <div style={{flexDirection:'row',display:'flex',alignItems:'center'}}>
            <div style={{width:window.innerWidth*0.73,height:10}}/>
            <div className="arrow-container" onClick={toggleDirection}>
          <img src={'./arrowdown.png'} alt='trakitrak shoppers logo' 
           className={`arrow ${extend ? 'up' : 'down'}`}
           style={{height:35,width:35, objectFit:'contain' }} />

    
    </div>
    </div>
    {/* "order_numbers": 5,
                "profit": 10,
                "branch_id": 112,
                "store_id": 10,
                "min_hours": 2,
                "acceptance_rate": 50,
                "multiplication_rate": 1.2, */}
    {extend&&(
      <div>
    <div style={{ width: '100%', height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ width: '50%', height: '100%' }}></div>
      <div style={{ width: '25%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: 'black', fontSize: '15px', fontWeight: 'bold' }}>Minimum</p>
      </div>
      <div style={{ width: '25%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: 'black', fontSize: '15px', fontWeight: 'bold' }}>Actual</p>
      </div>
    </div>

    <div style={{ width: '100%', height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ width: '55%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <p style={{ color: 'gray', fontSize: '13px', fontWeight: 500 }}>% Minimum Acceptance</p>
      </div>
      <div style={{ width: '22.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: 'gray', fontSize: '15px', fontWeight: 500 }}>{Number(item.acceptance_rate).toFixed(0)}%</p>
      </div>
      <div style={{ width: '22.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: 'black', fontSize: '18px', fontWeight: 500 }}>
        0
        </p>
      </div>
    </div>

    <div style={{ width: '95%', height: '1px', backgroundColor: 'gray' }}></div>

    <div style={{ width: '100%', height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ width: '55%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <p style={{ color: 'gray', fontSize: '13px', fontWeight: 500 }}>Minimum Connected Hours</p>
      </div>
      <div style={{ width: '22.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: 'gray', fontSize: '15px', fontWeight: 500 }}>{item.min_hours}</p>
      </div>
      <div style={{ width: '22.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: 'black', fontSize: '18px', fontWeight: 500 }}>
          0
        </p>
      </div>
    </div>

    <div style={{ width: '95%', height: '1px', backgroundColor: 'gray' }}></div>

    <div style={{ width: '100%', height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ width: '55%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <p style={{ color: 'gray', fontSize: '13px', fontWeight: 500 }}>Minimum Order Number</p>
      </div>
      <div style={{ width: '22.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color: 'gray', fontSize: '15px', fontWeight: 500 }}>{item.order_numbers}</p>
      </div>
      <div style={{ width: '22.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ color:'black', fontSize: '18px', fontWeight: 500 }}>
       0
        </p>
      </div>
    </div>

    <div style={{ width: '95%', height: '1px', backgroundColor: 'gray', marginBottom: '10px' }}></div>

    <p style={{ fontSize: '16px', color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>An Order Accepted Within 1 KM</p>
    {item.stores.map((itemm,i)=>(
            <>
            {itemm.branches.map((item1,i)=>(
                     <h6 className='mt-0' style={{
                      fontSize:14,
                      color:'blue',
                      width:'99%',
                      fontWeight:'500'
                     }}>{itemm.name} {item1}</h6>

            ))}
            </>
         ))}
  </div>
    )}
          

       

  </div>

        </div>
      
  
    

  )
}
