import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData,sendData } from '../utils';
import { FaAngleRight, FaClock } from 'react-icons/fa';

export default function ShopperSessions(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(35);
    const [totalPages, setTotalPages] = useState(1);
    const [filter, setFilter] = useState(1);
    const [sessions, setSessions] = useState([]);

    const GetShopperSessions = async() =>{
        const {status, data, msg} = await sendData('/shoppers/sessions', JSON.stringify({userid:props.userid,page,limit}));
        if(status){
          setCount(data.count)
         setSessions(data.data)
  
         let totalPagesx = Math.ceil(data.count / 30);
         if(totalPagesx == 0) totalPagesx = 1;
         setTotalPages(totalPagesx)
         setLoading(false);
        }else{
          setLoading(false);
        }
      }

    useEffect(()=>{
        GetShopperSessions();
  },[page,limit]);
  return (
    <div >
        {loading?
   
        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingBottom:10}}>
  <p className='m-0'>Sessions</p>
<div style={{display:'flex',alignItems:'center'}}>
      <p  id="pagination-text" className="mx-2 m-0" style={{ fontSize: '12px' }}>
            {page == 1 ? 0 : (page - 1) * limit}-{page * limit <= count ? page * limit : count} of {count}
          </p>
          <div className="pagination-container py-2 border d-flex h-100">
          <svg  onClick={() => {
                if (page > 1) setPage(page - 1);
              }} style={{ opacity: page > 1 ? '1' : '0.25' }} className="border-end" fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
  <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
</svg>
         
          
         <svg  onClick={() => {
                if (page < totalPages) setPage(page + 1);
              }}   style={{ opacity: page < totalPages ? '1' : '0.25' }} fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)">
  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
  </g>
</svg>
          </div>
      </div>
</div>
      {sessions.map((session, index) => (
       <div>
               <p style={{color:'black'}}>{session.date}</p>
               {session.data.map((session, index) => (
                <div  style={{cursor:session.orders.length==0?'not-allowed':'pointer'}} onClick={()=>{
                    if(session.orders.length==0){

                    }else{
                        navigate(`/session/${session.id}`)
                    }
                 }} className='sessioncontainer'>
                    <div style={{width:'25%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <p className='m-0'>{session.orders==null?0:session.orders.length}</p>
                        <p className='m-0'>{session.orders==null?'Order':session.orders.length>1?'Orders':'Order'}</p>
                    </div>
                    <div style={{width:'65%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <p className='m-0'>{String(session.connect_date).slice(11,16)} - {String(session.disconnect_date).slice(11,16)}</p>
                        <div style={{display:'flex',alignItems:'center'}}>
                        <FaClock style={{marginRight:10}} size={15} />
                        <p className='m-0'>Duration: {session.duration} min</p>
                        </div>
                    </div>
                    <div style={{width:'10%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    <FaAngleRight size={20} color='#d1d1d1' />
                    </div>
                

                </div>
              ))} 
       </div>
              ))}
              </>
            }
    </div>
  )
}
