import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';
export default function EditSubCity() {
    const {id} = useParams()
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [data, setData] = useState([]);
    const [cityid, setCityid] = useState();
    const [loading, setLoading] = useState(true)
    const handleAdd = () => {
        if (validateFields()) {
            AddCatt()
        } else {
          console.log('Validation failed');
        }
      };
      const AddCatt = async () => {
        setLoading(true)
        const {status, data, msg} = await sendData('/cities/edit', JSON.stringify({name,type:2,id,cityid:cityid}));
        if(status){
            setLoading(false)
         navigate('/cities')
        }
      }
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
    const GetCat = async ()=>{
        const {status, data, msg} = await fetchData(`/cities/${id}/2`);
        if(status){
            setName(data.name)
            setCityid(data.cityid)
            // setData(data)
              setLoading(false)
         }
    }
    useEffect(()=>{
      GetCat()
      GetCat1()
    },[])
    const GetCat1 = async ()=>{
        const {status, data, msg} = await fetchData(`/cities/dropdown`);
        if(status){
            setData(data)
            setLoading(false)
         }
    }
  return (
    <div style={{marginTop:'5%'}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<label style={{marginLeft:10}} className="form-label mt-4">Cities <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={cityid} disabled={true} onChange={(e)=>setCityid(e.target.value)} required name="code" className="form-control"  >
{data.map((item,i)=>(
<option value={item.id}>{item.name}</option>
))}

</select>

</div>
<div style={{margin:'5px 10px 10px 10px'}} >
<label className="form-label mt-4">Sub City Name</label>
 <input required type="text" className="form-control"  name="slogan" value={name} 
 onChange={(e) => {setName(e.target.value)}}
 />
         <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleAdd}>Save</Button>

 </div>
</>
}



</div>
  )
}
