import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthedRoute from './components/AuthedRoute';
import ProtectedRoute from './components/ProtectedRoute';
import Login from "./pages/Login";
import Home from "./pages/Home";
import Products from "./pages/Products";
import AddProductForm from "./pages/AddProductForm";
import Orders from "./pages/Orders";
import ApprovedStores from "./pages/ApprovedStores";
import PendingStores from "./pages/PendingStores";
import RejectedStores from "./pages/RejectedStores";
import StoreDetails from "./pages/StoreDetails";
import ApprovedShopper from "./pages/ApprovedShopper";
import BranchDetails from "./pages/BranchDetails";
import ShopperDetails from "./pages/ShopperDetails";
import SessionDetails from "./pages/SessionDetails";
import Banks from "./pages/Banks";
import BanksDetails from "./pages/BanksDetails";
import AddBank from "./pages/AddBank";
import StoreCat from "./pages/StoreCat";
import AddStorecat from "./pages/AddStorecat";
import StoreCatDetails from "./pages/StoreCatDetails";
import ProductCat from "./pages/ProductCat";
import AddCat from "./pages/AddCat";
import AddSubCat from "./pages/AddSubCat";
import AddSubSub from "./pages/AddSubSub";
import EditCat from "./pages/EditCat";
import EditSubCat from "./pages/EditSubCat";
import EditSubSub from "./pages/EditSubSub";
import Cities from "./pages/Cities";
import AddCity from "./pages/AddCity";
import AddSubCity from "./pages/AddSubCity";
import EditCity from "./pages/EditCity";
import EditSubCity from "./pages/EditSubCity";
import ShopperOrderPayments from "./pages/ShopperOrderPayments";
import ShopperOrderSummary from "./pages/ShopperOrderSummary";
import DepositDetails from "./pages/DepositDetails";
import User from "./pages/User";
import ProductSearch from "./pages/ProductSearch";
import UserDetails from "./pages/UserDetails";
import Shifts from "./pages/Shifts";
import AddShifts from "./pages/AddShifts";
import Branches from "./pages/Branches";
import Global from "./pages/Global";
import AddSettelments from "./pages/AddSettelments";
import Deposits from "./pages/Deposits";
import DepositDetailss from "./pages/DepositDetailss";
import ShopperSettelments from "./pages/ShopperSettelments";
import AddShopperSettelments from "./pages/AddShopperSettelments";
import Settelments from "./pages/Settelments";
import DeliveryFees from "./pages/DeliveryFees";
import AddLimiter from "./pages/AddLimiter";
import EditLimiter from "./pages/EditLimiter";
import Quote from "./pages/Quote";
import EarningPlan from "./pages/EarningPlan";
import Brands from "./pages/Brands";
import AddBrand from "./pages/AddBrand";
import EditBrand from "./pages/EditBrand";
import Faq from "./pages/Faq";
import AddFaq from "./pages/AddFaq";
import EditFaq from "./pages/EditFaq";
import OrderFaq from "./pages/OrderFaq";
import AddOrderFaq from "./pages/AddOrderFaq";
import EditOrderFaq from "./pages/EditOrderFaq";
import Promotions from "./pages/Promotions/Promotions";
import AddPromotion from "./pages/Promotions/AddPromotion";
import PromotionsDetails from "./pages/Promotions/PromotionsDetails";
import PromotionProducts from "./pages/Promotions/PromotionProducts";
import PromotionBranches from "./pages/Promotions/PromotionBranches";
import PromotionsProductsByBranch from "./pages/Promotions/PromotionsProductsByBranch";
import AppVersions from "./pages/Appversion/AppVersions";
import MainPromotions from "./pages/MainPromotions/MainPromotions";
import AddMainPromotion from "./pages/MainPromotions/AddMainPromotion";
import EditMainPromotions from "./pages/MainPromotions/EditMainPromotions";
import Incentives from "./pages/Incentive/Incentives";
import AddIncentive from "./pages/Incentive/AddIncentive";
import EditIncentive from "./pages/Incentive/EditIncentive";
import AddStoreLimiter from "./pages/StoreLimiter/AddStoreLimiter";
import GetStoreLimiter from "./pages/StoreLimiter/GetStoreLimiter";
import EditStoreLimiter from "./pages/StoreLimiter/EditStoreLimiter";
import GetStoreCashier from "./pages/StoreCashier/GetStoreCashier";
import EditStoreCashier from "./pages/StoreCashier/EditStoreCashier";

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/login" element={<AuthedRoute component={Login} />}></Route>
          <Route path="/home" element={<ProtectedRoute component={Home} />}></Route>

          <Route path="/stores/approved" element={<ProtectedRoute component={ApprovedStores} />}></Route>
          <Route path="/stores/pending" element={<ProtectedRoute component={PendingStores} />}></Route>
          <Route path="/stores/rejected" element={<ProtectedRoute component={RejectedStores} />}></Route>
          <Route path="/store/branches/:id" element={<ProtectedRoute component={BranchDetails} />}></Route>
          <Route path="/store/:id" element={<ProtectedRoute component={StoreDetails} />}></Route>

          <Route path="/mainpromotion" element={<ProtectedRoute component={MainPromotions} />}></Route>
          <Route path="/addmainpromotion/:id" element={<ProtectedRoute component={AddMainPromotion} />}></Route>
          <Route path="/editmainpromotion/:id" element={<ProtectedRoute component={EditMainPromotions} />}></Route>

          <Route path="/incentives" element={<ProtectedRoute component={Incentives} />}></Route>
          <Route path="/addincentive" element={<ProtectedRoute component={AddIncentive} />}></Route>
          <Route path="/editincentive/:id" element={<ProtectedRoute component={EditIncentive} />}></Route>


          <Route path="/addstorelimiter/:storeid" element={<ProtectedRoute component={AddStoreLimiter} />}></Route>
          <Route path="/getstorelimiter/:storeid" element={<ProtectedRoute component={GetStoreLimiter} />}></Route>
          <Route path="/editstorelimiter/:storeid/:id" element={<ProtectedRoute component={EditStoreLimiter} />}></Route>

          {/* <Route path="/addstorelimiter/:storeid" element={<ProtectedRoute component={AddStoreLimiter} />}></Route> */}
          <Route path="/getstorecashier/:storeid" element={<ProtectedRoute component={GetStoreCashier} />}></Route>
          <Route path="/editstoreCashier/:id" element={<ProtectedRoute component={EditStoreCashier} />}></Route>

          



          <Route path="/shoppers" element={<ProtectedRoute component={ApprovedShopper} />}></Route>
          <Route path="/shopper/:id" element={<ProtectedRoute component={ShopperDetails} />}></Route>
          <Route path="/session/:id" element={<ProtectedRoute component={SessionDetails} />}></Route>
          <Route path="/payment-orders/:id" element={<ProtectedRoute component={ShopperOrderPayments} />}></Route>
          <Route path="/order-summary/:driverid/:orderid" element={<ProtectedRoute component={ShopperOrderSummary} />}></Route>
          <Route path="/deposit-detail/:id" element={<ProtectedRoute component={DepositDetails} />}></Route>



          <Route path="/users" element={<ProtectedRoute component={User} />}></Route>
          <Route path="/user/detail/:id" element={<ProtectedRoute component={UserDetails} />}></Route>


          <Route path="/product/searched" element={<ProtectedRoute component={ProductSearch} />}></Route>


          <Route path="/product-cat" element={<ProtectedRoute component={ProductCat} />}></Route>

          <Route path="/add-cat" element={<ProtectedRoute component={AddCat} />}></Route>
          <Route path="/edit-cat/:id" element={<ProtectedRoute component={EditCat} />}></Route>

          <Route path="/add-subcat" element={<ProtectedRoute component={AddSubCat} />}></Route>
          <Route path="/edit-subcat/:id" element={<ProtectedRoute component={EditSubCat} />}></Route>

          <Route path="/add-subsubcat" element={<ProtectedRoute component={AddSubSub} />}></Route>
          <Route path="/edit-subsubcat/:id" element={<ProtectedRoute component={EditSubSub} />}></Route>


          <Route path="/store-cat" element={<ProtectedRoute component={StoreCat} />}></Route>
          <Route path="/add-store-cat" element={<ProtectedRoute component={AddStorecat} />}></Route>
          <Route path="/storecat/:id" element={<ProtectedRoute component={StoreCatDetails} />}></Route>


          <Route path="/banks" element={<ProtectedRoute component={Banks} />}></Route>
          <Route path="/add-bank" element={<ProtectedRoute component={AddBank} />}></Route>
          <Route path="/bank/:id" element={<ProtectedRoute component={BanksDetails} />}></Route>

          <Route path="/cities" element={<ProtectedRoute component={Cities} />}></Route>
          <Route path="/add-city" element={<ProtectedRoute component={AddCity} />}></Route>
          <Route path="/add-subcity" element={<ProtectedRoute component={AddSubCity} />}></Route>
          <Route path="/edit-city/:id" element={<ProtectedRoute component={EditCity} />}></Route>
          <Route path="/edit-subcity/:id" element={<ProtectedRoute component={EditSubCity} />}></Route>


          <Route path="/shifts" element={<ProtectedRoute component={Shifts} />}></Route>
          <Route path="/add-shift" element={<ProtectedRoute component={AddShifts} />}></Route>
          <Route path="/branches" element={<ProtectedRoute component={Branches} />}></Route>
          <Route path="/global-status" element={<ProtectedRoute component={Global} />}></Route>


          <Route path="/add-settelments" element={<ProtectedRoute component={AddSettelments} />}></Route>
          <Route path="/shopper-depoists" element={<ProtectedRoute component={Deposits} />}></Route>
          <Route path="/deposit-details/:id" element={<ProtectedRoute component={DepositDetailss} />}></Route>
          <Route path="/shopper-settelments" element={<ProtectedRoute component={ShopperSettelments} />}></Route>
          <Route path="/add-shopper-settelments" element={<ProtectedRoute component={AddShopperSettelments} />}></Route>
          <Route path="/settelments" element={<ProtectedRoute component={Settelments} />}></Route>


          <Route path="/deliveryfees" element={<ProtectedRoute component={DeliveryFees} />}></Route>
          <Route path="/add-delivery-fees" element={<ProtectedRoute component={AddLimiter} />}></Route>
          <Route path="/edit-delivery-fees/:id" element={<ProtectedRoute component={EditLimiter} />}></Route>

          <Route path="/quote" element={<ProtectedRoute component={Quote} />}></Route>
          <Route path="/earning-plan" element={<ProtectedRoute component={EarningPlan} />}></Route>

          


          <Route path="/brands" element={<ProtectedRoute component={Brands} />}></Route>
          <Route path="/add-brand" element={<ProtectedRoute component={AddBrand} />}></Route>
          <Route path="/brand/:id" element={<ProtectedRoute component={EditBrand} />}></Route>

          <Route path="/faq" element={<ProtectedRoute component={Faq} />}></Route>
          <Route path="/add-faq" element={<ProtectedRoute component={AddFaq} />}></Route>
          <Route path="/faq/:id" element={<ProtectedRoute component={EditFaq} />}></Route>

          <Route path="/orders-faq" element={<ProtectedRoute component={OrderFaq} />}></Route>
          <Route path="/add-order-faq" element={<ProtectedRoute component={AddOrderFaq} />}></Route>
          <Route path="/order-faq/:id" element={<ProtectedRoute component={EditOrderFaq} />}></Route>

          <Route path="/promotion-products/:id" element={<ProtectedRoute component={PromotionProducts} />}></Route>
          <Route path="/promotions" element={<ProtectedRoute component={Promotions} />}></Route>
          <Route path="/add-promotion" element={<ProtectedRoute component={AddPromotion} />}></Route>
          <Route path="/promotion/:id" element={<ProtectedRoute component={PromotionsDetails} />}></Route>
          <Route path="/promotion-branches/:id" element={<ProtectedRoute component={PromotionBranches} />}></Route>
          <Route path="/promotion-productsbranch/:id/:branchid" element={<ProtectedRoute component={PromotionsProductsByBranch} />}></Route>



          <Route path="/products" element={<ProtectedRoute component={Products} />}></Route>
          <Route path="/products-add" element={<ProtectedRoute component={AddProductForm} />}></Route>
          <Route path="/orders" element={<ProtectedRoute component={Orders} />}></Route>

          <Route path="/app-version" element={<ProtectedRoute component={AppVersions} />}></Route>
          {/* <Route path="/products-add" element={<ProtectedRoute component={AddProductForm} />}></Route>
          <Route path="/orders" element={<ProtectedRoute component={Orders} />}></Route> */}


          <Route path="*" element={<Navigate to="/home" />}></Route>
        </Routes>
        <ToastContainer />
    </Router>
  );
}

export default App;
