import React,{useEffect,useState,useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { FaClock, FaShoppingCart, FaStarHalfAlt,FaRegStar, FaTruck,FaStar, FaStore, FaHome } from 'react-icons/fa';
import Map, { GeolocateControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { PiUserFill } from "react-icons/pi";
export default function ShopperOrderSummary() {
    const mapRef = useRef()
    const {driverid,orderid} = useParams()
    const [loading, setLoading] = useState(true);
    const [orderinfo, setOrderInfo] = useState();
    const [showclientemap, setShowClientemap] = useState(false);
    const [showbranchmap, setShowBranchmap] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [viewport, setViewport] = useState({
      latitude: 33.888630,
      longitude: 35.495480,
      zoom: 16,
    });
        const GetOrderSummary = async () => {
            const {status, data, msg} = await sendData('/shoppers/orders/summary', JSON.stringify({userid:driverid,checkoutbranchid:orderid}));
            if(status){
                setOrderInfo(data)
                setViewport({
                    latitude: data.branch.lat,
                    longitude: data.branch.longg,
                    zoom: 16,
                  });
                setLoading(false)
            }
        }
        useEffect(()=>{
            GetOrderSummary();
        },[]);

const renderRatingStars = (x) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div  key={i} >
            {x>=i?<FaStar color='orange' />: <FaRegStar />}
         
       </div>
      );
    }
    return stars;
  };
  return (
    <div style={{paddingTop:'5%',minHeight:'100vh',backgroundColor:'#f1f1f1',flex:1}}>
       {loading?

<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{overflow:'scroll',height:'88vh',paddingTop:'5%'}}>
<div style={{height:150,marginBottom:10,backgroundColor:orderinfo.stor.color,display:'flex',alignItems:'center',justifyContent:'center'}}>
<img src={orderinfo.stor.img} style={{width:'70%',height:'70%',objectFit:'contain'}}/>
</div>
        <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Order Number</p>
        <p className='m-0' style={{color:'black'}}>{orderinfo.order_number}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
        <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Branch</p>
        <p className='m-0' style={{color:'black'}}>{orderinfo.stor.name} {orderinfo.branch.name}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
        <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Product delivered</p>
        <p className='m-0' style={{color:'black'}}>{orderinfo.products.length}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Number of bags</p>
        <p className='m-0' style={{color:'black'}}>{orderinfo.bags_sum_quantity}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Customer evaluation</p>
        <div style={{display:'flex'}}>
            {renderRatingStars(orderinfo.rating?orderinfo.rating.rating:0)}
        </div>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,display:'flex',alignItems:'center',backgroundColor:'#d1d1d1'}}>
        <p className='m-0' style={{paddingTop:10,paddingBottom:10}}>Picking</p>
    </div>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Order date</p>
        <p className='m-0' style={{color:'black'}}>{orderinfo?.cartdriverx.begin}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Picking duration</p>
        <p className='m-0' style={{color:'black'}}>{Number(orderinfo?.pick_duration?orderinfo?.pick_duration:orderinfo?.pick_duration).toFixed(2)} mins</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Time per products</p>
        <p className='m-0' style={{color:'black'}}>{Number(orderinfo.time_per_prod).toFixed(2)} mins</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Commission</p>
        <p className='m-0' style={{color:'black'}}>${orderinfo?.earnings?.pick_total}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Commission + 10 Kg</p>
        <p className='m-0' style={{color:'black'}}>${orderinfo?.earnings?.pick_weight}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <>
    <div style={{paddingLeft:10,width:'100%',paddingTop:5,paddingBottom:5,backgroundColor:'white',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
     <div>
     <p className='m-0' style={{color:'#919191'}}>Distance to branch</p>
        <p className='m-0' style={{color:'black'}}>{Number(orderinfo?.cartdriverx.distance_to_store)/1000} km</p>
        </div>
              <div onClick={()=>{setShowBranchmap(!showbranchmap)}} style={{cursor:'pointer',justifyContent:'center',display:'flex',flexDirection:'row',alignItems:'center',height:'100%',marginRight:'5%'}}>
            <p className='m-0' style={{color:'gray',fontSize:15,fontWeight:'400'}}>{showbranchmap?'Hide route':'Show route'}</p>
            <svg
            style={{marginLeft:4,top:3}}
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width={14}
            height={14}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1024 1024"
            >
            {showbranchmap?
            <path
            fill={'gray'}
            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
            />
            :
            <path
            fill={'gray'}
            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
            />
            }

            </svg>
            </div>
           
    </div>
    {showbranchmap?
            <div>
{viewport.latitude && viewport.longitude && (
<Map
ref={mapRef}
style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
initialViewState={viewport}
//viewState={viewport}
onMouseOut={()=>setZoom(false)}
onMove={evt => setViewport(evt.viewState)}

onClick={()=>setZoom(true)}
onTouchEnd={()=>setZoom(false)}

scrollZoom={zoom}  // Disable scroll zooming
mapStyle="mapbox://styles/mapbox/streets-v11"
>
<Marker
offsetLeft={-25} // half of your custom marker's width
offsetTop={-50} // your custom marker's height
  longitude={orderinfo?.branch.longg}
  latitude={orderinfo?.branch.lat}
>
<PiUserFill color='#e05b0e' size={30}/>

    </Marker>
<Marker
offsetLeft={-25} // half of your custom marker's width
offsetTop={-50} // your custom marker's height
  longitude={orderinfo?.branch.longg}
  latitude={orderinfo?.cartdriverx.begin_lat}
>
<FaStore color='#e05b0e' size={30} />

    </Marker>

<GeolocateControl
positionOptions={{ enableHighAccuracy: true }}
trackUserLocation={true}
/>
</Map>
)} 
            </div>:null}
    </>
    
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,display:'flex',alignItems:'center',backgroundColor:'#d1d1d1'}}>
        <p className='m-0' style={{paddingTop:10,paddingBottom:10}}>Delivery</p>
    </div>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Delivery accepted</p>
        <p className='m-0' style={{color:'black'}}>{orderinfo.cartdriverx.begin}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Delivery Date</p>
        <p className='m-0' style={{color:'black'}}>{orderinfo?.cartdriverx.delivered_at}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Commission</p>
        <p className='m-0' style={{color:'black'}}>${orderinfo?.earnings?.delivery_total}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Commission + 10 Kg</p>
        <p className='m-0' style={{color:'black'}}>${orderinfo?.earnings?.delivery_weight}</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,paddingTop:5,paddingBottom:5,backgroundColor:'white'}}>
        <p className='m-0' style={{color:'#919191'}}>Distance to branch</p>
        <p className='m-0' style={{color:'black'}}>0 km</p>
    </div>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <>
  
    <div style={{paddingLeft:10,width:'100%',paddingTop:5,paddingBottom:5,backgroundColor:'white',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
       <div>
       <p className='m-0' style={{color:'#919191'}}>Distance to customer</p>
        <p className='m-0' style={{color:'black'}}>{Number(orderinfo?.distance)/1000} km</p>
       </div>
        <div onClick={()=>{setShowClientemap(!showclientemap)}} style={{cursor:'pointer',justifyContent:'center',display:'flex',flexDirection:'row',alignItems:'center',height:'100%',marginRight:'5%'}}>
            <p className='m-0' style={{color:'gray',fontSize:15,fontWeight:'400'}}>{showbranchmap?'Hide route':'Show route'}</p>
            <svg
            style={{marginLeft:4,top:3}}
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width={14}
            height={14}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1024 1024"
            >
            {showbranchmap?
            <path
            fill={'gray'}
            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
            />
            :
            <path
            fill={'gray'}
            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
            />
            }

            </svg>
            </div>
    </div>
    {showclientemap?
            <div>
{viewport.latitude && viewport.longitude && (
<Map
ref={mapRef}
style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
initialViewState={viewport}
//viewState={viewport}
onMouseOut={()=>setZoom(false)}
onMove={evt => setViewport(evt.viewState)}

onClick={()=>setZoom(true)}
onTouchEnd={()=>setZoom(false)}

scrollZoom={zoom}  // Disable scroll zooming
mapStyle="mapbox://styles/mapbox/streets-v11"
>
<Marker
offsetLeft={-25} // half of your custom marker's width
offsetTop={-50} // your custom marker's height
  longitude={orderinfo?.branch.longg}
  latitude={orderinfo?.branch.lat}
>
<PiUserFill color='#e05b0e' size={30}/>
 </Marker>
<Marker
offsetLeft={-25} // half of your custom marker's width
offsetTop={-50} // your custom marker's height
  longitude={orderinfo?.longg}
  latitude={orderinfo?.lat}
>
<FaHome color='#e05b0e' size={25} />

    </Marker>

<GeolocateControl
positionOptions={{ enableHighAccuracy: true }}
trackUserLocation={true}
/>
</Map>
)} 
            </div>:null}

    </>
    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
    <div style={{paddingLeft:10,display:'flex',alignItems:'center',backgroundColor:'#d1d1d1'}}>
        <p className='m-0' style={{paddingTop:10,paddingBottom:10}}>Product</p>
    </div>
    {orderinfo.products.map((item,i)=>(
       <>
             <div style={{width:'100%',paddingLeft:10,backgroundColor:'white',display:'flex',alignItems:'center',height:100}}>
            <div style={{backgroundColor:'#d1d1d1',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:7,width:45,height:25}}>
                <p className='m-0'>{item.quan?item.quan:item.gram} {item.quan?'':'g'}</p>
            </div>
            <div style={{width:60,height:60,marginLeft:20,display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'white',border:'1px solid #f1f1f1', borderRadius:7}}>
                <img className='m-0' src={item.prod?item.prod.img:null} style={{width:40,height:40,objectFit:'contain'}}/>
            </div>
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',paddingLeft:10,minWidth:300}}>
                <p className='m-0'>{item.added? item.prodbarcode!=null?item.prodbarcode.name:item.prodname  : item.CartProdStatus=='replaced'?item.alternative?.prodname :  item.prod!=null?item.prod.name:null}</p>
                <p className='m-0'>{item.CartProdStatus=='replaced'?item.alternative?.format:   item.prod!=null?item.prod.formatt:null}</p>
                <div style={{width:100,display:'flex',alignContent:'center',marginTop:2,height:30,justifyContent:'center',alignItems:'center',borderRadius:20,backgroundColor:item.CartProdStatus=='found'?'#3cb043':item.CartProdStatus=='rejected'?'red':'orange'}}>
                  <p className='m-0' style={{color:'white',fontSize:12,textTransform:'capitalize'}}>{item.CartProdStatus}</p>
                </div>
            </div>
        </div>   
        <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/>
       </>

    ))}
</div>
} 
    </div>
  )
}
