import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData,sendData } from '../utils';
import { FaAngleRight, FaClock } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';

export default function ShopperSettings(props) {
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(false);
    const [connected, setConnected] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [alcohol, setAlcohol] = useState(false);
    const [devices, setDevices] = useState([]);
    const [storeid, setStoreid] = useState(props.shopperInfo.storid);
    const [branchid, setBranchid] = useState(props.shopperInfo.branchid);
    const [stores, setStores] = useState([]);
    const [branches, setBranches] = useState([]);
    const fetchSettings= async ()=>{
        const {status, data, msg} = await fetchData(`/shoppers/settings/${props.userid}`);
              if(status){
            setConnected(data.connected)
            setActive(data.active)
            setBlocked(data.user.blocked)
            setLoading(false)
              }else{
                console.log({msg})
              }
        }
    useEffect(()=>{
        fetchSettings();
        GetUserDdevices()
        GetStores()
  },[]);
  const handleSave = async() =>{
    setLoading(true)
   const {status, data, msg} = await sendData('/shoppers/settings/edit', JSON.stringify({userid:props.userid,active:active?1:0,connected:connected?1:0,blocked:blocked?1:0}));
   if(status){
    props.GetShopperDetails()
    setLoading(false)

   }else{
      
   
   }

 }
 const handleAssign = async() =>{
  setLoading(true)
 const {status, data, msg} = await sendData('/shoppers/assign', JSON.stringify({driverid:props.userid,branchid}));
 if(status){
  props.GetShopperDetails()
  setLoading(false)

 }else{
  setLoading(false)
  alert(msg)
 
 }

}
const GetUserDdevices = async() =>{

const {status, data, msg} = await fetchData(`/deviceids/${props.userid}`);
if(status){
setDevices(data)
// props.GetUserDetails()
setLoading(false)

}else{
  

}

}
const GetStores = async() =>{

  const {status, data, msg} = await fetchData(`/stores/dropdown`);
  if(status){
    setStores(data)
    if(props.shopperInfo.branchid){
      getBranches(props.shopperInfo.storid)
    }
  setLoading(false)
  
  }else{
    
  
  }
  
  }
const getBranches = async(storeid) =>{
  setLoading(true)
  const {status, data, msg} = await fetchData(`/branchesdropdown/${storeid}`);
  if(status){
    setBranches(data)
  setLoading(false)
  }else{
  
  
  }
  
  }
const handleBlockById = async(device_id) =>{
setLoading(true)

const {status, data, msg} = await sendData('/deviceids/block', JSON.stringify({device_id}));
if(status){
props.GetShopperDetails()
setLoading(false)

}else{


}

}
const handleCheckboxChange = (id) => {
  const updatedDevices = devices.map((device) =>
    device.id === id ? { ...device, isBlocked: !device.isBlocked } : device
  );
  setDevices(updatedDevices);
};
  return (
    <div style={{paddingTop:'5%'}}>
                {loading?
   
   <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<div>

<label style={{marginLeft:10}} className="form-label mt-4">Stores</label>
<div  >
<select value={storeid} onChange={(e)=>{if(e.target.value==''){
setStoreid('')
setStores([])
setBranchid('')
setBranches([])
}else{
  setStoreid(e.target.value),getBranches(e.target.value)
}}} required name="code" className="form-control"  >
<option value={''}>None</option>
{stores.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}

</select>

</div>
<label style={{marginLeft:10}} className="form-label mt-2">Branches</label>
<div  >
<select value={branchid} onChange={(e)=>{setBranchid(e.target.value)}} required name="code" className="form-control"  >
{branches.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}
</select>

</div>
<Button style={{marginTop:'2%'}} onClick={handleAssign}>Assign</Button>
</div>

<label className="form-label mt-4" style={{ fontFamily: 'Poppins-SemiBold' }}>Shopper Status</label>
<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">

<input name="commercial_license" className="form-check-input" type="checkbox" id="flexCheckDefault1" value={connected} checked={connected}  onChange={() => setConnected(!connected)} />
<label className="form-check-label" htmlFor="flexCheckDefault1">
Connected
</label>
</div>
</div>

<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">


<input name="receipt" className="form-check-input" type="checkbox" id="flexCheckDefault2" value={active} checked={active}  onChange={() => setActive(!active)} />
<label className="form-check-label" htmlFor="flexCheckDefault2">
Active
</label>
</div>
</div>

<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">
<input name="sanitary" className="form-check-input" type="checkbox" id="flexCheckDefault3"  value={blocked} checked={blocked}  onChange={() => setBlocked(!blocked)}/>
<label className="form-check-label" htmlFor="flexCheckDefault3">
Blocked 
</label>
</div>
</div>

<Button style={{marginTop:'2%'}} onClick={handleSave}>Save</Button>
{devices.map((device) => (
        <div className='form-control' style={{marginTop:10,justifyContent:'space-between',alignItems:'center',display:'flex'}}  key={device.id}>
          <label>
            <input style={{marginRight:5}}
              type="checkbox"
              checked={device.isBlocked?true:false}
              onChange={() => handleCheckboxChange(device.id)}
            />
            {device.device_id} - {device.brand} - {device.os}
          </label>
          <Button  onClick={()=>{handleBlockById(device.device_id)}}>Save</Button>
        </div>
      ))}
</>}
   
    </div>
  )
}
