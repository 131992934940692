import React,{useState,useEffect} from 'react'
import { fetchData, sendData } from '../utils';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Alert, ModalTitle } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { SlArrowRight } from "react-icons/sl";
import { FaClock, FaShoppingCart, FaStarHalfAlt,FaRegStar, FaTruck,FaStar } from 'react-icons/fa';

export default function ShopperOrders(props) {
  const navigate = useNavigate()
  const [search, setSearch] = useState('');
  const [storename, setStorename] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(35);
  const [totalPages, setTotalPages] = useState(1);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState(orders);
  
  const GetOrders = async() =>{
    // setLoading(true)
    const {status, data, msg} = await sendData('/shoppers/orders', JSON.stringify({userid:props.userid,page,limit,number:search,storename}));
    if(status){
      setCount(data.count)
      setOrders(data.data)
      setFilteredOrders(data.data)
      setLoading(false)
      let totalPagesx = Math.ceil(data.count / 30);
      if(totalPagesx == 0) totalPagesx = 1;
      setTotalPages(totalPagesx)
        // navigate(`/store/${storeid}`)
    }else{
      // setShowalert(true)
      // setMsg(msg)
      setLoading(false);
    }

  }
 
  useEffect(()=>{
    GetOrders()
  },[page,limit,search,storename])

  const renderRatingStars = (x) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div  key={i} >
            {x>=i?<FaStar color='orange' />: <FaRegStar />}
         
       </div>
      );
    }
    return stars;
  };
  return (
    <div style={{paddingTop:'1%'}}>
             <p  style={{paddingLeft:20}}className='m-0'>All Orders</p>
 <div  style={{width:'100%',}}className="border-bottom px-3 py-3 d-flex justify-content-between">
 
      <div style={{display:'flex',alignItems:'center',width:'55%'}}>
      <div style={{cursor:'pointer',width:'44%',marginRight:'2%',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          id="search-product"
          placeholder="Search By Order Number"
          style={{width:'100%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {search!=''?   <svg onClick={()=>setSearch('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
         <div style={{cursor:'pointer',width:'44%',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={storename}
          onChange={(e) => setStorename(e.target.value)}
          type="text"
          id="search-product"
          placeholder="Search By Store Name"
          style={{width:'100%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {storename!=''?   <svg onClick={()=>setStorename('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <p  id="pagination-text" className="mx-2 m-0" style={{ fontSize: '12px' }}>
            {page == 1 ? 0 : (page - 1) * limit}-{page * limit <= count ? page * limit : count} of {count}
          </p>
          <div className="pagination-container py-2 border d-flex h-100">
          <svg  onClick={() => {
                if (page > 1) setPage(page - 1);
              }} style={{ opacity: page > 1 ? '1' : '0.25' }} className="border-end" fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
  <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
</svg>
         
          
         <svg  onClick={() => {
                if (page < totalPages) setPage(page + 1);
              }}   style={{ opacity: page < totalPages ? '1' : '0.25' }} fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)">
  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
  </g>
</svg>
          </div>
      </div>
      </div>

      {loading?
   
   <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
{orders.map((session,i)=>(
 <div onClick={()=>navigate(`/order-summary/${props.userid}/${session.id}`)} style={{width:'100%',cursor:'pointer',height:100,display:'flex',backgroundColor:'white',padding:5,alignItems:'center',marginBottom:'2%'}}>
 <div style={{width:'8%',display:'flex',alignItems:'center',justifyContent:'center'}}>
 <div style={{width:70,height:70,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:5,backgroundColor:session.color}}>
         <img src={session.img} style={{width:60,height:60,objectFit:'contain'}}/>
 </div>
  </div>   
 <div style={{display:'flex',width:'90%',marginLeft:10,flexDirection:'column',justifyContent:'center'}}>
     <p className='m-0'>{session.storename}</p>
      <div style={{display:'flex'}}>
      {renderRatingStars(session.rating)}
     </div>
     <div style={{display:'flex',alignItems:'center',}}>
     <div style={{display:'flex',alignItems:'center',marginRight:10}}>
     <FaShoppingCart style={{marginRight:10}}/>
     <p className='m-0'>{session.count} {session.count>1?'Products':'Product'}</p>
     </div>
     <div style={{display:'flex',alignItems:'center'}}>
     <FaTruck style={{marginRight:10}}/>
     <p className='m-0'>{session.branchname}</p>
     </div>
     </div>

 </div>
 <div style={{width:'20%',height:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'column'}}>
 <div style={{width:'100%',height:'50%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
  <p className='m-0' style={{fontSize:14,}}>{session.order_number}</p>
 <SlArrowRight />
 </div>
 <div style={{width:'100%',height:'50%',display:'flex',alignItems:'center'}}>
  <p className='m-0' style={{fontSize:14,}}>Amount: ${session.totalx}</p>
 
 </div>

 </div>
</div>
))}

         </>
       }
    </div>
  )
}
