import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';

export default function Quote() {
    const [des, setDes] = useState('');
    const [img, setImg] = useState('');
    const [loading, setLoading] = useState(true);
    const [imgchanged, setImhchanged] = useState(false);
    const [id, setId] = useState();
    const GetQuote = async ()=>{
        const {status, data, msg} = await fetchData(`/quote`);
        if(status){
            setDes(data.des)
            setImg(data.img)
            setId(data.id)
            setLoading(false)
         }
    }
    useEffect(()=>{
      GetQuote()
    },[])
    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setImg(selectedFile);
        setImhchanged(true)
      };
      const handleEdit = async() => {
        setLoading(true)
          try {
          
           
            const formData = new FormData();
            formData.append('id', id);
             if(imgchanged){
                formData.append('file', img);
             }
             formData.append('des', des);
             formData.append('imgchanged', imgchanged?1:0);
           
       
          const req = await fetch("https://api.trakitrak.com/control/quote/edit", {
            method:'POST',
            body: formData,
    })
    if(req.status==200){
        const json = await req.json()
        console.log(json)

 
        setImhchanged(false)
        GetQuote()

    }else{
        const json = await req.json()
        console.log(json) 
      
        setLoading(true)
    }
          } catch (error) {
            
          }
       
        
      };
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
{loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%',marginLeft:10}}>
<label className="form-label mt-4">Image</label>
<div>
        <img src={!imgchanged?img:URL.createObjectURL(img)} style={{width:100,height:100}}/>
        <input style={{marginLeft:10}} required accept="image/*"  type="file" name="exterior" id="exterior" onChange={handleLogoChange} />

    </div>

<label className="form-label mt-4">Description</label>
        <input required type="text" className="form-control"  name="slogan" value={des} 
        onChange={(e) => {setDes(e.target.value)}}
        />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleEdit}>Save</Button>

</div>
}
    </div>
  )
}
