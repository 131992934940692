import React,{useState,useEffect,useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
export default function PromotionsProductsByBranch() {
    const navigate = useNavigate()
    const {id,branchid} = useParams();
    const divRef = useRef(null); // Step 1: Use a Ref
    const columnHelper = createColumnHelper()
    const columns = [
        columnHelper.accessor('id', {
           id: 'id',
           cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
            header: () =><span>Product Id</span>,
            footer: info => info.column.id,
          }),
          columnHelper.accessor('name', {
              id: 'name',
              cell: info => <i 
               
               style={{cursor:'pointer'}}>{info.getValue()}</i>,
               header: () =><span>Name</span>,
               footer: info => info.column.id,
             }),
             columnHelper.accessor('img', {
              id: 'img',
               cell: info => <div style={{borderRadius:7,width:50,height:50,display:'flex',alignItems:'center',justifyContent:'center'}}> <img src={info.getValue()} style={{width:40,height:40,objectFit:'contain'}} /></div>,
               header: () =><p className='m-0' style={{minWidth:200}}>Product Image</p>,
               footer: info => info.column.id,
             }),
             columnHelper.accessor('brandname', {
              id: 'brandname',
              cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
               header: () =><span>Brand Name</span>,
               footer: info => info.column.id,
             }),
             columnHelper.accessor('barcode', {
              id: 'barcode',
              cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
               header: () =><span>Barcode</span>,
               footer: info => info.column.id,
             }),
             columnHelper.accessor('dess', {
              id: 'dess',
              cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
               header: () =><span>Description</span>,
               footer: info => info.column.id,
             }),
             columnHelper.accessor('catname', {
              id: 'catname',
               cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
               header: () =><p className='m-0' style={{minWidth:200}}>Product category</p>,
               footer: info => info.column.id,
             }),
             
             
             columnHelper.accessor('sku', {
              id: 'sku',
              cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
               header: () =><span>Sku</span>,
               footer: info => info.column.id,
             }),
            
             
      ]
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [showalert, setShowalert] = useState(false);
const [msg, setMsg] = useState('');
const [merchantid, setMerchantid] = useState();
const [page, setPage] = useState(1);
const [limitt, setLimitt] = useState(35);
const [totalPages, setTotalPages] = useState(1);
const [count, setCount] = useState(0);
const [limit, setLimit] = useState(0);
const [type, setType] = useState(0);
const [data, setProducts] = useState([]);
 const [loading, setLoading] = useState(true);
 const [search, setSearch] = useState('');
 const [stores, setStores] = useState([]);
 const [branches, setBranches] = useState([]);
 const [storeid, setStoreid] = useState();
 const [selectedRowIds, setSelectedRowIds] = useState([]);
//     /promotions/products
// POST
// branchid
// promotionid
// page
// limit(default bl backend aande 7atto 150 in case u didnt send it)
useEffect(()=>{

        FetchProducts();
    
    },[page,limitt,search]);
    const FetchProducts = async () => {
        //setLoading(true)
        const {status, data, msg} = await sendData('/promotions/products', JSON.stringify({
      limit:limitt,page:page,search,promotionid:id,branchid
        }));
        if(status){
            let totalPagesx = Math.ceil(data.count / data.limit);
            if(totalPagesx == 0) totalPagesx = 1;
            setProducts(data.products)
            // setMerchantid(data.products[0].userid)
            setTotalPages(totalPagesx);
            setCount(data.count);
            setLoading(false);
        }else{
                setShowalert(true)
                setMsg(msg)
                setLoading(false);
        }
      }
      const table = useReactTable({
        data,
        columns: columns.filter(column => visibleColumns.includes(column.id)),
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        //onPaginationChange: setPagination, 
        enableRowSelection:true, //hoist up the row selection state to your own scope
        state: {
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      });
  return (
    <div style={{overflowY:'scroll',height:'100vh'}} className="p-2">
    {loading?
  <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
<div className="table-container">
    <table className="table"> 
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td>
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
<div className="pagination">
        <button onClick={() => {setPage(page-1)}}  disabled={page === 1}>Previous</button>
        <button onClick={() => {setPage(page+1)}} disabled={page  === Math.ceil(count/limitt)}>Next</button>
        <span>
          Page
          <strong>
          {' '+page} of {Math.ceil(count/limitt)}
          </strong>
        </span>
        <select value={limitt} onChange={(e) => {
            setPage(1)
            setLimitt(Number(e.target.value))
            }}>
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      {/* //<Button onClick={AddProducts}>Add</Button> */}
</div>
}
    </div>
  )
}
