import * as React from "react";
const User = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="20.000000pt" height="20.000000pt" viewBox="0 0 256.000000 256.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
   fill="white" stroke="none">
   <path d="M577 2350 c-113 -19 -222 -103 -275 -209 -24 -50 -27 -67 -27 -161 0
   -100 1 -108 34 -168 41 -75 103 -135 179 -170 49 -23 70 -27 152 -27 77 0 104
   4 145 23 49 22 51 25 69 86 20 71 81 171 126 210 25 20 30 32 30 69 0 211
   -215 383 -433 347z"/>
   <path d="M1857 2350 c-153 -26 -286 -162 -304 -310 -5 -45 -3 -55 13 -67 88
   -62 176 -196 190 -288 6 -45 7 -45 62 -61 167 -49 348 30 432 188 62 115 53
   266 -21 377 -80 120 -229 185 -372 161z"/>
   <path d="M1200 1955 c-328 -92 -370 -549 -63 -696 207 -99 456 11 519 229 24
   82 15 193 -21 268 -78 159 -267 246 -435 199z"/>
   <path d="M368 1564 c-168 -40 -306 -176 -350 -343 -13 -53 -17 -112 -17 -291
   l-1 -226 99 -27 c132 -37 283 -65 386 -73 l85 -7 0 94 c0 177 50 301 169 420
   56 56 96 86 153 113 l76 38 -35 47 c-42 54 -79 133 -88 187 -15 94 11 84 -218
   83 -135 0 -221 -5 -259 -15z"/>
   <path d="M1757 1524 c-6 -63 -52 -167 -98 -225 -17 -22 -30 -41 -28 -43 2 -1
   25 -10 52 -20 26 -10 73 -37 105 -60 161 -119 241 -284 242 -498 l0 -87 53 5
   c172 16 318 48 417 90 l60 27 -1 221 c0 175 -4 235 -17 287 -45 173 -198 316
   -371 349 -30 5 -133 10 -231 10 l-177 0 -6 -56z"/>
   <path d="M1017 1175 c-130 -36 -242 -127 -301 -246 -51 -101 -57 -147 -54
   -394 l3 -220 100 -28 c391 -108 807 -107 1098 5 l77 29 0 208 c0 114 -5 234
   -11 267 -32 178 -176 333 -353 380 -70 19 -490 18 -559 -1z"/>
   </g>
   </svg>
);
export default User;
