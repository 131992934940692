import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';
export default function AddBank() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [mt, setMt] = useState(false);
    const handleAdd = () => {
        if (validateFields()) {
            AddBank()
        } else {
          console.log('Validation failed');
        }
      };
      const AddBank = async () => {
        const {status, data, msg} = await sendData('/banks/add', JSON.stringify({name,mt}));
        if(status){
         navigate('/banks')
        }
      }
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>
      <label className="form-label mt-4">Bank Name</label>
        <input required type="text" className="form-control"  name="slogan" value={name} 
        onChange={(e) => {setName(e.target.value)}}
        />
<div style={{marginTop:'1%'}} className="form-check">
<input name="commercial_license" className="form-check-input" type="checkbox" id="flexCheckDefault1" value={mt} checked={mt}  onChange={() => setMt(!mt)} />
<label className="form-check-label" htmlFor="flexCheckDefault1">
Money Transfer
</label>
</div>

<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleAdd}>Add Bank</Button>

    </div>
  )
}
