import React,{useEffect,useState} from 'react'
import { fetchData, sendData } from '../utils';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import { FaRegTrashAlt, FaTrash } from 'react-icons/fa';

export default function Banks() {
    const navigate =  useNavigate()
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span>Bank Id</span>,
          footer: info => info.column.id,
        }),
    
           columnHelper.accessor('name', {
            id: 'name',
            cell: info => <i  onClick={()=>{
                navigate(`/bank/${info.row.original.id}`)
              }}  className="hover-underline"style={{paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()}</i>,
             header: () =><span>Bank Name</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('mt', {
            id: 'mt',
            cell: info => <i style={{paddingRight:50}}>{info.getValue()?"Yes":'No'}</i>,
             header: () =><span>Is Money Transfer</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('actions', {
            id: 'actions',
            header: 'Actions',
            cell: info => (
                <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
                  {/* <button className="view-button" onClick={()=>{
                    navigate(`/bank/${info.row.original.id}`)
                  }}>Edit</button> */}
                  <div  style={{display:'flex',alignItems:'center'}} onClick={()=>{
                       setShow1(true)
                       setBankid(info.row.original.id)
                  }} className='buttondelete' >
                
                  <FaTrash size={15}  style={{marginRight:5}}/>
                  <p className='m-0'>Deactivate</p>
                  </div>
                  {/* <button className='buttondelete' onClick={()=>{
                       setShow1(true)
                       setBankid(info.row.original.id)
                  }} >Delete</button> */}
                  {/* <button className="view-button" onClick={() => alert(`Viewing ${info.row.original.firstName}`)}>View</button> */}
                </div>
              ),
          }),
           
         
    ]
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow = () => setShow1(true);
    const [bankid, setBankid] = useState();
    const [data, setData] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);
    const table = useReactTable({
        data,
        columns: columns.filter(column => visibleColumns.includes(column.id)),
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        //onPaginationChange: setPagination, 
        enableRowSelection:true, //hoist up the row selection state to your own scope
        state: {
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      });
    const GetBanks = async ()=>{
        const {status, data, msg} = await fetchData(`/banks`);
        if(status){
            setData(data)
            setLoading(false)
         }
    }
    useEffect(()=>{
        GetBanks()
    },[])
    const DeleteBank = async(id) =>{
        const {status, data, msg} = await sendData('/banks/deactivate', JSON.stringify({id:bankid}));
        if(status){
            GetBanks()
        }else{
          setLoading(false);
        }
        //  /stores/status/edit
        //   type ya 1(accept) ya 2(reject)
      }
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
      {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
    <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Banks</p>
        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={()=>navigate('/add-bank')}>Add Bank</Button>

   </div>
<div style={{marginLeft:'1.5%'}} className="table-container">
    <table className="table"> 

      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                {/* <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th> */}
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            {/* <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td> */}
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
 <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Deactivate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Deactivate this Bank?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button  variant="primary" onClick={()=>{   setShow1(false) 
            DeleteBank(bankid)}} >
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
</div>
}
    </div>
  )
}
