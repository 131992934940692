import React,{useState,useEffect, useRef, useMemo} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import debounce from 'lodash.debounce';
import { Alert } from 'react-bootstrap';
import Map, { GeolocateControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Modal,Button } from 'react-bootstrap';
import Editor from "./Editor";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from 'draft-js';

function hourDifference(timeString1, timeString2) {
    // Assuming time strings are in the format HH:mm
    let time2='00'
    if(timeString2){
      time2 = timeString2.split(':').map(Number);
    }else{
      time2='00'
    }
    const [hours1, minutes1] = timeString1.split(':').map(Number);
    const [hours2, minutes2] = time2;
  
    // Create Date objects with a fixed date and the given time
    const date1 = new Date(2000, 0, 1, hours1, minutes1);
    const date2 = new Date(2000, 0, 1, hours2, minutes2);
  
    // Check if date2 is earlier than date1 (next day)
    if (date2 < date1) {
      date2.setDate(date2.getDate() + 1); // Move date2 to the next day
    }
  
    // Calculate the hour difference
    const hourDiff = (date2 - date1) / (1000 * 60 * 60);
  
    return Math.abs(hourDiff); // Return the absolute value to handle negative differences
  }
  
  function isBefore(openTime, closeTime) {
    if(openTime==''&&closeTime==''){
      return true;
    }else{
      let close='00'
      const open = openTime.split(':').map(Number);
      if(closeTime){
      close = closeTime.split(':').map(Number);
      }else{
        close='00'
      }
  
  
  
    if (close[0] > open[0]) {
        // The close time is on the next day
        return true;
    } else if (close[0] === open[0] && close[1] >= open[1]) {
     // The close time is on the same day and at or after the open time
      return true;
      }
      return false;
    }
        // The close time is on the same day and before the open time
     
  }
export default function BranchDetails() {
    const {id} =useParams()
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
      );
      const handleClose = () => setShow(false);
      const [show, setShow] = useState(false);
      const [excel, setexcel] = useState(null);
      const [processmodal, setProcessmodal] = useState(false);
      const [processloader, setProcessloader] = useState(false);
      const [modalLoading2, setModalLoading2] = useState(false);
      const [loadermodal, setLoadermodal] = useState(false);
const [modalInput, setModalInput] = useState(null);
      const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
      const mapRef = useRef()
      const [storeid, setStoreid] = useState();
      const [conf, setConf] = useState();
      const [search, setsearch] = useState([]);
      const [showsearch, setshowsearch] = useState(true);
      const [error, setError] = useState(false);
      const [errors, setErrors] = useState([]);
      const [extraimg, setExtraimg] = useState([]);
      const [interiorImage, setInteriorImage] = useState(null);
      const [interiorImagechanged, setInteriorImagechanged] = useState(false);
      const [logochanged, setLogochanged] = useState(false);
      const [imgs, setimgs] = useState([]);
      const [name, setName] = useState('');
      const [Email, setEmail] = useState('');
      const [citys, setCitys] = useState([]);
      const [city, setCity] = useState('');
      const [unit, setUnit] = useState('');
      const [address, setAddress] = useState('');
      const [reference, setReference] = useState('');
      const [zoom, setZoom] = useState(false);
      const [viewport, setViewport] = useState({
        latitude: 33.888630,
        longitude: 35.495480,
        zoom: 16,
      });
      const [monday1, setMonday1] = useState('09:00');
      const [monday2, setMonday2] = useState('17:00');
      const [tuesday1, setTuesday1] = useState('09:00');
      const [tuesday2, setTuesday2] = useState('17:00');
      const [wednesday1, setWednesday1] = useState('09:00');
      const [wednesday2, setWednesday2] = useState('17:00');
      const [thursday1, setThursday1] = useState('09:00');
      const [thursday2, setThursday2] = useState('17:00');
      const [friday1, setFriday1] = useState('09:00');
      const [friday2, setFriday2] = useState('17:00');
      const [saturday1, setSaturday1] = useState('09:00');
      const [saturday2, setSaturday2] = useState('17:00');
      const [sunday1, setSunday1] = useState('09:00');
      const [sunday2, setSunday2] = useState('17:00');
      const [logoImage, setLogoImage] = useState(null);
      const [show1, setShow1] = useState(false);
      const [subcitys, setSubCitys] = useState([]);
      const [subcity, setsubcity] = useState('');
      const handleClose1 = () => setShow1(false);

  const handleImages = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage && imgs.length < 3) {
        setimgs((prevImages) => [...prevImages, selectedImage]);
    }
  };

 
  const handleLogoChange = (event) => {
    const selectedFile = event.target.files[0];
    setLogoImage(selectedFile);
  };
  const handlebannerChange = (event) => {
     const selectedFile = event.target.files[0];
     setInteriorImage(selectedFile);
   };
 
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setCity(selectedValue);
    GetArea(selectedValue)
  };
  const GetArea= async (x)=>{
    const {status, data, msg} = await fetchData(`/subcities/dropdown/${x}`);
    setSubCitys(data)
   if(data.length>0){
    setsubcity(data[0].id)
   }
    //console.log(data)
    //setCitys(data);
    }
//   const hasTimeError = useMemo(() => {
//     const arr = [[monday1, monday2], [tuesday1, tuesday2], [wednesday1, wednesday2], [thursday1, thursday2], [friday1, friday2], [saturday1, saturday2], [sunday1, sunday2]];
//     const notBefore = arr.some(day => !isBefore(day[0], day[1]));
//     const hrDiff = arr.some(day => hourDifference(day[0], day[1]) < 2);
//     // console.log({monday1, monday2})
//     // console.log({notBefore, hrDiff})
//     if(notBefore && !hrDiff) return 2;
//     else if(!notBefore && hrDiff) return 1;
//     else if(notBefore) return 1;
//     else return false;
//   },[monday1, monday2, tuesday1, tuesday2, wednesday1, wednesday2, thursday1, thursday2, friday1, friday2, saturday1, saturday2, sunday1, sunday2]);

//   console.log({hasTimeError})


    // useEffect(() => {
    //   navigator.geolocation.getCurrentPosition((pos) => {
    //     setViewport({
    //       ...viewport,
    //       latitude: pos.coords.latitude,
    //       longitude: pos.coords.longitude,
    //       zoom: 16,
    //     });
    //   });
    // }, []);
    const delayedGecode = debounce(() => {
        if (address !== '') {
          // Call your gecode function here
          gecodex();
        }
      }, 500); // Adjust the delay time as needed (e.g., 1000 milliseconds = 1 second)
    
      useEffect(() => {
        // This will be called each time the address changes
        delayedGecode();
    
        // Cleanup the debounce function on component unmount
        return () => delayedGecode.cancel();
      }, [address]);
      const gecode = async (f) => {
        let x =
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          f +
          '&key=AIzaSyBCd9yNUYE6BQKavXhy265DDQ0DHkBmVvc'
    
          // console.log(x)
        await fetch(x)
          .then(response => response.json())
          .then(json => {
      
            mapRef.current?.flyTo({center: [json.results[0].geometry.location.lng, json.results[0].geometry.location.lat], duration: 2000,zoom:16})
            setsearch([])
  setTimeout(() => {
    setsearch([])
setshowsearch(true)
  }, 2000);

    
            // console.log(json.results[0].geometry.location.lat)
            // console.log(json.results[0].geometry.location.lng)
    
          })
          .catch(error => {})
      }
      const gecodex = async () => {
        const {status, data, msg} = await fetchData(`/addressgeo/${address}`);
        //console.log(data.predictions)
        setsearch(data.predictions)
        setshowsearch(true)
        // setCitys(data);

        

      }

      const HandlePreImport = async() => {
         setProcessloader(true)
        //setLoadermodal(true)
          try {
            let userid =localStorage.getItem('userid')
            let storid =localStorage.getItem('storeid')
             
            const formData = new FormData();
            formData.append('branchid', id);
            formData.append('file', excel);
            const req = await fetch("https://api.trakitrak.com/control/branches/zone/import", {
             method:'POST',
             body: formData,
             })
      if(req.status==200){
        const json = await req.json()
          console.log(json)
          setProcessloader(false)
          handleClose()
        }else{
        const json = await req.json()
        console.log(json) 
        setProcessloader(false)
        handleClose()
        }
          } catch (error) {

          }
       
        
      };

      const exportzone = async() => {
        setProcessloader(true)
     
        try {
        
        const req = await fetch("https://api.trakitrak.com/control/branches/zone/export", {
          method:'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
          body: JSON.stringify({
             branchid:id
          }),
    })
    console.log(req.status)
    if(req.status==200){
      const blob = await req.blob()
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `TrakiTrak - ${id}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setProcessloader(false)
      handleClose()    
    }else{
      const json = await req.json()
      console.log(json) 
      setProcessloader(false)
      handleClose()
    }
        } catch (error) {
          setProcessloader(false)
          handleClose()
    }
   
   
    
  };


    const GetStoreDetails = async ()=>{
        const {status, data, msg} = await fetchData(`/branches/${id}`);
        if(status){ 
            // const [interiorImage, setInteriorImage] = useState(null);
            // const [imgs, setimgs] = useState([]);
             setLogoImage(data.branch.img1)
            setInteriorImage(data.branch.img2)
            setStoreid(data.branch.storid)
            setConf(data.branch.conf)
            setName(data.branch.name)
            setEmail(data.branch.email)
            setCity(data.branch.cityid)
            GetArea(data.branch.cityid)
            setsubcity(data.branch.subcityid)
            setUnit(data.branch.unitt)
            setAddress(data.branch.address)
            setReference(data.branch.reference)
            setCitys(data.cities)
            setMonday1(data.branch.monday1)
            setMonday2(data.branch.monday2)
            setTuesday1(data.branch.tuesday1)
            setTuesday2(data.branch.tuesday2)
            setWednesday1(data.branch.wedneday1)
            setWednesday2(data.branch.wedneday2)
            setThursday1(data.branch.thursday1)
            setThursday2(data.branch.thursday2)
            setFriday1(data.branch.friday1)
            setFriday2(data.branch.friday2)
            setSaturday1(data.branch.saturday1)
            setSaturday2(data.branch.saturday2)
            setSunday1(data.branch.sunday1)
            setSunday2(data.branch.sunday2)
            setViewport({
                latitude: data.branch.lat,
                longitude: data.branch.longg,
                zoom: 16,
              });

          setLoading(false)
        }
    }
    useEffect(()=>{
        GetStoreDetails()
    },[])
    const showAlerts = () =>{
        return (
          <>
      {showalert&&(
    <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
        {msg}
    </Alert>
      )}
         
        </>
        )
      }
      const AcceptBranch = async() =>{
        const {status, data, msg} = await sendData('/branches/acceptreject', JSON.stringify({branchid:id,conf:1}));
        if(status){
            navigate(`/store/${storeid}`)
        }else{
          setShowalert(true)
          setMsg(msg)
          setLoading(false);
        }

      }
      const RejectBranch = async() =>{
        const {status, data, msg} = await sendData('/branches/acceptreject', JSON.stringify({branchid:id,conf:2,reason:stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")}));
        if(status){
            navigate(`/store/${storeid}`)
        }else{
          setShowalert(true)
          setMsg(msg)
          setLoading(false);
        }
        //  /stores/status/edit
        //   type ya 1(accept) ya 2(reject)
      } 
      const isFormValid = () => {
                 let x=[]
          
              // Validate string fields
              if (!name) x.push('Name is required');
              if (!Email) x.push('Email is required');
              if (!city) x.push('City is required');
              if (!address) x.push('Address is required');
              if (!unit) x.push('Unit is required');
              if (!reference) x.push('reference is required');
              if (!viewport) x.push('latlng is required');
              if (!subcity) x.push('Sub City is required');
            
              // Validate email
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(Email)) x.push('Invalid email');
            
              // Validate images
              if (!logoImage) x.push('Exterior image is required');
              if (!interiorImage) x.push('Interior image is required');
           
            
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };
      const handleSubmit = async() => {
              setErrors([])
      
        
              if (isFormValid()) {
                setLoading(false)
                    setError(false)
                try {
          
                 
                  const formData = new FormData();
                  formData.append('storeid', storeid);
                   formData.append('branchid', id);
                   formData.append('name', name);
                   formData.append('email', Email);
                   formData.append('cityid', city);
                   formData.append('subcityid', subcity);
                   formData.append('unit', unit);
                   formData.append('address', address);
                   formData.append('reference', reference);
                   formData.append('lat', viewport.latitude);
                   formData.append('long', viewport.longitude);
   
                   formData.append('interior', interiorImage);
                   formData.append('exterior', logoImage);

                   formData.append('monday1', monday1);
                   formData.append('monday2', monday2);
                   formData.append('tuesday1', tuesday1);
                   formData.append('tuesday2', tuesday2);
                   formData.append('wedneday1', wednesday1?wednesday1:'');
                   formData.append('wedneday2', wednesday2?wednesday2:'');
                   formData.append('thursday1', thursday1);
                   formData.append('thursday2', thursday2);
                   formData.append('friday1', friday1);
                   formData.append('friday2', friday2);
                   formData.append('saturday1', saturday1);
                   formData.append('saturday2', saturday2);
                   formData.append('sunday1', sunday1);
                   formData.append('sunday2', sunday2);
                  
                   if(extraimg.length>0){
                    for (let i = 0; i < extraimg.length; i++) {
                      formData.append(`extra_${i+1}`, extraimg[i]);
                      
                    }
                   }
             
                const req = await fetch("https://api.trakitrak.com/control/branches/edit", {
                  method:'POST',
                  body: formData,
          })
          if(req.status==200){
              const json = await req.json()
              console.log(json)
      
              setLoading(true)
              navigate(`/store/${storeid}}`, {replace: true});
      
          }else{
              const json = await req.json()
              console.log(json) 
              setShowalert(true)
              setMsg(json.message)
              setLoading(true)
          }
                } catch (error) {
                  
                }
             
              } else {
                  setError(true)
      
              }
            };
            const handleSelectChangee = (event) => {
              const selectedValue = event.target.value;
              setsubcity(selectedValue);
            };
  return (
    <div style={{marginTop:'5%'}}>
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to accept?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {
            AcceptBranch();
            setShowConfirmModal(false); // Close modal after action
          }}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
          <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Reject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label className="form-label mt-4">Reason <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <Editor editorState={editorState} setEditorState={setEditorState} />
      
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button disabled={stateToHTML(editorState.getCurrentContent()) == "<p><br></p>"?true:false} variant="primary" onClick={()=>{   setShow1(false) 
            RejectBranch()}} >
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal1"  // Custom class for centering horizontally
     show={show} onHide={handleClose}>
    
    <div className="modal-content" style={{minHeight:'75vh',backgroundColor:'#fafbff'}}>
                        <div className="modal-header" style={{ display: "block" }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Upload spreadsheet</h5>
                                <div className="d-flex align-items-center">
                                    <div>
                                      <button onClick={()=>{setShow(false)
                                      setexcel(null)
                                      setModalInput(null)
                                      }} type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button disabled={processloader&&excel?true:excel&&!processloader?false:true} onClick={HandlePreImport} type="submit" className="btn buttonx-outline">Upload</button></div>
                                </div>
                            </div>
                        </div>
                        <div className={"modal-body" + (modalLoading2 ? ' d-flex justify-content-center align-items-center' : '')} style={{ backgroundColor: "#fafbff",width:'90%',marginLeft:'5%' }}>
                        {processloader?
                        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'28%'}}>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      </div> : (
                                <div>
                                    <input type="hidden" name="_token" value="{{csrf_token()}}" />
                                    <input type="hidden" name="storeid" value="{{$storeid}}" />
                                    <div className="d-flex">
                                        <div className="d-flex justify-content-center" >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">1</p>
                                            </div>
                                        </div>
                                        <p style={{ margin:0,marginLeft:10,marginTop:3 }}>Download the spreadsheet template and fill in the required information.</p>
                                    </div>

                                    <div className="d-flex justify-content-center mt-1"  >
                                        <a onClick={exportzone} download className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "red", cursor: "pointer",marginTop:50 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            <p className="text-white ms-1 m-0" style={{ fontSize: "14px" }}>Download template</p>
                                        </a>
                                    </div>

                                    <div className="d-flex mt-4">
                                        <div className="d-flex justify-content-center" >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">2</p>
                                            </div>
                                        </div>
                                        <p style={{ margin:0,marginLeft:10,marginTop:3 }}>Upload your spreadsheet here</p>
                                    </div>

                                    <div className="d-flex flex-column justify-content-center mt-2 py-4" style={{ backgroundColor: "white",height:'30vh' ,borderRadius:10,marginBottom:10 }}>

                                        {modalInput && (
                                          <div style={{display:'flex',justifyContent:'space-between',height:70,alignItems:'center',borderBottom:'1px solid red'}}>
                                       
                                            <div style={{marginLeft:15,}} className="d-flex  align-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                                                </svg>
                                                <p className='m-0' style={{fontSize:17,fontWeight:'bold'}}>{modalInput}</p>
                                            </div>

                                            <div onClick={()=>{
                                              setModalInput(null)
                                              setexcel(null)
                                            }} style={{marginRight:34,cursor:'pointer'}}>
                                                <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                  <line x1="18" y1="6" x2="6" y2="18" />
                                                  <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                    
                                            </div>
                                        )}

                                        <label htmlFor="file" style={{display:'flex',justifyContent:modalInput?'flex-end':'center',width:'100%',height:50,alignItems:'center' }}>
                                            <input onChange={(e) => {
                                                const file = e.target.files[0];
                                                   console.log({excel})
                                                   setexcel(e.target.files[0])
                                                   console.log({excel})
                                                if (file) {
                                                  console.log({file})
                                             
                                                    setModalInput(file.name)
                                                } else setModalInput(null);
                                            }} id="file" name="file" type="file"  style={{ display: "none" }} />
                                         
                                            <div className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "#f1f1f1",width:120,height:40, cursor: "pointer" }}>
                                                <p className='m-0'  style={{ fontSize: "14px",color:'black',fontWeight:'bolder', }}>{excel?'Replace file':'Select a file'}</p>
                                            </div>
                                        </label>

                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
        
            
       

</Modal>
          {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{overflowY:'scroll'}}>
<div className="card" style={{overflowY:'scroll',height:'90vh'}}>
<div  className="card-header d-flex justify-content-between align-items-center">
<p className='m-0'>{'Edit Branch'}</p>
<div style={{display:'flex',alignItems:'center'}}>
<input onClick={()=>{setShow(true)}} type="submit" style={{marginRight:10}} className="btn btn-outline-primary buttonx-outline" value="Coverage area" />

{conf==0?
<div style={{display:'flex',alignItems:'center'}}>
<input onClick={()=>{setShowConfirmModal(true)}}  type="submit" style={{marginRight:10}} className="btn btn-outline-primary buttonx-outline" value="Accept" />
<input onClick={()=>setShow1(true)}  type="submit" style={{marginRight:10}} className="btn btn-outline-primary buttonx-outline" value="Reject" />

</div>
:
conf==1?
<input   onClick={()=>setShow1(true)} type="submit" style={{marginRight:10}} className="btn btn-outline-primary buttonx-outline" value="Reject" />

:
conf==2?
<input onClick={()=>{setShowConfirmModal(true)}} type="submit" style={{marginRight:10}} className="btn btn-outline-primary buttonx-outline" value="Accept" />

:null

}

<input  onClick={()=>{handleSubmit()}} type="submit" className="btn btn-outline-primary buttonx-outline" value="Save" />
  
</div> 
   </div>
   <div className="card-body">
   {error && (
<div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
<div className="d-flex align-items-center">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
</svg>
<strong className="ms-1">{errors[0]}</strong>
</div>
<button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
</svg>
</button>
</div>
)}
<label className="form-label">Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />

<label className="form-label mt-4">Email <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required type="text" className="form-control" name="company"  value={Email} onChange={(e) => setEmail(e.target.value)}  />

<label className="form-label mt-4">Interior and exterior photos of this branch <span style={{color:'gray',fontSize:13}}>(required)</span></label>
<div id="main-img-div" className="rounded border p-2">
   <div className="border-bottom pb-2">
       <label htmlFor="exterior" className="w-100 label-input">
           <input required accept="image/*" style={{ display: 'none' }} type="file" name="exterior" id="exterior" onChange={handleLogoChange} />
           <div className="d-flex align-items-center">
               <div>
                   <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                       <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                           <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                       </svg>
                       {logoImage!=null?<img className="img-uploaded" src={!logochanged?logoImage:URL.createObjectURL(logoImage)}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null}

                   </div>
   
               </div>


               <div className="d-flex flex-column justify-content-center ms-2 m-0">
                   <p className='m-0'>Exterior photo</p>
                   <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
               </div>
               <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',position:'absolute',right:10}}>
<Button style={{marginRight:20}} onClick={()=>{ window.open(!logochanged?logoImage:URL.createObjectURL(logoImage), '_blank');}}>
  View Image
</Button>
</div>
           </div>
       </label>
    
   </div>

   <div className="mt-2 border-bottom pb-2">
       <label className="w-100 label-input" htmlFor="interior">
           <input required accept="image/*" style={{ display: 'none' }} type="file" name="interior" id="interior" onChange={handlebannerChange} />
           <div className="d-flex align-items-center">
               <div>
                   <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                       <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                           <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                       </svg>
                       {interiorImage!=null?<img className="img-uploaded" src={!interiorImagechanged?interiorImage:URL.createObjectURL(interiorImage)}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null}
                   </div>
               </div>

               <div className="d-flex flex-column justify-content-center ms-2">
                   <p  className='m-0'>Interior photo</p>
                   <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
               </div>
               <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',position:'absolute',right:10}}>
<Button style={{marginRight:20}} onClick={()=>{ window.open(!interiorImagechanged?interiorImage:URL.createObjectURL(interiorImage), '_blank');}}>
  View Image
</Button>
</div>
           </div>
       </label>
   </div>
   {extraimg.map((item,i)=>(
                   <div className="border-bottom pb-2">
       <label className="w-100 label-input"  style={{marginTop:'1%'}}>
   <input accept="image/*" style={{ display: 'none' }} type="file" onChange={handleImages}  />
   <div className="d-flex align-items-center justify-content-between">
       <div className="d-flex align-items-center">
           <div>
               <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                   <svg className="img-preview " style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                       <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                   </svg>
                   {/* {imgs[i]?<img className="img-uploaded" src={URL.createObjectURL(imgs[i])}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null} */}
               </div>
           </div>

           <div className="d-flex flex-column justify-content-center ms-2">
               <p className='m-0'>Add another interior or exterior photo</p>
               <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
           </div>
       </div>

       <div  onClick={(e)=>{
           if (extraimg.length > 0) {
               e.preventDefault()
               setExtraimg(prevImages => prevImages.filter((_, index) => index !== i));
           }
       }}  className='close-img'>
           <svg className='m-0' xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512">
               <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
           </svg>
       </div>
   </div>
</label>
</div>
   ))}


   <div onClick={()=>{
  if (extraimg.length < 3) {
   setExtraimg(prevImages => [...prevImages, null]);
}
       }} id="add-img" className="p-2 mt-2 justify-content-between align-items-center" style={{ display: 'flex',cursor:'pointer' }}>
       <div className="d-flex align-items-center">
           <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
               <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
               <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
               <g id="SVGRepo_iconCarrier">
                   <title>plus-circle</title>
                   <desc>Created with Sketch Beta.</desc>
                   <defs></defs>
                   <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
                       <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
                           <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup">
                           </path>
                       </g>
                   </g>
               </g>
           </svg>
           <p className="ms-2 m-0">Add another interior or exterior photo</p>
       </div>
       <svg height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
           <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
           <g id="SVGRepo_iconCarrier">
               <path d="M10 7L15 12L10 17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
           </g>
       </svg>
   </div>
</div>

<label className="form-label mt-4">City <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<select className="form-control" value={city} onChange={handleSelectChange}>
<option value="" disabled>Select an option</option>
{citys.map((option, index) => (
<option key={index} value={option.id}>{option.name}</option>
))}
</select>
<label className="form-label mt-4">Area <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <select className="form-control" value={subcity} onChange={handleSelectChangee}>
      <option value="" disabled>Select an option</option>
      {subcitys.map((option, index) => (
        <option key={index} value={option.id}>{option.name}</option>
      ))}
    </select>
<label className="form-label mt-4">Address <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required type="text" className="form-control" name="company" value={address} onChange={(e) => setAddress(e.target.value)}  />
{search.length>0&&showsearch&&address?
<div className="form-control" style={{marginTop:'1%'}}>
{search.map((item,i)=>(
<div onClick={()=>{
setAddress(item.description)
setshowsearch(false)
 gecode(item.description)
setsearch([])
}} style={{borderBottomWidth:1,display:'flex',alignItems:'center',borderColor:'gray',cursor:'pointer',width:'100%',height:40,}}>
<p>{item.description}</p>
</div>
))}
</div>:null}

<label className="form-label mt-4">Unit <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required type="text" placeholder="Example: building x, 2nd floor" className="form-control" name="company" value={unit} onChange={(e) => setUnit(e.target.value)}  />

<label className="form-label mt-4">Reference <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required placeholder="Example: in front of the metro" type="text" className="form-control" name="company" value={reference} onChange={(e) => setReference(e.target.value)}  />
{viewport.latitude && viewport.longitude && (
<Map
ref={mapRef}
style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
initialViewState={viewport}
//viewState={viewport}
onMouseOut={()=>setZoom(false)}
onMove={evt => setViewport(evt.viewState)}

onClick={()=>setZoom(true)}
onTouchEnd={()=>setZoom(false)}

scrollZoom={zoom}  // Disable scroll zooming
mapStyle="mapbox://styles/mapbox/streets-v11"
>
<Marker

  longitude={viewport.longitude}
  latitude={viewport.latitude}
/>
<GeolocateControl
positionOptions={{ enableHighAccuracy: true }}
trackUserLocation={true}
/>
</Map>
)} 
        <h5 className="text-center text-secondary mt-3">If the location does not correspond to your branch, move the map to the correct location.</h5>


 {/* testing */}

 {/* testing */}


       <h6 className="mt-5">Opening times <span style={{color:'gray',fontSize:13}}>(This is the opening schedule time when our Shoppers can visit your store)</span></h6>

       {/* {hasTimeError &&
       <div id="time-error" className="alert alert-danger alert-dismissible fade show w-100 mt-3 justify-content-between align-items-center" style={{ backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
           </svg>
           <strong id="err-text" className="ms-1">{hasTimeError == 1 ? 'The time between opening time and closing time must be at least 2 hours' : 'Closing time cannot be after 23:59' }</strong>
         </div>
       </div> } */}

<div className="mt-3 table-responsive">
<table className="table">
<thead>
<tr className="head-tr">
<th scope="col">Day</th>
<th scope="col">Opening time</th>
<th scope="col">Closing time</th>
</tr>
</thead>
<tbody>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
<div className="form-check">
<input
name="monday"
className="form-check-input"
type="checkbox"
onChange={()=>{
if(monday1&&monday2){
   setMonday1('')
   setMonday2('')
}else{
   setMonday1('09:00')
   setMonday2('17:00')
}

}}
checked={ !monday1 && !monday2}
/>
<label className="form-check-label">Monday</label>
</div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: monday1 === '' && monday2 === '' ? 'table-cell' : 'none' }}>
<p>Closed</p>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
 <TimePicker 
  onChange={setMonday1} 
  value={monday1} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />

</div>
</div>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setMonday2} 
  value={monday2} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>
</tr>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
<div className="form-check">
<input
name="monday"
className="form-check-input"
type="checkbox"
onChange={()=>{
if(tuesday1&&tuesday2){
   setTuesday1('')
   setTuesday2('')
}else{
   setTuesday1('09:00')
   setTuesday2('17:00')
}

}}
checked={!tuesday1 && !tuesday2}
/>
<label className="form-check-label">Tuesday</label>
</div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: tuesday1 === '' && tuesday2 === '' ? 'table-cell' : 'none' }}>
<p>Closed</p>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setTuesday1} 
  value={tuesday1} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday1 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setTuesday2} 
  value={tuesday2} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>
</tr>


<tr data-checked={ wednesday1 === '' && wednesday2 === '' ? '0' : '1'}>
<th scope="row">
<div className="form-check">
<input
name="monday"
className="form-check-input"
type="checkbox"
onChange={()=>{
if(wednesday1&&wednesday2){
   setWednesday1('')
   setWednesday2('')
}else{
   setWednesday1('09:00')
   setWednesday2('17:00')
}

}}
checked={( !wednesday1 && !wednesday2)}
/>
<label className="form-check-label">Wednesday</label>
</div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: wednesday1 === '' && wednesday2 === '' ? 'table-cell' : 'none' }}>
<p>Closed</p>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setWednesday1} 
  value={wednesday1} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setWednesday2} 
  value={wednesday2} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>
</tr>


<tr data-checked={ thursday1 === '' && thursday2 === '' ? '0' : '1'}>
<th scope="row">
<div className="form-check">
<input
name="monday"
className="form-check-input"
type="checkbox"
onChange={()=>{
if(thursday1&&thursday2){
   setThursday1('')
   setThursday2('')
}else{
   setThursday1('09:00')
   setThursday2('17:00')
}

}}
checked={( (!thursday1 && !thursday2))}
/>
<label className="form-check-label">Thursday</label>
</div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: thursday1 === '' && thursday2 === '' ? 'table-cell' : 'none' }}>
<p>Closed</p>
</td>

<td className="time-row" style={{ display:  thursday1 === '' && thursday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setThursday1} 
  value={thursday1} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>

<td className="time-row" style={{ display: thursday1 === '' &&thursday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setThursday2} 
  value={thursday2} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>
</tr>


<tr data-checked={ friday1 === '' && friday2 === '' ? '0' : '1'}>
<th scope="row">
<div className="form-check">
<input
name="monday"
className="form-check-input"
type="checkbox"
onChange={()=>{
if(friday1&&friday2){
   setFriday1('')
   setFriday2('')
}else{
   setFriday1('09:00')
   setFriday2('17:00')
}

}}
checked={(( !friday1 && !friday2))}
/>
<label className="form-check-label">Friday</label>
</div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: friday1 === '' && friday2 === '' ? 'table-cell' : 'none' }}>
<p>Closed</p>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setFriday1} 
  value={friday1} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setFriday2} 
  value={friday2} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>
</tr>
<tr data-checked={ saturday1 === '' && saturday2 === '' ? '0' : '1'}>
<th scope="row">
<div className="form-check">
<input
name="monday"
className="form-check-input"
type="checkbox"
onChange={()=>{
if(saturday1&&saturday2){
   setSaturday1('')
   setSaturday2('')
}else{
   setSaturday1('09:00')
   setSaturday2('17:00')
}

}}
checked={( ( !saturday1 && !saturday2))}
/>
<label className="form-check-label">Saturday</label>
</div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: saturday1 === '' && saturday2 === '' ? 'table-cell' : 'none' }}>
<p>Closed</p>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setSaturday1} 
  value={saturday1} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setSaturday2} 
  value={saturday2} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>
</tr>
<tr data-checked={sunday1 === '' &&sunday2 === '' ? '0' : '1'}>
<th scope="row">
<div className="form-check">
<input
name="monday"
className="form-check-input"
type="checkbox"
onChange={()=>{
if(sunday1&&sunday2){
   setSunday1('')
   setSunday2('')
}else{
   setSunday1('09:00')
   setSunday2('17:00')
}

}}
checked={( (!sunday1 && !sunday2))}
/>
<label className="form-check-label">Sunday</label>
</div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display:sunday1 === '' &&sunday2 === '' ? 'table-cell' : 'none' }}>
<p>Closed</p>
</td>

<td className="time-row" style={{ display: sunday1 === '' &&sunday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setSunday1} 
  value={sunday1} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>

<td className="time-row" style={{ display:  sunday1 === '' && sunday2 === '' ? 'none' : 'table-cell' }}>
<div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
<div className="d-flex align-items-center time-div">
<TimePicker 
  onChange={setSunday2} 
  value={sunday2} 
  clearIcon={null}
  //clockIcon={null}
  disableClock={true}
  format='HH:mm'
  />
</div>
</div>
</td>
</tr>
</tbody>
</table>
</div>
   </div>
</div>
</div>
      }
    </div>
  )
}
