import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { fetchData, sendData  } from '../../utils';

export default function AppVersions() {
    const [constants, setConstants] = useState([]);
    const [userIos, setUserIos] = useState();
    const [userAndroid, setUserAndroid] = useState();
    const [shopperAndroid, setShopperAndroid] = useState();
    const [shopperIos, setShopperIos] = useState();
    const [loading, setLoading] = useState(true);

    const GetVersion = async ()=>{
        setLoading(true)
        const {status, data, msg} = await fetchData(`/constants`);
        if(status){
            setConstants(data)
            for (let i = 0; i < data.length; i++) {
                if(data[i].key=='user_version_ios'){
                    setUserIos(data[i].value)
                }else  if(data[i].key=='user_version_android'){
                    setUserAndroid(data[i].value)
                }else  if(data[i].key=='shopper_version_ios'){
                    setShopperIos(data[i].value)
                }else  if(data[i].key=='shopper_version_android'){
                    setShopperAndroid(data[i].value)
                }
                setLoading(false)
            }
            // setDes(data.des)
            // setImg(data.img)
            // setId(data.id)
            // setLoading(false)
         }
    }
    useEffect(()=>{
      GetVersion()
    },[])
    const Update = async () => {
        if (!userAndroid || userAndroid <= 0 ||
            !userIos || userIos <= 0 ||
            !shopperIos || shopperIos <= 0 ||
            !shopperAndroid || shopperAndroid <= 0) {
            console.error('Error: All version numbers must be positive and non-empty.');
            return;  // Exit the function if validation fails
        }
        const {status, data, msg} = await sendData('/constants/update', JSON.stringify({
            user_version_android:userAndroid,
            user_version_ios:userIos,
            shopper_version_ios:shopperIos,
            shopper_version_android:shopperAndroid
        }));

        if(status){
            GetVersion()
        }
      }
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>

{loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>

             <label className="form-label mt-4">User Ios</label>
      <input required type="text" className="form-control"  name="slogan" value={userIos} 
      onChange={(e) => {setUserIos(e.target.value)}}
      />

<label className="form-label mt-4">User Android</label>
      <input required type="text" className="form-control"  name="slogan" value={userAndroid} 
      onChange={(e) => {setUserAndroid(e.target.value)}}
      />

<label className="form-label mt-4">Shopper Ios</label>
      <input required type="text" className="form-control"  name="slogan" value={shopperIos} 
      onChange={(e) => {setShopperIos(e.target.value)}}
      />

<label className="form-label mt-4">Shopper Android</label>
      <input required type="text" className="form-control"  name="slogan" value={shopperAndroid} 
      onChange={(e) => {setShopperAndroid(e.target.value)}}
      />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={Update}>Save</Button>
</>
}

  
  </div>
  )
}
