import * as React from "react";
const BankIcon = (props) => (
<svg fill="white" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"  
	 width="20px" height="20px" viewBox="0 0 512 512" >

<g>
	<polygon class="st0" points="256,0 30.828,85.781 30.828,126 46.906,126 46.906,138.063 465.094,138.063 465.094,126 481.172,126 
		481.172,85.781 	"/>
	<polygon class="st0" points="465.094,423.531 46.906,423.531 46.906,455.719 30.828,455.719 30.828,512 481.172,512 
		481.172,455.719 465.094,455.719 	"/>
	<polygon class="st0" points="360.547,375.281 360.547,399.406 440.969,399.406 440.969,375.281 432.922,375.281 432.922,178.266 
		440.969,178.266 440.969,154.141 360.547,154.141 360.547,178.266 368.594,178.266 368.594,375.281 	"/>
	<polygon class="st0" points="215.781,375.281 215.781,399.406 296.203,399.406 296.203,375.281 288.172,375.281 288.172,178.266 
		296.203,178.266 296.203,154.141 215.781,154.141 215.781,178.266 223.828,178.266 223.828,375.281 	"/>
	<polygon class="st0" points="71.031,375.281 71.031,399.406 151.453,399.406 151.453,375.281 143.406,375.281 143.406,178.266 
		151.453,178.266 151.453,154.141 71.031,154.141 71.031,178.266 79.078,178.266 79.078,375.281 	"/>
</g>
</svg>
);
export default BankIcon;