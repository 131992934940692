import React,{useEffect,useState} from 'react'
import { fetchData, sendData } from '../utils';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import { FaRegTrashAlt, FaTrash } from 'react-icons/fa';

export default function Brands() {
    const navigate =  useNavigate()
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span>Brand Id</span>,
          footer: info => info.column.id,
        }),
    
           columnHelper.accessor('name', {
            id: 'name',
            cell: info => <i  onClick={()=>{
              navigate(`/brand/${info.row.original.id}`)
              }}  className="hover-underline"style={{paddingRight:50,cursor:'pointer',textUnderlineOffset:'red'}}>{info.getValue()}</i>,
             header: () =><span>Brand Name</span>,
             footer: info => info.column.id,
           }),
          
           columnHelper.accessor('actions', {
            id: 'actions',
            header: 'Actions',
            cell: info => (
                <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
                  <button className="view-button" onClick={()=>{
                    navigate(`/brand/${info.row.original.id}`)
                  }}
                  >Edit</button>
                </div>
              ),
          }),
           
         
    ]
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow = () => setShow1(true);
    const [bankid, setBankid] = useState();
    const [data, setData] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [limitt, setLimitt] = useState(10);
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const [debouncedValue, setDebouncedValue] = useState(search);
    const table = useReactTable({
        data,
        columns: columns.filter(column => visibleColumns.includes(column.id)),
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        //onPaginationChange: setPagination, 
        enableRowSelection:true, //hoist up the row selection state to your own scope
        state: {
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      });
    const GetBrands = async ()=>{
        const {status, data, msg} = await fetchData(`/brands?page=${page}&limit=${limitt}&search=${search}`);
        if(status){
            setData(data.data)
            setCount(data.count)
            setLoading(false)
         }
    }
    useEffect(()=>{
        GetBrands()
    },[page,limitt])
    useEffect(() => {
      setPage(1)
      const handler = setTimeout(() => {
        setDebouncedValue(search);
      }, 500); // 500 ms delay
  
      return () => clearTimeout(handler);
    }, [search]);
  
    // Fetch data using debounced value
    useEffect(() => {

        GetBrands();
      
    }, [debouncedValue]);
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
      {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
<div style={{width:'96%',marginLeft:'1%'}}>
   <input
  type="text"
  className='form-control'
   value={search}
   onChange={e => {setPage(1);setSearch(e.target.value)}}
  placeholder="Search by brand name..."
/>
    </div>
    <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Brands</p>
        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={()=>navigate('/add-brand')}>Add Brand</Button>

   </div>
<div style={{marginLeft:'1.5%'}} className="table-container">
    <table className="table"> 

      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
               
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
          
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
 <div className="pagination">
        <button onClick={() => {setPage(page-1)}}  disabled={page === 1}>Previous</button>
        <button onClick={() => {setPage(page+1)}} disabled={page  === Math.ceil(count/limitt)}>Next</button>
        <span>
          Page
          <strong>
          {' '+page} of {Math.ceil(count/limitt)}
          </strong>
        </span>
        <select value={limitt} onChange={(e) => {
            setPage(1)
            setLimitt(Number(e.target.value))
            }}>
          {[10, 20, 30, 40, 50, 100].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

</div>
}
    </div>
  )
}
