import React, { useMemo ,useState,useEffect,useRef} from 'react';
import { FaTrash, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { fetchData, sendData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import Switch from "react-switch";
import Multiselect from 'multiselect-react-dropdown';


export default function Global() {
    const [cities, setCities] = useState([]);
    const [status1, setStatus1] = useState(1);
    const [msg1, setMsg] = useState('');
    const [subcities, setSubCities] = useState([]);
    const [cityid, setCityid] = useState();
    const [subcityid, setSubCityid] = useState([]);
    const [loading, setLoading] = useState(true)
    const GetCities = async ()=>{
        
        const {status, data, msg} = await fetchData(`/cities`);
        if(status){
            setCities(data)
            setCityid(data[0].id)
            GetArea(data[0].id)
            setLoading(false)

         }
    }
    useEffect(()=>{
        GetCities()
    },[])
    const GetArea= async (x)=>{
        const {status, data, msg} = await fetchData(`/subcities/dropdown/${x}`);
        setSubCities(data)
       if(data.length>0){
        setSubCityid(data[0].id)
       }
        //console.log(data)
        //setCitys(data);
        }
        const handleSave = async() =>{
            setLoading(true)
            const {status, data, msg} = await sendData('/ordstatc/edit', JSON.stringify({
                status:status1,
                cityid:cityid,
                subcityid:subcityid,
                msg:msg1
            }));
            if(status){
                setLoading(false)
            }else{
              
            }
    
          }

  return (
    <div style={{paddingTop:'5%'}}>
        {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<label style={{marginLeft:10}} className="form-label mt-4">Status <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={status1}  onChange={(e)=>{setStatus1(Number(e.target.value))}} required name="code" className="form-control"  >
<option value={1}>Active</option>
<option value={0}>InActive</option>
</select>
</div>
     
     
      <label style={{marginLeft:10}} className="form-label mt-4">Cities <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={cityid}  onChange={(e)=>{setCityid(Number(e.target.value));GetArea(Number(e.target.value))}} required name="code" className="form-control"  >
{cities.map((item,i)=>(
<option value={item.id}>{item.name}</option>
))}

</select>

</div>
<label style={{marginLeft:10}} className="form-label mt-4">Sub Cities <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={subcityid}  onChange={(e)=>{setSubCityid(Number(e.target.value))}} required name="code" className="form-control"  >
<option value={null}>ALL</option>
{subcities.map((item,i)=>(
<option value={item.id}>{item.name}</option>
))}

</select>

</div>

<label style={{marginLeft:10}} className="form-label mt-4">Reason <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<input placeholder='Reason' value={msg1} onChange={(e)=>setMsg(e.target.value)} className='form-control'/>

</div>

<Button disabled={msg1?false:true} style={{margin:10}} onClick={handleSave}>Save</Button>
</>
}
    </div>
  )
}
