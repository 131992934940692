import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData,sendData } from '../utils';
import { FaAngleRight, FaClock } from 'react-icons/fa';
import { SlArrowRight } from "react-icons/sl";
export default function ShopperFinancial(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(35);
    const [totalPages, setTotalPages] = useState(1);
    const [payments, setPayments] = useState([]);

    const fetchPayments = async() =>{
      // setLoading(true)
      const {status, data, msg} = await sendData('/shoppers/payments', JSON.stringify({userid:props.userid,page,limit}));
      if(status){
        setCount(data.count)
        setPayments(data.data)
        setLoading(false)
        let totalPagesx = Math.ceil(data.count / 30);
        if(totalPagesx == 0) totalPagesx = 1;
        setTotalPages(totalPagesx)
          // navigate(`/store/${storeid}`)
      }else{
        // setShowalert(true)
        // setMsg(msg)
        setLoading(false);
      }
  
    }
   
    useEffect(()=>{
      fetchPayments()
    },[page,limit,])


  return (
    <div >
    {loading?

    <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingBottom:10}}>
  <p className='m-0'>Payments</p>
<div style={{display:'flex',alignItems:'center'}}>
      <p  id="pagination-text" className="mx-2 m-0" style={{ fontSize: '12px' }}>
            {page == 1 ? 0 : (page - 1) * limit}-{page * limit <= count ? page * limit : count} of {count}
          </p>
          <div className="pagination-container py-2 border d-flex h-100">
          <svg  onClick={() => {
                if (page > 1) setPage(page - 1);
              }} style={{ opacity: page > 1 ? '1' : '0.25' }} className="border-end" fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
  <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
</svg>
         
          
         <svg  onClick={() => {
                if (page < totalPages) setPage(page + 1);
              }}   style={{ opacity: page < totalPages ? '1' : '0.25' }} fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)">
  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
  </g>
</svg>
          </div>
      </div>
</div>
{payments.map((item,i)=>(
  <div onClick={()=>navigate(`/payment-orders/${item.id}`)} style={{width:'100%',cursor:'pointer',display:'flex',alignItems:'center',height:100,backgroundColor:'white',marginBottom:'2%'}}> 
      <div style={{width:'25%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
         <p>${item.total}</p>
      </div>
      <div style={{width:'60%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <p className='m-0' style={{color:'black',fontWeight:'600'}}>Paid on {item.pay_date}</p>
        <p className='m-0' style={{color:'#a1a1a1',fontWeight:'400'}}>Commission of {item.orders_count} pickings and deliveries</p>
      </div>
      <div style={{width:'15%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <SlArrowRight />
      {/* <p className='m-0' style={{fontSize:18,fontWeight:'600',color:item.quantity>2?'green':'red',textTransform:'capitalize'}}>{item.quantity}</p> */}

      </div>
  </div>
))}
   
          </>
        }
</div>
  )
}
