import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Alert, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
export default function EditIncentive() {
  const {id} =useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [stores, setStores] = useState([]);
  const [branchid, setBranchid] = useState();
  const [storeid, setStoreid] = useState();
  const [name, setName] = useState('');
  const [description, setDes] = useState('');
  const [profit, setProfit] = useState('');
  const [from, setFrom] = useState('');
  const [ordernumber, setOrderNumber] = useState(5);
  const [type, setType] = useState(1);
  const [MultiPlicationrate, setMultiRate] = useState(1);
  const [hoursnb, setHoursnb] = useState(1);
  const [acceptrate, setAcceptRate] = useState(1);
  const [to, setTo] = useState('');

  const [Errors, setErrors] = useState([]);
  const [error, setError] = useState(false);
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  useEffect(()=>{
     // GetStoreBranches()
     GetStores()
     GetIncentiveDetails()
     },[])
     const showAlerts = () =>{
      return (
        <>
    {showalert&&(
  <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
      {msg}
  </Alert>
    )}
       
      </>
      )
    }
    const isFormValid = () => {
      let x=[]
   // Validate string fields
   if (!name) x.push('Name is required');
   if (!description) x.push('Description is required');
   if (!from) x.push('from is required');
   if (!to) x.push('to is required');
   if (!ordernumber) x.push('Number of Orders is required');
   if (!profit) x.push('profit is required');
   if (!storeid) x.push('Store is required');
   if (!branchid) x.push('Branch is required');
   if (!hoursnb) x.push('Number of Hours is required');
   if (!acceptrate) x.push('Accept rate is required');
   // if (!MultiPlicationrate) x.push('MultiPlication rate is required');

   if (!type) x.push('Incentive Type is required');
   if (x.length > 0) {
     console.log('Validation Errors:', x);
     setErrors(x)
     return false;
   }
  if(x.length==0){
   setErrors([])
   return true;
  }
   // If all validations pass, return true
  
  
  };
 

  const handleSubmit = async () => {
   setLoading(true)
   setErrors([])
 if(!isFormValid()){
   setLoading(false)
   setError(true)
 }else{
   const {status, data, msg} = await sendData(`/incentive/edit/${id}`, JSON.stringify({id,name,description,profit,from,to,branch_id:branchid,store_id:storeid,order_numbers:ordernumber,
    acceptance_rate:acceptrate,
    min_hours:hoursnb,
    type:type,
    multiplication_rate:MultiPlicationrate
   }));
   if(status){
       setLoading(false)
    navigate(-1)
   }else{
       setShowalert(true)
       setMsg(msg)
       setLoading(false)
   }
 }
 }

     const GetIncentiveDetails = async() =>{

      const {status, data, msg} = await fetchData(`/incentive/${id}`);
      if(status){
        setType(data.type)
        setHoursnb(data.min_hours)
        setMultiRate(data.multiplication_rate)
        setAcceptRate(data.acceptance_rate)
          setName(data.name)
          setDes(data.description)
          setFrom(data.from)
          setTo(data.to)
          setOrderNumber(data.order_numbers)
          setProfit(data.profit)

      
          setStoreid(data.store_id)
          getBranches(data.store_id,data.branch_id)
          setBranchid(data.branch_id)
          setLoading(false)
      
      }else{
        
      
      }
      
      }
    const GetStores = async() =>{

      const {status, data, msg} = await fetchData(`/stores/dropdown`);
      if(status){
        setStores(data)
       
      setLoading(false)
      
      }else{
        
      
      }
      
      }
    const getBranches = async(storeid,x) =>{

      const {status, data, msg} = await fetchData(`/branchesdropdown/${storeid}`);
      if(status){
        setBranches(data)
        if(x){
          setBranchid(x)

        }else{
          setBranchid(data[0].id)

        }
      }else{
      
      
      }
      
      }
      const confirmDelete = () => {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure you want to delete this item?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => HandleDelete()
            },
            {
              label: 'No',
              onClick: () => console.log('Cancel Pressed')
            }
          ]
        });
      };
      const confirmDeleteTotally = () => {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure you want to delete this item?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => HandleDeleteTotally()
            },
            {
              label: 'No',
              onClick: () => console.log('Cancel Pressed')
            }
          ]
        });
      };
      const HandleDeleteTotally = async () => {
        const {status, data, msg} = await sendData(`/incentive/delete-totally`,JSON.stringify({id}));
        if(status){ 
            navigate(-1)        
            setLoading(false)
        }
      }
      const HandleDelete = async () => {
        const {status, data, msg} = await sendData(`/incentive/delete`,JSON.stringify({id}));
        if(status){ 
            navigate(-1)        
            setLoading(false)
        }
      }

  return (
    <div style={{marginTop:'3%',marginLeft:'1%',overflow:'scroll',height:'95vh',paddingBottom:50}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>:
<>
    {showAlerts()}
         {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}

<label className="form-label mt-4">Incentive Type</label>
     <select value={type} onChange={(e)=>setType(e.target.value)} required name="code" className="form-control"  >

<option value={1}>Guarante</option>
<option value={2}>Multiplication</option>


</select>
        <label className="form-label mt-4">Name</label>
<input  className="form-control" type="text" id="name" value={name} onChange={e => setName(e.target.value)} />

<label className="form-label mt-4">Description</label>
<input  className="form-control" type="text" id="namex" value={description} onChange={e => setDes(e.target.value)} />


<label className="form-label mt-4">From (Date)</label>
<input  className="form-control"  type="datetime-local" id="from" value={from} onChange={e => setFrom(e.target.value)} />

<label className="form-label mt-4" htmlFor="to">To (Date)</label>
<input  className="form-control"  type="datetime-local" id="to" value={to}   min={from} onChange={e => setTo(e.target.value)} />

{type==1?
<>

  <label className="form-label mt-4">Profit</label>
<input  className="form-control" type="number" min={1} id="namex" value={profit} onChange={e => setProfit(e.target.value)} />
</>
:
<>
<label className="form-label mt-4">MultiPlication Rate</label>
<input  className="form-control" type="number" min={1} id="namex" value={MultiPlicationrate} onChange={e => setMultiRate(e.target.value)} />
</>
}
<label className="form-label mt-4">Minimum Number of hours</label>
<input  className="form-control" type="number" min={0} id="namex" value={hoursnb} onChange={e => setHoursnb(e.target.value)} />
<label className="form-label mt-4">Accept rate %</label>
<input  className="form-control" type="number" min={0} id="namex" value={acceptrate} onChange={e => setAcceptRate(e.target.value)} />












<label  className="form-label mt-2">Stores</label>
<div  >
<select value={storeid} onChange={(e)=>{if(e.target.value==''){
setStoreid('')
setStores([])
setBranchid('')
setBranches([])
}else{
  setStoreid(e.target.value),getBranches(e.target.value)
}}} required name="code" className="form-control"  >
<option value={''}>None</option>
{stores.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}

</select>

</div>
<label  className="form-label mt-2">Branches</label>
<div  >
<select value={branchid} onChange={(e)=>{setBranchid(e.target.value)}} required name="code" className="form-control"  >
{branches.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}
</select>

</div>






<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleSubmit}>Save</Button>
<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40,marginLeft:10}} onClick={confirmDelete}>Delete</Button>
<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40,marginLeft:10}} onClick={confirmDeleteTotally}>Delete Totally</Button>

</>
}
    </div>
  )
}
