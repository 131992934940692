import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Alert, Button } from 'react-bootstrap';
import Select from 'react-select';

export default function AddIncentive() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [branches, setBranches] = useState([]);
    const [stores, setStores] = useState([]);
    const [branchid, setBranchid] = useState();
    const [storeid, setStoreid] = useState();
    const [name, setName] = useState('');
    const [description, setDes] = useState('');
    const [profit, setProfit] = useState('');
    const [from, setFrom] = useState('');
    const [ordernumber, setOrderNumber] = useState(1);

    const [to, setTo] = useState('');

    const [Errors, setErrors] = useState([]);
    const [error, setError] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [type, setType] = useState(1);
    const [MultiPlicationrate, setMultiRate] = useState(1);
    const [hoursnb, setHoursnb] = useState(1);
    const [acceptrate, setAcceptRate] = useState(1);
    const [selectedStores, setSelectedStores] = useState([]);

    useEffect(()=>{
       // GetStoreBranches()
       GetStores()
       },[])
       const showAlerts = () =>{
        return (
          <>
      {showalert&&(
    <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
        {msg}
    </Alert>
      )}
         
        </>
        )
      }
         const isFormValid = () => {
           let x=[]
        // Validate string fields
        if (!name) x.push('Name is required');
        if (!description) x.push('Description is required');
        if (!from) x.push('from is required');
        if (!to) x.push('to is required');
        if (!ordernumber) x.push('Number of Orders is required');
        if (!hoursnb) x.push('Number of Hours is required');
        if (!acceptrate) x.push('Accept rate is required');
        // if (!MultiPlicationrate) x.push('MultiPlication rate is required');

        if (!type) x.push('Incentive Type is required');
        // if (!profit) x.push('profit is required');
        if (!selectedStores) x.push('Store is required');
        // if (!storeid) x.push('Store is required');
        // if (!branchid) x.push('Branch is required');
       
        if (x.length > 0) {
          console.log('Validation Errors:', x);
          setErrors(x)
          return false;
        }
       if(x.length==0){
        setErrors([])
        return true;
       }
        // If all validations pass, return true
       
       
       };
       const handleStoreChange = (selectedStores) => {
        const storeIds = selectedStores ? selectedStores.map(store => store.value) : [];
        console.log(storeIds)
        setStoreid(storeIds);
        if (storeIds.length === 0) {
          setBranches([]);
          setBranchid([]);
        } else {
          getBranches(storeIds);
        }
      };
    
      const handleBranchChange = (selectedBranches) => {
        const branchIds = selectedBranches ? selectedBranches.map(branch => branch.id) : [];
        setBranchid(branchIds);
      };
      function addSeconds(timeString) {
        return timeString + ":00";
      }
       const handleSubmit = async () => {
        setLoading(true)
        setErrors([])
        const storesWithStringBranchIds = storeBranchSelections.map(store => ({
          id: store.id,
          branch_ids: store.branch_ids,
        }));
        const output = storesWithStringBranchIds.map(item => {
          // Extract the latest array from branch_ids
          const latestBranchIds = item.branch_ids[item.branch_ids.length - 1];
          
          // Create the transformed object
          return {
            id: parseInt(item.id), // Convert id to integer if needed
            branch_ids: latestBranchIds.map(branch => branch.value).join(',') // Convert branch_ids to comma-separated string
          };
        });
       
      if(!isFormValid()){
        setLoading(false)
        setError(true)
      }else{
        const storesWithStringBranchIds = storeBranchSelections.map(store => ({
          id: store.id,
          branch_ids: store.branch_ids.join(', '),
        }));
        const {status, data, msg} = await sendData('/incentive/addMulti', JSON.stringify({
          name,
          description,
          profit,
          from:from.replace('T',' ')+ ":00",
          to:to.replace('T',' ')+ ":00",
          // store_ids:selectedStores.map(store => ({
          //   id: store.id,
          //   branch_ids: store.branch_ids.join(', '),
          // })),
            store_ids:output,
          
          
          // branch_id:branchid,
          // store_id:storeid,
          order_numbers:ordernumber,
          acceptance_rate:acceptrate,
          min_hours:hoursnb,
          type:type,
          multiplication_rate:MultiPlicationrate
        }
        ));
        if(status){
            setLoading(false)
         navigate(-1)
        }else{
            setShowalert(true)
            setMsg(msg)
            setLoading(false)
        }
      }
      }
  //     const GetStores = async() =>{

  //       const {status, data, msg} = await fetchData(`/stores/dropdown`);
  //       if(status){
  //         setStores(data)
         
  //       setLoading(false)
        
  //       }else{
          
        
  //       }
        
  //       }
  //     const getBranches = async(storeid) =>{
  // console.log({storeid})
  //       const {status, data, msg} = await fetchData(`/branchesdropdown/${storeid}`);
  //       if(status){
  //         setBranches(data)
  //         setBranchid(data[0].id)
  //       }else{
        
        
  //       }
        
  //       }
        const storeOptions = stores.map(store => ({
          value: store.id,
          label: store.name,
        }));
      
        // const branchOptions = branches.map(branch => ({
        //   value: branch.id,
        //   label: branch.name,
        // }));
             const GetStores = async() =>{

        const {status, data, msg} = await fetchData(`/stores/dropdown`);
        if(status){
          setStores(data)
         
        setLoading(false)
        
        }else{
          
        
        }
        
        }
        const getBranches = async (storeId) => {
          const { status, data } = await fetchData(`/branchesdropdown/${storeId}`);
          if (status) {
            setBranches((prev) => ({
              ...prev,
              [storeId]: data,
            }));
          } else {
            // Handle error
          }
        };
      
        const handleStoreChange1 = (storeId) => {
          getBranches(storeId);
          setSelectedStores((prev) => {
            if (prev.some((store) => store.id === storeId)) {
              return prev.filter((store) => store.id !== storeId);
            } else {
              return [...prev, { id: storeId, branch_ids: [] }];
            }
          });
        };
      
        const handleBranchChange1 = (storeId, branchId) => {
          setSelectedStores((prev) =>
            prev.map((store) =>
              store.id === storeId
                ? {
                    ...store,
                    branch_ids: store.branch_ids.includes(branchId)
                      ? store.branch_ids.filter((id) => id !== branchId)
                      : [...store.branch_ids, branchId],
                  }
                : store
            )
          );
        };





        const [storeBranchSelections, setStoreBranchSelections] = useState([{ id: null, branch_ids: [] }]);
        const handleStoreChange2 = (index, storeId) => {
          getBranches(storeId);
          setStoreBranchSelections((prev) => {
            const newSelections = [...prev];
            newSelections[index] = { id: storeId, branch_ids: [] };
            return newSelections;
          });
        };
      
        const handleBranchChange2 = (index, branchId) => {
          setStoreBranchSelections((prev) => {
            const newSelections = [...prev];
            const storeSelection = newSelections[index];
            if (storeSelection.branch_ids.includes(branchId)) {
              storeSelection.branch_ids = storeSelection.branch_ids.filter((id) => id !== branchId);
            } else {
              storeSelection.branch_ids.push(branchId);
            }
            newSelections[index] = storeSelection;
            return newSelections;
          });
        };
      
        const addNewStoreSelection = () => {
          setStoreBranchSelections((prev) => [...prev, { id: null, branch_ids: [] }]);
        };
        const deleteStoreSelection = (index) => {
          setStoreBranchSelections((prev) => prev.filter((_, i) => i !== index));
        };
      
     
  return (
    <div style={{marginTop:'3%',marginLeft:'1%',overflow:'scroll',height:'95vh',paddingBottom:50}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>:
<>
    {showAlerts()}
         {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
            <label className="form-label mt-4">Incentive Type</label>
     <select value={type} onChange={(e)=>setType(e.target.value)} required name="code" className="form-control"  >

<option value={1}>Guarante</option>
<option value={2}>Multiplication</option>


</select>
        <label className="form-label mt-4">Name</label>
<input  className="form-control" type="text" id="name" value={name} onChange={e => setName(e.target.value)} />

<label className="form-label mt-4">Description</label>
<input  className="form-control" type="text" id="namex" value={description} onChange={e => setDes(e.target.value)} />


<label className="form-label mt-4">From (Date)</label>
<input  className="form-control"  type="datetime-local" id="from" value={from} onChange={e => setFrom(e.target.value)} />

<label className="form-label mt-4" htmlFor="to">To (Date)</label>
<input  className="form-control"  type="datetime-local" id="to" value={to}   min={from} onChange={e => setTo(e.target.value)} />

{type==1?
<>

  <label className="form-label mt-4">Profit</label>
<input  className="form-control" type="number" min={1} id="namex" value={profit} onChange={e => setProfit(e.target.value)} />
</>
:
<>
<label className="form-label mt-4">MultiPlication Rate</label>
<input  className="form-control" type="number" min={1} id="namex" value={MultiPlicationrate} onChange={e => setMultiRate(e.target.value)} />
</>
}


<label className="form-label mt-4">Minimum Number of hours</label>
<input  className="form-control" type="number" min={0} id="namex" value={hoursnb} onChange={e => setHoursnb(e.target.value)} />
<label className="form-label mt-4">Minimum Number of Order</label>
<input  className="form-control" type="number" min={0} id="namex" value={ordernumber} onChange={e => setOrderNumber(e.target.value)} />

<label className="form-label mt-4">Accept rate %</label>
<input  className="form-control" type="number" min={0} id="namex" value={acceptrate} onChange={e => setAcceptRate(e.target.value)} />





{/* 

<label className="form-label mt-2">Stores</label>
      <Select
        isMulti
        options={storeOptions}
        onChange={handleStoreChange}
        className="form-control"
        placeholder="Select Stores"
      />

      <label className="form-label mt-2">Branches</label>
      <Select
        isMulti
        options={branchOptions}
        onChange={handleBranchChange}
        className="form-control"
        placeholder="Select Branches"
       // isDisabled={storeid.length === 0} // Disable if no stores are selected
      /> */}




 {/* <label  className="form-label mt-2">Stores</label>
<div  >
<select value={storeid} onChange={(e)=>{if(e.target.value==''){
setStoreid('')
setStores([])
setBranchid('')
setBranches([])
}else{
  setStoreid(e.target.value),getBranches(e.target.value)
}}} required name="code" className="form-control"  >
<option value={''}>None</option>
{stores.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}

</select>

</div>
<label  className="form-label mt-2">Branches</label>
<div  >
<select value={branchid} onChange={(e)=>{setBranchid(e.target.value)}} required name="code" className="form-control"  >
{branches.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}
</select>

</div> */}

{/* <label className="form-label mt-2">Stores</label>
      <div>
        <select
          multiple
          onChange={(e) => handleStoreChange1(e.target.value)}
          className="form-control"
        >
          {stores.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {selectedStores.map((store) => (
        <div key={store.id}>
          <label onClick={()=>{console.log(store)}} className="form-label mt-2">Branches for {store.name}</label>
          <div>
            <select
              multiple
              onChange={(e) =>
                handleBranchChange1(
                  store.id,
                  Array.from(e.target.selectedOptions, (option) => option.value)
                )
              }
              className="form-control"
            >
              {(branches[store.id] || []).map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      ))} */}



{/* // testing version */}
{/* <div style={{display:'flex',marginBottom:10,flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
          <label className="form-label mt-2"></label>
          <button onClick={addNewStoreSelection} className="btn btn-primary mt-3">Add New Store</button>

          </div> */}
 <p style={{marginTop:30,fontSize:18,fontWeight:'500',color:'black',marginBottom:-50}} >Select Stores</p>

{storeBranchSelections.map((selection, index) => (
        <div  key={index} className="store-branch-box">
          <div style={{display:'flex',marginBottom:5,marginTop:10,width:'101%',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
          <label className="form-label mt-2"></label>
 
          {index==0?          <button onClick={addNewStoreSelection} style={{marginRight:'3%'}} className="btn btn-primary mt-3">Add New Store</button>
:
          <svg style={{marginRight:'3%',width:25,height:25,cursor:'pointer'}} onClick={() => deleteStoreSelection(index)}fill="black" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
     }

          </div>
<div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'99%',marginRight:'1%'}}>


          <div style={{width:'48%'}}>
            <select
              value={selection.id || ''}
              onChange={(e) => handleStoreChange2(index, e.target.value)}
              className="form-control"
            >
              <option value={''}>None</option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{width:'48%'}}>
          {selection.id && (
            <div>


              {/* <label style={{marginTop:-10}} className="form-label mt-2">Branches for {stores.find((s) => s.id === Number(selection.id))?.name}</label> */}
              <div>
                <Select
                  isMulti
                  options={(branches[Number(selection.id)] || []).map(branch => ({
                    value: branch.id,
                    label: branch.name
                  }))}
                  // value={(branches[Number(selection.id)||[]]).filter(branch => selection.branch_ids.includes(Number(branch.id))).map(branch => ({
                  //   value: branch.id,
                  //   label: branch.name
                  // }))}
                  onChange={(selectedOptions) => handleBranchChange2(index, selectedOptions)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>
          )}
          </div>
</div>
        </div>
        
      ))}
        









{/* //working version but need enhacemnts


<label className="form-label mt-2">Stores</label>
      <div>
        {stores.map((item) => (
          <div style={{marginLeft:10,marginTop:5,height:35}} key={item.id}>
            <input
              type="checkbox"
              id={`store-${item.id}`}
              onChange={() => handleStoreChange1(item.id)}
              checked={selectedStores.some((store) => store.id === item.id)}
            />
            <label  style={{marginLeft:10}} htmlFor={`store-${item.id}`}>{item.name}</label>
          </div>
        ))}
      </div>

      {selectedStores.map((store) => (
        <div key={store.id}>
          <label style={{color:'black',fontSize:19,fontWeight:'500'}} className="form-label mt-2">Branches for {stores.find((s) => s.id === store.id)?.name}</label>
          <div>
            {(branches[store.id] || []).map((branch) => (
              <div style={{marginLeft:10,marginTop:5,height:35}} key={branch.id}>
                <input
                  type="checkbox"
                  id={`branch-${branch.id}`}
                  onChange={() => handleBranchChange1(store.id, branch.id)}
                  checked={store.branch_ids.includes(branch.id)}
                />
                <label style={{marginLeft:10}} htmlFor={`branch-${branch.id}`}>{branch.name}</label>
              </div>
            ))}
          </div>
        </div>
      ))} */}





<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} 
 onClick={handleSubmit}
//  onClick={()=>{
//   console.log(selectedStores)
//  }}
>Add</Button>
</>
}
    </div>
  )
}

