import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Button } from 'react-bootstrap';

export default function AddOrderFaq() {
    const navigate = useNavigate();
    const [dropdowndata, setDrop] = useState([]);
    const [existing, setExisting] = useState(0);
    const [questionid, setQuestionid] = useState();
    const [quest, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [hasNoAnswer, setHasNoAnswer] = useState(false);
    const [type, setType] = useState('1');
  
      const Addfaq = async () => {
        const {status, data, msg} = await sendData('/orderfaqs/add', JSON.stringify({
            type:existing==1?1:existing==2?2:null,
            question:quest,answer:hasNoAnswer?null:answer,questionid:existing==2?questionid:null}));
        if(status){
         navigate('/orders-faq')
        }
      }
      const validateFields = () => {
        if (!quest.trim()) {
          alert('Question cannot be empty.');
          return false;
        }
        if (!answer.trim()) {
            alert('Answer cannot be empty.');
            return false;
          }
        return true;
      };
      const GetDropDown = async ()=>{
        const {status, data, msg} = await fetchData(`/orderfaqs/dropdown`);
        if(status){
            setDrop(data)
            setQuestionid(data[0].id)
            // setData(data)
            //  setLoading(false)
         }
    }
    useEffect(()=>{
        GetDropDown()
    },[])
  return (
    <div style={{marginTop:'6%',marginLeft:'1%'}}>
      <div style={{display:'flex',alignItems:'center'}}>
        <Button disabled={existing==1?true:false} onClick={()=>{setExisting(1)}} style={{backgroundColor:'blue',borderColor:'blue'}}>
            Add New Question
        </Button>
        <Button disabled={existing==2?true:false} onClick={()=>{setExisting(2)}} style={{backgroundColor:'red',borderColor:'red',marginLeft:10}}>
            Add To An Existing Question
        </Button>
      </div>
      <div>
            {existing==1?
                    <>
                     <label className="form-label mt-4">Question</label>
      <input required type="text" className="form-control"  name="slogan" value={quest} 
      onChange={(e) => {setQuestion(e.target.value)}}
      />
        <div style={{display:'flex',alignItems:'center',marginTop:15}}>
        <input style={{marginRight:10}} required type="checkbox"   name="slogan" checked={hasNoAnswer} value={hasNoAnswer} 
        onChange={(e) => {setHasNoAnswer(!hasNoAnswer)}}
      />
      <p style={{marginLeft:10}} className='m-0'>This question has no answer, it has follow-up questions</p> 
        </div>
        <label className="form-label mt-4">Answer</label>
      <input disabled={hasNoAnswer?true:false}  required type="text" className="form-control"  name="slogan" value={answer} 
      onChange={(e) => {setAnswer(e.target.value)}}
      />  
      <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={Addfaq}>Add</Button>

                    </>
                    :
                    existing==2?
                    <>
                            <label style={{marginLeft:10}} className="form-label mt-4">Questions <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={questionid} onChange={(e)=>setQuestionid(e.target.value)} required name="code" className="form-control"  >
{dropdowndata.map((item,i)=>(
      <option value={item.id}>{item.title}</option>
))}

</select>

</div>
                    <label className="form-label mt-4">Question</label>
     <input required type="text" className="form-control"  name="slogan" value={quest} 
     onChange={(e) => {setQuestion(e.target.value)}}
     />
       <div style={{display:'flex',alignItems:'center',marginTop:15}}>
       <input style={{marginRight:10}} required type="checkbox"   name="slogan" checked={hasNoAnswer} value={hasNoAnswer} 
       onChange={(e) => {setHasNoAnswer(!hasNoAnswer)}}
     />
     <p style={{marginLeft:10}} className='m-0'>This question has no answer, it has follow-up questions</p> 
       </div>
       <label className="form-label mt-4">Answer</label>
     <input disabled={hasNoAnswer?true:false}  required type="text" className="form-control"  name="slogan" value={answer} 
     onChange={(e) => {setAnswer(e.target.value)}}
     />  
     <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={Addfaq}>Add</Button>

                   </>
                    :null
            }
      </div>
    </div>
  )
}
