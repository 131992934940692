import React,{useState,useEffect, useRef} from 'react'
import { fetchData, sendData } from '../utils';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';

export default function User(props) {

    const navigate =  useNavigate()
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [storeid, setStoreid] = useState();
    const [show1, setShow1] = useState(false);
    const [show, setShow] = useState(false);

    const divRef = useRef(null); // Step 1: Use a Ref
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span>User Id</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('name', {
            id: 'name',
            cell: info => <i 
             onClick={()=>(navigate(`/user/detail/${info.row.original.id}`))}
             style={{cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Name</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('profile.lastname2', {
            id: 'profile.lastname2',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Last Name</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('profile.phone', {
            id: 'profile.phone',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Phone Number</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('email', {
            id: 'email',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Email</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.ip', {
            id: 'user_logins.ip',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Last Ip Address</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.os', {
            id: 'user_logins.os',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>Last Operating System</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.brand', {
            id: 'user_logins.brand',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Last Brand</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.country', {
            id: 'user_logins.country',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Country</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.region', {
            id: 'user_logins.region',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Region</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.city', {
            id: 'user_logins.city',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>City</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.isp', {
            id: 'user_logins.isp',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>Internet Service Provider</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('user_logins.last_login_date', {
            id: 'user_logins.last_login_date',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>Last Login Date</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('orders_count', {
            id: 'orders_count',
            cell: info => <p  style={{minWidth:150,textAlign:'center',cursor:'pointer'}}>{info.getValue()}</p>,
             header: () =><p className='m-0' style={{minWidth:150,textAlign:'center',cursor:'pointer'}}>Orders count</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('addresses_count', {
            id: 'addresses_count',
            header: () =><p className='m-0' style={{minWidth:150,textAlign:'center',cursor:'pointer'}}>Addresses count</p>,
            cell: info => <p  style={{textAlign:'center',minWidth:150,cursor:'pointer'}}>{info.getValue()}</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('is_apple', {
            id: 'is_apple',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()!=null?'Yes':'No'}</i>,
             header: () =><span>Is Apple</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('google_id', {
            id: 'google_id',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()!=null?'Yes':'No'}</i>,
             header: () =><span>Is Google</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('is_facebook', {
            id: 'is_facebook',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()!=null?'Yes':'No'}</i>,
             header: () =><span>Is Facebook</span>,
             footer: info => info.column.id,
           }),
         
           columnHelper.accessor('created_at', {
            id: 'created_at',
            header: () =><p className='m-0' style={{minWidth:150,textAlign:'center',cursor:'pointer'}}>Created At</p>,
            cell: info => <p  style={{textAlign:'center',minWidth:150,cursor:'pointer'}}>{String(info.getValue()).substring(0,10)} {String(info.getValue()).substring(12,16)}</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('updated_at', {
            id: 'updated_at',
            header: () =><p className='m-0' style={{minWidth:150,textAlign:'center',cursor:'pointer'}}>Last Update At</p>,
            cell: info => <p  style={{textAlign:'center',minWidth:150,cursor:'pointer'}}>{String(info.getValue()).substring(0,10)} {String(info.getValue()).substring(12,16)}</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('blocked', {
            id: 'blocked',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()==1?'Yes':'No'}</i>,
             header: () =><span>Is Blocked</span>,
             footer: info => info.column.id,
           }),
            columnHelper.accessor('actions', {
                id: 'actions',
                header: 'Actions',
                cell: info => (
                    <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
                    {/* <button className="edit-button" onClick={() => alert(`Editing ${info.row.original.firstName}`)}>Edit</button>
                    <button className="delete-button" 
                    // onClick={() =>{
                    //         setShow1(true)
                    //         setStoreid(info.row.original.id)
                    // }}
                    >Reject</button> */}
                    </div>
                ),
            }),
    ]
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);
    const [showalert, setShowalert] = useState(false);
const [msg, setMsg] = useState('');
const [page, setPage] = useState(1);
const [limitt, setLimitt] = useState(35);
const [totalPages, setTotalPages] = useState(1);
const [count, setCount] = useState(0);
const [limit, setLimit] = useState(0);
const [type, setType] = useState(0);
const [data, setUsers] = useState([]);
const [search, setSearch] = useState('');
const [debouncedValue, setDebouncedValue] = useState(search);
const [search1, setSearch1] = useState('');
const [search2, setSearch2] = useState('');
const [debouncedValue1, setDebouncedValue1] = useState(search1);
const [debouncedValue2, setDebouncedValue2] = useState(search2);

useEffect(() => {

    setPage(1)
    const handler = setTimeout(() => {
      setDebouncedValue(search);
    }, 500); // 500 ms delay
  


  return () => clearTimeout(handler);
}, [search]);
useEffect(() => {

    setPage(1)
    const handler = setTimeout(() => {
      setDebouncedValue1(search1);
    }, 500); // 500 ms delay
  

  return () => clearTimeout(handler);
}, [search1]);
useEffect(() => {

  setPage(1)
  const handler = setTimeout(() => {
    setDebouncedValue2(search2);
  }, 500); // 500 ms delay


return () => clearTimeout(handler);
}, [search2]);
useEffect(()=>{
  if(search1){
    fetchUsers();
  }

},[page,debouncedValue1]);
useEffect(()=>{
  if(search){
    fetchUsers();
  }

},[page,debouncedValue]);
useEffect(() => {
  setPage(1)
  const handler = setTimeout(() => {
    setDebouncedValue(search);
  }, 500); // 500 ms delay

  return () => clearTimeout(handler);
}, [search]);

useEffect(()=>{
  fetchUsers();
},[page,debouncedValue]);


const table = useReactTable({
    data,
    columns: columns.filter(column => visibleColumns.includes(column.id)),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    //onPaginationChange: setPagination, 
    enableRowSelection:true, //hoist up the row selection state to your own scope
    state: {
      rowSelection, //pass the row selection state back to the table instance
    //   //pagination
    },
  });
    useEffect(()=>{
        fetchUsers();
    },[page,limitt,type,debouncedValue,debouncedValue1,debouncedValue2]);
    const fetchUsers = async () => {
        setLoading(true)
        const {status, data, msg} = await sendData('/users', JSON.stringify({type:type,limit:limitt,page:page,search_name:search,search_id:search1,search_last_name:search2}));
        if(status){
            let totalPagesx = Math.ceil(data.count / data.limit);
            if(totalPagesx == 0) totalPagesx = 1;
         
            data.data.forEach(user => {
              if (Array.isArray(user.user_logins)) {
                  user.user_logins = user.user_logins.length > 0 ? user.user_logins[0] : {};
              }
          })
           console.log(data.data)
   setUsers(data.data)
            setTotalPages(totalPagesx);
            setCount(data.count);
            setLoading(false);
        }else{
                setShowalert(true)
                setMsg(msg)
                setLoading(false);
        }
      }
     
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
{loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
<div style={{display:'flex',alignItems:'center',marginLeft:5}}>
<div style={{cursor:'pointer',marginRight:'1.5%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={search}
          onChange={(e) => {

  setSearch(e.target.value)

          }}
          type="text"
          id="search-product"
          placeholder="Search By User Name"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {search!=''?   <svg onClick={()=>{setSearch('');setPage(1),fetchUsers()}} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
         <div style={{cursor:'pointer',marginRight:'1.5%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={search2}
          onChange={(e) => {

  setSearch2(e.target.value)

          }}
          type="text"
          id="search-product"
          placeholder="Search By Last Name"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {search2!=''?   <svg onClick={()=>{setSearch2('');setPage(1),fetchUsers()}} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
         <div style={{cursor:'pointer',marginRight:'1.5%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={search1}
          onChange={(e) => {

  setSearch1(e.target.value)

          }}
          type="text"
          id="search-product"
          placeholder="Search By User ID"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {search1!=''?   <svg onClick={()=>{setSearch1('');setPage(1),fetchUsers()}} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}

         </div>
         <a  
        href={`https://api.trakitrak.com/control/excel/exportUsersToExcel`} download 
        data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-danger buttonx me-2 exportbuttons" style={{ fontSize: '13px',marginLeft:props.isopen?'27%':'38%' }}>
            Export
          </a>
  </div>
  {/* getUsers I added new input  search_last_name
getShoppers new input last_name */}
<div ref={divRef}    style={{display:'flex',position:'relative',justifyContent:'space-between',alignItems:'center',marginLeft:10}}>
    <p className='m-0' style={{marginLeft:5}}>{count} {type==0?'':type==1?'Blocked':''} User</p>
<button onClick={()=>setShow(!show)} style={{marginRight:20}} id="ember5628" class="btn o__secondary o__in-right-list js__displayed-attributes-toggle ember-view" role="button" type="button">  
  <svg class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"></path><path d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"></path><path d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"></path></svg>
  <svg class="interface-icon o__standard o__standard__small-down-arrow" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.79491 10.1807C7.18432 10.6065 7.81568 10.6064 8.20509 10.1807L11.2942 6.80291C11.7339 6.32211 11.4225 5.5 10.8006 5.5L4.1994 5.5C3.57755 5.5 3.26612 6.32211 3.70584 6.80291L6.79491 10.1807Z"></path></svg>
</button>
{show&&<div className='dropdownn'>
{columns.map(column => (
    <div style={{marginLeft:10}} key={column.id}>
      <label style={{display:'flex',alignItems:'center'}}>

        <input
        style={{marginRight:5}}
          type="checkbox"
          checked={visibleColumns.includes(column.id)}
          onChange={e => {
            const isChecked = e.target.checked;
            setVisibleColumns(current =>
              isChecked
                ? [...current, column.id]
                : current.filter(v => v !== column.id)
            );
          }}
        />
        {column.id=='Customer_Email'?<svg style={{marginLeft:7,margin:0}} fill="#000000" width="16px" height="16px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z" fill-rule="evenodd"/>
            </svg>:
        column.id=='Rejected_Quantity'?
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M729 2532 c-69 -36 -106 -88 -119 -167 -5 -33 -10 -240 -10 -461 l0
-401 113 -6 c132 -7 226 -31 336 -87 84 -42 217 -143 245 -185 l16 -25 423 0
c412 0 424 -1 445 -20 14 -14 22 -33 22 -55 0 -22 -8 -41 -22 -55 -21 -19 -33
-20 -391 -20 -348 0 -369 -1 -362 -18 28 -66 56 -157 66 -214 l12 -68 327 0
c314 0 327 -1 348 -20 14 -14 22 -33 22 -55 0 -22 -8 -41 -22 -55 -21 -19 -34
-20 -348 -20 l-326 0 -18 -89 c-38 -188 -142 -360 -291 -479 l-40 -32 611 0
610 0 55 28 c69 35 106 87 119 167 6 36 10 394 10 888 l0 827 -303 0 c-291 0
-304 1 -325 20 -22 21 -22 25 -22 325 l0 305 -564 0 -564 0 -53 -28z m1449
-902 c30 -28 30 -82 0 -110 -21 -20 -32 -20 -598 -20 -566 0 -577 0 -598 20
-14 14 -22 33 -22 55 0 22 8 41 22 55 21 20 32 20 598 20 566 0 577 0 598 -20z"/>
<path d="M2050 2285 l0 -225 232 0 233 0 -225 225 c-124 124 -228 225 -232
225 -5 0 -8 -101 -8 -225z"/>
<path d="M545 1335 c-206 -45 -375 -176 -464 -357 -54 -111 -71 -182 -71 -303
0 -122 16 -192 72 -305 32 -67 58 -102 122 -166 142 -141 280 -199 476 -199
196 0 334 58 476 199 133 133 194 281 194 471 0 122 -16 192 -72 305 -57 119
-177 238 -298 297 -138 68 -296 89 -435 58z m41 -457 l94 -92 94 92 c83 82 97
92 129 92 44 0 77 -31 77 -72 0 -23 -20 -49 -94 -126 l-94 -97 94 -97 c74 -77
94 -103 94 -125 0 -42 -33 -73 -77 -73 -32 0 -46 10 -129 92 l-94 92 -94 -92
c-83 -82 -97 -92 -129 -92 -44 0 -77 31 -77 73 0 22 20 48 94 125 l94 97 -94
97 c-74 77 -94 103 -94 126 0 41 33 72 77 72 32 0 46 -10 129 -92z"/>
</g>
       </svg>:
        column.id=='Replacement_Quantity'?
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="16px" height="16px"viewBox="0 0 256.000000 256.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M1410 2542 c-52 -25 -70 -70 -70 -172 l0 -86 -93 -12 c-324 -41 -640
       -224 -835 -485 -125 -167 -195 -323 -234 -516 -24 -124 -19 -152 32 -169 40
       -14 75 8 146 92 168 198 409 323 684 357 88 10 249 6 285 -7 12 -5 15 -25 15
       -92 0 -110 23 -163 79 -186 76 -30 90 -20 364 252 140 139 258 265 269 287 25
       47 29 131 9 190 -11 31 -68 95 -245 274 -127 128 -251 247 -275 262 -52 33
       -82 36 -131 11z"/>
       <path d="M2298 1456 c-15 -6 -57 -47 -95 -91 -90 -105 -193 -183 -328 -250
       -120 -59 -221 -89 -360 -106 -83 -10 -245 -6 -280 7 -12 5 -15 25 -15 93 0 62
       -5 95 -18 120 -38 75 -108 93 -184 48 -58 -34 -490 -472 -511 -519 -28 -59
       -32 -119 -12 -179 16 -49 40 -77 259 -297 132 -134 258 -252 278 -263 45 -23
       67 -24 114 -4 50 21 73 77 74 180 l0 81 93 12 c408 52 777 316 962 690 48 97
       98 251 116 356 10 62 10 70 -8 93 -25 34 -53 43 -85 29z"/>
       </g>
       </svg>:
        column.id=='Picked_Quantity'?<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M257 2370 c-38 -30 -52 -80 -33 -123 20 -49 59 -67 148 -67 l73 0
       147 -737 c165 -825 155 -792 253 -842 l49 -26 546 -3 c300 -2 565 0 589 3 96
       14 134 108 72 177 l-29 33 -565 3 -565 2 -11 51 c-6 27 -11 59 -11 69 0 20 12
       20 573 20 532 0 575 2 612 19 57 26 111 90 129 153 31 107 176 779 176 813 0
       46 -21 87 -57 111 -27 18 -62 19 -845 22 -764 2 -817 4 -822 20 -2 9 -12 55
       -21 102 -20 97 -32 124 -72 161 -53 50 -84 59 -201 59 -94 0 -113 -3 -135 -20z
       m1395 -767 c117 -117 128 -130 128 -163 0 -39 -18 -63 -57 -74 -28 -9 -57 6
       -110 57 l-43 41 0 -134 c0 -124 -2 -135 -22 -157 -12 -13 -33 -23 -49 -23 -74
       0 -89 32 -89 186 l0 117 -45 -42 c-53 -49 -93 -54 -130 -16 -48 47 -38 68 103
       208 104 102 134 127 157 127 23 0 53 -25 157 -127z"/>
       <path d="M940 525 c-69 -32 -110 -94 -110 -169 0 -110 75 -186 185 -186 183 0
       252 234 99 339 -42 29 -127 37 -174 16z"/>
       <path d="M1740 525 c-69 -32 -110 -94 -110 -169 0 -110 75 -186 185 -186 183
       0 252 234 99 339 -42 29 -127 37 -174 16z"/>
       </g>
       </svg>:
       column.id=='Total_Weight'?
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
       preserveAspectRatio="xMidYMid meet">
      
      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M1191 2549 c-158 -31 -309 -170 -352 -324 -20 -71 -17 -198 5 -265
      19 -55 75 -152 95 -164 34 -21 -7 -26 -234 -26 l-244 0 -35 -31 c-29 -25 -37
      -42 -50 -102 -37 -169 -266 -1476 -266 -1515 0 -34 7 -49 34 -80 l34 -37 1102
      0 1102 0 34 37 c27 31 34 46 34 80 0 39 -229 1346 -266 1515 -13 60 -21 77
      -50 102 l-35 31 -244 0 c-227 0 -268 5 -234 26 20 12 76 109 95 164 22 67 25
      194 5 265 -62 222 -302 369 -530 324z m177 -268 c72 -37 112 -102 112 -181 0
      -113 -87 -200 -200 -200 -214 0 -277 282 -85 383 42 23 127 21 173 -2z m308
      -1146 c54 -17 81 -36 108 -77 15 -23 16 -31 4 -55 -19 -40 -69 -44 -100 -8
      -32 38 -70 55 -118 55 -85 0 -140 -47 -160 -135 -42 -186 97 -320 250 -242 40
      19 45 26 48 60 l3 37 -50 0 c-38 0 -56 5 -71 20 -24 24 -24 29 -4 58 14 20 23
      22 118 22 66 0 106 -4 114 -12 8 -8 12 -48 12 -113 0 -112 -2 -116 -69 -150
      -184 -93 -390 -38 -452 123 -31 81 -31 198 0 268 59 134 212 196 367 149z
      m-822 -11 c13 -12 16 -39 16 -125 0 -60 3 -109 8 -108 4 0 58 57 121 125 110
      121 114 124 155 124 43 0 66 -13 66 -38 0 -8 -38 -53 -84 -101 l-85 -86 100
      -153 c54 -84 99 -159 99 -166 0 -25 -28 -37 -76 -34 l-48 3 -71 123 c-40 68
      -76 127 -82 133 -6 6 -26 -7 -56 -40 -47 -50 -47 -51 -47 -120 0 -50 -4 -74
      -16 -85 -20 -21 -53 -20 -76 1 -17 15 -18 37 -18 271 0 224 2 258 17 274 20
      22 56 23 77 2z"/>
      </g>
      </svg>:
      column.id=='Bag_Used'?
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="16px" height="16px" viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet">
     
     <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
     fill="#000000" stroke="none">
     <path d="M1154 2545 c-107 -23 -191 -71 -279 -160 -123 -122 -174 -248 -175
     -427 l0 -78 -184 0 c-102 0 -187 -4 -190 -9 -9 -13 -147 -1851 -141 -1862 8
     -12 2182 -12 2190 0 6 11 -133 1849 -141 1862 -3 5 -88 9 -190 9 l-184 0 0 78
     c0 101 -21 193 -61 278 -46 96 -168 219 -262 263 -121 58 -256 74 -383 46z
     m276 -174 c165 -64 257 -199 268 -393 l5 -98 -422 0 -421 0 0 73 c0 213 118
     378 310 432 72 20 188 14 260 -14z m-730 -776 c0 -99 2 -107 25 -130 32 -33
     78 -33 110 0 23 23 25 31 25 130 0 58 3 105 7 105 3 0 22 -12 41 -26 103 -80
     90 -254 -25 -325 -57 -36 -154 -34 -210 3 -111 73 -124 237 -25 318 20 16 40
     30 44 30 5 0 8 -47 8 -105z m1000 0 c0 -99 2 -107 25 -130 32 -33 78 -33 110
     0 23 23 25 31 25 130 0 58 3 105 7 105 3 0 22 -12 41 -26 103 -80 90 -254 -25
     -325 -57 -36 -154 -34 -210 3 -111 73 -124 237 -25 318 20 16 40 30 44 30 5 0
     8 -47 8 -105z"/>
     </g>
     </svg>:
     column.id=='Ordered_Quantity'?
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="26px" height="26px">
<g><path  fill="#000000" d="M 34.5,-0.5 C 79.8333,-0.5 125.167,-0.5 170.5,-0.5C 175.234,1.73934 178.401,5.40601 180,10.5C 180.5,33.1642 180.667,55.8309 180.5,78.5C 175.821,78.3342 171.155,78.5008 166.5,79C 164.667,79.5 163.5,80.6667 163,82.5C 162.5,107.498 162.333,132.498 162.5,157.5C 156.158,157.666 149.825,157.5 143.5,157C 147.062,153.942 150.062,150.442 152.5,146.5C 151.788,144.954 150.622,143.954 149,143.5C 143.878,147.62 139.044,152.12 134.5,157C 128.858,158.276 123.692,157.442 119,154.5C 117.739,154.759 116.739,155.426 116,156.5C 115.548,182.699 115.381,209.032 115.5,235.5C 79.8333,235.5 44.1667,235.5 8.5,235.5C 7.01307,234.196 5.3464,233.03 3.5,232C 2.30342,229.985 0.970083,228.151 -0.5,226.5C -0.5,162.833 -0.5,99.1667 -0.5,35.5C 28.7722,40.5615 40.4389,28.5615 34.5,-0.5 Z M 147.5,64.5 C 151,64.4954 152.5,66.1621 152,69.5C 144.833,76.6667 137.667,83.8333 130.5,91C 129.167,91.6667 127.833,91.6667 126.5,91C 123,87.5 119.5,84 116,80.5C 115.277,77.8751 116.277,76.2084 119,75.5C 122.118,77.6149 124.952,80.1149 127.5,83C 134.829,77.8486 141.496,71.6819 147.5,64.5 Z M 29.5,74.5 C 48.8362,74.3334 68.1695,74.5 87.5,75C 90.8251,76.6144 91.1585,78.6144 88.5,81C 68.8333,81.6667 49.1667,81.6667 29.5,81C 26.9571,78.8483 26.9571,76.6816 29.5,74.5 Z M 148.5,103.5 C 151.114,104.264 152.281,105.93 152,108.5C 144.319,116.348 136.486,124.015 128.5,131.5C 124.182,127.682 120.015,123.682 116,119.5C 115.259,116.888 116.259,115.222 119,114.5C 122.313,116.979 125.48,119.645 128.5,122.5C 135.214,116.117 141.88,109.784 148.5,103.5 Z M 28.5,114.5 C 48.5028,114.333 68.5028,114.5 88.5,115C 90.7811,116.851 90.7811,118.518 88.5,120C 68.5,120.667 48.5,120.667 28.5,120C 27.2475,118.172 27.2475,116.339 28.5,114.5 Z M 29.5,153.5 C 48.8362,153.333 68.1695,153.5 87.5,154C 91.5,156 91.5,158 87.5,160C 68.1667,160.667 48.8333,160.667 29.5,160C 26.9571,157.848 26.9571,155.682 29.5,153.5 Z"/></g>
<g><path  fill="#000000" d="M 26.5,4.5 C 33.1673,24.8414 25.834,32.508 4.5,27.5C 11.8548,19.8128 19.1881,12.1461 26.5,4.5 Z"/></g>
<g><path  fill="#000000" d="M 255.5,85.5 C 255.5,109.5 255.5,133.5 255.5,157.5C 226.833,157.5 198.167,157.5 169.5,157.5C 169.5,133.5 169.5,109.5 169.5,85.5C 179.5,85.5 189.5,85.5 199.5,85.5C 199.334,92.8409 199.5,100.174 200,107.5C 200.739,108.574 201.739,109.241 203,109.5C 209.333,108.167 215.667,108.167 222,109.5C 223.261,109.241 224.261,108.574 225,107.5C 225.5,100.174 225.666,92.8409 225.5,85.5C 235.5,85.5 245.5,85.5 255.5,85.5 Z"/></g>
<g><path  fill="#000000" d="M 206.5,85.5 C 210.833,85.5 215.167,85.5 219.5,85.5C 219.827,90.544 219.494,95.544 218.5,100.5C 217.707,100.931 216.873,101.265 216,101.5C 212.971,101.184 209.971,100.85 207,100.5C 206.501,95.5111 206.334,90.5111 206.5,85.5 Z"/></g>
<g><path  fill="#000000" d="M 209.5,235.5 C 180.5,235.5 151.5,235.5 122.5,235.5C 122.5,211.833 122.5,188.167 122.5,164.5C 132.5,164.5 142.5,164.5 152.5,164.5C 152.334,171.508 152.5,178.508 153,185.5C 154.688,189.026 156.855,189.359 159.5,186.5C 160.5,187.167 161.5,187.833 162.5,188.5C 164.611,185.956 166.778,185.956 169,188.5C 170.085,187.707 171.252,187.04 172.5,186.5C 174.371,187.767 176.204,187.767 178,186.5C 179.429,179.281 179.929,171.947 179.5,164.5C 189.5,164.5 199.5,164.5 209.5,164.5C 209.5,188.167 209.5,211.833 209.5,235.5 Z"/></g>
<g><path  fill="#000000" d="M 159.5,164.5 C 163.833,164.5 168.167,164.5 172.5,164.5C 172.827,169.544 172.494,174.544 171.5,179.5C 170.833,179.833 170.167,180.167 169.5,180.5C 166.455,179.802 163.455,179.468 160.5,179.5C 159.506,174.544 159.173,169.544 159.5,164.5 Z"/></g>
<g><path  fill="#000000" d="M 216.5,164.5 C 217.5,164.5 218.5,164.5 219.5,164.5C 219.827,169.544 219.494,174.544 218.5,179.5C 217.914,179.709 217.414,180.043 217,180.5C 216.501,175.177 216.334,169.844 216.5,164.5 Z"/></g>
<g><path fill="#000000" d="M 255.5,164.5 C 255.5,188.167 255.5,211.833 255.5,235.5C 242.167,235.5 228.833,235.5 215.5,235.5C 215.333,219.83 215.5,204.163 216,188.5C 217.085,187.707 218.252,187.04 219.5,186.5C 221.371,187.767 223.204,187.767 225,186.5C 225.5,179.174 225.666,171.841 225.5,164.5C 235.5,164.5 245.5,164.5 255.5,164.5 Z"/></g>
</svg>
  
            
             : <svg style={{margin:0}} class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"></path><path d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"></path><path d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"></path></svg>
            }

      <p style={{marginLeft:7,marginTop:0,marginBottom:0}}>{column.id}</p>
      </label>
    </div>
  ))}
    </div>}

</div>
<label style={{marginLeft:10}} className="form-label mt-4">Status <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select required name="code" className="form-control" value={type} onChange={(e)=>{setType(Number(e.target.value))}} >
  <option value="0">All</option>
  <option value="1">Blocked</option>
</select>
</div>

<div className="table-container">
    <table className="table"> 
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td>
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
<div className="pagination">
        <button onClick={() => {setPage(page-1)}}  disabled={page === 1}>Previous</button>
        <button onClick={() => {setPage(page+1)}} disabled={page  === Math.ceil(count/limitt)}>Next</button>
        <span>
          Page
          <strong>
          {' '+page} of {Math.ceil(count/limitt)}
          </strong>
        </span>
        <select value={limitt} onChange={(e) => {
            setPage(1)
            setLimitt(Number(e.target.value))
            }}>
          {[10, 20, 30, 40, 50, 100, 150, 200].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

</div>
}
        
    </div>
  )
}
