import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import { FaClock, FaShoppingCart, FaStarHalfAlt,FaRegStar, FaTruck,FaStar } from 'react-icons/fa';
import { SlArrowRight } from "react-icons/sl";
import { Button } from 'react-bootstrap';

export default function DepositDetailss() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [deposits, setDeposits] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchDepositDetails= async ()=>{
        const {status, data, msg} = await fetchData(`/shoppers/deposits/details/${id}`);
              if(status){
                setDeposits(data)
                 setLoading(false)
              }else{
           
              }
        }
    useEffect(()=>{
      fetchDepositDetails();
  },[]);
  const renderRatingStars = (x) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div  key={i} >
            {x>=i?<FaStar color='orange' />: <FaRegStar />}
         
       </div>
      );
    }
    return stars;
  };

const decide = async (type,id,driverid) => {
    setLoading(true)
    const {status, data, msg} = await sendData('/shoppers/deposits/decide', 
    JSON.stringify({type:type,id:id,driverid:driverid}));
    if(status){
        setLoading(false)
      navigate('/shopper-depoists')
    }
  }
  return (
    <div style={{paddingTop:'5%',backgroundColor:'#f1f1f1',height:'100vh'}}>
    {loading?

    <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{backgroundColor:'#f1f1f1'}}>
<div style={{width:'95%',marginLeft:'1%',marginBottom:10,height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Deposit</p>
        <div style={{display:'flex',alignItems:'center'}}>
        <Button style={{marginTop:'1%',backgroundColor:'green',borderColor:'green',paddingLeft:40,paddingRight:40}} onClick={()=>{decide(1,deposits.id,deposits.driverid)}}>Accept</Button>
        <Button style={{marginTop:'1%',marginLeft:10,backgroundColor:'red',borderColor:'red',paddingLeft:40,paddingRight:40}} onClick={()=>{decide(2,deposits.id,deposits.driverid)}}>Reject</Button>

            </div>
   </div>
<div style={{width:'93%',marginLeft:'3%',height:100,backgroundColor:'white',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
<div style={{width:'25%'}}>
<p className='m-0' style={{color:'black',fontSize:18,fontWeight:'500',paddingLeft:10}}>${deposits.total}</p>
  </div>
<div style={{width:'50%',alignItems:'center',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <p className='m-0' style={{color:'black',fontWeight:'600'}}>Paid at {deposits.paid_at}</p>
        <p className='m-0' style={{color:'#a1a1a1',fontWeight:'400'}}>Payments of {deposits.orders.length} pickings and deliveries</p>
      </div>
      <div style={{width:'25%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <p className='m-0'>{deposits.number}</p>
        <a className="link-hover-effect" onClick={()=>{ window.open(deposits.img, '_blank');}}>
          View Receipt Image
        </a>
      </div>

</div>


    {deposits.orders.map((session,i)=>(
      <div onClick={()=>navigate(`/order-summary/${session.driverid}/${session.checkoutbranchid}`)} style={{width:'93%',marginLeft:'3%',marginTop:i==0?10:0,cursor:'pointer',height:100,display:'flex',padding:5,alignItems:'center',backgroundColor:'white',marginBottom:'2%'}}>
      <div style={{width:'8%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <div style={{width:70,height:70,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:5,backgroundColor:session.order.stor.color}}>
              <img src={session.order.stor.img} style={{width:60,height:60,objectFit:'contain'}}/>
      </div>
       </div>   
      <div style={{display:'flex',width:'100%',marginLeft:10,flexDirection:'column',justifyContent:'center'}}>
          <p className='m-0'>{session.order.stor.storename}</p>
           <div style={{display:'flex'}}>
           {renderRatingStars(session.order.rating?.rating)}
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
         
          <div style={{display:'flex',alignItems:'center'}}>
          <FaTruck style={{marginRight:10}}/>
          <p className='m-0'>{session.order.branch.name}</p>
          </div>
          </div>
  
      </div>
      <div style={{width:'25%',display:'flex',alignItems:'center',justifyContent:'space-evenly'}}>
      <p className='m-0'>Amount:  ${session.order.totalx}</p>
      <SlArrowRight />

      </div>
  </div>
    ))}
   
          </div>
        }
</div>
  )
}
