import React,{useState,useEffect} from 'react'
import { fetchData ,sendData} from '../utils';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Tippy from '@tippyjs/react/headless';
import Select, { components, createFilter } from "react-select"
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

export default function ProdItem(props) {
    const navigate = useNavigate();
    const awsUrl = process.env.REACT_APP_AWS_LINK;
    const [showw, setShoww] = useState(false);
    const [showww, setshowww] = useState(false);
    const [words, setWords] = useState([]);
    const [wordsorg, setWordsorg] = useState([]);
    const [currentInput, setCurrentInput] = useState('');
    // Functions to handle opening and closing the modal
    const handleClosee = () => setShoww(false);
    const handleShoww = () => setShoww(true);
//edit product states
const handleInputChange = (event) => {
  setCurrentInput(event.target.value);
};
const removeTagg = (index) => {
  setWords(words.filter((_, i) => i !== index));
};
// Function to add the current input to the array of words
const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    setWords([...words, currentInput]);
    setCurrentInput(''); // Clear the input field
  }
};  
const [size, setSize] = useState('');
const [weight, setWeight] = useState('');
const [hasBarcode, setHasBarcode] = useState(false);
const [otherImages, setOtherImages] = useState([]);
const [ImageSize, setImageSize] = useState('');
const [ImageName, setImageName] = useState('');
const [ImagesName, setImagesName] = useState([]);
const [ImagesSize, setImagesSize] = useState([]);
const [ErrorImg, setErrorImg] = useState('');
const [image, setImage] = useState(null);
const [barcode, setBarcode] = useState('');
const [shoppermsg, setShoppermsg] = useState('');
const [loader, setLoader] = useState(false);
const [featured, setFeatured] = useState('0');
const [unitmeasure, setUnitMeasure] = useState('unit');
const [unitmeasure2, setUnitMeasure2] = useState(null);
const [prixunit1, setPrixunit1] = useState('');
const [prixbulk1, setPrixbulk1] = useState('');
const [prixpack1, setPrixpack1] = useState('');
const [unit, setUnit] = useState('');
const [weightpack1, setWeightpack1] = useState('');
const [cats, setCats] = useState([]);
const [brands, setBrands] = useState([]);
const [selectedBrand, setSelectedBrand] = useState({value: null, label: 'Select Brand'});
const [selectedBrandorig, setSelectedBrandorig] = useState({value: null, label: 'Select Brand'});
const [newBrand, setNewBrand] = useState(false);
const [newBrandName, setNewBrandName] = useState('');
const [newBrandNameorig, setNewBrandNameorig] = useState('');
const [selectedCat, setSelectedCat] = useState({value: null, label: 'Category (required)'});
const [tags, setTags] = useState([]);
const [tagsorg, setTagsorig] = useState([]);





const [imgs, setimgs] = useState([]);
const [avArray, setAvArray] = useState([]);
const [extraimg, setExtraimg] = useState([]);
const [name, setName] = useState(props.product.name?props.product.name:null);
const [format, setFormat] = useState(props.product.formatt?props.product.formatt:null);
const [Brand, setBrand] = useState('');
const [sku, setSku] = useState('');
const [des, setDes] = useState('');
const [cat, setCat] = useState('');
const [price, setPrice] = useState('');
const [img, setImg] = useState('');
const [img2, setImg2] = useState('');
const [img3, setImg3] = useState('');
const [img4, setImg4] = useState('');
const [img5, setImg5] = useState('');
//end edit product states
 
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [sameav, setSameav] = useState(false);
    const [prodinfo, setProdInfo] = useState();
    const [branches, setBranches] = useState([]);


    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [product, setProduct] = useState(props.product);
    const setMLoading = props.setModalState;
    const setMData = props.setModalInfo
    const setMPrev = props.setModalImgPrev
    const hasid = props.hasid  
    const id = props.id
    
    const [brandschanges, setBrandschanged] = useState(false);
    const [tagschanged, setTagschanged] = useState(false);
    
    const [imgchanged, setImgchanged] = useState(false);
    const [img2changed, setImg2changed] = useState(false);
    const [img3changed, setImg3changed] = useState(false);
    const [img4changed, setImg4changed] = useState(false);
    const [img5changed, setImg5changed] = useState(false);

    const [img22changed, setImg22changed] = useState(false);
    const [img33changed, setImg33changed] = useState(false);
    const [img44changed, setImg44changed] = useState(false);
    const [img55changed, setImg55changed] = useState(false);

    // const [img2, setImg2] = useState(false);
    // const [img3, setImg3] = useState(false);
    // const [img4, setImg4] = useState(false);
    // const [img5, setImg5] = useState(false);

    

    const [modalLoading2, setModalLoading2] = useState(false);
    const [modalInput, setModalInput] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [modalImgPrev, setModalImgPrev] = useState(null);
    const [modalLoading, setModalLoading] = useState(true);

    const [expanded, setExpanded] = useState(false);
    const [variants, setVariants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [signleProd, setSingleProd] = useState(false);
    const [mainVariant, setMainVariant] = useState(false);
    const [variantloader, setVariantLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const handleClose = () => {setShow(false)
      setOtherImages([])
      setImgchanged(false)
      setImg2changed(false)
      setImg3changed(false)
      setImg4changed(false)
      setImg5changed(false)

    };
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleShow = () => setShow(true);
  


    const handleImageChange = (e) => {
      const file = e.target.files[0];
      const maxSizeMB = 1; // Maximum file size allowed in MB
  
      // Check if a file is selected
      if (file) {
          // Convert file size to MB
          const fileSizeMB = file.size / (1024 * 1024);
  
          // Check if the file size is less than 1 MB
          if (fileSizeMB < maxSizeMB) {
              // File size is within the limit, you can proceed
              if (ImageName==file.name) {
                // If the image name exists, set image to null
               //setImage(null);
               // setErrorImg('Image with this name already exists.');
                return;
            }
              setImgchanged(true)
              setImageName(file.name)
              setImageSize(fileSizeMB)
              setImage(file);
              setErrorImg('')
          } else {
              // File size exceeds the limit, show an error message or handle accordingly
              setErrorImg('Photo is larger than 1 MB. Please compress the image or upload a different file.')
              setImage(null);
  
          }
      }
  };
  const handleOtherImageChange = (e, otherimg,i) => {
    const file = e.target.files[0];
    const maxSizeMB = 1; // Maximum file size allowed in MB
  
    // Check if a file is selected
    if (file) {
        // Convert file size to MB
        const fileSizeMB = file.size / (1024 * 1024);
  
        // Check if the file size is less than 1 MB
        if (fileSizeMB < maxSizeMB) {
          if (ImagesName.includes(file.name)) {
            // If the image name exists, set image to null
           //setImage(null);
            setErrorImg('Image with this name already exists.');
            return;
        }

        //  let b =[...ImagesName]
        //  b.push(file.name)
        //  setImagesName(b)
        //  let c =[...ImagesSize]
        //  c.push(fileSizeMB)
        //  setImagesSize(c)
  
          setErrorImg('')
         // alert(i)
         if(i==0){
          setImg2changed(true)
          setImg2(file)
         }
         if(i==1){
          setImg3changed(true)
          setImg3(file)
         }
         if(i==2){
          setImg4changed(true)
          setImg4(file)
         }
         if(i==3){
          setImg5changed(true)
          setImg5(file)
         }
            // File size is within the limit, update otherImages state
            setOtherImages(otherImages.map((oi) => {
                if (oi.key === otherimg.key) {
                    return { ...oi, value: file };
                } else {
                    return oi;
                }
            
            }));
        } else {
          setErrorImg('Photo is larger than 1 MB. Please compress the image or upload a different file.')
  
            // File size exceeds the limit, show an error message or handle accordingly
        }
    }
  };
  const addTag = (checked, type) => {
    if(checked && !tags.includes(type)){
      setTags([...tags, type]);
    } else if(!checked) {
      setTags(tags.filter(i => i !== type));
    }
}
const getDropDowns = async () => {

  const {status, data, msg} = await fetchData(`/products/createdropdown`);

  if(status){ 
    console.log({data})
    if(data.brands.length > 0){
      // setSelectedBrand({label: data.brands[0].name, value: data.brands[0].id})
      setBrands(data.brands.map(i => ({label: i.name, value: i.id})));
    }
    if(data.categories.length > 0){
     // setSelectedCat({label: data.categories[0].subsubs[0].name, value: data.categories[0].subsubs[0].value})
      setCats(data.categories.map(cat => {
        const mainlabel = cat['prodcat']['name'] + ' > ' + cat['name'];
        return {
          label: mainlabel,
          options: cat.subsubs.map(subsub => ({label: subsub.name, value: subsub.id, mainlabel }))
        }
      }));

    }
    
  }
}
const filterConfig = {
  ignoreCase: true,
  ignoreAccents: false,
  trim:true,
  matchFrom: 'any',
  stringify: (option) =>{
    return `${option.label} ${option.data.mainlabel}`;
  }
};   
const groupStyles = {
// border: `2px dotted blue`,
color: '#8b8b93',
backgroundColor: 'white',
padding: '5px 0px 0px 8px',
display: 'flex',
};

const GroupHeading = (props) => (
<div style={groupStyles}>
<p style={{fontSize:17}}>{props.data.label} </p>
{/* <components.GroupHeading {...props} /> */}
</div>
);
    const fetchAvailability= async ()=>{
      const {status, data, msg} = await fetchData(`/productbranches/${product.hasvar ? product.hasvar.id : product.id}`);
    if(status){
      setBranches(data)
    }else{
      setShowalert(true)
      setMsg(msg)
 
    }
      }
    const fetchProduct = async (productid) => {
        setModalLoading(true);
        const {status, data, msg} = await sendData('/getproduct', JSON.stringify({  productid: product.id}));
        if(status){
      console.log({"savio":data})
     
      if(data.barcode==null){
        setHasBarcode(false)
      }else{
        setHasBarcode(true)
      }
      setProdInfo(data)
       setModalData(data);
       setModalImgPrev(data.img)
       setModalLoading(false);
if(data.brand!=null){
  setSelectedBrand({label: data.brand.name, value: data.brand.id})
  setSelectedBrandorig({label: data.brand.name, value: data.brand.id})
}
if(data.prodcat){
 setSelectedCat({label: data.prodcat.name, value: data.prodcat.id})

}
       
       setPrixbulk1(data.prixbulk1)
       setPrixpack1(data.prixpack1)
       setPrixunit1(data.prixunit1)
       setUnit(data.unit)
       setWeightpack1(data.weightpack1)
       setUnitMeasure(data.unitmeasure)
       setUnitMeasure2(data.unitmeasure2)
       setFormat(data.formatt)
       setName(data.name)
       setBrand(data.brand!=null?data.brand.name:'')
       setSku(data.sku)
       setDes(data.dess)
       setCat(data.catid)
       setPrice(data.prixbulk1)
       setImage(data.img)
       setTagsorig(data.prodtaggs.map(i=>i.taggs.name))
       setTags(data.prodtaggs.map(i=>i.taggs.name))
      
       if(data.img2!=null){
        console.log('hajs')
        otherImages.push({value:data.img2,key:`1`})
        imgs.push(data.img2)
       }
       if(data.img3!=null){
        otherImages.push({value:data.img3,key:`2`})
        imgs.push(data.img3)
       }
       if(data.img4!=null){
        otherImages.push({value:data.img4,key:`3`})
        imgs.push(data.img4)
       }
       if(data.img5!=null){
        otherImages.push({value:data.img5,key:`4`})
        imgs.push(data.img5)
       }

       setImg2(data.img2!=null?data.img2:'')
       setImg3(data.img3!=null?data.img3:'')
       setImg4(data.img4!=null?data.img4:'')
       setImg5(data.img5!=null?data.img5:'')

        }else{

          setShowalert(true)
          setMsg(msg)
        }
 }
      const fetchProductVariant = async (id) => {
        setModalLoading(true);
        const {status, data, msg} = await sendData('/getproduct', JSON.stringify({  productid: id}));
        if(status){
      console.log({"savio":data})
      if(data.barcode==null){
        setHasBarcode(false)
      }else{
        setHasBarcode(true)
      }
      setSize(data.size)
      setWeight(data.unit)
      setProdInfo(data)
       setModalData(data);
       setModalImgPrev(data.img)
       setModalLoading(false);
if(data.brand!=null){
  setSelectedBrand({label: data.brand.name, value: data.brand.id})
  setSelectedBrandorig({label: data.brand.name, value: data.brand.id})
}
if(data.prodcat){
 setSelectedCat({label: data.prodcat.name, value: data.prodcat.id})

}
       
       setPrixbulk1(data.prixbulk1)
       setPrixpack1(data.prixpack1)
       setPrixunit1(data.prixunit1)
       setUnit(data.unit)
       setWeightpack1(data.weightpack1)
       setUnitMeasure(data.unitmeasure)
       setUnitMeasure2(data.unitmeasure2)
       setFormat(data.formatt)
       setName(data.name)
       setBrand(data.brand!=null?data.brand.name:'')
       setSku(data.sku)
       setDes(data.dess)
       setCat(data.catid)
       setPrice(data.prixbulk1)
       setImage(data.img)
       setTagsorig(data.prodtaggs.map(i=>i.taggs.name))
       setTags(data.prodtaggs.map(i=>i.taggs.name))
      
       if(data.img2!=null){
        console.log('hajs')
        otherImages.push({value:data.img2,key:`1`})
        imgs.push(data.img2)
       }
       if(data.img3!=null){
        otherImages.push({value:data.img3,key:`2`})
        imgs.push(data.img3)
       }
       if(data.img4!=null){
        otherImages.push({value:data.img4,key:`3`})
        imgs.push(data.img4)
       }
       if(data.img5!=null){
        otherImages.push({value:data.img5,key:`4`})
        imgs.push(data.img5)
       }

       setImg2(data.img2!=null?data.img2:'')
       setImg3(data.img3!=null?data.img3:'')
       setImg4(data.img4!=null?data.img4:'')
       setImg5(data.img5!=null?data.img5:'')

        }else{

          setShowalert(true)
          setMsg(msg)
        }
  }
    const fetchVariants = async (productid) => {
  
      const {status, data, msg} = await sendData('/getproductvariants', JSON.stringify({  productid: product.id}));
      if(status){
     setVariants(data);
     setLoading(false);
      }else{

        setShowalert(true)
        setMsg(msg)
      }
    }
 useEffect(()=>{

        
            if(expanded) fetchVariants();
}, [expanded])
const showAlerts = () =>{
          return (
            <>
        {showalert&&(
      <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
          {msg}
      </Alert>
        )}
           
          </>
          )
}
const handleImages = (e) => {
          const selectedImage = e.target.files[0];
      
          if (selectedImage && extraimg.length < 5) {
              setimgs((prevImages) => [...prevImages, selectedImage]);
          }
 };
const handleDragOver = (e) => {
          e.preventDefault();
          // Optionally, you can add styles to indicate the drag-and-drop area
          // e.target.classList.add('drag-over');
 };
const handleDrop = (e) => {
          e.preventDefault();
          // Optionally, you can remove styles added in handleDragOver
          // e.target.classList.remove('drag-over');
      
          const files = e.dataTransfer.files[0];
          if (files.length > 0) {
            // Handle the dropped files (e.g., call handleImages)
            handleImages(files);
          }
        };
        
function containsOnlyNumbers(str) {
  // Regular expression to match only digits
  const regex = /^[0-9]+$/;
  
  // Test the string against the regular expression
  return regex.test(str);
}
const isFormValid = () => {
  let x=[]
        // Validate string fields
        if (!name) x.push('Name is required');
        if(newBrand){
          if (!newBrandName) x.push('Brand is required');
        }else{
          if (!selectedBrand.value) x.push('Brand is required');
        }        if (!format) x.push('Format is required');
        if (!selectedCat.value) x.push('Category is required');
        if (!barcode) x.push('Barcode is required');
        if(!containsOnlyNumbers(barcode)) x.push('Barcode Should be only numbers '+barcode); 

  
    
        if(unitmeasure=='unit'&&unitmeasure2==null){
          if (!prixunit1) x.push('Price is required'); 
          if (!unit) x.push('Weight is required'); 
          if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          //if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
        }
        if(unitmeasure=='weight'&&unitmeasure2=='bulk'){
          if (!prixbulk1) x.push('Price is required'); 
          //if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
        }
        if(unitmeasure=='weight'&&unitmeasure2=='package'){
          if (!prixpack1) x.push('Price is required'); 
          if (!weightpack1) x.push('Weight per package is required');
          if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
          //if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 

        }
        if(unitmeasure=='weight'&&unitmeasure2=='both'){
          if (!prixbulk1) x.push('Price is required'); 
          if (!prixpack1) x.push('Price is required'); 
          if (!weightpack1) x.push('Weight per package is required');
          if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
          // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');
          // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
 
        }
        if(unitmeasure=='both'&&unitmeasure2=='weight'){
          if (!prixbulk1) x.push('Price is required'); 
          if (!prixunit1) x.push('Price is required'); 
          if (!unit) x.push('Weight per unit is required');
          if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
          // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');

        }
        if(unitmeasure=='both'&&unitmeasure2=='package'){
          if (!prixunit1) x.push('Price is required'); 
          if (!prixpack1) x.push('Price is required'); 
          if (!weightpack1) x.push('Weight per package is required');
          if (!unit) x.push('Weight per unit is required');
          if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
          //if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
          //if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
        }
        if(unitmeasure=='both'&&unitmeasure2=='both'){
          if (!prixunit1) x.push('Price is required'); 
          if (!prixbulk1) x.push('Price is required'); 
          if (!prixpack1) x.push('Price is required'); 
          if (!unit) x.push('Weight per unit is required');
          if (!weightpack1) x.push('Weight per package is required');
          if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
          if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
          //if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
          //if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
          //if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
        }
        if(unitmeasure=='both'&&!unitmeasure2){
          x.push('Please choose a unit measure 2');
        }
        if(unitmeasure=='weight'&&!unitmeasure2){
          x.push('Please choose a unit measure 2');
        }
        if(containsOnlyNumbers(format)) x.push(`Format can't be only numbers`); 
      

      



        // Validate images
         if (!image) x.push('Image is required');
         for (let i = 0; i < otherImages.length; i++) {
          if (!otherImages[i].value) x.push(`Image ${i+2} is required`);
          
         }
  
        if (x.length > 0) {
          console.log('Validation Errors:', x);
          setErrors(x)
          return false;
        }
      if(x.length==0){
        setErrors([])
        return true;
      }
        // If all validations pass, return true
  
   
      };

      const handleSubmit = async() => {
        setErrors([])

  setSingleProd(true)
        if (isFormValid()) {
          setLoader(true)
          setError(false)

          try {
 
             let userid =localStorage.getItem('userid')
            const formData = new FormData();
           
        

          
             formData.append('userid', userid);
             formData.append('name', name);
             formData.append('id', product.id);
             if(selectedBrand.value){
              formData.append('brandid', selectedBrand.value);
             }
             formData.append('brandchanged',newBrand?1: !newBrand&&selectedBrand.value==selectedBrandorig.value?0:!newBrand&&selectedBrand.value!=selectedBrandorig.value?1:0);
             formData.append('newbrand', newBrand);
             formData.append('brandx', newBrandName);
             formData.append('description', des);
             formData.append('formatt', format);
             formData.append('barcode', barcode);
             formData.append('categoryid', selectedCat.value);
             formData.append('unitmeasure', unitmeasure);
             //formData.append('unitmeasure2', unitmeasure2);
             if(unitmeasure2){
              formData.append('unitmeasure2', unitmeasure2);
             }
             formData.append('img1', image);
             formData.append('imgchanged',imgchanged?1:0)
             formData.append('img2changed',img2changed||img22changed?1:0)
             formData.append('img3changed',img3changed||img33changed?1:0)
             formData.append('img4changed',img4changed||img44changed?1:0)
             formData.append('img5changed',img5changed||img55changed?1:0)

             formData.append('prixbulk1', prixbulk1);
             formData.append('prixpack1', prixpack1);
             formData.append('prixunit1', prixunit1);
             formData.append('weightpack1', weightpack1);
             formData.append('unit', unit);

             const areArraysEqual = JSON.stringify(tags) === JSON.stringify(tagsorg);
              if (areArraysEqual) {
                formData.append('tagschanged', 0);
              } else {
                formData.append('tagschanged', 1);   
                         }
               formData.append('frozen', tags.includes("Frozen")?1:0);
               formData.append('alcoholic', tags.includes("Alcohol")?1:0);
               formData.append('fragile', tags.includes("Fragile")?1:0);
               formData.append('tobacco', tags.includes("Tobacco")?1:0);
               const areArraysEqual1 = JSON.stringify(words) === JSON.stringify(wordsorg);
               if (areArraysEqual1) {
                formData.append('tagschanged2', 0);
              } else {
                formData.append('tagschanged2', 1);   
                         }
               formData.append('tags', JSON.stringify(words));
            
             if(otherImages.length>0){
              for (let i = 0; i < otherImages.length; i++) {
                    if(i==0&&(img2changed||img22changed)){
                      formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                    }
                    if(i==1&&(img3changed||img33changed)){
                      formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                    }
                    if(i==2&&(img4changed||img44changed)){
                      formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                    }
                    if(i==3&&(img5changed||img55changed)){
                      formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                    }
                
              }
             
         }
          const req = await fetch("https://api.trakitrak.com/control/products/edit", {
            method:'POST',
            body: formData,
    })
    if(req.status==200){
        const json = await req.json()
        console.log(json)
        setSingleProd(false)
        setLoader(false)
        setShow(false)
        setShow1(false)
        setShow2(false)
        setOtherImages([])
        props.fetch()
        setImg22changed(false)
        setImg2changed(false)
        setImgchanged(false)
        setImg44changed(false)
        setImg33changed(false)
        navigate('/products')
    
       


    }else{
        const json = await req.json()
        console.log(json) 
        setSingleProd(false)
        setShowalert(true)
        setMsg(json.message)
        setLoader(false)
        setShow(false)
        setShow1(false)
        setShow2(false)
        setImg22changed(false)
        setImg2changed(false)
        setImgchanged(false)
        setImg44changed(false)
        setImg33changed(false)
        setOtherImages([])
    }
          } catch (error) {
            setSingleProd(false)
            setLoader(false)
          }
       
        } else {
          setSingleProd(false)
          setLoader(false)
            setError(true)
            setShow(false)
          
            setOtherImages([])
           

        }
      };  
      const isFormValid3 = () => {
        let x=[]
 
  
              // Validate string fields
              if (!name) x.push('Name is required');
              if (!selectedBrand.value) x.push('Brand is required');
              if (!selectedCat.value) x.push('Category is required');
      
        
              // Validate images
               if (!image) x.push('Image is required');
              
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            }; 
      const handleSubmit3 = async() => {
        setErrors([])
setMainVariant(true)
  
        if (isFormValid3()) {
          setLoader(true)
          setError(false)

          try {
 
          
             let userid =localStorage.getItem('userid')
             let storeid =localStorage.getItem('storeid')
            const formData = new FormData();
       
              formData.append('userid', userid);
              formData.append('storeid', storeid);
              formData.append('id', product.id);
              formData.append('name', name);
              formData.append('brandid', selectedBrand.value);
              formData.append('brandchanged', selectedBrand.value==selectedBrandorig.value?0:1);
              formData.append('newbrand', newBrand);
              formData.append('brandx', newBrandName);
              formData.append('categoryid', selectedCat.value);
              formData.append('highlight', featured);
              formData.append('img1', image);
              formData.append('imgchanged',imgchanged?1:0)
            
          
          const req = await fetch("https://api.trakitrak.com/merchant/products/edit", {
            method:'POST',
            body: formData,
    })
    if(req.status==200){
        const json = await req.json()
        console.log(json)
        setMainVariant(false)
        setLoader(false)
        setShow(false)
        setShow1(false)
        setShow2(false)
        setOtherImages([])
        props.fetch()
        navigate('/products')
       


    }else{
        const json = await req.json()
        console.log(json) 
        setMainVariant(false)
        setShowalert(true)
        setMsg(json.message)
        setLoader(false)
        setShow(false)
        setShow1(false)
        setShow2(false)
        setOtherImages([])
    }
          } catch (error) {
            setMainVariant(false)
            setLoader(false)
          }
       
        } else {
          setMainVariant(false)

          setLoader(false)
            setError(true)
            setShow(false)
            setShow1(false)
            setShow2(false)
            setOtherImages([])
            window.scrollTo({
              top: 0,
              behavior: "smooth" // Use smooth scrolling animation
            });

        }
      };  
      const isFormValid4 = () => {
        let x=[]
 
  
              // Validate string fields
              if (!name) x.push('Name is required');
              if (!sku) x.push('SKU is required');
              if (!size) x.push('Size is required');
              if (!prixunit1) x.push('Price is required');
              if (!weight) x.push('Weight is required');
          
      
        
              // Validate images
              if (!image) x.push('Image is required');
              for (let i = 0; i < otherImages.length; i++) {
               if (!otherImages[i].value) x.push(`Image ${i+2} is required`);
               
              }
              
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            }; 
      const handleSubmit4 = async() => {
        setErrors([])
setVariantLoader(true)
  
        if (isFormValid4()) {
          setLoader(true)
          setError(false)

          try {
 
          
             let userid =localStorage.getItem('userid')
             let storeid =localStorage.getItem('storeid')
            const formData = new FormData();
       
              formData.append('userid', userid);
              formData.append('storeid', storeid);
              formData.append('id', product.hasvar.id)
              formData.append('name', name);
              formData.append('weight', weight);
              formData.append('size', size);
              formData.append('prixunit1', prixunit1);
              formData.append('description', des);
              formData.append('sku', sku);
   
              formData.append('img1', image);
              formData.append('imgchanged',imgchanged?1:0)
              formData.append('img2changed',img2changed?1:0)
              formData.append('img3changed',img3changed?1:0)
              formData.append('img4changed',img4changed?1:0)
              formData.append('img5changed',img5changed?1:0)
              if(otherImages.length>0){
                for (let i = 0; i < otherImages.length; i++) {
                      if(i==0&&(img2changed||img22changed)){
                        formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                      }
                      if(i==1&&(img3changed||img33changed)){
                        formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                      }
                      if(i==2&&(img4changed||img44changed)){
                        formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                      }
                      if(i==3&&(img5changed||img55changed)){
                        formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                      }
                  
                }
               }
              // if(otherImages.length>0){
              //   for (let i = 0; i < otherImages.length; i++) {
          
              //     formData.append(`img${i+2}`,i==0?img2:i==1?img3:i==2?img4:i==3?img5:null );
                  
              //   }
              //  }
            
          
          const req = await fetch("https://api.trakitrak.com/merchant/products/edit", {
            method:'POST',
            body: formData,
    })
    if(req.status==200){
        const json = await req.json()
        console.log(json)
        setVariantLoader(false)
        setLoader(false)
        setShow(false)
        setShow1(false)
        setShow2(false)
        setOtherImages([])
        props.fetch()
        navigate('/products')
       


    }else{
        const json = await req.json()
        console.log(json) 
        setVariantLoader(false)
        setShowalert(true)
        setMsg(json.message)
        setLoader(false)
        setShow(false)
        setShow1(false)
        setShow2(false)
        setOtherImages([])
    }
          } catch (error) {
            setVariantLoader(false)
            setLoader(false)
          }
       
        } else {
          setVariantLoader(false)
          setLoader(false)
            setError(true)
            setShow(false)
            setShow1(false)
            setShow2(false)
            setOtherImages([])
            window.scrollTo({
              top: 0,
              behavior: "smooth" // Use smooth scrolling animation
            });

        }
      }; 
   
const DeleteProduct = async()=>{
console.log(JSON.stringify({prodid: modalData && modalData.id,userid:localStorage.getItem('userid'),storeid:localStorage.getItem('storeid')}))
  const {status, data, msg} = await sendData('/products/delete', JSON.stringify({prodid: modalData && modalData.id,userid:localStorage.getItem('userid'),storeid:localStorage.getItem('storeid')}));
  if(status){
    console.log({data})
    props.fetch()
    navigate('/products')
    handleClosee()
  
// setShow(false)
// setShow1(false)
// setSameav(false)
//  setVariants(data);
//  setLoading(false);
  }else{

    setShowalert(true)
    setMsg(msg)
  }
}
const filldata = async()=>{
  console.log({product})
  let arr=[]
  if(product.alcoholic){arr.push('Alcohol')}
  if(product.fragile){arr.push('Fragile')}
  if(product.frozen){arr.push('Frozen')}
  if(product.tobacco){arr.push('Tobacco')}
  setTags(arr)
  setTagsorig(arr)
  if(product.prodbrand!=null){
    setSelectedBrand({label: product?.prodbrand.name, value: product?.prodbrand.id})
    setSelectedBrandorig({label: product?.prodbrand.name, value: product?.prodbrand.id})
  }
  if(product?.prodcat){
   setSelectedCat({label: product?.prodsubsub.name, value: product?.prodsubsub.id})
  
  }      
         setShoppermsg(product?.shopper_msg)
         setBarcode(product?.barcode)
         setPrixbulk1(product?.prixbulk1)
         setPrixpack1(product?.prixpack1)
         setPrixunit1(product?.prixunit1)
         setUnit(product?.unit)
         setWeightpack1(product?.weightpack1)
         setUnitMeasure(product?.unitmeasure)
         setUnitMeasure2(product?.unitmeasure2)
         setFormat(product?.formatt)
         setName(product?.name)
         setSku(product?.sku)
         setDes(product?.dess)
         setCat(product?.catid)
         setPrice(product?.prixbulk1)
         setImage(product?.img)
        
        //  if(product?.img2!=null){
        //   console.log('hajs')
        //   otherImages.push({value:product?.img2,key:`1`})
        //   imgs.push(product?.img2)
        //  }
        //  if(product?.img3!=null){
        //   otherImages.push({value:product?.img3,key:`2`})
        //   imgs.push(product?.img3)
        //  }
        //  if(product?.img4!=null){
        //   otherImages.push({value:product?.img4,key:`3`})
        //   imgs.push(product?.img4)
        //  }
        //  if(product?.img5!=null){
        //   otherImages.push({value:product?.img5,key:`4`})
        //   imgs.push(product?.img5)
        //  }
  
        //  setImg2(product?.img2!=null?product?.img2:'')
        //  setImg3(product?.img3!=null?product?.img3:'')
        //  setImg4(product?.img4!=null?product?.img4:'')
        //  setImg5(product?.img5!=null?product?.img5:'')

          if(product?.prodtags){
            let w=[]
            if(product?.prodtags.length>0){
             for (let i = 0; i < product.prodtags.length; i++) {
               w.push(product.prodtags[i].name)
               
             }
             setWords(w)
             setWordsorg(w)
            }
          }

       
 }
 useEffect(()=>{
  if(product.img2!=null){
    console.log('hajs')
    otherImages.push({value:product.img2,key:`1`})
    imgs.push(product.img2)
   }
   if(product.img3!=null){
    otherImages.push({value:product.img3,key:`2`})
    imgs.push(product.img3)
   }
   if(product.img4!=null){
    otherImages.push({value:product.img4,key:`3`})
    imgs.push(product.img4)
   }
   if(product.img5!=null){
    otherImages.push({value:product.img5,key:`4`})
    imgs.push(product.img5)
   }

   setImg2(product.img2!=null?product.img2:'')
   setImg3(product.img3!=null?product.img3:'')
   setImg4(product.img4!=null?product.img4:'')
   setImg5(product.img5!=null?product.img5:'')
 },[])
  return (
<>
<Modal show={showww} onHide={()=>setshowww(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to clear?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>setshowww(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={()=>{setWords([])
        setshowww(false)
        }}>
          Clear
        </Button>
      </Modal.Footer>
    </Modal>
<Modal show={showw} onHide={handleClosee}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosee}>
          Cancel
        </Button>
        <Button variant="danger" onClick={DeleteProduct}>
          Delete
        </Button>
      </Modal.Footer>
</Modal>
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show} onHide={handleClose}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h5 style={{margin:0,marginLeft:10}}>{product.brand ? product.brand.name + '・' : ''}{product.name}</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button onClick={()=>{setShow(false)
                                              setOtherImages([])
                                    }} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn">Close</button>
                                      </div>
            </div>
         <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginBottom:'1%',marginTop:'1%'}}/>
          
                     
          {props.fromStore?null:  <a  onClick={()=>{
                getDropDowns()
                setShow2(true)
              
              
         
                  }} className="modal-button ms-2">Edit Product </a>}
            <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginTop:'1%'}}/>

        </div>

      
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'90%',marginBottom:'5%'}}>
        {/* first div */}
        <div style={{width:'40%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Product</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
            <img src={props.product.img?props.product.img:props.product.image?props.product.image:''} className='modal2' />
   
                <button onClick={()=>console.log(props.product.name)} className="mt-3 modal-button modal-buttonx" style={{ width: '95%',marginBottom:10 }}>
                  Delete product 
                </button>
             
                
              
            </div>
        </div>
        {/* second div */}
        <div style={{width:'60%'}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>GENERAL INFORMATION</p>
        <div style={{width:'98%',height:'55vh', overflowY: 'auto',paddingBottom:15,marginLeft:'1%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{props.product.name}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
                Name
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{props.product.prodbrand ? props.product.prodbrand.name :props.product.brand ? props.product.brand.name: '---'}</p>
              <p  style={{margin:0, color: 'gray', fontSize: '13px' }}>
              Brand
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{ props.product.barcode ? props.product.barcode : '---'}</p>
              <p  style={{ margin:0,color: 'gray', fontSize: '13px' }}>
              Barcode
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
      
         
         
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{ props.product.sku ? props.product.sku : '---'}</p>
              <p  style={{ margin:0,color: 'gray', fontSize: '13px' }}>
              SKU
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{props.product.formatt ? props.product.formatt : '---'}</p>
              <p  style={{ margin:0,color: 'gray', fontSize: '13px' }}>
              Format
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{props.product.dess ? props.product.dess : '---'}</p>
              <p  style={{margin:0, color: 'gray', fontSize: '13px' }}>
              Description
              </p>
            </div>
            
          
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{props.product.prodcat ? `${props.product.prodcat.name} > ${props.product.prodsub.name} > ${props.product.prodsubsub.name}` : '---'}</p>
              <p  style={{margin:0, color: 'gray', fontSize: '13px' }}>
              Category
              </p>
            </div>
          
        </div>
      
          
        </div>
    </div>


</div>


</Modal>


<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show2} onHide={handleClose2}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h5 style={{margin:0,marginLeft:10}}>Edit Product</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button disabled={signleProd?true:false} onClick={()=>{
                                      setShow2(false)
                          
                                    }} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn"
>Close</button>
                                    <button disabled={signleProd?true:false} onClick={()=>{
                                handleSubmit()
                             
                                    }} data-bs-dismiss="modal" style={{marginLeft:5}} className="btn btn-outline-primary custom-btn"
>Save</button>
                                      </div>
            </div>
       

        </div>
        {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
      {signleProd?
        <div className="spinner-border" style={{display:'flex',marginTop:250,justifyContent:'center',alignItems:'center'}} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        :
        
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'95%',marginBottom:'5%'}}>
    
        {/* second div */}
        <div style={{width:'70%',height:'80vh', overflowY: 'auto',paddingBottom:100}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Information</p>
          
        <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',}}>
          <div style={{justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p className='m-0' style={{fontWeight:'500',fontSize:18,color:'gray'}}>Name</p>
            <input value={name}  onChange={(e)=>setName(e.target.value)}  style={{outline:'none',border:'none',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div  style={{width:'95%'}} className="d-flex mt-4 justify-content-between align-items-center">
                            <label className="form-label">Brand</label>
                            <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{background: '#f1f1f1', color: 'black', maxWidth: 300}}>
                                <strong className="text-black" style={{fontFamily: 'Poppins-SemiBold', fontSize: '12px'}}>IMPORTANT:</strong>
                                <p style={{fontSize: '13px'}}>This is the manufacturer's brand. If the product is produced by your store, you do not need to enter a brand.</p>
                                <p style={{fontSize: '13px', marginTop: '5px'}}>If the brand you are looking for does not exist, you can create one. Remember to take into consideration the following tips</p>
                                <ul className="myul">
                                  <li>Use capital letters only for brand names or at the beginning of the name.</li>
                                </ul>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                            </Tippy>
                            </div>
                        
                            <div style={{width:'95%'}}>
                    <div className="rounded" style={{ backgroundColor: 'white',cursor:hasBarcode?'not-allowed':'', padding: '10px 0' }}>

                    <Select
                    isDisabled={hasBarcode?true: newBrand}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti={false}
                    options={brands}
                    onChange={(e) => setSelectedBrand(e)}
                    value={selectedBrand}
                    blurInputOnSelect={true}
                    styles={{
                      container: (styles) => ({...styles, border: 0}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none'}),
                    }}
                    />

                    </div>
                    </div>

                    <div style={{width:'95%',marginLeft:5}} className="mb-2 form-check mt-2">
                        <input  checked={newBrand} onChange={(e) => setNewBrand(e.target.checked)} className="form-check-input brand-check" type="checkbox" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                        Add new brand instead
                        </label>
                    </div>

                    {newBrand &&
                    <div style={{width:'95%',marginLeft:5}} className="mt-2 mb-2 new-brand" >
                        <input value={newBrandName} onChange={(e) => {setNewBrandName(e.target.value);setBrandschanged(true) }} type="text" name="brandx" placeholder="Enter new brand name" className="form-control" />
                    </div>
                    }
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{width:'95%'}}>
          <div  style={{width:'95%'}} className="d-flex mt-4 justify-content-between align-items-center">
                            <label className="form-label">Category</label>
                            </div>
                <div className="rounded" style={{ backgroundColor: 'white',cursor:hasBarcode?'not-allowed':'', padding: '10px 0' }}>
                    <Select
                    is
                    closeMenuOnSelect={false}
                    components={{GroupHeading}}
                    filterOption={createFilter(filterConfig)}
                    isMulti={false}
                    options={cats}
                    onChange={(e) => setSelectedCat(e)}
                    value={selectedCat}
                    blurInputOnSelect={true}
                    //styles={colourStyles}
                    styles={{
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? 'white' : isFocused ? '#f1f1f1' : 'white',
                        color: isSelected ? 'black' : 'black',
                        fontFamily: isSelected ? 'Poppins-SemiBold' : '500',
                        height: 55,
                        margin:0,
                       
                        // margin: '0 15px 15px 15px',
                        display: 'flex',
                        alignItems: 'center', // Center vertically
                        borderBottom:'1px solid #f3f3f3',
                      
                        // justifyContent: 'center', // Center horizontally
                      }),
                      container: (styles) => ({...styles, border: 0,color:'black'}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none',}),
                      groupHeading: (base) => ({
                        ...base,
                        flex: '1 1',
                        color: 'white',
                        fontSize: 15,
                        margin: 100,

                      })
                    }}
                    />
                </div>
                 </div>
          
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p  className="m-0"style={{fontWeight:'500',fontSize:'18px',color:'gray'}}>Barcode (required)</p>
            <input value={barcode} onChange={(e)=>setBarcode(e.target.value)} style={{border:'none',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p  className="m-0"style={{fontWeight:'500',fontSize:'18px',color:'gray'}}>Shopper message (required)</p>
            <input value={shoppermsg} onChange={(e)=>setShoppermsg(e.target.value)} style={{border:'none',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
          <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
            <label className="form-label">Tags </label>   
           </div>
           <input  type="text" value={currentInput} onChange={handleInputChange}  onKeyDown={handleKeyDown} className="form-control w-75"  placeholder="Product tags"/>
           {words.length > 0 && (
            //  <div className="form-control w-75"  style={{display:'flex',marginTop:10,flexDirection:'row',alignItems:'center'}}>
            <div className="form-control w-75" style={{
              display: 'flex',
              marginTop: 10,
              flexWrap: 'wrap', // Enable wrapping
              flexDirection: 'row',
              alignItems: 'center',
              gap: '15px' // This will ensure spacing between tags, removing the need for marginLeft condition in each tag
          }}>
            <div style={{display:'flex',cursor:'pointer',backgroundColor:'#DC143C',padding:7,borderRadius:5,alignItems:'center'}} onClick={()=>{
              setshowww(true)
            }}>
              <p style={{margin:0,marginRight:5,color:'white'}}>Clear</p>
             <svg style={{cursor:'pointer'}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="22.000000pt" height="22.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M504 5110 c-226 -33 -411 -197 -481 -425 -17 -57 -18 -148 -18 -2125
0 -1977 1 -2068 18 -2125 62 -202 210 -350 412 -412 57 -17 148 -18 2125 -18
1977 0 2068 1 2125 18 202 62 350 210 412 412 17 57 18 148 18 2125 0 1977 -1
2068 -18 2125 -62 200 -203 343 -407 412 -53 17 -140 18 -2095 19 -1122 1
-2063 -2 -2091 -6z m1330 -1552 c35 -10 95 -65 384 -352 l342 -340 343 340
c355 354 368 364 448 364 111 0 219 -108 219 -219 0 -80 -10 -93 -364 -448
l-340 -343 340 -343 c354 -355 364 -368 364 -448 0 -111 -108 -219 -219 -219
-80 0 -93 10 -448 364 l-343 340 -342 -340 c-356 -354 -369 -364 -449 -364
              -111 0 -219 108 -219 219 0 80 10 93 364 449 l340 342 -340 343 c-354 355
              -364 368 -364 448 0 108 107 217 214 219 16 0 47 -5 70 -12z"/>
              </g>
              </svg>
            </div>
              {words.map((word, index) => (
              <div style={{marginLeft:index==0?0:15,paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,position:'relative',borderRadius:5,backgroundColor:'#f1f1f1',display:'flex',alignItems:'center'}}>
                <p key={index} style={{marginTop:0,marginBottom:0,color:'black'}}>{word}</p>
                <div onClick={() => removeTagg(index)} style={{backgroundColor:'gray',cursor:"pointer",position:'absolute',top:-6,right:-6,padding:3,width:20,height:20,borderRadius:10,justifyContent:'center',alignItems:'center',display:'flex',color:'white'}}>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="white" stroke="none">
            <path d="M1017 4072 c-110 -110 -177 -185 -177 -197 0 -13 230 -250 662 -682
            l663 -663 -663 -663 c-428 -428 -662 -669 -662 -682 0 -27 348 -375 375 -375
            13 0 246 226 682 662 l663 663 663 -663 c486 -486 668 -662 685 -662 33 0 372
            337 372 370 0 19 -146 171 -662 687 l-663 663 663 663 c436 436 662 669 662
            682 0 27 -348 375 -375 375 -13 0 -254 -234 -682 -662 l-663 -663 -663 663
            c-433 433 -669 662 -682 662 -13 0 -86 -66 -198 -178z"/>
            </g>
            </svg>
                  </div>
               </div>
              ))}
          </div>
      )}
          <div style={{height:55,justifyContent:'center',cursor:hasBarcode?'not-allowed':'',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p className="m-0" style={{fontWeight:'500',fontSize:'18px',color:'gray'}}>Format (required)</p>
            <input value={format}  onChange={(e)=>setFormat(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',cursor:hasBarcode?'not-allowed':'',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p className="m-0" style={{fontWeight:'500',fontSize:'18px',color:'gray'}}>Description</p>
            <input value={des}  onChange={(e)=>setDes(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>

          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
          
     <label className="form-label mt-4 w-100">Images</label>
                    
                    <div id="main-img-div" style={{width:'98%'}} className="rounded border p-2">
                              
                              <div className="border-bottom pb-2">
                                <label htmlFor="img1" className="w-100 label-input">
                                  {hasBarcode?null:
                                  <input
                                  onChange={handleImageChange}
                                 
                                  required
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  type="file"
                                  name="img1"
                                  id="img1"
                                  //onChange={handleImg1Change}
                              />
                                  }
                                  
                                 {ErrorImg?
                                <Alert dismissible key={'danger'} variant={'danger'}>
                                 <div style={{display:'flex',alignItems:'center'}}>
                                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#ff0000" stroke="none">
<path d="M1555 5115 c-5 -2 -23 -6 -40 -10 -23 -5 -210 -185 -753 -728 -398
-397 -731 -735 -740 -752 -16 -28 -17 -113 -17 -1055 0 -564 4 -1038 8 -1055
6 -21 219 -241 730 -753 397 -398 736 -731 752 -740 27 -16 116 -17 1045 -20
558 -2 1031 0 1050 3 30 5 139 110 769 738 425 424 739 746 747 764 12 27 14
201 14 1052 0 685 -3 1029 -10 1048 -14 38 -1455 1481 -1497 1499 -27 12 -197
14 -1040 13 -554 0 -1012 -2 -1018 -4z m1276 -1078 c19 -12 43 -40 54 -62 l19
-40 -66 -883 c-73 -969 -66 -922 -138 -969 -29 -20 -46 -23 -140 -23 -94 0
-111 3 -140 23 -72 48 -66 4 -136 930 -35 463 -64 865 -64 892 0 59 25 106 72
135 30 18 51 20 269 20 229 0 237 -1 270 -23z m-165 -2394 c67 -23 154 -110
177 -177 40 -117 14 -231 -72 -317 -72 -71 -132 -94 -235 -87 -82 6 -133 28
-189 85 -67 66 -82 105 -82 213 0 78 4 102 22 135 38 71 112 134 183 156 45
13 145 9 196 -8z"/>
</g>
</svg>
                       <p style={{marginLeft:10}}>{ErrorImg}</p>
                                 </div>
                              </Alert>:null}
                             
                                  <div className="d-flex align-items-center" style={{ cursor:hasBarcode?'not-allowed': 'pointer' }}>
                                        <div>
                                          <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                            {!image ?
                                            <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                             : 
                                             <img alt='img1' className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} src={!imgchanged?image: URL.createObjectURL(image)}></img>
                                             }
                                          </div>
                                        </div>

                                        <div className="d-flex flex-column justify-content-center ms-2">
                                          <p className='m-0'>{ImageName?ImageName:'Image 1'}</p>
                                          <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>{ImageSize?Number(ImageSize*1000).toFixed(0)+'KB':'Choose...'}</span>
                                        </div>
                                  </div>
                                </label>
                              </div>

                              {otherImages.map((otherimg,i) => {
                                return (
                                  <div className={`${i < 3 ? 'border-bottom pb-2' : ''}`}>
                                  <label htmlFor={"img" + i+2} className="w-100 label-input">
                                    {hasBarcode?null:
                                    <input
                                    onChange={e => handleOtherImageChange(e, otherimg,i)}
                                   // onChange={e => setOtherImages(otherImages.map((oi) => {
                                   //   if(oi.key == otherimg.key) return {...oi, value: e.target.files[0]}
                                   //   else return oi;
                                   // }))}
                                   required
                                   accept="image/*"
                                   style={{ display: 'none' }}
                                   type="file"
                                   name={"img" + i+2}
                                   id={"img" + i+2}
                                   
                               />
                                    }
                                    
                                    <div className="d-flex align-items-center justify-content-between" style={{ cursor:hasBarcode?'not-allowed': 'pointer' }}>
                                      <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>

                                          <div>
                                            <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                              {!otherimg.value ?
                                              <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                               : 
                                               i==0?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img2changed?img2: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>
                                               :i==1?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img3changed?img3: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>
                                               :i==2?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img4changed?img4: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>:
                                               i==3?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img5changed?img5: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>:null

                                               }
                                               
                                            </div>
                                          </div>
  
                                          <div className="d-flex flex-column justify-content-center ms-2">
                                            <p className='m-0'>{ImagesName[i]?ImagesName[i]:`Image ${i+2}`}</p>
                                            <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>{ImagesSize[i]?Number(ImagesSize[i]*1000).toFixed(0)+"KB":`Choose...`}</span>
                                          </div>

                                      </div>

                                         {hasBarcode?null:
                                          <div onClick={() => {
                                            if(i==0){
                                              setImg22changed(true)
                                              if(img3){
                                                setImg2(img3)
                                              }
                                              if(img4){
                                                setImg3(img4)
                                              }
                                              if(img5){
                                                setImg4(img5)
                                              }
                                            }
                                            if(i==1){
                                              setImg33changed(true)
                                              if(img4){
                                                setImg3(img4)
                                              }
                                              if(img5){
                                                setImg4(img5)
                                              }
                                            }
                                            if(i==2){
                                              setImg44changed(true)
                                              if(img5){
                                                setImg4(img5)
                                              }
                                            }
                                            if(i==3){
                                              setImg55changed(true)
                                              setImg5('')
                                            }
                                            console.log(otherImages)
                                            console.log(otherImages.filter((_, index) => index !== i))
                                           setOtherImages(otherImages.filter((_, index) => index !== i));

                                            //setOtherImages(otherImages.filter(x => x.key !== otherimg.key))
                                          
                                          }} class="close-img">
                                          
                                          <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                                      </div>}
                                    </div>
                                  </label>
                                </div>
                                )
                                })}
                              
                             
                            
                           {hasBarcode?null:
                           <>
                            { otherImages.length < 4 &&

<div onClick={() => {
  setOtherImages([...otherImages, {key: Math.random(), value: null,}]);
}} 
id="add-img" 
className="p-2 mt-2 justify-content-between align-items-center" 
style={{ display: 'flex', cursor: 'pointer' }}
>
    <div className="d-flex align-items-center">
      <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
    </svg>
    <p className="m-0" style={{marginLeft:5}}>Add another image</p>
    </div>
    <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
    <title>plus-circle</title>
    <desc>Created with Sketch Beta.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
    <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
        <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup"></path>
    </g>
    </g>
</g>
</svg>
</div>
}
                           </>
                           }

                            </div>
                            <div className="mt-1 w-75">
                            <p style={{textAlign:'left',color:'#8e8e93',fontSize:13}}>Learn <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8479767-what-to-consider-when-creating-a-non-variant-products' style={{fontSize:13,color:'#007aff'}}>Best practices for uploading images.</a> You can arrange them any way you want them to show in the app.</p>
                            </div>



       
 {/* unit measure  */}
 <div style={{display:'flex',alignSelf:'center',width:'98%'}} className="unitmeasuresmaindiv mt-4" id="unitmeasuremaindiv">

                
<div
 onClick={() => {
  if(hasBarcode){
    console.log('')
  }else{
    setUnitMeasure('unit');
    setUnitMeasure2(null);
  }

}}
className="measurediv unitdiv" style={{opacity: unitmeasure == 'unit' ? 1 : 0.5,cursor:hasBarcode?'not-allowed':'pointer',  transform: `scale(${unitmeasure == 'unit' ? 1.04 : 1})`}}>
    <div className="measure-first">
      <input
      checked={unitmeasure == 'unit'}
          id="unit-check"
          style={{ marginLeft: '10px' }}
          value="unit"
          type="radio"
          name="unitmeasure"
      />
      <div className="measure-text">
          <p>By unit</p>
          <p>Example: soda can</p>
      </div>
    </div>
    <div className="measure-second">
      <img src={`${awsUrl}/quotes/pepsi.png`} alt="soda-can" style={{ width: '100%', top: '10px' }} />
    </div>
</div>

<div style={{ flex: '0.1' }}></div>

<div 
 onClick={() => {
  if(hasBarcode){
    console.log('')
  }else{
    setUnitMeasure('weight');
  setUnitMeasure2(null);
  }

}}
 
className="measurediv bulkdiv" style={{opacity: unitmeasure == 'weight' ? 1 : 0.5,cursor:hasBarcode?'not-allowed':'pointer', transform: `scale(${unitmeasure == 'weight' ? 1.04 : 1})`}}>
    <div style={{width:'50%'}} className="measure-first">
    <input
        checked={unitmeasure == 'weight'}
        id="weight-check"
        type="radio"
        name="unitmeasure"
        value="weight"
        style={{ marginLeft: '10px' }}
    />
    <div className="measure-text" style={{ color: 'black' }}>
        <p>By Weight</p>
        <p>Example: Lemon</p>
    </div>
    </div>
    <div className="measure-second">
    <img src={`${awsUrl}/quotes/lemon.png`} alt="lemon" style={{ width: '100%', top: '10px' }} />
    </div>
</div>

</div>

{/* UNIT AND WEIGHT INPUTS */}
{unitmeasure == 'unit'?
  <div id="unit-inputs" style={{width:'98%'}} className="mt-3  m-auto">
  <label className="form-label w-100">Price per unit</label>
  <input value={prixunit1}  onChange={e => setPrixunit1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

  <label className="form-label w-100 mt-3">Weight per unit<span style={{color:'gray', fontSize:'13px'}}> (in grams)</span>                    
<span style={{marginLeft:5}}>
<Tippy
            placement='left'
            theme='tomato'
            render={attrs => (
              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                  <p>This value won't appear to the customer, however it is required for our internal systems.</p>
              </div>

            )}
            >
              <i id="brand-tip">
                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
              </i>
    </Tippy>
</span>
    </label>
  <input value={unit}  style={{cursor:hasBarcode?'not-allowed':''}} onChange={e => setUnit(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
</div>
:
unitmeasure == 'weight'?
<div  style={{width:'98%'}} className="mt-3  m-auto">
<div className='d-flex'>
<div className="form-check">
    <input checked={unitmeasure2 == 'bulk'}  onClick={() => setUnitMeasure2('bulk')} value="bulk" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
    <label className="form-check-label">weight                  
 <span style={{marginLeft:5}}>
 <Tippy
            placement='left'
            theme='tomato'
            render={attrs => (
              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                  <p style={{fontSize: 14}}>In this case, products are sold only by kilograms such as 1 KG Tomato.</p>
              </div>

            )}
            >
              <i id="brand-tip">
                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
              </i>
    </Tippy>
 </span>
    </label>
</div>

<div className="form-check ms-4">
    <input checked={unitmeasure2 == 'package'}  onClick={() => setUnitMeasure2('package')} value="package" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
    <label className="form-check-label">package                     
   <span style={{marginLeft:5}}>
   <Tippy
  
  placement='left'
  theme='tomato'
  render={attrs => (
    <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
        <p style={{fontSize: 14}}>Some packed products like meat have different weights. For this type of product, you must include the approximate weight per unit.</p>
    </div>

  )}
  >
    <i id="brand-tip">
      <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
    </i>
</Tippy>
   </span>
    </label>
</div>
{/* <i className="ms-1" id="package-tip">
  <svg stroke="red" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="2.5"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" stroke-width="2.5" stroke-linecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
</i>   */}


<div className="form-check ms-4">
    <input checked={unitmeasure2 == 'both'}  onClick={() => setUnitMeasure2('both')} value="both" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
    <label className="form-check-label">weight &amp; package
    
    <span>                    <Tippy
            placement='left'
            theme='tomato'
            render={attrs => (
              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                  <p style={{fontSize: 14}}>some products may be requested by the
customer either by bulk (1 kg of apples) or by units (5 apples). Check this option if the product can be ordered by weight or unit.</p>
              <ul style={{marginTop: 5}}>
                <li  style={{fontSize: 14}}>If that is the case, do not forget to include the products approximate weight per unit.</li>
              </ul>
              </div>

            )}
            >
              <i id="brand-tip">
                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
              </i>
    </Tippy></span>
    </label>
</div>


</div>

{/* inputs unitmeasure2 */}
<div className='mt-3'>
  {unitmeasure2 == 'bulk' ?
  (
    <>
      <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
      <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
    </>
  )
  : unitmeasure2 == 'package' ?
    (
      <>
        <label className="form-label w-100">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
        <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

        <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
        <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
      </>
    )
  : unitmeasure2 == 'both' ? (
    <>
    <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
    <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
  
    <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
    <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

    <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
    <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
    </>
    ) : null
  }
</div>
{/* inputs unitmeasure2 */}

</div>
:
null
}


{/* UNIT AND WEIGHT INPUTS */}

{/* unit measure  */}
<div style={{display:'flex',alignSelf:'center',justifyContent:'center',alignItems:'center',width:'100%'}}  id="unitmeasuremaindiv">
<div
 onClick={() => {
  if(hasBarcode){
    console.log('')
  }else{
    setUnitMeasure('both');
    setUnitMeasure2(null);
  }

}}

style={{opacity: unitmeasure == 'both' ? 1 : 0.5,cursor:hasBarcode?'not-allowed':'pointer',  transform: `scale(${unitmeasure == 'both' ? 1.04 : 1})`,width:'95%'}} className="weightunitdiv" id="weightunitdiv">
    <div style={{width:'95%'}}  className="measure-first">
        <input checked={unitmeasure == 'both'} id="weightunit-check" style={{marginLeft:10}} type="radio" name="unitmeasure"
            value="both"/>
        <div className="measure-text">
            <p>By unit and weight</p>
            <p>Example: pieces of meat</p>
        </div>
    </div>
    <div className="measure-second">
        <img src={`${awsUrl}/quotes/meat.png`} alt="meat" style={{top:-30}}/>
    </div>
</div>
</div>

{unitmeasure == 'both' && (
<>
<div id="unit-inputs" style={{width:'98%'}} className="mt-3 m-auto">
<label className="form-label w-100">Price per unit</label>
<input value={prixunit1} onChange={e => setPrixunit1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

<label className="form-label w-100 mt-3">Weight per unit<span style={{color:'gray', fontSize:'13px'}}> (in grams)</span>
<span style={{marginLeft:5}}>
<Tippy
            placement='left'
            theme='tomato'
            render={attrs => (
              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                  <p>This value won't appear to the customer, however it is required for our internal systems.</p>
              </div>

            )}
            >
              <i id="brand-tip">
                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
              </i>
    </Tippy>
    </span>
</label>

<input value={unit}  onChange={e => setUnit(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
</div>


<div style={{width:'98%'}} className="mt-3 m-auto">
<div className='d-flex'>
<div className="form-check">
    <input checked={unitmeasure2 == 'bulk'}  onClick={() => setUnitMeasure2('bulk')} value="bulk" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
    <label className="form-check-label">weight                  
    <span style={{marginLeft:5}}>
    <Tippy
            placement='left'
            theme='tomato'
            render={attrs => (
              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                  <p style={{fontSize: 14}}>In this case, products are sold only by kilograms such as 1 KG Tomato.</p>
              </div>

            )}
            >
              <i id="brand-tip">
                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
              </i>
    </Tippy>
    </span>
    </label>
</div>

<div className="form-check ms-4">
    <input checked={unitmeasure2 == 'package'}  onClick={() => setUnitMeasure2('package')} value="package" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
    <label className="form-check-label">package                    

    <span style={{marginLeft:5}}>    <Tippy
            placement='left'
            theme='tomato'
            render={attrs => (
              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                  <p style={{fontSize: 14}}>Some packed products like meat have different weights. For this type of product, you must include the approximate weight per unit.</p>
              </div>

            )}
            >
              <i id="brand-tip">
                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
              </i>
    </Tippy></span>
    </label>
</div>
{/* <i className="ms-1" id="package-tip">
  <svg stroke="red" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="2.5"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" stroke-width="2.5" stroke-linecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
</i>   */}


<div className="form-check ms-4">
    <input checked={unitmeasure2 == 'both'}  onClick={() => setUnitMeasure2('both')} value="both" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
    <label className="form-check-label">weight &amp; package
    
    <span>                    <Tippy
            placement='left'
            theme='tomato'
            render={attrs => (
              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                  <p style={{fontSize: 14}}>some products may be requested by the
customer either by bulk (1 kg of apples) or by units (5 apples). Check this option if the product can be ordered by weight or unit.</p>
              <ul style={{marginTop: 5}}>
                <li  style={{fontSize: 14}}>If that is the case, do not forget to include the products approximate weight per unit.</li>
              </ul>
              </div>

            )}
            >
              <i id="brand-tip">
                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
              </i>
    </Tippy></span>
    </label>
</div>


</div>

{/* inputs unitmeasure2 */}
<div className='mt-3'>
  {unitmeasure2 == 'bulk' ?
  (
    <>
      <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
      <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
    </>
  )
  : unitmeasure2 == 'package' ?
    (
      <>
        <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
        <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

        <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
        <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
      </>
    )
  : unitmeasure2 == 'both' ? (
    <>
    <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
    <input value={prixbulk1} onChange={e => setPrixbulk1(e.target.value)} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
  
    <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
    <input value={prixpack1} onChange={e => setPrixpack1(e.target.value)} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

    <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
    <input value={weightpack1} onChange={e => setWeightpack1(e.target.value)} min="1" name="unit" className="form-control w-100" type="number" />
    </>
    ) : null
  }
</div>
{/* inputs unitmeasure2 */}

</div>


</>

)}





       {/* product tags */}
       <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}}  className=" mt-5 ">
           <div className="d-flex w-100 mt-4 justify-content-between align-items-center">
                    <label className="form-label">   Product tags <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span></label>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                                  <p>This information is optional, but it can be important for the Shopper to know if there’s any product that needs to be treated with special care.
</p>
                              
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                </div>
                </div>
           <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%',paddingBottom:'5%'}}  >
         
      <div className="row ms-0 me-0" >
        <div className="col-sm-6">
          <div onClick={() =>
          {
            if(hasBarcode){
              console.log()
            }else{
              addTag(!tags.includes("Frozen"), 'Frozen')
            }
          }
          }  className="tag-container rounded mt-4 bg-primary d-flex align-items-center px-3" style={{ height: '100px' }}>
            <input  checked={tags.includes("Frozen")} onChange={(e) => addTag(e.target.checked, 'Frozen')} 
            value="Frozen" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Frozen</h6>
              <p>Example: Ice</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => 
           {
            if(hasBarcode){
              console.log()
            }else{
              addTag(!tags.includes("Alcohol"), 'Alcohol')
            }
          }
            
            } className="bg-danger mt-4 d-flex align-items-center px-3 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(121 55 89 / 80%) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input  checked={tags.includes("Alcohol")} onChange={(e) => addTag(e.target.checked, 'Alcohol')}  value="Alcohol" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Alcohol</h6>
              <p>Example: Beer</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => 
           {
            if(hasBarcode){
              console.log()
            }else{
              addTag(!tags.includes("Fragile"), 'Fragile')
            }
          }
            
            } className="d-flex align-items-center px-3 mt-4 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(48 206 62) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input  checked={tags.includes("Fragile")} onChange={(e) => addTag(e.target.checked, 'Fragile')}  value="Fragile" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Fragile</h6>
              <p>Example: Cake</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => 
           {
            if(hasBarcode){
              console.log()
            }else{
              addTag(!tags.includes("Tobacco"), 'Tobacco')
            }
          }
          
            } className="tag-container rounded d-flex align-items-center px-3 mt-4" style={{ height: '100px', background: 'linear-gradient(to right, rgb(206 48 160) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input  checked={tags.includes("Tobacco")} onChange={(e) => addTag(e.target.checked, 'Tobacco')}  value="Tobacco" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Tobacco</h6>
              <p>Example: Cigarette</p>
            </div>
          </div>
        </div>
      </div>
    </div>

           {/* product tags */}
     
          </div>
        </div>
         {/* first div */}
         <div style={{width:'45%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Preview</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <img src={props.product.img?props.product.img:''} className='modal2' />
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Name</p>
                <p style={{marginLeft:'2%',color:'gray',overflow:'hidden'}}>{name?name:'Product name'}</p>
                </div>
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Format</p>
                <p style={{marginLeft:'2%',color:'gray'}}>{format?format:'Product format'}</p>
                </div>
            </div>
        </div>
        
    </div>
       }


</div>


</Modal>

<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show3} onHide={handleClose3}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h5 style={{margin:0,marginLeft:10}}>Edit Product</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button disabled={mainVariant?true:false}  onClick={()=>{
                                      setShow3(false)
                          
                                    }} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn"
>Close</button>
                                    <button disabled={mainVariant?true:false} onClick={()=>{
                                handleSubmit3()
                             
                                    }} data-bs-dismiss="modal" style={{marginLeft:5}} className="btn btn-outline-primary custom-btn"
>Save</button>
                                      </div>
            </div>
       

        </div>
        {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
       {mainVariant?
        <div className="spinner-border" style={{display:'flex',marginTop:250,justifyContent:'center',alignItems:'center'}} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        :
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'95%',marginBottom:'5%'}}>
       
        {/* second div */}
        <div style={{width:'70%',height:'80vh', overflowY: 'auto',paddingBottom:100}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Information</p>
          
        <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',}}>
          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Name</p>
            <input value={name}  onChange={(e)=>setName(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div  style={{width:'95%'}} className="d-flex mt-4 justify-content-between align-items-center">
                            <label className="form-label">Brand</label>
                            <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{background: '#f1f1f1', color: 'black', maxWidth: 300}}>
                                <strong className="text-black" style={{fontFamily: 'Poppins-SemiBold', fontSize: '12px'}}>IMPORTANT:</strong>
                                <p style={{fontSize: '13px'}}>This is the manufacturer's brand. If the product is produced by your store, you do not need to enter a brand.</p>
                                <p style={{fontSize: '13px', marginTop: '5px'}}>If the brand you are looking for does not exist, you can create one. Remember to take into consideration the following tips</p>
                                <ul className="myul">
                                  <li>Use capital letters only for brand names or at the beginning of the name.</li>
                                </ul>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                            </Tippy>
                            </div>
                        
                            <div style={{width:'95%'}}>
                    <div className="rounded" style={{ backgroundColor: 'white',cursor:hasBarcode?'not-allowed':'', padding: '10px 0' }}>

                    <Select
                    isDisabled={hasBarcode?true: newBrand}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti={false}
                    options={brands}
                    onChange={(e) => setSelectedBrand(e)}
                    value={selectedBrand}
                    blurInputOnSelect={true}
                    styles={{
                      container: (styles) => ({...styles, border: 0}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none'}),
                    }}
                    />

                    </div>
                    </div>

                    <div style={{width:'95%',marginLeft:5}} className="mb-2 form-check mt-2">
                        <input  checked={newBrand} onChange={(e) => setNewBrand(e.target.checked)} className="form-check-input brand-check" type="checkbox" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                        Add new brand instead
                        </label>
                    </div>

                    {newBrand &&
                    <div style={{width:'95%',marginLeft:5}} className="mt-2 mb-2 new-brand" >
                        <input value={newBrandName} onChange={(e) => setNewBrandName(e.target.value) } type="text" name="brandx" placeholder="Enter new brand name" className="form-control" />
                    </div>
                    }
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{width:'95%'}}>
          <div  style={{width:'95%'}} className="d-flex mt-4 justify-content-between align-items-center">
                            <label className="form-label">Category</label>
                            </div>
                <div className="rounded" style={{ backgroundColor: 'white',cursor:hasBarcode?'not-allowed':'', padding: '10px 0' }}>
                    <Select
                    is
                    closeMenuOnSelect={false}
                    components={{GroupHeading}}
                    filterOption={createFilter(filterConfig)}
                    isMulti={false}
                    options={cats}
                    onChange={(e) => setSelectedCat(e)}
                    value={selectedCat}
                    blurInputOnSelect={true}
                    //styles={colourStyles}
                    styles={{
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? 'white' : isFocused ? '#f1f1f1' : 'white',
                        color: isSelected ? 'black' : 'black',
                        fontFamily: isSelected ? 'Poppins-SemiBold' : '500',
                        height: 55,
                        margin:0,
                       
                        // margin: '0 15px 15px 15px',
                        display: 'flex',
                        alignItems: 'center', // Center vertically
                        borderBottom:'1px solid #f3f3f3',
                      
                        // justifyContent: 'center', // Center horizontally
                      }),
                      container: (styles) => ({...styles, border: 0,color:'black'}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none',}),
                      groupHeading: (base) => ({
                        ...base,
                        flex: '1 1',
                        color: 'white',
                        fontSize: 15,
                        margin: 100,

                      })
                    }}
                    />
                </div>
                 </div>
          
         

          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
         
     <label className="form-label mt-4 w-100">Images</label>
                    
                    <div id="main-img-div" style={{width:'98%'}} className="rounded border p-2">
                              
                              <div className="border-bottom pb-2">
                                <label htmlFor="img1" className="w-100 label-input">
                                  {hasBarcode?null:
                                  <input
                                  onChange={handleImageChange}
                                 
                                  required
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  type="file"
                                  name="img1"
                                  id="img1"
                                  //onChange={handleImg1Change}
                              />
                                  }
                                  
                                 {ErrorImg?
                                <Alert dismissible key={'danger'} variant={'danger'}>
                                 <div style={{display:'flex',alignItems:'center'}}>
                                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#ff0000" stroke="none">
<path d="M1555 5115 c-5 -2 -23 -6 -40 -10 -23 -5 -210 -185 -753 -728 -398
-397 -731 -735 -740 -752 -16 -28 -17 -113 -17 -1055 0 -564 4 -1038 8 -1055
6 -21 219 -241 730 -753 397 -398 736 -731 752 -740 27 -16 116 -17 1045 -20
558 -2 1031 0 1050 3 30 5 139 110 769 738 425 424 739 746 747 764 12 27 14
201 14 1052 0 685 -3 1029 -10 1048 -14 38 -1455 1481 -1497 1499 -27 12 -197
14 -1040 13 -554 0 -1012 -2 -1018 -4z m1276 -1078 c19 -12 43 -40 54 -62 l19
-40 -66 -883 c-73 -969 -66 -922 -138 -969 -29 -20 -46 -23 -140 -23 -94 0
-111 3 -140 23 -72 48 -66 4 -136 930 -35 463 -64 865 -64 892 0 59 25 106 72
135 30 18 51 20 269 20 229 0 237 -1 270 -23z m-165 -2394 c67 -23 154 -110
177 -177 40 -117 14 -231 -72 -317 -72 -71 -132 -94 -235 -87 -82 6 -133 28
-189 85 -67 66 -82 105 -82 213 0 78 4 102 22 135 38 71 112 134 183 156 45
13 145 9 196 -8z"/>
</g>
</svg>
                       <p style={{marginLeft:10}}>{ErrorImg}</p>
                                 </div>
                              </Alert>:null}
                             
                                  <div className="d-flex align-items-center" style={{ cursor:hasBarcode?'not-allowed': 'pointer' }}>
                                        <div>
                                          <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                            {!image ?
                                            <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                             : 
                                             <img alt='img1' className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} src={!imgchanged?image: URL.createObjectURL(image)}></img>
                                             }
                                          </div>
                                        </div>

                                        <div className="d-flex flex-column justify-content-center ms-2">
                                          <p>{ImageName?ImageName:'Image 1'}</p>
                                          <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>{ImageSize?Number(ImageSize*1000).toFixed(0)+'KB':'Choose...'}</span>
                                        </div>
                                  </div>
                                </label>
                              </div>

                             

                            </div>
                            <div className="mt-1 w-75">
                            <p style={{textAlign:'left',color:'#8e8e93',fontSize:13}}>Learn <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8479767-what-to-consider-when-creating-a-non-variant-products' style={{fontSize:13,color:'#007aff'}}>Best practices for uploading images.</a> You can arrange them any way you want them to show in the app.</p>
                            </div>


      <label style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}} className="form-label mt-4 w-98">Featured</label>
      <select value={featured} onChange={(e) => setFeatured(e.target.value)} style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}} className="form-select mb-4 " name="highlight" id="status">
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
          </div>
        </div>
         {/* first div */}
         <div style={{width:'45%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Preview</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <img src={prodinfo?.img} className='modal2' />
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Name</p>
                <p style={{marginLeft:'2%',color:'gray',overflow:'hidden'}}>{name?name:'Product name'}</p>
                </div>
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Format</p>
                <p style={{marginLeft:'2%',color:'gray'}}>{format?format:'Product format'}</p>
                </div>
            </div>
        </div>
    </div>
}

</div>


</Modal>
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show4} onHide={handleClose4}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h5 style={{margin:0,marginLeft:10}}>Edit Product</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button disabled={variantloader?true:false} onClick={()=>{
                                      setShow4(false)
                          
                                    }} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn"
>Close</button>
                                    <button disabled={variantloader?true:false} onClick={()=>{
                      handleSubmit4()
                             
                                    }} data-bs-dismiss="modal" style={{marginLeft:5}} className="btn btn-outline-primary custom-btn"
>Save</button>
                                      </div>
            </div>
       

        </div>
        {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
       {variantloader?
        <div className="spinner-border" style={{display:'flex',marginTop:250,justifyContent:'center',alignItems:'center'}} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        :
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'95%',marginBottom:'5%'}}>
       
        {/* second div */}
        <div style={{width:'70%',height:'80vh', overflowY: 'auto',paddingBottom:100}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Information</p>
          
        <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',}}>
          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Name</p>
            <input value={name}  onChange={(e)=>setName(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>

          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>SKU (required)</p>
            <input value={sku} onChange={(e)=>setSku(e.target.value)} style={{border:'none',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',cursor:hasBarcode?'not-allowed':'',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>price (required)</p>
            <input value={prixunit1}  onChange={(e)=>setPrixunit1(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',cursor:hasBarcode?'not-allowed':'',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Size</p>
            <input value={size}  onChange={(e)=>setSize(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>

          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
          <div style={{height:55,justifyContent:'center',cursor:hasBarcode?'not-allowed':'',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Description</p>
            <input value={des}  onChange={(e)=>setDes(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
          <div style={{height:55,justifyContent:'center',cursor:hasBarcode?'not-allowed':'',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Weight</p>
            <input value={weight}  onChange={(e)=>setWeight(e.target.value)} style={{border:'none',cursor:hasBarcode?'not-allowed':'',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

     <label className="form-label mt-4 w-100">Images</label>
                    
                    <div id="main-img-div" style={{width:'98%'}} className="rounded border p-2">
                              
                              <div className="border-bottom pb-2">
                                <label htmlFor="img1" className="w-100 label-input">
                                  {hasBarcode?null:
                                  <input
                                  onChange={handleImageChange}
                                 
                                  required
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  type="file"
                                  name="img1"
                                  id="img1"
                                  //onChange={handleImg1Change}
                              />
                                  }
                                  
                                 {ErrorImg?
                                <Alert dismissible key={'danger'} variant={'danger'}>
                                 <div style={{display:'flex',alignItems:'center'}}>
                                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#ff0000" stroke="none">
<path d="M1555 5115 c-5 -2 -23 -6 -40 -10 -23 -5 -210 -185 -753 -728 -398
-397 -731 -735 -740 -752 -16 -28 -17 -113 -17 -1055 0 -564 4 -1038 8 -1055
6 -21 219 -241 730 -753 397 -398 736 -731 752 -740 27 -16 116 -17 1045 -20
558 -2 1031 0 1050 3 30 5 139 110 769 738 425 424 739 746 747 764 12 27 14
201 14 1052 0 685 -3 1029 -10 1048 -14 38 -1455 1481 -1497 1499 -27 12 -197
14 -1040 13 -554 0 -1012 -2 -1018 -4z m1276 -1078 c19 -12 43 -40 54 -62 l19
-40 -66 -883 c-73 -969 -66 -922 -138 -969 -29 -20 -46 -23 -140 -23 -94 0
-111 3 -140 23 -72 48 -66 4 -136 930 -35 463 -64 865 -64 892 0 59 25 106 72
135 30 18 51 20 269 20 229 0 237 -1 270 -23z m-165 -2394 c67 -23 154 -110
177 -177 40 -117 14 -231 -72 -317 -72 -71 -132 -94 -235 -87 -82 6 -133 28
-189 85 -67 66 -82 105 -82 213 0 78 4 102 22 135 38 71 112 134 183 156 45
13 145 9 196 -8z"/>
</g>
</svg>
                       <p style={{marginLeft:10}}>{ErrorImg}</p>
                                 </div>
                              </Alert>:null}
                             
                                  <div className="d-flex align-items-center" style={{ cursor:hasBarcode?'not-allowed': 'pointer' }}>
                                        <div>
                                          <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                            {!image ?
                                            <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                             : 
                                             <img alt='img1' className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} src={!imgchanged?image: URL.createObjectURL(image)}></img>
                                             }
                                          </div>
                                        </div>

                                        <div className="d-flex flex-column justify-content-center ms-2">
                                          <p>{ImageName?ImageName:'Image 1'}</p>
                                          <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>{ImageSize?Number(ImageSize*1000).toFixed(0)+'KB':'Choose...'}</span>
                                        </div>
                                  </div>
                                </label>
                              </div>

                              {otherImages.map((otherimg,i) => {
                                return (
                                  <div className={`${i < 3 ? 'border-bottom pb-2' : ''}`}>
                                  <label htmlFor={"img" + i+2} className="w-100 label-input">
                                    {hasBarcode?null:
                                    <input
                                    onChange={e => handleOtherImageChange(e, otherimg,i)}
                                   // onChange={e => setOtherImages(otherImages.map((oi) => {
                                   //   if(oi.key == otherimg.key) return {...oi, value: e.target.files[0]}
                                   //   else return oi;
                                   // }))}
                                   required
                                   accept="image/*"
                                   style={{ display: 'none' }}
                                   type="file"
                                   name={"img" + i+2}
                                   id={"img" + i+2}
                                   
                               />
                                    }
                                    
                                    <div className="d-flex align-items-center justify-content-between" style={{ cursor:hasBarcode?'not-allowed': 'pointer' }}>
                                      <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>

                                          <div>
                                            <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                              {!otherimg.value ?
                                              <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                               : 
                                               i==0?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img2changed?img2: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>
                                               :i==1?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img3changed?img3: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>
                                               :i==2?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img4changed?img4: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>:
                                               i==3?
                                               <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} 
                                               //src={otherimg.value.includes("https:")?otherimg.value: URL.createObjectURL(otherimg.value)}
                                               src={!img5changed?img5: URL.createObjectURL(otherimg.value)}
                                               
                                               ></img>:null

                                               }
                                               
                                            </div>
                                          </div>
  
                                          <div className="d-flex flex-column justify-content-center ms-2">
                                            <p>{ImagesName[i]?ImagesName[i]:`Image ${i+2}`}</p>
                                            <span className='m-0' style={{ color: 'gray', fontSize: '13px' }}>{ImagesSize[i]?Number(ImagesSize[i]*1000).toFixed(0)+"KB":`Choose...`}</span>
                                          </div>

                                      </div>

                                         {hasBarcode?null:
                                          <div onClick={() => {
                                            if(i==0){
                                              setImg22changed(true)
                                              if(img3){
                                                setImg2(img3)
                                              }
                                              if(img4){
                                                setImg3(img4)
                                              }
                                              if(img5){
                                                setImg4(img5)
                                              }
                                            }
                                            if(i==1){
                                              setImg33changed(true)
                                              if(img4){
                                                setImg3(img4)
                                              }
                                              if(img5){
                                                setImg4(img5)
                                              }
                                            }
                                            if(i==2){
                                              setImg44changed(true)
                                              if(img5){
                                                setImg4(img5)
                                              }
                                            }
                                            if(i==3){
                                              setImg55changed(true)
                                              setImg5('')
                                            }
                                            // if(i==0){
                                            //   setImg22changed(true)
                                            //   if(img3){
                                            //     setImg2(img3)
                                            //   }
                                            // }
                                            // if(i==1){setImg33changed(true)
                                            //   if(img4){
                                            //     setImg3(img4)
                                            //   }
                                            // }
                                            // if(i==2){setImg44changed(true)
                                            //   if(img5){
                                            //     setImg4(img5)
                                            //   }
                                            // }
                                            // if(i==3){
                                            //   setImg55changed(true)
                                            //   setImg5('')
                                            // }
                                            setOtherImages(otherImages.filter(x => x.key !== otherimg.key))
                                          
                                          }} class="close-img">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                                      </div>}
                                    </div>
                                  </label>
                                </div>
                                )
                                })}
                              

                            
                           {hasBarcode?null:
                           <>
                            { otherImages.length < 4 &&

<div onClick={() => {
  setOtherImages([...otherImages, {key: Math.random(), value: null,}]);
}} 
id="add-img" 
className="p-2 mt-2 justify-content-between align-items-center" 
style={{ display: 'flex', cursor: 'pointer' }}
>
    <div className="d-flex align-items-center">
      <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
    </svg>
    <p className="m-0" style={{marginLeft:5}}>Add another image</p>

    </div>
    <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
    <title>plus-circle</title>
    <desc>Created with Sketch Beta.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
    <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
        <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup"></path>
    </g>
    </g>
</g>
</svg>
</div>
}
                           </>
                           }

                            </div>
                            <div className="mt-1 w-75">
                            <p style={{textAlign:'left',color:'#8e8e93',fontSize:13}}>Learn <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8479767-what-to-consider-when-creating-a-non-variant-products' style={{fontSize:13,color:'#007aff'}}>Best practices for uploading images.</a> You can arrange them any way you want them to show in the app.</p>
                            </div>





    
          </div>
        </div>
         {/* first div */}
         <div style={{width:'45%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Preview</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <img src={prodinfo?.img} className='modal2' />
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Name</p>
                <p style={{marginLeft:'2%',color:'gray',overflow:'hidden'}}>{name?name:'Product name'}</p>
                </div>
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Format</p>
                <p style={{marginLeft:'2%',color:'gray'}}>{format?format:'Product format'}</p>
                </div>
            </div>
        </div>
    </div>
}

</div>


</Modal>

    <div className="product-item-container mt-3 rounded">
    {showAlerts()}
    <div
      onClick={(e) => {
        if(product.img){
          filldata()
          setShow(true)
        }else{
          filldata()
          setShow(true)
        }
       
      }}
      className="d-flex align-items-center"
      style={{ height: '80px', cursor: 'pointer' }}
    >
      <div className="product-image-container border">
        <img src={product.img?product.img:product.image} alt="product" />
      </div>

      <div className="product-name-container px-3" style={{ flex: '0.93' }}>
        <p className='prodname m-0'>{product.prodbrand ? product.prodbrand.name + '・' : ''}{product.name}</p>
        <span >{product.barcode}</span>
      </div>
    </div>

  
  </div>
  </>
  )
}
