import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';

export default function EditStoreLimiter() {
    const navigate =  useNavigate()
    const {id,storeid} = useParams();
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState(1);
    const [amounttype, setAmountType] = useState(1);
    const EditLimit = async () => {
        const {status, data, msg} = await sendData('/limiters/edit', JSON.stringify({id,from,to,amount,type,amount_type:amounttype,store_id:storeid}));
        if(status){
         navigate(-1)
        }
      }

      const GetLimiter = async ()=>{
        const {status, data, msg} = await fetchData(`/limiters/${id}`);
        if(status){
            setTo(data.tor)
            setFrom(data.fromm)
            setAmount(data.amountt)
            setType(data.type)
            setAmountType(data.amount_type)
            
         }
    }
    useEffect(()=>{
        GetLimiter()
    },[])
  return (
    <div style={{marginTop:'5%',marginLeft:'1%'}}>
             <label style={{marginLeft:10}} className="form-label mt-4">Type <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={type} onChange={(e)=>setType(e.target.value)} required name="code" className="form-control"  >

  <option value={1}>Delivery Fee</option>
  <option value={2}>Small Order Fee</option>
  <option value={3}>Preperation Fee</option>
  <option value={4}>Service Fee</option>


</select>

</div>
<label style={{marginLeft:10}} className="form-label mt-4">Type <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={amounttype} onChange={(e)=>setAmountType(e.target.value)} required name="code" className="form-control"  >

  <option value={1}>Fixed</option>
  <option value={2}>Percentage</option>
  <option value={3}>Charge Of Service</option>


</select>

</div>
    <label className="form-label mt-4">From</label>
      <input min={0} type="number" className="form-control"  name="slogan" value={from} 
      onChange={(e) => {setFrom(e.target.value?Number(e.target.value):'')}}
      />
       <label className="form-label mt-4">To</label>
      <input  min={0}  type="number" className="form-control"  name="slogan" value={to} 
      onChange={(e) => {setTo(e.target.value?Number(e.target.value):'')}}
      />
       <label className="form-label mt-4">Amount</label>
      <input  min={0}  type="number" className="form-control"  name="slogan" value={amount} 
      onChange={(e) => {setAmount(e.target.value?Number(e.target.value):'')}}
      />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={EditLimit}>Save</Button>

  </div>
  )
}
