import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';

export default function PromotionsDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [name, setName] = useState('');
    const [namex, setNamex] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [file, setFile] = useState('');
    const [file2, setFile2] = useState('');
    const [color, setColor] = useState('#000000');
    const [color2, setColor2] = useState('#000000');
    const [typ, setTyp] = useState('');
    const [perc, setPerc] = useState('');
    const [des, setDes] = useState('');
    const [prixmax, setPrixmax] = useState('');
    const [Errors, setErrors] = useState([]);
    const [error, setError] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imgchanged, setImgchanged] = useState(false);
    const [img2changed, setImg2changed] = useState(false);
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
            setTyp(selectedValue)
      };
    const GetPromotionDetails = async ()=>{
        const {status, data, msg} = await fetchData(`/promotions/${id}`);
        if(status){
             setName(data.name)
             setNamex(data.namex)
             setDes(data.des)
             const dateObj = new Date(data.from);

            // Format the date to YYYY-MM-DD
            const formattedDate = dateObj.toISOString().split('T')[0];

            // Set the formatted date to your state
            setFrom(formattedDate);
            const dateObj1 = new Date(data.to);

            // Format the date to YYYY-MM-DD
            const formattedDate1 = dateObj1.toISOString().split('T')[0];

            // Set the formatted date to your state
            setFrom(formattedDate);
             setTo(formattedDate1)
             setFile(data.img)
             setFile2(data.img2)
             setColor(data.color)
             setColor2(data.color2)
             setTyp(data.typ)
             setPerc(data.perc)
             setPrixmax(data.prixmax)
            setLoading(false)
         }
    }
    useEffect(()=>{
        GetPromotionDetails()
    },[])
    const isFormValid = () => {
        let x=[]
 
     // Validate string fields
     if (!name) x.push('Name is required');
     if (!namex) x.push('namex is required');
     if (!from) x.push('from is required');
     if (!to) x.push('to is required');
     if (!file) x.push('Logo is required');
     if (!file2) x.push('Banner is required');
     if (!color) x.push('Logo Color is required');
     if (!color2) x.push('Banner color is required');
     if (!typ) x.push('Promotion Type is required');

   
 
  
   

     if (x.length > 0) {
       console.log('Validation Errors:', x);
       setErrors(x)
       return false;
     }
   if(x.length==0){
     setErrors([])
     return true;
   }
     // If all validations pass, return true


   };
const handleSubmit = async() => {
     setErrors([])


     if (isFormValid()) {
       setLoading(false)
           setError(false)
       try {
 
        
         const formData = new FormData();
         formData.append('id', id);
          formData.append('name', name);
          formData.append('namex', namex);
          formData.append('from', from);
          formData.append('to', to);
          formData.append('img', file);
          formData.append('img2', file2);
          formData.append('imgchanged', imgchanged?1:0);
          formData.append('img2changed', img2changed?1:0);
          formData.append('color', color);
          formData.append('color2', color2);
          formData.append('typ', typ);
          formData.append('perc', perc);
          formData.append('prixmax', prixmax);
          formData.append('des', des);
          
         
         
        
    
       const req = await fetch("https://api.trakitrak.com/control/promotions/edit", {
         method:'POST',
         body: formData,
 })
 if(req.status==200){
     const json = await req.json()
     console.log(json)

     setLoading(true)
     navigate(`/promotions`, {replace: true});

 }else{
     const json = await req.json()
     console.log(json) 
    //  setShowalert(true)
    //  setMsg(json.message)
      setLoading(true)
 }
       } catch (error) {
         
       }
    
     } else {
         setError(true)

     }
   };
  return (
    <div>
              {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:

      <div style={{marginTop:'5%',marginLeft:'1%',overflow:'scroll',height:'95vh',paddingBottom:50}}>
         {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
     {/* <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Promotions</p>
        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={()=>navigate(`/promotion-products/${id}`)}>Add Products</Button>

   </div> */}
        <label className="form-label mt-4">Name</label>
<input  className="form-control" type="text" id="name" value={name} onChange={e => setName(e.target.value)} />

<label className="form-label mt-4">NameX</label>
<input  className="form-control" type="text" id="namex" value={namex} onChange={e => setNamex(e.target.value)} />
<label className="form-label mt-4">Description</label>
<input  className="form-control" type="text" id="namex" value={des} onChange={e => setDes(e.target.value)} />


<label className="form-label mt-4">From (Date)</label>
<input  className="form-control" type="date" id="from" value={from} onChange={e => setFrom(e.target.value)} />

<label className="form-label mt-4" htmlFor="to">To (Date)</label>
<input  className="form-control" type="date" id="to" value={to}  min={from} onChange={e => setTo(e.target.value)} />

{/* <label className="form-label mt-4" htmlFor="file">File (Image)</label>
<input  className="form-control" type="file" id="file" onChange={e => {setFile(e.target.files[0]);setImgchanged(true)}} />
<img className="form-control" src={imgchanged?URL.createObjectURL(file):file} style={{width:100,height:100,objectFit:'contain',marginTop:10,backgroundColor:color}} />

<label className="form-label mt-4" htmlFor="file2">File 2 (Image)</label>
<input  className="form-control" type="file" id="file2" onChange={e => {setFile2(e.target.files[0]);setImg2changed(true)}} />
<img className="form-control" src={img2changed?URL.createObjectURL(file2):file2} style={{width:'80vw',height:150,objectFit:'contain',marginTop:10,backgroundColor:color2}} /> */}


<label className="form-label mt-4">Logo <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div>
<div className="bg-primary rounded d-flex p-2">
<svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
</svg>
<div style={{ width: '95%', color: 'white' }}>
  <p>We're going to generate a black and white version of the logo. For this reason, it must meet some requirements:</p>
  <ol>
    <li>It must be in PNG format.</li>
    <li>It must have a transparent background.</li>
    <li>Its width must be at least 1,000 px.</li>
  </ol>
</div>
</div>

<div className="d-flex mt-3 rounded my-shadow w-75 m-auto" style={{ position: 'relative' }}>
<div className="camera-div">
  <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="logo">
    <input required name="logo" id="logo" type="file" accept="image/*" className="d-none" onChange={e => {setFile(e.target.files[0]);setImgchanged(true)}} />
    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
      
      <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
    </svg>
  </label>
</div>

<div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#b2b2b2', width: '50%',  borderTopRightRadius: '0 !important', borderBottomRightRadius: '0 !important' }}>

{file ?(

<div style={{justifyContent:'center',backgroundColor:color,alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
  <img style={{width:'70%',height:'70%',objectFit:'contain',filter: 'brightness(1000%)'}} src={ imgchanged?URL.createObjectURL(file):file} alt="Logo Preview" />
</div>
):
<svg xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">

<path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
</svg>
}
 

</div>

<div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#f8f8f9', width: '50%',  borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0 !important' }}>
{file ?(
<div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
 <img style={{width:'70%',height:'70%',objectFit:'contain',filter: 'brightness(0%)'}} src={imgchanged?URL.createObjectURL(file):file} alt="Logo Preview" />
</div>
):
  <svg style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">
    <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
  </svg>
}
</div>

</div>
<div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
<Button style={{marginRight:20}} onClick={()=>{ window.open(imgchanged?URL.createObjectURL(file):file, '_blank');}}>
  View Image
</Button>
</div>
</div>
<label className="form-label mt-4" htmlFor="color2">Logo Background Color</label>
<input  className="form-control" type="color" id="color" value={color} onChange={e => setColor(e.target.value)} />




<label className="form-label mt-5">Banner <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div>
<div className="bg-primary rounded d-flex p-2">
<svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
</svg>
<div style={{ width: '95%', color: 'white' }}>
  <p>The banner needs to match the following conditions:</p>
  <ol>
    <li>The width must be at least 1296px.</li>
    <li>The height must be at least 540px.</li>
  </ol>
</div>
</div>

<div className="mt-3 logodiv22" style={{ width: '90%',marginLeft:'5%',  backgroundColor: '#b2b2b2', position: 'relative' }}>
<div id="banner_cameradiv" className="camera-div" style={{zIndex:10}}>
  <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="banner">
    <input required name="banner" id="banner" type="file" accept="image/*" className="d-none" onChange={e => {setFile2(e.target.files[0]);setImg2changed(true)}} />
    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
      <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
    </svg>
  </label>
</div>

<div className="rounded d-flex justify-content-center align-items-center bannerdiv" style={{ backgroundColor: color2, width: '100%', height: '100%' }}>
{file2 &&(
<div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%',}}>
 <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(100%)'}} src={ img2changed?URL.createObjectURL(file2):file2} alt="Logo Preview" />
</div>
)}
</div>
</div>


<div style={{display:'flex',marginTop:10,alignItems:'center',justifyContent:'flex-end'}}>
<Button style={{marginRight:20}} onClick={()=>{ window.open(img2changed?URL.createObjectURL(file2):file2, '_blank');}}>
  View Image
</Button>
</div>

</div>




<label className="form-label mt-4" htmlFor="color2">Banner Background Color</label>
<input  className="form-control" type="color" id="color2" value={color2} onChange={e => setColor2(e.target.value)} />

<label className="form-label mt-4" htmlFor="typ">Type</label>
<select className="form-control" value={typ} onChange={handleSelectChange}>
<option value="" disabled>Select an option</option>
<option  value={'price'}>Price</option>
<option  value={'percentage'}>Percentage</option>
</select>

<label className="form-label mt-4" htmlFor="perc">Percentage</label>
<input disabled={typ=='percentage'?false:true}   className="form-control" type="number" id="perc" value={perc} onChange={e => setPerc(e.target.value)} />

<label className="form-label mt-4" htmlFor="prixmax">Maximum Price</label>
<input disabled={typ=='price'?false:true}  className="form-control" type="number" id="prixmax" value={prixmax} onChange={e => setPrixmax(e.target.value)} />


<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleSubmit}>Save</Button>

    </div>
}
    </div>
  )
}
