import React, {useRef,useState,useEffect} from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { hasJWT } from "./AuthedRoute";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import logo from "../trakitrak_logo.webp";
import logo1 from "../whittelogo.png";
import Store from "../icons/Store";
import Shopper from "../icons/Shopper";
import User from "../icons/User";
import Product from "../icons/Product";
import Order from "../icons/Order";
import BankIcon from "../icons/BankIcon";
import Settingsicon from "../icons/Settingsicon";
import { IoDocumentLock, IoSettingsSharp ,IoStorefront} from "react-icons/io5";
import { TbCategoryFilled } from "react-icons/tb";
import { FaCity, FaClock, FaRegCalendarAlt, FaSearch } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { FiClock } from "react-icons/fi";
import { MdPayments } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaListCheck } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdDisplaySettings,MdDeliveryDining } from "react-icons/md";
import { RiQuoteText } from "react-icons/ri";
import { MdOutlineAttachMoney } from "react-icons/md";
import { TbBrandSuperhuman } from "react-icons/tb";
import { IoIosHelpCircle } from "react-icons/io";
import { MdLocalOffer } from "react-icons/md";

const menuStyle = {
  SubMenuExpandIcon: {
    color:'white',
  },
  subMenuContent: {
    backgroundColor:'#e05b0e',
    
  },
  button:{
    '&:hover' : {
      backgroundColor: 'rgba(219,144,115,0.47)'
    },
    fontSize:15
  }
}

function ProtectedRoute({ component: Component, ...params }) {
const location = useLocation()
const { hash, pathname, search } = location;
  const mainDivRef = useRef(null);
  const [isopen, setIsopn] = useState(true);
  const scrollToTop = () => {
    if(mainDivRef.current){
      mainDivRef.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
  useEffect(() => {
    // Function to handle the resize event
    const handleResize = () => {
      // Set sidebar collapsed state based on window width
      if(window.innerWidth < 768){
        setIsopn(false);
      }
     
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call the resize function to set initial state
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (hasJWT())
    return (
        <div style={{display: 'flex', height: '100%'}}>

            <Sidebar
            collapsed={!isopen?true:false}
            toggled={true}
            breakPoint="md"
            backgroundColor={'#e05b0e'}
            collapsedWidth="5vw"
            rootStyles={{
              color: 'white',
              height: '100vh',
              width:'20vw'
            }}
            >

              <div style={{ display: 'flex', flexDirection: 'column', height: '100%'}} >
                
                {/* Sidebar  */}
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <img src={!isopen?logo1:logo} alt="trakitrak logo" style={{width: '100%', height: 35, objectFit: 'contain'}} />
                </div>

                <div style={{flex: 1, marginBottom: 32}}>

                  <div style={{margin:!isopen?'20px 20px 0 10px': '20px 20px 0 20px'}}>
                    <p style={{opacity: 0.7, fontSize:!isopen?12: 14, fontWeight: 'bold'}}>General</p>
                  </div>

                  <Menu menuItemStyles={menuStyle}>

                    <SubMenu label="Stores" icon={<Store />}>
                      <MenuItem component={<Link to={'/stores/pending'}/>}>Pending</MenuItem>
                      <MenuItem component={<Link to={'/stores/approved'}/>}>Approved</MenuItem>
                      <MenuItem component={<Link to={'/stores/rejected'}/>}>Rejected</MenuItem>
                    </SubMenu>
                    <MenuItem label="Shoppers" icon={<Shopper />} component={<Link to={'/shoppers'}/>}>Shoppers</MenuItem>
                    {/* <SubMenu label="Shoppers" icon={<Shopper />}>
                      <MenuItem   component={<Link to={'/shopper/pending'}/>}>Pending</MenuItem>
                      <MenuItem   component={<Link to={'/shopper/approved'}/>}>Approved</MenuItem>
                      <MenuItem   component={<Link to={'/shopper/rejected'}/>} >Blocked</MenuItem>
                    </SubMenu> */}

                    
                    <MenuItem icon={<User />} component={<Link to={'/users'}/>}>Users</MenuItem>
                   

                  </Menu>

                  <div style={{margin:!isopen?'20px 20px 0 10px': '20px 20px 0 20px'}}>
                    <p style={{opacity: 0.7, fontSize:!isopen?12: 14, fontWeight: 'bold'}}>Products</p>
                  </div>
                  <Menu menuItemStyles={menuStyle}>

                      <MenuItem icon={<Product />} component={<Link to={'/products'}/>}>My Products</MenuItem>

                  </Menu>
                  <div style={{margin:!isopen?'20px 20px 0 10px': '20px 20px 0 20px'}}>
                    <p style={{opacity: 0.7, fontSize:pathname.includes('/orders')?12: 14, fontWeight: 'bold'}}>Orders</p>
                  </div>
                  <Menu menuItemStyles={menuStyle}>

                  <MenuItem icon={<Order />} component={<Link to={'/orders'}/>}>Orders</MenuItem>

                  </Menu>
                  <div style={{margin:!isopen?'20px 20px 0 10px': '20px 20px 0 20px'}}>
                    <p style={{opacity: 0.7, fontSize:pathname.includes('/orders')?12: 14, fontWeight: 'bold'}}>Settings</p>
                  </div>
                  <Menu menuItemStyles={menuStyle} style={{paddingBottom:30}}>
                  <SubMenu  label="Settings" icon={<IoSettingsSharp size={25} />}>
                      <MenuItem icon={<BankIcon size={20} />} component={<Link to={'/banks'}/>}>Banks</MenuItem>
                      <MenuItem icon={<TbBrandSuperhuman size={20} />} component={<Link to={'/brands'}/>}>Brands</MenuItem>
                      <MenuItem icon={<TbCategoryFilled size={20} />} component={<Link to={'/product-cat'}/>}>Product Categories</MenuItem>
                      <MenuItem icon={<IoStorefront size={20} />} component={<Link to={'/store-cat'}/>}  >Store Categories</MenuItem>
                      <MenuItem  icon={<FaCity size={20}/>} component={<Link to={'/cities'}/>}>Cities</MenuItem>

                      
                    </SubMenu>
                    <SubMenu  label="Settlements" icon={<MdPayments size={25}/>}>
                      <MenuItem icon={<IoAddCircleOutline size={20} /> } component={<Link to={'/add-settelments'}/>}>Add Settlements</MenuItem>
                      <MenuItem icon={<FaListCheck size={20}/>} component={<Link to={'/settelments'}/>}>View Settlements</MenuItem>
                      <MenuItem icon={<IoAddCircleOutline size={20} />} component={<Link to={'/add-shopper-settelments'}/>}  >Add Shopper Settlements</MenuItem>
                      <MenuItem  icon={<FaListCheck size={20}/>} component={<Link to={'/shopper-settelments'}/>}>View Shopper Settlements</MenuItem>
                      <MenuItem  icon={<GiTakeMyMoney size={20}/>} component={<Link to={'/shopper-depoists'}/>}>Shopper Depoists</MenuItem>
                      
                    </SubMenu>
                   

                    <SubMenu  label="Utils" icon={<MdDisplaySettings size={25}/>}>
                      <MenuItem icon={<MdDeliveryDining size={20} /> } component={<Link to={'/deliveryfees'}/>}>Limiters</MenuItem>
                      <MenuItem icon={<IoAddCircleOutline size={20} />  } component={<Link to={'/add-delivery-fees'}/>}>Add Limiters</MenuItem>
                      <MenuItem icon={<RiQuoteText size={20} />  } component={<Link to={'/quote'}/>}>Quote</MenuItem>
                      <MenuItem icon={<MdOutlineAttachMoney size={20} />  } component={<Link to={'/earning-plan'}/>}>Shopper Earning Plan</MenuItem>
                      <MenuItem  icon={<MdOutlineAttachMoney size={20}/>} component={<Link to={'/incentives'}/>}>Incentives</MenuItem>
                      <MenuItem icon={<IoIosHelpCircle size={20} />  } component={<Link to={'/faq'}/>}>Faq</MenuItem>                    
                      <MenuItem icon={<IoIosHelpCircle size={20} />  } component={<Link to={'/orders-faq'}/>}>Orders Faq</MenuItem>
                      <MenuItem  icon={<FaCity size={20}/>} component={<Link to={'/app-version'}/>}>App Version</MenuItem>
                      
                    </SubMenu>
                    
                    <MenuItem icon={<MdLocalOffer  size={20}/>} component={<Link to={'/promotions'}/>}>Promotions</MenuItem>

                    <SubMenu  label="Delivery Time" icon={<FiClock size={25} />}>
                      {/* <MenuItem icon={<FaRegCalendarAlt  size={20}/>} component={<Link to={'/add-shift'}/>}>Add Shift</MenuItem> */}
                      <MenuItem icon={<FaRegCalendarAlt  size={20}/>} component={<Link to={'/shifts'}/>}>View Shifts</MenuItem>
                      <MenuItem icon={<IoStorefront size={20} />} component={<Link to={'/branches'}/>}  >Branches</MenuItem>
                      <MenuItem  icon={<FaCity size={20}/>} component={<Link to={'/global-status'}/>}>Global State</MenuItem>
                    </SubMenu>
                    <SubMenu  label="Reports" icon={<TbReportSearch  size={25} />}>
                    <MenuItem icon={<FaSearch size={20}/>} component={<Link to={'/product/searched'}/>}>Product Searched</MenuItem>
                    </SubMenu>
                  </Menu>
                  

                </div>

              </div>
            
            </Sidebar>

            <main ref={mainDivRef} style={{display: 'block',overflow:'scroll', width:!isopen?'95vw': '80vw'}}>
            <svg className="togglle" onClick={()=>{setIsopn(!isopen)}}   width="25" height="25" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 14C1.89543 14 1 13.1046 1 12V4C1 2.89543 1.89543 2 3 2H13C14.1046 2 15 2.89543 15 4V12C15 13.1046 14.1046 14 13 14H3ZM6.85 3.7H13C13.1657 3.7 13.3 3.83431 13.3 4V12C13.3 12.1657 13.1657 12.3 13 12.3H6.85V3.7ZM5.15 3.7H3C2.83431 3.7 2.7 3.83431 2.7 4V12C2.7 12.1657 2.83431 12.3 3 12.3H5.15V3.7Z"></path></svg>
              <Component isopen={isopen} scrollToTop={scrollToTop} {...params} />
            </main>
        </div>
    );
  else return <Navigate to={"/login"} replace />;
}

export default ProtectedRoute;
