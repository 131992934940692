import React,{useState,useEffect,useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
export default function PromotionProducts() {
    const navigate = useNavigate()
    const {id,} = useParams();
    const divRef = useRef(null); // Step 1: Use a Ref
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span>Product Id</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('name', {
            id: 'name',
            cell: info => <i 
            // onClick={()=>(navigate(`/user/detail/${info.row.original.id}`))}
             style={{cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Name</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('img', {
            id: 'img',
             cell: info => <div style={{borderRadius:7,width:50,height:50,display:'flex',alignItems:'center',justifyContent:'center'}}> <img src={info.getValue()} style={{width:40,height:40,objectFit:'contain'}} /></div>,
             header: () =><p className='m-0' style={{minWidth:200}}>Product Image</p>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('brand.name', {
            id: 'brand.name',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Brand Name</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('barcode', {
            id: 'barcode',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Barcode</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('dess', {
            id: 'dess',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Description</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('prodcat.name', {
            id: 'prodcat.nmae',
             cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><p className='m-0' style={{minWidth:200}}>Product category</p>,
             footer: info => info.column.id,
           }),
           
           
           columnHelper.accessor('sku', {
            id: 'sku',
            cell: info => <i  style={{paddingRight:50,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Sku</span>,
             footer: info => info.column.id,
           }),
          
           
    ]
    function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};
    
        for (var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for (i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      }
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [rowSelection1, setRowSelection1] = useState([]);

    const [showalert, setShowalert] = useState(false);
const [msg, setMsg] = useState('');
const [merchantid, setMerchantid] = useState();
const [page, setPage] = useState(1);
const [limitt, setLimitt] = useState(35);
const [totalPages, setTotalPages] = useState(1);
const [count, setCount] = useState(0);
const [limit, setLimit] = useState(0);
const [type, setType] = useState(0);
const [data, setProducts] = useState([]);
 const [loading, setLoading] = useState(true);
 const [search, setSearch] = useState('');
 const [barcode, setBarcode] = useState('');
 const [stores, setStores] = useState([]);
 const [branches, setBranches] = useState([]);
 const [storeid, setStoreid] = useState();
 const [branchid, setBranchid] = useState();
 const [selectedRowIds, setSelectedRowIds] = useState([]);

 const table = useReactTable({
    data,
    columns: columns.filter(column => visibleColumns.includes(column.id)),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    //onPaginationChange: setPagination, 
    enableRowSelection:true, //hoist up the row selection state to your own scope
    state: {
      rowSelection, //pass the row selection state back to the table instance
    //   //pagination
    },
  });
  const AddProducts = async () => {
    const {status, data, msg} = await sendData('/promotions/assign', JSON.stringify({
     id:id,
     //products:selectedRowIds.map(o => o.id),
     products:selectedRowIds,
     storeid,
     merchantid,
     branchid
    }));
    if(status){
     navigate('/promotions')
    }
  }
  // useEffect(() => {
    


  //   // setSelectedRowIds([])
  //  if(data.length>0){
  //   let b =[]
  //   for (let i = 0; i < Object.keys(rowSelection).length; i++) {
     
  //       // console.log(data[Object.keys(rowSelection)[i]])
    
  //        b.push(data[Object.keys(rowSelection)[i]])
         
  //        //setSelectedRowIds(removeDuplicates(b, "Id"))
  //   }

  //   setSelectedRowIds(removeDuplicates(b, "Id"))
  //   //setSelectedRowIds(b)
  //   //console.log({b})
  //  }else{
  //   setSelectedRowIds([])
  //  }
  // }, [rowSelection]);
 const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setStoreid(selectedValue)
    GetBranches(selectedValue)
  };
  const handleSelectChange1 = (event) => {
    const selectedValue = event.target.value;
    setBranchid(selectedValue)
  };
 const GetStores = async ()=>{
    const {status, data, msg} = await fetchData(`/stores/dropdown`);
    if(status){
        setStores(data)
       // GetBranches(data[0].id)
        setLoading(false)
     }
}
const GetBranches = async (storeid)=>{
    const {status, data, msg} = await fetchData(`/branchesdropdown/${storeid}`);
    if(status){
        setBranches(data)
        setLoading(false)
     }
}
useEffect(()=>{
    GetStores()
},[])

useEffect(()=>{
if(storeid&&branchid){
    FetchProducts();
}
},[page,limitt,search,storeid,branchid]);
const FetchProducts = async () => {
    //setLoading(true)
    const {status, data, msg} = await sendData('/branches/products', JSON.stringify({
  limit:limitt,page:page,search,storeid,branchid
    }));
    if(status){
        let totalPagesx = Math.ceil(data.count / data.limit);
        if(totalPagesx == 0) totalPagesx = 1;
        setProducts(data.products)
        setMerchantid(data.products[0]?.userid)
        setTotalPages(totalPagesx);
        setCount(data.count);
        setLoading(false);
    }else{
            setShowalert(true)
            setMsg(msg)
            setLoading(false);
    }
  }
  return (
 <div style={{overflowY:'scroll',height:'100vh'}} className="p-2">
    {loading?
  <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>
 <div style={{width:'97%',marginLeft:'1.5%',marginBottom:'2%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p className='m-0'>Manage Promotions Products</p>
        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={()=>{
          if (window.confirm('Are you sure you want to Add these products ?')) {
         AddProducts()
          }
        }}>Add Products</Button>

   </div>
    <div style={{cursor:'pointer',marginLeft:'0%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
 
 <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
 <path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
 </g>
 </svg>
 
         <input
           value={search}
           onChange={(e) => {
 
   setSearch(e.target.value)
 
           }}
           type="text"
           id="search-product"
           placeholder="Search Product"
           style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
           // className="form-control"
         />
      {search!=''?   <svg onClick={()=>setSearch('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet">
 
 <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
 fill="#000000" stroke="none">
 <path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
 -491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
 -24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
 -209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
 44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
 196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
 480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
 -468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
 c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
 -50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
 330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
 325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
 -379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
 477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
 24 151 22 204 -5z"/>
 </g>
 </svg>:null}
          </div>
          {/* <div style={{cursor:'pointer',marginTop:10,marginLeft:'0%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
 
 <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
 <path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
 </g>
 </svg>
 
         <input
           value={barcode}
           onChange={(e) => {
 
            setBarcode(e.target.value)
 
           }}
           type="text"
           id="search-product"
           placeholder="Search Barcode"
           style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
           // className="form-control"
         />
      {search!=''?   <svg onClick={()=>setBarcode('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet">
 
 <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
 fill="#000000" stroke="none">
 <path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
 -491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
 -24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
 -209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
 44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
 196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
 480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
 -468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
 c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
 -50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
 330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
 325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
 -379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
 477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
 24 151 22 204 -5z"/>
 </g>
 </svg>:null}
          </div> */}

      <label className="form-label mt-4" htmlFor="typ">Stores</label>
<select className="form-control" value={storeid} onChange={handleSelectChange}>
<option  value={0}>Select An Option</option>
{stores.map((item,i)=>(
    <option  value={item.id}>{item.name}</option>
))}
</select>
<label className="form-label mt-4" htmlFor="typ">Stores</label>
<select className="form-control" value={branchid} onChange={handleSelectChange1}>
<option  value={0}>Select An Option</option>
{branches.map((item,i)=>(
    <option  value={item.id}>{item.name}</option>
))}
</select>
<div className="table-container">
    <table className="table"> 
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            <td className='noborder'>
             <input
                    type="checkbox"
                    checked={selectedRowIds.some(item => item === row.original.id)}
                    onChange={()=>{
                      let b = [...selectedRowIds]; // Start with the existing array

                      // Check if the current ID is already in the array
                      if (b.includes(row.original.id)) {
                        // If it is, remove it from the array (deselect)
                        b = b.filter(id => id !== row.original.id);
                      } else {
                        // If not, add it to the array (select)
                        b.push(row.original.id);
                      }
                    
                      console.log(b);
                      setSelectedRowIds(b)
                    }}
                    //removeDuplicates(b, "Id")
                   // onChange={row.getToggleSelectedHandler()}
                  />
                </td>
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
<div className="pagination">
        <button onClick={() => {setPage(page-1)}}  disabled={page === 1}>Previous</button>
        <button onClick={() => {setPage(page+1)}} disabled={page  === Math.ceil(count/limitt)}>Next</button>
        <span>
          Page
          <strong>
          {' '+page} of {Math.ceil(count/limitt)}
          </strong>
        </span>
        <select value={limitt} onChange={(e) => {
            setPage(1)
            setLimitt(Number(e.target.value))
            }}>
          {[10, 20, 30, 40, 50,200,500,1000].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

</div>
}
    </div>
  )
}
