import React, { useMemo ,useState,useEffect} from 'react';
import {
   ExpandedState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getExpandedRowModel,
    getFilteredRowModel,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
  import { FaTrash, FaChevronRight, FaChevronDown } from 'react-icons/fa';
  import { fetchData, sendData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
export default function Faq() {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span> Faq Id</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('name', {
         id: 'name',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span>Question</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('dess', {
            id: 'dess',
            cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
             header: () =><span>Answer</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('typ', {
            id: 'type',
            cell: info => <i style={{paddingRight:50}}>{info.getValue()==1?'User':info.getValue()==2?'Driver':'.'}</i>,
             header: () =><span>Type</span>,
             footer: info => info.column.id,
           }),
           
          
           columnHelper.accessor('actions', {
            id: 'actions',
            header: 'Actions',
            cell: info => (
                <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
                 <button className="view-button" onClick={()=>{
                    navigate(`/faq/${info.row.original.id}`)
                  }}>Edit</button>
                  <div  style={{display:'flex',alignItems:'center'}} onClick={()=>{
                            if (window.confirm('Are you sure you want to Delete ?')) {
                                DeleteFaq(info.row.original.id);
                              }
                       
                  }} className='buttondelete' >
                
                  <FaTrash size={15}  style={{marginRight:5}}/>
                  <p className='m-0'>Delete</p>
                  </div>
                </div>
              ),
          }),
           
         
    ]
    const [expanded, setExpanded] = React.useState(false)
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const [storeCatId, setStoreCatId] = useState();
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState();
    const [type, setType] = useState()
    

    const GetFaq = async ()=>{
        const {status, data, msg} = await fetchData(`/faqs`);
        if(status){
            setData(data)
             setLoading(false)
         }
    }
    useEffect(()=>{
        GetFaq()
    },[])
    const table = useReactTable({
      data: data,
             state: {
          expanded,
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      columns: columns.filter(column => visibleColumns.includes(column.id)),
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      onRowSelectionChange: setRowSelection,
      onExpandedChange: setExpanded,
      getFilteredRowModel: getFilteredRowModel(),
      getSubRows: (row) => row.subcats || row.subsubs,
      enableRowSelection: true,
    });
  
      
      const DeleteFaq = async(id) =>{
        const {status, data, msg} = await sendData('/faqs/delete', JSON.stringify({id}));
        if(status){
            GetFaq()
        }else{
          setLoading(false);
        }
        //  /stores/status/edit
        //   type ya 1(accept) ya 2(reject)
      }
     
  return (
    <div style={{height:'100vh',overflowY:'scroll',paddingBottom:50}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<div style={{paddingTop:'5%'}}>

   
  <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <p>Manage Faq </p>
      <div style={{display:'flex',alignItems:'center'}}>
      <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-faq')}>Add Faq</Button>
      {/* <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-subcat')}>Add Sub Category</Button>
      <Button style={{marginTop:'1%',marginRight:10}} onClick={()=>navigate('/add-subsubcat')}>Add Sub Sub Category</Button> */}

        </div>
 </div>
<div style={{marginLeft:'1.5%'}} className="table-container">
  <table className="table"> 

    <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
            
          {headerGroup.headers.map(header => (
            <th key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                   {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
            </th>
          ))}
        </tr>
      ))}
    </thead>

    <tbody>
      {table.getRowModel().rows.map(row => (
        <tr  key={row.id}>
       
          {row.getVisibleCells().map(cell => (
            <td className='noborder' key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
 
  </table>
</div>

</div>
}
  </div>
  )
}
