import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../../utils';
import { Button } from 'react-bootstrap';
import Select, { components, createFilter } from "react-select"
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
export default function MainPromotions(props) {
 const {branches,storid} = props
 const navigate = useNavigate();
 const [name, setName] = useState('');
 const [namex, setNamex] = useState('');
 const [from, setFrom] = useState('');
 const [branchid, setBranchid] = useState();
 const [to, setTo] = useState('');
 const [file, setFile] = useState('');
 const [file2, setFile2] = useState('');
 const [file3, setFile3] = useState('');
 const [color, setColor] = useState('#000000');
 const [color2, setColor2] = useState('#000000');
 const [typ, setTyp] = useState('');
 const [perc, setPerc] = useState('');
 const [des, setDes] = useState('');
 const [prixmax, setPrixmax] = useState('');
 const [Errors, setErrors] = useState([]);
 const [error, setError] = useState(false);
 const [showalert, setShowalert] = useState(false);

 const [selectedPromIds, setSelectedPromIds] = useState([]);
 const [mainprom, setMainProm] = useState([]);
 const handleCheckBoxChange = (promid, isChecked) => {
   if (isChecked) {
    setSelectedPromIds([...selectedPromIds, promid]);
   } else {
    setSelectedPromIds(selectedPromIds.filter(id => id !== promid));
   }
 };
 const [loading, setLoading] = useState(false);

 const GetStoreBranches = async (x) => {
    const {status, data, msg} = await fetchData(`/getBranchPromotionsByBranch/${x}`);
    if(status){ 
      setMainProm(data)
    }
  }
 


 const AddBank = async () => { 

    const {status, data, msg} = await sendData('/promotions/add', JSON.stringify({
        name,
        namex,
        from,
        to,
        file,
    }));
    if(status){
     navigate('/banks')
    }
  }

  const validateFields = () => {
    if (!name.trim()) {
      alert('Name cannot be empty.');
      return false;
    }
    return true;
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setBranchid(selectedValue)
    GetStoreBranches(selectedValue)
  };
  const isFormValid = () => {
    let x=[]

 // Validate string fields
 if (!name) x.push('Name is required');
 if (!namex) x.push('namex is required');
 if (!from) x.push('from is required');
 if (!to) x.push('to is required');
 if (!file) x.push('Logo is required');
 if (!file2) x.push('Banner is required');
 if (!color) x.push('Logo Color is required');
 if (!color2) x.push('Banner color is required');
 if (!typ) x.push('Promotion Type is required');






 if (x.length > 0) {
   console.log('Validation Errors:', x);
   setErrors(x)
   return false;
 }
if(x.length==0){
 setErrors([])
 return true;
}
 // If all validations pass, return true


};
const handleSubmit = async() => {
setLoading(true)
 setErrors([])


 if (isFormValid()) {

       setError(false)
   try {

    
     const formData = new FormData();
      formData.append('name', name);
      formData.append('namex', namex);
      formData.append('from', from);
      formData.append('to', to);
      formData.append('img', file);
      formData.append('img2', file2);
      formData.append('color', color);
      formData.append('color2', color2);
      formData.append('typ', typ);
      formData.append('perc', perc);
      formData.append('prixmax', prixmax);
      formData.append('des', des);
      
     
     
    

   const req = await fetch("https://api.trakitrak.com/control/promotions/add", {
     method:'POST',
     body: formData,
})
if(req.status==200){
 const json = await req.json()
 console.log(json)
 navigate(`/promotions`, {replace: true});
 setLoading(false)


}else{
 const json = await req.json()
 console.log(json) 
//  setShowalert(true)
//  setMsg(json.message)
  setLoading(false)
}
   } catch (error) {
     
   }

 } else {
  setLoading(false)
     setError(true)

 }
};
// getBranchPromotionsByBranch
  return (
    <div style={{marginTop:'1%',marginLeft:'1%',overflow:'scroll',height:'95vh',paddingBottom:50}}>
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>:
<>

         {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
        <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Main Promotions</p>
        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={()=>navigate(`/addmainpromotion/${props.storid}`)}>Add Main Promotions</Button>

   </div> 

   <label className="form-label mt-4" htmlFor="typ">Branch</label>
   <select className="form-control" value={branchid} onChange={handleSelectChange}>
   <option value="" >Select an option</option>
   {branches.map((item,i)=>(
       <option  value={item.id}>{item.name}</option>
   ))}
   
   </select>
   <div>
    {mainprom.map((item,i)=>(
      <div 
      onClick={()=>navigate(`/editmainpromotion/${item.id}`)} 
      style={{width:'100%',height:120,backgroundColor:'white',cursor:'pointer',borderRadius:10,marginTop:10,alignItems:'center',alignSelf:'center',display:'flex',flexDirection:'row'}}>
<img src={item.image} style={{width:75,height:75,borderRadius:7,objectFit:'contain'}} />       
 <div style={{display:'flex',flexDirection:'column',marginLeft:10,justifyContent:'center'}}>
        <h6 className='mt-0' >{item.short_name}</h6>
          <h6 className='mt-0' >{item.long_name}</h6>
          <h6 className='mt-0'> {item.description}</h6>
        </div>

          
      </div>
    ))}
   </div>
</>
}
    </div>
  )
}
