import React,{useState,useEffect,useRef} from 'react'
import { fetchData, sendData } from '../utils';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getRowSelectionRowModel
  } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
export default function AddShopperSettelments() {
    const navigate =  useNavigate()
    const divRef = useRef(null); // Step 1: Use a Ref
    
    const [selectedParking, setParkingSelected] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedRowIds1, setSelectedRowIds1] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [Errors, setErrors] = useState([]);
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [bank, setBank] = useState();
    const [bankid, setBankid] = useState();
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [errorr, setErrorr] = useState('');
    const [shopperid, setShopperid] = useState();

    const handleStartDateChange = (e) => {
      setFrom(e.target.value);
      setErrorr(''); // Reset error message
    };
  
    const handleEndDateChange = (e) => {
      if (from && e.target.value < from) {
        setErrorr('End date cannot be less than start date.');
        setTo('');
      } else {
        setTo(e.target.value);
        setErrorr(''); // Reset error message
      }
    };
const handleFileChange = (event) => {
  const file = event.target.files[0];
  setSelectedFile(file);
};

    const columnHelper = createColumnHelper()
    const columns = [
      columnHelper.accessor('id', {
         id: 'id',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span>Order ID</span>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('order_number', {
            id: 'order_number',
            cell: info => <i onClick={()=>{
              const url = `${window.location.origin}/order-summary/${shopperid}/${info.row.original.id}`;
              window.open(url, '_blank');
             // navigate(`/order-summary/${shopperid}/${info.row.original.id}`)
            }
            }style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Order Number</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('totalx', {
            id: 'totalx',
            cell: info => <i onClick={()=>{
              const url = `${window.location.origin}/order-summary/${shopperid}/${info.row.original.id}`;
              window.open(url, '_blank');
             // navigate(`/order-summary/${shopperid}/${info.row.original.id}`)
            }
            }  style={{paddingRight:150,cursor:'pointer'}}>${info.getValue()}</i>,
             header: () =><span>Total</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('total_fee', {
            id: 'total_fee',
            cell: info => <i onClick={()=>{
              const url = `${window.location.origin}/order-summary/${shopperid}/${info.row.original.id}`;
              window.open(url, '_blank');
              // navigate(`/order-summary/${shopperid}/${info.row.original.id}`)
            }
            }  style={{paddingRight:150,cursor:'pointer'}}>${info.getValue()}</i>,
             header: () =><span>Debit</span>,
             footer: info => info.column.id,
           }),
           
        columnHelper.accessor('storename', {
            id: 'storename',
            cell: info => <i 
            //onClick={()=>(navigate(`/shopper/${info.row.original.userid}`))}
             style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Store Name</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('img', {
            id: 'img',
             cell: info => <div style={{borderRadius:7,backgroundColor:info.row.original.color,width:50,height:50,display:'flex',alignItems:'center',justifyContent:'center'}}> <img src={info.getValue()} style={{width:40,height:40,objectFit:'contain'}} /></div>,
             header: () =><span>Store Logo</span>,
             footer: info => info.column.id,
           }),
           columnHelper.accessor('branchname', {
            id: 'branchname',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Branch Name</span>,
             footer: info => info.column.id,
           }),
         
           
           
           
          
    //     columnHelper.accessor('actions', {
    //      id: 'actions',
    //      header: 'Actions',
    //      cell: info => (
    //         //  <div className="action-buttons" style={{display:'flex',alignItems:'center'}}>
    //         //    <button className="edit-button" onClick={() => alert(`Editing ${info.row.original.firstName}`)}>Edit</button>
    //         //    <button className="delete-button" onClick={() =>{
    //         //          setShow1(true)
    //         //          setStoreid(info.row.original.id)
    //         //  }}>Reject</button>
    //         //    {/* <button className="view-button" onClick={() => alert(`Viewing ${info.row.original.firstName}`)}>View</button> */}
    //         //  </div>
    //        ),
    //    }),
    ]
    const columnHelpe1r = createColumnHelper()
    const columns1 = [
      columnHelpe1r.accessor('incentive_name', {
         id: 'incentive_name',
         cell: info => <i style={{paddingRight:50}}>{info.getValue()}</i>,
          header: () =><span>Incentive Name</span>,
          footer: info => info.column.id,
        }),
        columnHelpe1r.accessor('incentive_type', {
            id: 'incentive_type',
            cell: info => <i style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()==1?'Guarrante':'Multiplier'}</i>,
             header: () =><span>Incentive type</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('incentive_win', {
            id: 'incentive_win',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Incentive Profit</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('total_log_time', {
            id: 'total_log_time',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()} Hours</i>,
             header: () =><span>Total Connect Time</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('total_earning', {
            id: 'total_earning',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Total Earning</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('acceptance_rate', {
            id: 'acceptance_rate',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Acceptance Rate</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('incentive_form', {
            id: 'incentive_form',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>From</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('incentive_to', {
            id: 'incentive_to',
            cell: info => <i style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>To</span>,
             footer: info => info.column.id,
           }),
           
        columnHelpe1r.accessor('incentive_profit', {
            id: 'incentive_profit',
            cell: info => <i 
             style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Profit</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('incentive_min_hours', {
            id: 'incentive_min_hours',
             cell: info => <div style={{borderRadius:7,backgroundColor:info.row.original.color,width:50,height:50,display:'flex',alignItems:'center',justifyContent:'center'}}>{info.getValue()}</div>,
             header: () =><span>Min hours</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('total_orders', {
            id: 'total_orders',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Total Orders</span>,
             footer: info => info.column.id,
           }),
           columnHelpe1r.accessor('rejected_orders', {
            id: 'rejected_orders',
            cell: info => <i  style={{paddingRight:150,cursor:'pointer'}}>{info.getValue()}</i>,
             header: () =><span>Rejcted Orders</span>,
             footer: info => info.column.id,
           }),
          
           
         
           
      
    ]
  
    const [expenses, setexpenses] = useState([]);
    const addexpenses = () => {
        setexpenses([...expenses, { amount:0,description:''}]);
    };
    const removeexpenses = (index) => {
        const updatedTimePickers = [...expenses];
        updatedTimePickers.splice(index, 1);
        setexpenses(updatedTimePickers);
    };
    const setexpense = (index, field, value) => {
        const updatedTimePickers = [...expenses];
        updatedTimePickers[index][field] = value;
        setexpenses(updatedTimePickers);
    };


    const [profits, setProfits] = useState([]);
    const addprofit = () => {
        setProfits([...profits, { amount:0,description:''}]);
    };
    const removeprofit = (index) => {
        const updatedTimePickers = [...profits];
        updatedTimePickers.splice(index, 1);
        setProfits(updatedTimePickers);
    };
    const setprofit = (index, field, value) => {
        const updatedTimePickers = [...profits];
        updatedTimePickers[index][field] = value;
        setProfits(updatedTimePickers);
    };
    const [visibleColumns, setVisibleColumns] = useState(columns.map(column => column.id));
    const [rowSelection, setRowSelection] = useState({});
    const [rowSelection1, setRowSelection1] = useState({});
    const [tax, setTax] = useState(11);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [parking, setparking] = useState([]);
    const [loading, setLoading] = useState([]);

    const [shoppers, setshoppers] = useState([]);
    const table = useReactTable({
        data,
        columns: columns.filter(column => visibleColumns.includes(column.id)),
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        //onPaginationChange: setPagination, 
        enableRowSelection:true, //hoist up the row selection state to your own scope
        state: {
          rowSelection, //pass the row selection state back to the table instance
        //   //pagination
        },
      });
      const table1 = useReactTable({
        data:data1,
        columns: columns1,
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection1,
        //onPaginationChange: setPagination, 
        enableRowSelection:true, //hoist up the row selection state to your own scope
        state: {
          rowSelection:rowSelection1, //pass the row selection state back to the table instance
        //   //pagination
        },
      });
      function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};
    
        for (var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for (i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      }
      useEffect(() => {
    
  

       
        if(data1.length>0){
         let b =[]
         for (let i = 0; i < Object.keys(rowSelection1).length; i++) {
          
            //  console.log(data[Object.keys(rowSelection)[i]])
         
              b.push(data1[Object.keys(rowSelection1)[i]])
              
              setSelectedRowIds1(removeDuplicates(b, "Id"))
         }
     
     
         setSelectedRowIds1(b)
        }else{
         setSelectedRowIds1([])
        }
       }, [rowSelection1, data1]);
      useEffect(() => {
    
  

       
       if(data.length>0){
        let b =[]
        for (let i = 0; i < Object.keys(rowSelection).length; i++) {
         
            console.log(data[Object.keys(rowSelection)[i]])
        
             b.push(data[Object.keys(rowSelection)[i]])
             
             setSelectedRowIds(removeDuplicates(b, "Id"))
        }
    
    
        setSelectedRowIds(b)
       }else{
        setSelectedRowIds([])
       }
      }, [rowSelection, data]);
      
    const fetchdriver = async ()=>{
        const {status, data, msg} = await fetchData(`/shoppers/dropdown`);
        if(status){
            setshoppers(data)
           // setShopperid(data[0].id)
            setLoading(false)
         }
    }
    const getUnpaidOrders = async (driver_id)=>{
      const {status, data, msg} = await fetchData(`/getUnpaidOrderByDriver/${driver_id}`);
      if(status){
          
       }
  }
    const FetchOrders = async (x)=>{
        const {status, data, msg} = await fetchData(`/shoppers/unpaidorders/${x}`);
        if(status){
            setData(data.orders)
            setparking(data.parkings)
            setBank(data.banks)
         }
    }
    const FetchIncentive = async (x)=>{
      const {status, data, msg} = await fetchData(`/getIncentivesByDriver/${x}/1/0`);
      if(status){
              setData1(data)
          //  setData1(data.orders1)
          // setparking(data.parkings)
          // setBank(data.banks)
       }
  }

    useEffect(()=>{
        fetchdriver()
    },[])
    const isFormValid = () => {
        let x=[]
              // Validate string fields
              
              if (shopperid==0) x.push('Shopper is required');
              if (!from) x.push('Start Date is required');
              if (!to) x.push('End Date is required');
              if (!bankid) x.push('Bank is required');
              if (tax<0||tax>100) x.push('Tax Should be between 0 and 100');
              const hasInvalidConf = parking.some(p => p.conf === 0);
             if(hasInvalidConf){
              x.push('Please Accept or Reject  all the remaining parking')
             }
         
      
      
      
              // Validate images
               if (!selectedFile) x.push('Receip is required');
               
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };
      const handleSubmit = async() => {
              setErrors([])
      
        
              if (isFormValid()) {
                setLoader(true)
                setError(false)
      
                try {
                  const formData = new FormData();
                 
                  
                   formData.append('week_start',from);
                   formData.append('week_end',to);
                   formData.append('orders',JSON.stringify(selectedRowIds.map(o => o.id)));
                   formData.append('parkings',JSON.stringify(parking));
                   formData.append('total', Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2));
                   formData.append('driverid', shopperid);
                   formData.append('tax', tax/100);
                   formData.append('bankid', bankid);
                   formData.append('other_earnings', JSON.stringify(profits));
                   formData.append('other_expenses', JSON.stringify(expenses));
                 //  formData.append('other_expenses', JSON.stringify([]));
                  formData.append('file', selectedFile);
                   formData.append('other_earnings_total',profits.reduce((sum, item) => sum + Number(item.amount), 0).toFixed(2));
                   formData.append('other_expenses_total', expenses.reduce((sum, item) => sum + Number(item.amount), 0).toFixed(2));
                 
             
                const req = await fetch("https://api.trakitrak.com/control/payments/shoppers/pay", {
                  method:'POST',
                  body: formData,
          })
          if(req.status==200){
              const json = await req.json()
              console.log(json)
              // setLoader(false)
              PayIncentive()
               navigate('/shopper-settelments')
             
      
      
          }else{
              const json = await req.json()
              console.log(json) 
            //   setShowalert(true)
            //   setMsg(json.message)
              setLoader(false)
          }
                } catch (error) {
                  setLoader(false)
                }
             
              } else {
                setLoader(false)
                  setError(true)
                  //alert('hi')
                //   props.scrollToTop();
              }
            }; 
            const PayIncentive = async () => {

                const {status, data, msg} = await sendData('/payIncentivesByDriver', JSON.stringify({driver_id:shopperid}));
                if(status){
                 
                }
              
            }
            function updateConf(item, newValue) {
                const updatedParking = parking.map(p => {
                    if (p.id === item.id) { // Assuming each item has a unique 'id' field
                        return {...p, conf: newValue};
                    }
                    return p;
                });
                setparking(updatedParking); // Assuming 'setParking' is your state updater function
            }
            const [shopperId, setShopperId] = useState(0);
            const [searchQuery, setSearchQuery] = useState('');
            // Function to sort shoppers alphabetically by name
            const sortShoppers = (shoppers) => {
              return shoppers.sort((a, b) => {
                const nameA = `${a.name} ${a.lastname || ''}`.toUpperCase();
                const nameB = `${b.name} ${b.lastname || ''}`.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });
            };
            // Function to filter shoppers based on search query
            const filterShoppers = (shoppers, query) => {
              return shoppers.filter((shopper) => {
                const fullName = `${shopper.name} ${shopper.lastname || ''}`.toUpperCase();
                return (
                  fullName.includes(query.toUpperCase()) ||
                  (shopper.username && shopper.username.toUpperCase().includes(query.toUpperCase()))
                );
              });
            };
            const handleSearchChange = (e) => {
              setSearchQuery(e.target.value);
            };
            const sortedShoppers = sortShoppers(shoppers);
            const displayedShoppers = filterShoppers(sortedShoppers, searchQuery);

  return (
    <div style={{marginTop:'5%',height:'97vh',overflow:'scroll',paddingBottom:100}}>
            {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
      {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<label style={{marginLeft:10}} className="form-label mt-4">Shopper </label>
<div style={{margin:'5px 10px 10px 10px'}} >
   <input
        type="text"
        placeholder="Search by name or lastname"
        value={searchQuery}
        onChange={handleSearchChange}
        className="form-control mb-3"
   />
<select value={shopperid} onChange={(e)=>{
  setShopperid(e.target.value);
  if(e.target.value!=0){
    FetchOrders(e.target.value)
    FetchIncentive(e.target.value)
    // getUnpaidOrders(e.target.value)
}else{
    setData([])
}}} required name="code" className="form-control"  >
<option value={0}>Select Shopper</option>
{displayedShoppers.map((item,i)=>(
  <option value={item.id}>{item.name} - {item.lastname}</option>
))}
</select>
</div>
{shopperid!=0?
    <>
{data.length>0?(
    <div className="table-container">
    <table className="table"> 

      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                <th>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td>
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
):null}
{data1.length>0?(
    <div className="table-container">
    <table className="table"> 

      <thead>
        {table1.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
                <th>
                <input
                  type="checkbox"
                  checked={table1.getIsAllRowsSelected()}
                  onChange={table1.getToggleAllRowsSelectedHandler()}
                />
              </th>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table1.getRowModel().rows.map(row => (
          <tr  key={row.id}>
            <td className='noborder'>
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </td>
            {row.getVisibleCells().map(cell => (
              <td className='noborder' key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
   
    </table>
 </div>
):null}
{parking.length>0?(
<div style={{marginTop:25}}>
<p style={{margin:5}}>Parking</p>
{parking.map((item, i) => (
    <div style={{display: 'flex', alignItems: 'center', marginLeft: '1.5%'}}>
        <div style={{display: 'flex', flexDirection: 'row', width: '84%'}}>
            <img onClick={()=>{window.open(item.receipt, '_blank')}} src={item.receipt} style={{width: 65,cursor:'pointer', height: 65, borderRadius: 10, objectFit: 'contain'}} />
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <p>Order Id: {item.checkoutbranchid}</p>
                <p>Amount: ${item.amount}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 25}}>
                <p>Check In: {item.checkintime}</p>
                <p>Departure: {item.departuretime}</p>
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', marginLeft: 10}}>
            {item.conf === 0 || item.conf === 2 ? (
                <Button onClick={() => updateConf(item, 1)}>Accept</Button>
            ) : null}
            {item.conf === 0 || item.conf === 1 ? (
                <Button style={{marginLeft: 10}} onClick={() => updateConf(item, 2)}>Reject</Button>
            ) : null}
        </div>
    </div>
))}
</div>
):
<p style={{margin:10}}>No Parking receipt Available</p>
}
<div>
      <div style={{ marginLeft: '1%', width: '95%' }} className="d-flex mt-4 justify-content-between align-items-center">
        <label className="form-label">
          From
        </label>
      </div>
      <input value={from} onChange={handleStartDateChange} name="fromDate" id="from-input" type="date" style={{ width: '95%', marginLeft: '1%' }} className="form-control" />

      <div style={{ marginLeft: '1%', width: '95%' }} className="d-flex mt-4 justify-content-between align-items-center">
        <label className="form-label">
          To
        </label>
      </div>
      <input value={to} onChange={handleEndDateChange} name="toDate" id="to-input" type="date" style={{ width: '95%', marginLeft: '1%' }} className="form-control" />

      {errorr && <div style={{ color: 'red', marginLeft: '1%' }}>{errorr}</div>}
    </div>

  <div onClick={()=>console.log(selectedRowIds)} style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Total 
                        </label>
                       
  </div>
  <input disabled value={"$ "+Number(selectedRowIds.reduce((sum, item) => sum + Number(Number(item.totalx)), 0)).toFixed(2)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />
  <div  style={{marginLeft:'1%',width:'95%'}} className="d-flex  mt-4 justify-content-between align-items-center">
                        <label className="form-label">
                        Tax %
                        </label>
                       
  </div>

  <input  value={tax} onChange={(e)=>setTax(e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />
  <label style={{margin:10}} className="form-label">Other Expenses</label>
            {expenses.map((exp, index) => (
    <div key={index} style={{ display: 'flex',marginLeft:'2%', alignItems: 'center', marginBottom: '10px',width:'97vw' }}>
        <div style={{ marginRight: '10px',width:'34.5%' }}>
            <label>Description:</label>
            <input placeholder='Description' value={exp.description} onChange={(e)=>setexpense(index, 'description', e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />

        </div>
        <div style={{ marginRight: '10px',width:'34.5%' }}>
            <label>Amount:</label>
            <input placeholder='Amount'  value={exp.amount} type='number'  onChange={(e)=>setexpense(index, 'amount', e.target.value)}  name="name" id="name-input"  style={{width:'95%',marginLeft:'1%'}} className="form-control " />

        </div>
      
        <Button onClick={() => removeexpenses(index)} style={{ marginLeft: '10px' }}>Remove</Button>
    </div>
))}

<div style={{marginBottom:10,marginLeft:'1.4%',marginTop:10,display:'flex',flexDirection:'column',width:'90%'}} >
                
                    <Button  onClick={addexpenses} style={{ margin: 10,width:150 }}>Add Expenses</Button>

            </div>

            <label style={{margin:10}} className="form-label">Other Profit</label>
            {profits.map((exp, index) => (
    <div key={index} style={{ display: 'flex',marginLeft:'2%', alignItems: 'center', marginBottom: '10px',width:'97vw' }}>
         <div style={{ marginRight: '10px',width:'34.5%' }}>
            <label>Description:</label>
            <input placeholder='Description'  value={exp.description} onChange={(e)=>setprofit(index, 'description', e.target.value)}  name="name" id="name-input" type="text" style={{width:'95%',marginLeft:'1%'}} className="form-control " />

        </div>
        <div style={{ marginRight: '10px',width:'34.5%' }}>
            <label>Amount:</label>
            <input  placeholder='Amount' value={exp.amount} type='number'  onChange={(e)=>setprofit(index, 'amount', e.target.value)}  name="name" id="name-input"  style={{width:'95%',marginLeft:'1%'}} className="form-control " />

        </div>
      
        <Button onClick={() => removeprofit(index)} style={{ marginLeft: '10px' }}>Remove</Button>
    </div>
))}

<div style={{marginBottom:10,marginLeft:'1.4%',marginTop:10,display:'flex',flexDirection:'column',width:'90%'}} >
                
                    <Button  onClick={addprofit} style={{ margin: 10,width:150 }}>Add Profits</Button>

            </div>
            <label style={{margin:10}} className="form-label">Receipt</label>
            <input type="file" ref={fileInputRef} className='form-input' onChange={handleFileChange} accept="application/pdf,image/*" />
        


<div style={{width:'80%',height:410,marginLeft:'10%',marginTop:'5%',backgroundColor:'rgba(0, 0, 0, 0.05)',borderRadius:10,alignSelf:'center'}}>
    <h1 style={{textAlign:'center',color:'green'}}>Payment summary</h1>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Profits</p>    
                <p>{"$ "+Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Tax</p>    
                <p>-{Number((tax/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Sub Total Profit</p>    
                <p>$ {Number(Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)-Number((tax/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)).toFixed(2)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Other expenses</p>    
                <p>-$ {Number(expenses.reduce((sum, item) => sum +  Number(item.amount), 0)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <p>Other Profits</p>    
                <p>$ {profits.reduce((sum, item) => sum + Number(item.amount), 0).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <h5>SubTotal</h5>    
                <p>$ {
                  Number(
               Number( Number(profits.reduce((sum, item) => sum +  Number(item.amount), 0)).toFixed(2)
               -Number(expenses.reduce((sum, item) => sum +  Number(item.amount), 0)).toFixed(2))
               +
               Number(Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)-Number((tax/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)).toFixed(2))
               ).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <h5>DebitSubTotal</h5>    
                <p>$ {Number(selectedRowIds.reduce((sum, item) => sum + Number(item.total_fee), 0)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <h5>Incentive Total</h5>    
                <p>$ {Number(selectedRowIds1.reduce((sum, item) => sum + Number(item.incentive_win), 0)).toFixed(2)}</p>
             
    </div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'90%',marginLeft:'5%'}}>
                <h3>Total</h3>    
                <p>$ {Number(
 Number(
  Number( Number(profits.reduce((sum, item) => sum +  Number(item.amount), 0)).toFixed(2)
  -Number(expenses.reduce((sum, item) => sum +  Number(item.amount), 0)).toFixed(2))
  +
  Number(Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)-Number((tax/100)*Number(selectedRowIds.reduce((sum, item) => sum + Number(item.totalx), 0)).toFixed(2)).toFixed(2))
  ).toFixed(2)
                  -
                    Number(selectedRowIds.reduce((sum, item) => sum + Number(item.total_fee), 0)).toFixed(2)
                )+Number(selectedRowIds1.reduce((sum, item) => sum + Number(item.incentive_win), 0)).toFixed(2)}</p>
             
    </div>

    
    


    
</div>
<div style={{margin:10}}>
{bank?.bankid!=null&&(
  <div style={{display:'flex',alignItems:'center'}}>
    <input type='checkbox' style={{marginRight:10}} checked={bankid==bank.bankid} onChange={()=>setBankid(bank.bankid)}/>
    <p className='m-0' style={{marginLeft:10}}>{bank.bankname}</p>
  </div>
)}
{bank?.mtid!=null&&(
  <div style={{display:'flex',alignItems:'center'}}>
<input type='checkbox' style={{marginRight:10}} checked={bankid==bank.mtid} onChange={()=>setBankid(bank.mtid)}/>
    <p className='m-0' style={{marginLeft:10}}>{bank.mtname}</p>
  </div>
)}


</div>
<div style={{width:'100%',height:100,display:'flex',justifyContent:'center',alignItems:'center'}}>
<Button style={{width:300,marginTop:'5%'}} onClick={handleSubmit}>Pay</Button>

</div>
</>
:null}
</>
}
    </div>
  )
}
