import React, { useMemo ,useState,useEffect,useRef} from 'react';
import { FaTrash, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { fetchData, sendData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import Switch from "react-switch";
export default function Branches() {
    const [isall, setIsall] = useState(false);
    const [day, setDay] = useState('monday');
    const [cities, setCities] = useState([]);
    
    const [subcities, setSubCities] = useState([]);
    const [cityid, setCityid] = useState();
    const [subcityid, setSubCityid] = useState();
    const [loading, setLoading] = useState(true)

    const GetCities = async ()=>{
        
        const {status, data, msg} = await fetchData(`/cities`);
        if(status){
            setCities(data)
            setCityid(data[0].id)
            GetArea(data[0].id)
            setLoading(false)

         }
    }
    useEffect(()=>{
        GetCities()
    },[])
    const GetArea= async (x)=>{
        const {status, data, msg} = await fetchData(`/subcities/dropdown/${x}`);
        setSubCities(data)
    //    if(data.length>0){
    //     setSubCityid(data[0].id)
    //    }
        //console.log(data)
        //setCitys(data);
        }
        const handleSave = async() =>{
            setLoading(true)
            const {status, data, msg} = await sendData('/branches/times', JSON.stringify({
                day,
                all:isall,
                cityid:isall?null:cityid,
                subcityid:isall?null:subcityid
            }));
            if(status){
                setLoading(false)
            }else{
              
            }
    
          }
  return (
    <div style={{paddingTop:'5%'}}>
        {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>

           <label style={{marginLeft:10}} className="form-label mt-4">Day <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={day}  onChange={(e)=>{setDay(e.target.value)}} required name="code" className="form-control"  >
<option value={'monday'}>Monday</option>
<option value={'tuesday'}>Tuesday</option>
<option value={'wedneday'}>Wednesday</option>
<option value={'thursday'}>Thursday</option>
<option value={'friday'}>Friday</option>
<option value={'saturday'}>Saturday</option>
<option value={'sunday'}>Sunday</option>


</select>

</div>
       <div style={{display:'flex',flexDirection:'column',margin:'5px 10px 10px 10px'}}>
       <label style={{marginLeft:10,marginBottom:10}}>All Branches</label>
        <Switch
                    offColor='#ff0000'
                    onChange={(e)=>{setIsall(!isall)}}
                    checked={isall}
                    className="react-switch"
                        />
       </div>
      <label style={{marginLeft:10}} className="form-label mt-4">Cities <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={cityid} disabled={isall?true:false} onChange={(e)=>{setCityid(Number(e.target.value));GetArea(Number(e.target.value))}} required name="code" className="form-control"  >
{cities.map((item,i)=>(
<option value={item.id}>{item.name}</option>
))}

</select>

</div>
<label style={{marginLeft:10}} className="form-label mt-4">Sub Cities <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={subcityid} disabled={isall?true:false} onChange={(e)=>{setSubCityid(Number(e.target.value))}} required name="code" className="form-control"  >
{subcities.map((item,i)=>(
<option value={item.id}>{item.name}</option>
))}

</select>

</div>
<Button style={{margin:10}} onClick={handleSave}>Save</Button>
</>
}
    </div>
  )
}
