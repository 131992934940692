import React , {useCallback,useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, generateTemplate, sendData } from '../utils';
import { Button } from 'react-bootstrap';
export default function EditSubSub() {
    const {id} = useParams()
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [catid, setCatid] = useState();
    const [subid, setSubid] = useState();
    const [loading, setLoading] = useState(true)
    const handleAdd = () => {
        if (validateFields()) {
            AddCatt()
        } else {
          console.log('Validation failed');
        }
      };
      const AddCatt = async () => {
        setLoading(true)
        const {status, data, msg} = await sendData('/categories/edit', JSON.stringify({name,id,type:3,subid:subid,catid:catid}));
        if(status){
          await  generateTemplate()
            setLoading(false)
         navigate('/product-cat')
        }
      }
      const validateFields = () => {
        if (!name.trim()) {
          alert('Name cannot be empty.');
          return false;
        }
        return true;
      };
    const GetCat = async ()=>{
        const {status, data, msg} = await fetchData(`/categories/${id}/3`);
        if(status){
            setName(data.name)
            setCatid(data.catid)
            setSubid(data.subid)
            GetCat2(data.catid)
            // setData(data)
              setLoading(false)
         }
    }
    useEffect(()=>{
      GetCat()
      GetCat1()
    },[])
    const GetCat1 = async ()=>{
        const {status, data, msg} = await fetchData(`/categories/dropdown`);
        if(status){
            setData(data)
            setLoading(false)
         }
    }
    const GetCat2 = async (x)=>{
        const {status, data, msg} = await fetchData(`/categories/dropdown/${x}`);
        if(status){
            setData1(data)
            setLoading(false)
         }
    }
  return (
    <div style={{marginTop:'5%'}}>
                 {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
    <label style={{marginLeft:10}} className="form-label mt-4">Category <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select disabled={true} value={catid} onChange={(e)=>{
    GetCat1(e.target.value)
    setCatid(e.target.value)
}} required name="code" className="form-control"  >
{data.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}
</select>

</div>
<label style={{marginLeft:10}} className="form-label mt-4">Sub Category <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select disabled={true}  value={subid} onChange={(e)=>{
    setSubid(e.target.value)
}} required name="code" className="form-control"  >
{data1.map((item,i)=>(
  <option value={item.id}>{item.name}</option>
))}
</select>

</div>
<div style={{margin:'5px 10px 10px 10px'}} >
<label className="form-label mt-4">Sub Sub Category Name</label>
      <input required type="text" className="form-control"  name="slogan" value={name} 
      onChange={(e) => {setName(e.target.value)}}
      />
              <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={handleAdd}>Save</Button>

      </div>
      </>
      }
  </div>
  )
}
