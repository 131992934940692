import React,{useState,useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import Multiselect from 'multiselect-react-dropdown';
import { Alert,Button,Modal } from 'react-bootstrap';
import Editor from "./Editor";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import { stateToHTML } from "draft-js-export-html";
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import ProdItem from './ProdItem';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import StoreOrder from './StoreOrder';
import PaymentDetails from './PaymentDetails';
import ExchangeRate from './ExchangeRate';
import MainPromotions from './MainPromotions/MainPromotions';
function useOutsideAlerter(ref,setIsExpanded) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        setIsExpanded(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function StoreDetails(props) {
  const navigate = useNavigate();
  const {id} = useParams();
  const awsUrl = process.env.REACT_APP_AWS_LINK;
  const menuItems = [
    { title: 'Overview', icon: 'icon-overview' }, // Replace 'icon-overview' with the actual icon class or path
    // { title: 'Personal Information', icon: 'icon-personal' },
    // { title: 'Materials', icon: 'icon-materials' },
    { title: 'Details', icon: 'icon-reports' },
    { title: 'Products', icon: 'icon-reports' },
    { title: 'Branches', icon: 'icon-sessions' },
    { title: 'Orders', icon: 'icon-orders', highlight: true },
    { title: 'Promotions', icon: 'icon-orders', highlight: true },
    { title: 'Main Promotion', icon: 'icon-settings' },
    // { title: 'Activity', icon: 'icon-activity' },
    // { title: 'Booklog', icon: 'icon-booklog' },
    // { title: 'Tasks', icon: 'icon-tasks' },
    { title: 'Financial Information', icon: 'icon-financial' },
    { title: 'Settings', icon: 'icon-settings' },
  
    { title: 'Exchange Rate', icon: 'icon-settings' }
  ];
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const handleCloser = () => setshowmodal(false);
  const [showmodal, setshowmodal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef ,setIsExpanded);
  const [paymentsData, setPaymentsData] = useState({from:'',to:'',num: '', date: '', prix: '', 'com': '', tax: '', final: '', comiss: '', file: '',taxperc:''});
  
  const [storecode, setStoreCode] = useState();
  const [payments, setPayments] = useState([]);
  const [loaderrr, setLoaderrr] = useState(true);
  const [bankinfoo, setbankinfoo] = useState({});
  const [billinfoo, setBillInfoo] = useState({});
  // const [billInfo, setBillInfo] = useState([]);
  const [bankaccounts, setBanks] = useState([]);
  const [priority, setpriority] = useState();
  const [catpriority, setcatpriority] = useState();


    const [nobranches, setNobranches] = useState([]);

    const [branchesss, setBranchesss] = useState([]);
    const [changed, setChanged] = useState(false);

    // const [showModal, setshowModal] = useState(false);
    const [BankAccount, setBankAccount] = useState(false);
    const [bill, setBill] = useState(false);
    const [paymentdetails, setPaymnentDetails] = useState(false);
    const [bankss, setBankss] = useState(false);
    const [branchmodal, setBranchModal] = useState(false);
    const [modalDataa, setModalDataa] = useState({id: '', rut:'', name:'', activity: '', street: '', unit: '', numsuite: '', city: '' })
    const [modalDataaa, setModalDataaa] = useState({acctype: '',accnum: '', bank:'', name: '', rut: '', email: '', id: '' })

    const [showModal, setShowModal] = useState(false);
    const handleClosee = () => setShowModal(false);
    const [showModal1, setShowModal1] = useState(false);
    const handleCloseee = () => setShowModal1(false);

  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(true);
  const [modalImgPrev, setModalImgPrev] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [show1, setShow1] = useState(false);
  const [conf, setConf] = useState(0);
  const [citiesarray, setCitiess] = useState([]);
  const [enable, setEnable] = useState(false);
  const [branchnamee, setBranchname] = useState();
  const [show, setShow] = useState(false);
  const [isopen, setIsopen] = useState(false);
  const [showww, setShowww] = useState(false);
  const [message, setMessage] = useState('');
  const handleCloseww = () => setShowww(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const disable = async (conf2) => {
    setShow(false)
    const {status, data, msg} = await sendData('/branches/status', JSON.stringify({conf:conf2, branchid:branchid,storeid:id,userid:localStorage.getItem('userid')}), navigate);
    if(status){
console.log(data)
GetStoreBranches()

    }else{
      setShowalert(true)
      setMsg(msg)
    }
  }
  const handleShifts = async () => {
    setShow(false)
    const {status, data, msg} = await sendData('/branches/shifts', JSON.stringify({
      conf:isopen?0:1, id:branchid,msg:isopen?0:message
    }), navigate);
    if(status){
console.log(data)
GetStoreBranches()
setMessage('')
setShowww(false)

    }else{
      setShowalert(true)
      setMsg(msg)
    }
  }
  const [storeType, setStoreType] = useState();
  const [loader, setLoader] = useState(false);
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [storecategories, setStorecategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState('');
  const [position, setPosition] = useState('banner');
  const [chargeofservice, setChargeofservice] = useState(0);
  const [company, setCompany] = useState('');
  const [cities, setCities] = useState([]);

  const [paymentsettings, setPaymentSettings] = useState([]);
  const [billInfo, setBillInfo] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [banksLoading, setBanksLoading] = useState(true);
  const [paymentsloading, setPaymentsLoading] = useState(true);
  const [paymentssloading, setPaymentssLoading] = useState(true);
  const [prodsLoading, setProdsLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(0);
  const [search, setSearch] = useState('');
  const [branchid, setBranchid] = useState('0');
  const [statuss, setStatus] = useState('ALL');
  const [branchname, setBranchName] = useState('');


  const handleClose3 = () => setPaymnentDetails(false);

  const [headquarters, setHeadquarters] = useState('');
  const [legalName, setLegalName] = useState('');
  const [govId, setGovId] = useState('');
  const [registernumber, setregisternumber] = useState('');
  const [phone, setPhone] = useState(); // assuming it's a number input
  const [website, setWebsite] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [email, setEmail] = useState('');
  const [slogan, setSlogan] = useState('');
  const [description, setDescription] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [ispublic, setIspublic] = useState('');
  const [signature, setSignature] = useState('');
   const [selectedNumProds, setSelectedNumProds] = useState('50');
   const [color, setColor] = useState('#ff0000');
   const [logoImage, setLogoImage] = useState(null);
   const [bannerImage, setBannerImage] = useState(null);
   const [commercialLicense, setCommercialLicense] = useState(false);
   const [receipt, setReceipt] = useState(true);
   const [sanitary, setSanitary] = useState(false);
   const [alcohol, setAlcohol] = useState(false);
   const [payPersonal, setPayPersonal] = useState(false);
   const [ownBags, setOwnBags] = useState(false);
   const [returnPolicy, setReturnPolicy] = useState(false);
   const [numBranches, setNumBranches] = useState(1); 
   const [bannerchanged, setBannerchanged] = useState(false);
   const [logochanged, setLogochanged] = useState(false);
   const [storeid, setStoreid] = useState();
   const fetchpayments = async () => {


    const {status, data, msg} = await sendData('/stores/payments', JSON.stringify({storeid: storeid,page:1,filter:''}));
    if(status){
    console.log(data)
    setLoaderrr(true)
     setPayments(data)
    }
  
  }
   const fetchCities= async (signal)=>{
   
    const {status, data, msg} = await fetchData(`/stores/edit/dropdown`);
          if(status){
            console.log({data})
            setCitiess(data.cities)
            setStorecategories(data.storecats);
          }else{
            console.log({msg})
          }
    // setCitiess(data)
    }
    const getStorecode= async (signal)=>{
   
      const {status, data, msg} = await fetchData(`/getStoreCode/${id} `);
            if(status){
              console.log({data})
              setStoreCode(data.store_code)
            }else{
              console.log({msg})
            }
      // setCitiess(data)
      }
const handleSaveCode = async ()=>{
  const {status, data, msg} = await sendData('/updateStoreCode', JSON.stringify({store_id:id,store_code:storecode}));

  if(status){
    // navigate(`/stores/rejected`)
  }else{
    // setShowalert(true)
    alert(msg)
    // setLoading(false);
  }
}

    const filldata = (data) =>{
        setStoreType(data.type)
      setStoreid(data.id)
      if(data.conf==2&&data.reason){
        const blocksFromHTML = convertFromHTML(data.reasonn);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(() => EditorState.createWithContent(state));

      }
      
      setConf(data?.conf)
      setSelectedOption(data.catid)
       setName(data?.name)
       setChargeofservice(data?.charge1)
       setPosition(data?.position)
       setpriority(data?.priority)
       setcatpriority(data?.cat_priority)
       setCompany(data.company?data.company:'-')
       setHeadquarters(data.hq?data.hq:'-')
       setIspublic(data.defpublic?'yes':'no')
       setSignature(data.elecsig?'yes':'no')
       setLegalName(data.legal_name?data.legal_name:'-')
       setGovId(data.govid?data.govid:'-')
       setSelectedNumProds(data.prodtypnum)
       setregisternumber(data.regnum)
       setNumBranches(data.branchnum)
       setColor(data.color)
       setPhone(data.phone)
       setEmail(data.email)
       setSlogan(data.slogan)
       setDescription(data.des)
       setWebsite(data.website)
       setTwitter(data.twiter)
       setInstagram(data.insta)
       setFacebook(data.facebook)
       setBannerImage(data.banner)
       setLogoImage(data.image)
       setCommercialLicense(data.commercial_license?true:false)
       setOwnBags(data.own_bags)
       setPayPersonal(data.pay_personal)
       setReturnPolicy(data.return_policy)
       setSanitary(data.sanitary_license)
       setReceipt(data.receipt)
       //setCities(data.storecities)
       let b =[]
       for (let i = 0; i < data.storecities.length; i++) {
        var obj = {};
        obj["name"] =data.storecities[i].city.name
        obj["id"] = data.storecities[i].cityid
        obj["status"] = 1
        b.push(obj)
       }
       setCities(b)
       console.log({b})
      
    
    }
    useEffect(()=>{
         getStorecode()
         fetchCities()
    },[])
   const handleLogoChange = (event) => {
     const selectedFile = event.target.files[0];
 
     // Do any additional checks or processing if needed
      setLogochanged(true)
     setLogoImage(selectedFile);
   };
   const handlebannerChange = (event) => {
      const selectedFile = event.target.files[0];
  
      // Do any additional checks or processing if needed
      setBannerchanged(true)
      setBannerImage(selectedFile);
    };
   const handleColorChange = (event) => {
     setColor(event.target.value);
   };
  const handleNumProdsChange = (event) => {
    setSelectedNumProds(event.target.value);
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };
  const handleOptionChangesignature = (event) => {
      setSignature(event.target.value);
    };
  const handleOptionChangeOpenTopublic = (event) => {
      setIspublic(event.target.value);
    };

    const DayContainer = ({ day, startTime, endTime, isClosed }) => (
      <OverlayTrigger
      key={'top'}
      placement={'top'}
      overlay={
        <Tooltip id={`tooltip-top`}>
  {isClosed ? 'Closed' : `${startTime} - ${endTime}`}
        </Tooltip>
      }
    >
      <div
   
        className={`day-container ${isClosed ? 'day-container-closed' : 'day-container-open'} ms-1`}
      >
        <p style={{margin:0}}>{day}</p>
      </div>
      </OverlayTrigger>
    );

    useEffect(()=>{
setErrors(errors)
setTimeout(() => {
  console.log({errors})  
}, 1500);
    },[errors])
    const setModalState = (state) => {
      setModalLoading(state);
  }
  const setModalInfo = (state) => {
      setModalData(state);
  }

  const [isoverview, setIsOverView] = useState(true);
  const [isOrder, setIsOrder] = useState(false);
  const [isFinancial, setIsFinancial] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isproducts, setIsproduts] = useState(false);
  const [isbranches, setisbranches] = useState(false);
  const [isDetails, setIsdetails] = useState(false);
  const [isPromotions, setIspromotions] = useState(false);
  const [isExchange, setIsExchange] = useState(false);
  const [mainprom, setIsmainprom] = useState(false);
  const [loading, setLoading] = useState(true);



    const [storeinfo, setStoreInfo] = useState({});
    useEffect(()=>{
        GetStoreDetails();
        GetStoreBranches()
    },[]);
    const GetpaymentSettings = async () => {
      const {status, data, msg} = await fetchData(`/stores/${id}/paymentsettings`);
      if(status){ 
      console.log({data})
      setPaymentSettings(data)
      setPaymentssLoading(false)
      }
    }
    const GetpaymentInfo = async () => {
      const {status, data, msg} = await fetchData(`/stores/${id}/billinfo`);
      if(status){ 
      console.log({data})
      setBillInfo(data)
      setPaymentsLoading(false)
      }
    }
    const GetBanksAccounts = async () => {
      const {status, data, msg} = await fetchData(`/stores/${id}/bankaccounts`);
      if(status){ 
      console.log({data})
      setBankAccounts(data)
      setBanksLoading(false)
      }
    }
 
    const GetStoreDetails = async () => {
        const {status, data, msg} = await fetchData(`/stores/${id}`);
        if(status){ 
      
          filldata(data)
           setStoreInfo(data)
           
          setLoading(false)
        }
      }
      const GetStoreBranches = async () => {
        const {status, data, msg} = await fetchData(`/stores/${id}/branches`);
        if(status){ 
      setBranches(data)
        }
      }
     
      const fetchprods = async () => {
        setProdsLoading(true)

        const {status, data, msg} = await sendData('/stores/products', JSON.stringify({status:statuss, branchid, storeid: id,search,page}));
        if(status){
          if(data.products.length>0){
    
            setProdsLoading(false)
          console.log(data)
          setProducts(data.products);
          let totalPagesx = Math.ceil(data.count / data.limit);
          if(totalPagesx == 0) totalPagesx = 1;
          setTotalPages(totalPagesx);
          setLimit(data.limit);
          setCount(data.count);
          setLoading(false)
   
        }else{
          setProducts([]);
          setProdsLoading(false)

 
        }
        }else{
          setShowalert(true)
          setMsg(msg)
    
          setProducts([]);
          setProdsLoading(false)

    
        }
      }
      useEffect(()=>{
        fetchprods();
    },[ branchid,statuss,search, page]);


   
      const onSelect=(selectedList, selectedItem) =>{
        cities.push(selectedItem.id)
                  }
      const onRemove=(selectedList, selectedItem) =>{
    
                    let indexToRemove = cities.indexOf(selectedItem.id);
        
        if (indexToRemove !== -1) {
          cities.splice(indexToRemove, 1);
        }
        
                              }
                              const showAlerts = () =>{
                                return (
                                  <>
                              {showalert&&(
 <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
                                {msg}
 </Alert>
                              )}
                                 
                                </>
                                )
                              }
                              const isFormValid = () => {
                                let x=[]
                          
                              // Validate string fields
                              if (!name) x.push('Name is required');
                              if (!company) x.push('Company name is required');
                              if (!selectedOption) x.push('Store category is required');
                              if (!cities) x.push('Cities is required');
                              if (!headquarters) x.push('Headquarters is required');
                              if (!ispublic) x.push('Public information is required');
                              if (!signature) x.push('Signature is required');
                              if (!legalName) x.push('Legal name is required');
                              if (!govId) x.push('Government ID is required');
                              if (!selectedNumProds) x.push('Number of products is required');
                              if (!registernumber) x.push('Register number is required');
                              if (!email) x.push('Email is required');
                              if (!slogan) x.push('Slogan is required');
                              if (!description) x.push('Description is required');
                              if (!color) x.push('Color is required');
                            

                              // if (!position) x.push('Position is required');
                              // if (chargeofservice>100){
                              //   x.push('Charge Of Service Cant be more than 100');
                              // } 
                              // if (chargeofservice<0){
                              //   x.push('Charge Of Service Cant be less than 0');
                              // } 
                              // Validate numeric fields
                              if (!phone) x.push('Phone must be a positive number');
                              if (numBranches < 1) x.push('Number of branches must be at least 1');
                            
                              // Validate email
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              if (!emailRegex.test(email)) x.push('Invalid email');
                            
                              // Validate images
                               if (!logoImage) x.push('Logo image is required');
                               if (!bannerImage) x.push('Banner image is required');
                            
                        
                              if (x.length > 0) {
                                console.log('Validation Errors:', x);
                                setErrors(x)
                                return false;
                              }
                            if(x.length==0){
                              setErrors([])
                              return true;
                            }
                              // If all validations pass, return true
                        
                         
                            };
                            const handleSubmit = async() => {
                              setErrors([])
                      
                        setLoading(true)
                              if (isFormValid()) {
                                setLoader(true)
                                setError(false)

                                try {
                          
                                   let userid =localStorage.getItem('userid')
                                   console.log(JSON.stringify(cities))
                                  const formData = new FormData();
                      
                                  formData.append('storid', id);
                                  formData.append('name', name);
                                  formData.append('userid', localStorage.getItem('userid'));
                                 formData.append('company', company);
                                 formData.append('categoryid', selectedOption);
                                 const ids = cities.map(city => {
                                  // Check if the element is an object and has an 'id' property
                                  if (typeof city === 'object' && city.id !== undefined) {
                                      return city.id; // Return the 'id' if it's an object with an 'id'
                                  }
                                  return city; // Otherwise, return the element itself (assuming it's already an ID)
                              });
                                 formData.append('cities', JSON.stringify(ids));
                                 formData.append('hq', headquarters);
                                formData.append('is_public', ispublic=='yes'?1:ispublic=='no'?0:0);
                                formData.append('signature', signature=='yes'?1:signature=='no'?0:0);
                                formData.append('legal_name', legalName);
                                formData.append('govid', govId);
                                formData.append('numprods', selectedNumProds);
                                formData.append('regnum', registernumber);
                                formData.append('numbranches', numBranches);
                                formData.append('code', '961');
                                formData.append('phone', phone.toString());
                                formData.append('website', website?website:'');
                                formData.append('facebook', facebook?facebook:'');
                                formData.append('twitter', twitter?twitter:'');
                                formData.append('instagram', instagram?instagram:'');
                                formData.append('email', email);
                                formData.append('slogan', slogan);
                                formData.append('description', description);
                                formData.append('color', color);
                                formData.append('logo', logoImage);
                                formData.append('banner', bannerImage);
                                formData.append('logochanged', logochanged?1:0);
                                formData.append('bannerchanged', bannerchanged?1:0);
                      
                                formData.append('chargeofservice', chargeofservice?chargeofservice:0); 
                                formData.append('position', position);

                                formData.append('commercial_license', commercialLicense ? 1 : 0);
                                formData.append('receipt', receipt ? 1 : 0);
                                formData.append('sanitary', sanitary ? 1 : 0);
                                formData.append('alcohol', alcohol ? 1 : 0);
                                formData.append('pay_personal', payPersonal ? 1 : 0);
                                formData.append('own_bags', ownBags ? 1 : 0);
                                formData.append('return_policy', returnPolicy ? 1 : 0);
                             
                                const req = await fetch("https://api.trakitrak.com/control/stores/edit", {
                                  method:'POST',
                                  body: formData,
                          })
                          if(req.status==200){
                              const json = await req.json()
                              GetStoreDetails()
                              console.log(json)
                              setLoading(false)
                              GetStoreDetails()
                              setIsOverView(true)
                              setIsdetails(false)
                              setIsSettings(false)
                              setBannerchanged(false)
                              
                             // setLoader(false)
                              // localStorage.setItem('isfirstbranch','true')
                              // localStorage.setItem('storename',json.storename)
                              // localStorage.setItem('storeid',json.storid)
                            //  navigate('/stores', {replace: true});
                      
                              // navigate('/stores/added', {replace: true});
                      
                          }else{
                              const json = await req.json()
                              console.log(json) 
                              setShowalert(true)
                              setLoading(false)
                              setMsg(json.message)
                              setLoader(false)
                          }
                                } catch (error) {
                                  
                                }
                             
                              } else {
                                props.scrollToTop();
                                  setError(true)
                                  setLoading(false)
                              }
                            };
                            const AcceptStore = async() =>{
                              const {status, data, msg} = await sendData('/stores/status/edit', JSON.stringify({storeid:id,type:1}));
                              if(status){
                              navigate(`/stores/approved`)

                              }else{
                                setShowalert(true)
                                setMsg(msg)
                                setLoading(false);
                              }
                              //  /stores/status/edit
                              //   type ya 1(accept) ya 2(reject)
                            }
                            const RejectStore = async() =>{
                              const {status, data, msg} = await sendData('/stores/status/edit', JSON.stringify({storeid:id,type:2,reason:stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")}));
                              if(status){
                                navigate(`/stores/rejected`)
                              }else{
                                setShowalert(true)
                                setMsg(msg)
                                setLoading(false);
                              }
                              //  /stores/status/edit
                              //   type ya 1(accept) ya 2(reject)
                            }
                            const [activeTab, setActiveTab] = useState('Settlements');
                            const handleTabClick = (tab) => {
                              setActiveTab(tab);
                            };
                            const HandleEditSettings = async() =>{
                              setLoading(true)
                              const {status, data, msg} = await sendData('/stores/settings', JSON.stringify({
                                storeid:id,
                                chargeofservice:chargeofservice,
                                position:position=='none'?null:position,
                                priority:priority,
                                cat_priority:catpriority,
                                type:storeType
                              }));
                              if(status){
                              //navigate(`/stores/approved`)
                              setLoading(false)
                              }else{
                                setShowalert(true)
                                setMsg(msg)
                                setLoading(false);
                              }
                              //  /stores/status/edit
                              //   type ya 1(accept) ya 2(reject)
                            }
                            const getPaymentOrder= async (id)=>{
                                const {status, data, msg} = await fetchData(`/stores/payments/${id}}`);
                                setOrders(data)              
                                }
                                useEffect(() => {
                                  const filterOrders = () => {
                                    if (!searchQuery) {
                                      setFilteredOrders(orders);
                                    } else {
                                      const filtered = orders.filter((order) =>
                                        order.checkoutbranch.number.toString().includes(searchQuery)
                                      );
                                      setFilteredOrders(filtered);
                                    }
                                  };
                              
                                  filterOrders();
                                }, [searchQuery, orders]);
                              
                                const handleSearchChange = (e) => {
                                  setSearchQuery(e.target.value);
                                };
                                function formatDate(dateString) {
                                  const months = [
                                    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                  ];
                                  const date = new Date(dateString);
                                  const year = date.getFullYear();
                                  const month = months[date.getMonth()];
                                  const day = date.getDate();
                                  let hours = date.getHours();
                                  const minutes = date.getMinutes();
                                  const ampm = hours >= 12 ? 'PM' : 'AM';
                                  hours = hours % 12;
                                  hours = hours ? hours : 12; // the hour '0' should be '12'
                                  const minutesStr = minutes < 10 ? '0'+minutes : minutes;
                                
                                  return `${month} ${day}, ${year} ${hours}:${minutesStr} ${ampm}`;
                                }
                                function convertTo12HourFormat(time24) {
                                  // Assuming the input is a string in "HH:MM" format
                            
                                  const [hours, minutes] = time24.split(':');
                                  
                                  // Create a Date object. The date doesn't matter since we're only interested in time.
                                  // Here, we're setting the date to "January 1, 1970" with the provided hours and minutes.
                                  const date = new Date(1970, 0, 1, hours, minutes);
                                  
                                  // Convert to 12-hour time format with AM/PM. Specify the locale as 'en-US' and use options to get the desired format.
                                  const time12Hour = date.toLocaleTimeString('en-US', {
                                      hour: 'numeric', // "numeric" for the hour, which could be 1 through 12.
                                      minute: '2-digit', // "2-digit" for the minute, ensuring two digits are always displayed.
                                      hour12: true // Ensure we're using the 12-hour format.
                                  });
                                  
                                  return time12Hour;
                              }
  return (
    <>
 
    {loading?
<div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
 <span className="visually-hidden">Loading...</span>
</div>
</div>
:
<>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{branchname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to {enable?'Enable':'Disable'} this branch?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>{disable(enable?1:2)}}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
     <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to accept?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {
            AcceptStore();
            setShowConfirmModal(false); // Close modal after action
          }}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={()=>setShow1(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label className="form-label mt-4">Reason <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <Editor editorState={editorState} setEditorState={setEditorState} />
      
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow1(false)}>
            Close
          </Button>
          <Button disabled={stateToHTML(editorState.getCurrentContent()) == "<p><br></p>"?true:false} variant="primary" onClick={()=>{   setShow1(false) 
            RejectStore()}} >
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div style={{width:'20vw',overflow:'scroll',display:'flex',flexDirection:'column',alignItems:'center',height:'100vh',backgroundColor:'white',paddingTop:'5%'}}>
             <div style={{width:100,height:100,borderRadius:7,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:storeinfo.color}}>
             <img src={storeinfo.image} style={{width:75,height:75,objectFit:'contain'}} />
             </div>
             <p className='m-0' style={{fontWeight:'600'}}>{storeinfo.user_name}</p>
             <p className='m-0'>{storeinfo.email}</p>
             <div style={{width:'100%',height:'5vh'}}/>
             <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
                {menuItems.map((item, index) => (
                  <div onClick={()=>{
                    if(item.title=='Overview'){
                            setIsOverView(true)
                            setIsFinancial(false)
                            setIsOrder(false)
                            setIsSettings(false)
                            setisbranches(false)
                            setIsproduts(false)
                            setIsdetails(false)
                            setIspromotions(false)
                            setIsExchange(false)
                            setIsmainprom(false)
                    }
                    if(item.title=='Details'){
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setisbranches(false)
                      setIsproduts(false)
                      setIsdetails(true)
                      setIspromotions(false)
                      setIsExchange(false)
                      setIsmainprom(false)

              }
                    if(item.title=='Branches'){
                      GetStoreBranches()
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setisbranches(true)
                      setIsproduts(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsExchange(false)
                      setIsmainprom(false)
              }
                   if(item.title=='Products'){
                          GetStoreBranches()
                          fetchprods()
                          setIsOverView(false)
                          setIsFinancial(false)
                          setIsOrder(false)
                          setIsSettings(false)
                          setisbranches(false)
                          setIsproduts(true)
                          setIsdetails(false)
                          setIspromotions(false)
                          setIsExchange(false)
                          setIsmainprom(false)
                  }
                    if(item.title=='Orders'){
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(true)
                      setIsSettings(false)
                      setisbranches(false)
                      setIsproduts(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsExchange(false)
                      setIsmainprom(false)
                    }
                    if(item.title=='Financial Information'){
                      fetchpayments()
                      setIsOverView(false)
                      setIsFinancial(true)
                      setIsOrder(false)
                      setIsSettings(false)
                      setisbranches(false)
                      setIsproduts(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsExchange(false)
                      setIsmainprom(false)
                    }
                    if(item.title=='Settings'){
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(true)
                      setisbranches(false)
                      setIsproduts(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsExchange(false)
                      setIsmainprom(false)
                    }
                    if(item.title=='Promotions'){
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setisbranches(false)
                      setIsproduts(false)
                      setIsdetails(false)
                      setIspromotions(true)
                      setIsExchange(false)
                      setIsmainprom(false)
                    }
                    if(item.title=='Main Promotion'){
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setisbranches(false)
                      setIsproduts(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsExchange(false)
                      setIsmainprom(true)
                    }
                    if(item.title=='Exchange Rate'){
                      setIsOverView(false)
                      setIsFinancial(false)
                      setIsOrder(false)
                      setIsSettings(false)
                      setisbranches(false)
                      setIsproduts(false)
                      setIsdetails(false)
                      setIspromotions(false)
                      setIsExchange(true)
                      setIsmainprom(false)
                    }
                    // 
                  }} key={index} style={{cursor:'pointer',width:'100%',backgroundColor:item.title=='Promotions'&&isPromotions?'#e05b0e':item.title=='Overview'&&isoverview?'#e05b0e':item.title=='Orders'&&isOrder?'#e05b0e':item.title=='Details'&&isDetails?'#e05b0e':item.title=='Financial Information'&&isFinancial?'#e05b0e':item.title=='Settings'&&isSettings?'#e05b0e':item.title=='Branches'&&isbranches?'#e05b0e':item.title=='Products'&&isproducts?'#e05b0e':item.title=='Exchange Rate'&&isExchange?'#e05b0e':item.title=='Main Promotion'&&mainprom?'#e05b0e':'white',display:'flex',alignItems:'center',height:45,}} >
                <div style={{paddingRight:10,paddingLeft:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <svg style={{marginRight:'2%',margin:0}} fill={item.title=='Promotions'&&isPromotions?'white':item.title=='Overview'&&isoverview?'white':item.title=='Orders'&&isOrder?'white':item.title=='Details'&&isDetails?'white':item.title=='Financial Information'&&isFinancial?'white':item.title=='Settings'&&isSettings?'white':item.title=='Branches'&&isbranches?'white':item.title=='Products'&&isproducts?'white':item.title=='Exchange Rate'&&isExchange?'white':item.title=='Main Promotion'&&mainprom?'white':'black'} width="16px" height="16px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z" fill-rule="evenodd"/>
                  </svg>
                  </div>
                    <span className='m-0' style={{color:item.title=='Promotions'&&isPromotions?'white':item.title=='Overview'&&isoverview?'white':item.title=='Orders'&&isOrder?'white':item.title=='Financial Information'&&isFinancial?'white':item.title=='Details'&&isDetails?'white':item.title=='Settings'&&isSettings?'white':item.title=='Branches'&&isbranches?'white':item.title=='Products'&&isproducts?'white':item.title=='Exchange Rate'&&isExchange?'white':item.title=='Main Promotion'&&mainprom?'white':'black',fontWeight:'600',marginRight:10}}>{item.title}</span>
                  </div>
                ))}
              </div>
        </div>
        <div style={{width:'80vw',padding:'5% 1% 3% 1%',overflow:'scroll',height:'100vh',backgroundColor:'#f9f9f9'}}>
          {isoverview?
          <>
            <div style={{height:300,display:'block'}}>        
              <img style={{width:'100%',height:'100%',objectFit:'cover'}} src={storeinfo.banner}  />
            </div>
<div style={{display:'flex',flexDirection:'column',backgroundColor:'white',padding:10}}>
              <p  className='m-0' style={{color:storeinfo.color,fontSize:24,fontWeight:'600'}} >{storeinfo.name} </p>
              <p className='m-0' style={{fontSize:18,fontWeight:'600',color:'#4a4a4a'}}>{storeinfo.store_category}</p>
              <p className='m-0' style={{fontSize:16,color:'#4a4a4a'}}>  {storeinfo.des}</p>
     </div>
          </>
            :
            isDetails?
  
            <>

            {error && (
<div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
    <div className="d-flex align-items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
    </svg>
    <strong className="ms-1">{errors[0]}</strong>
    </div>
    <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
    </svg>
    </button>
</div>
)}
<>
<label className="form-label">Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />

<label className="form-label mt-4">Company name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required type="text" className="form-control" name="company" value={company} onChange={(e) => setCompany(e.target.value)}  />

<label className="form-label mt-4">Store category <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<select className="form-control" value={selectedOption} onChange={handleSelectChange}>
<option value="" disabled>Select an option</option>
{storecategories.map((option, index) => (
<option key={index} value={option.id}>{option.name}</option>
))}
</select>


<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
<path d="M480 48c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48V96H224V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V96H112V24c0-13.3-10.7-24-24-24S64 10.7 64 24V96H48C21.5 96 0 117.5 0 144v96V464c0 26.5 21.5 48 48 48H304h32 96H592c26.5 0 48-21.5 48-48V240c0-26.5-21.5-48-48-48H480V48zm96 320v32c0 8.8-7.2 16-16 16H528c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM240 416H208c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zM128 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM560 256c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H528c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32zM256 176v32c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM112 160c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32zM256 304c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM112 320H80c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zm304-48v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM400 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32zm16 112v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16z"/>
</svg>

<label className="form-label mt-4">In which cities is your store located? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<Multiselect
   style={{chips:{background:'gray'}}}
options={citiesarray} // Options to display in the dropdown
selectedValues={cities} // Preselected value to persist in dropdown
onSelect={onSelect}
onRemove={onRemove}
displayValue="name" // Property name to display in the dropdown options
/>

<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
<path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zM448 0c-17.7 0-32 14.3-32 32V512h64V192H624c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H480c0-17.7-14.3-32-32-32z"/>
 </svg>

<label className="form-label mt-4">In what city is your headquarters located? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
<input required type="text" className="form-control" name="headquarters" value={headquarters} onChange={(e) => setHeadquarters(e.target.value)}  />


    <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"/>
      </svg>

    <label className="form-label mt-4 mb-0">Is your store open to the public? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span> </label>
    <p className="mb-2" style={{color: 'gray', fontSize: '13px'}}>For your store to be in Trakitrak, you need to have a physical store that Shoppers can go to. This excludes showrooms, collection points, and outlets at a different store.</p>
   <div>
   <label style={{ marginRight: '10px' }}>
<input
type="radio"
style={{ marginRight: '5px' }}
value="yes"
checked={ispublic === 'yes'}
onChange={handleOptionChangeOpenTopublic}
/>
Yes
    </label>
        <label>
        <input
            style={{ marginRight: '5px' }}
            type="radio"
            value="no"
            checked={ispublic === 'no'}
            onChange={handleOptionChangeOpenTopublic}
        />
        No
        </label>
   </div>

        <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}}  xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
            <path d="M192 128c0-17.7 14.3-32 32-32s32 14.3 32 32v7.8c0 27.7-2.4 55.3-7.1 82.5l-84.4 25.3c-40.6 12.2-68.4 49.6-68.4 92v71.9c0 40 32.5 72.5 72.5 72.5c26 0 50-13.9 62.9-36.5l13.9-24.3c26.8-47 46.5-97.7 58.4-150.5l94.4-28.3-12.5 37.5c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H460.4l18-53.9c3.8-11.3 .9-23.8-7.4-32.4s-20.7-11.8-32.2-8.4L316.4 198.1c2.4-20.7 3.6-41.4 3.6-62.3V128c0-53-43-96-96-96s-96 43-96 96v32c0 17.7 14.3 32 32 32s32-14.3 32-32V128zm-9.2 177l49-14.7c-10.4 33.8-24.5 66.4-42.1 97.2l-13.9 24.3c-1.5 2.6-4.3 4.3-7.4 4.3c-4.7 0-8.5-3.8-8.5-8.5V335.6c0-14.1 9.3-26.6 22.8-30.7zM24 368c-13.3 0-24 10.7-24 24s10.7 24 24 24H64.3c-.2-2.8-.3-5.6-.3-8.5V368H24zm592 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H305.9c-6.7 16.3-14.2 32.3-22.3 48H616z"/></svg>
          <label className="form-label mt-4 mb-0">Does the legal representative of your company have a registered electronic signature? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
      
          <div>
            
   <label style={{ marginRight: '10px' }}>
<input
type="radio"
style={{ marginRight: '5px' }}
value="yes"
checked={signature === 'yes'}
onChange={handleOptionChangesignature}
/>
Yes
    </label>
        <label>
        <input
            style={{ marginRight: '5px' }}
            type="radio"
            value="no"
            checked={signature === 'no'}
            onChange={handleOptionChangesignature}
        />
        No
        </label>
   </div>

   <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}}xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
<path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
</svg>
<label className="form-label mt-4">What is the name of your company's legal representative? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<input required type="text" className="form-control" name="legal_name" value={legalName} onChange={(e) => setLegalName(e.target.value)}  />

<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}}xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
<path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
</svg>
<label className="form-label mt-4">What is the government ID of your company's legal representative? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<input required type="text" className="form-control" name="govid" value={govId} onChange={(e) => setGovId(e.target.value)}  />

<label className="form-label mt-4 mb-0">How many different products do you sell in your store? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<select required name="numprods" className="form-select" id="prodnum"value={selectedNumProds} onChange={handleNumProdsChange} >
<option value="50">20 to 50 products</option>
<option value="100">51 to 100 products</option>
<option value="1000">101 to 1000 products</option>
<option value="1001">more than 1000 products</option>
</select>
<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} height="1em" viewBox="0 0 24 24" fill="rgb(30,48,80)" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
<g id="SVGRepo_iconCarrier">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 2L8.93417 2C8.04768 1.99995 7.28387 1.99991 6.67221 2.08215C6.01669 2.17028 5.38834 2.36902 4.87868 2.87868C4.36902 3.38835 4.17027 4.0167 4.08214 4.67221C3.9999 5.28387 3.99995 6.04769 4 6.93417L4 7V10.5V16.1707C2.83481 16.5825 2 17.6938 2 19C2 20.6569 3.34315 22 5 22H15.9966L16 22C17.6569 22 19 20.6569 19 19V9.00001V7.00001H19.5C20.8807 7.00001 22 5.88072 22 4.50001C22 3.11929 20.8807 2.00001 19.5 2.00001C19.3961 2.00001 19.2937 2.00634 19.1932 2.01865C19.1307 2.00641 19.0661 2 19 2H9ZM13.1707 20C13.0602 19.6872 13 19.3506 13 19V18H5C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20H13.1707ZM19 5.00001H19.5C19.7761 5.00001 20 4.77615 20 4.50001C20 4.22386 19.7761 4.00001 19.5 4.00001C19.2239 4.00001 19 4.22386 19 4.50001V5.00001ZM8 7C8 6.44772 8.44772 6 9 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H9C8.44772 8 8 7.55228 8 7ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H14C14.5523 12 15 11.5523 15 11C15 10.4477 14.5523 10 14 10H9Z" fill="#000000"></path>
</g>
</svg>

<label className="form-label mt-4 mb-0">Company registration number <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<input required type="text" className="form-control" name="regnum" value={registernumber} onChange={(e) => setregisternumber(e.target.value)}  />
<label className="form-label mt-4 mb-0">How many branches do you have in total? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<input required min="1" type="number" className="form-control"  name="numbranches"   value={numBranches}
onChange={(e) => setNumBranches(e.target.value)} />
<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
<path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
</svg>

<label className="form-label mt-4 mb-0">Country code <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div className="my-select">
<select required name="code" className="small-drop small-select">
  <option value="961">+961</option>
</select>
</div>

<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">

<path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
</svg>
  
<label className="form-label mt-4">Phone number <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<input required min="0" type="number" className="form-control" name="phone " value={phone} onChange={(e) => setPhone(e.target.value)} />
   
<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">

<path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
</svg>

<label className="form-label mt-4">Website</label>
<input type="text" className="form-control" name="website" value={website} onChange={(e) => setWebsite(e.target.value)} />


<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} height="1em" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000">
<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
<g id="SVGRepo_iconCarrier">
<title>Facebook-color</title>
<desc>Created with Sketch.</desc>
<defs></defs>
<g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
  <g id="Color-" transform="translate(-200.000000, -160.000000)" fill="#4460A0">
    <path d="M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z" id="Facebook"></path>
  </g>
</g>
</g>
</svg>

<label className="form-label mt-4">Facebook</label>
<input type="text" className="form-control" name="facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />

<svg  style={{marginRight:'0.5%',fill: '#1da1f2'}}xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">

<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
</svg>
<label className="form-label mt-4">Twitter</label>
<div className="input-group">
<div className="input-group-text">@</div>
<input type="text" className="form-control" name="twitter" value={twitter} onChange={(e) => setTwitter(e.target.value)}/>
</div>

<svg  style={{marginRight:'0.5%'}} height="1em" viewBox="0 0 200 200">
<defs>
  <linearGradient id="gradient1" x1=".8" y1=".8" x2="0">
    <stop offset="0" stopColor="#c92bb7" />
    <stop offset="1" stopColor="#3051f1" />
  </linearGradient>
  <radialGradient id="gradient2" cx=".2" cy="1" r="1.2">
    <stop offset="0" stopColor="#fcdf8f" />
    <stop offset=".1" stopColor="#fbd377" />
    <stop offset=".25" stopColor="#fa8e37" />
    <stop offset=".35" stopColor="#f73344" />
    <stop offset=".65" stopColor="#f73344" stopOpacity="0" />
  </radialGradient>
  <rect id="logoContainer" x="0" y="0" width="200" height="200" rx="50" ry="50" />
</defs>
<use xlinkHref="#logoContainer" fill="url(#gradient1)" />
<use xlinkHref="#logoContainer" fill="url(#gradient2)" />
<rect x="35" y="35" width="130" height="130" rx="30" ry="30" fill="none" stroke="#fff" strokeWidth="13" />
<circle cx="100" cy="100" r="32" fill="none" stroke="#fff" strokeWidth="13" />
<circle cx="140" cy="62" r="9" fill="#fff" />
</svg>

<label className="form-label mt-4">Instagram</label>
<div className="input-group">
<div className="input-group-text">@</div>
<input type="text" className="form-control" name="instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
</div>
<svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">

<path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
</svg>

<label className="form-label mt-4">Email <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<input required type="email" className="form-control" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />

<label className="form-label mt-4">Slogan <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<input required type="text" className="form-control" maxLength="30" name="slogan" value={slogan} onChange={(e) => setSlogan(e.target.value)}/>
<label className="form-label mt-4">Description <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<textarea required type="text" className="form-control" maxLength="140" name="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>


<hr />

<label className="form-label mt-4">Store color <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div className="d-flex flex-column">
<label className="form-control" style={{ color: 'gray' }}>
  <span id="color-label">{color}</span>
  <input
    required
    type="color"
    name="color"
    id="color"
    style={{ width: 0, height: 0, visibility: 'hidden' }}
    onChange={handleColorChange}
  />
</label>
</div> 

<label className="form-label mt-4">Logo <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div>
<div className="bg-primary rounded d-flex p-2">
<svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
</svg>
<div style={{ width: '95%', color: 'white' }}>
  <p>We're going to generate a black and white version of the logo. For this reason, it must meet some requirements:</p>
  <ol>
    <li>It must be in PNG format.</li>
    <li>It must have a transparent background.</li>
    <li>Its width must be at least 1,000 px.</li>
  </ol>
</div>
</div>

<div className="d-flex mt-3 rounded my-shadow w-75 m-auto" style={{ position: 'relative' }}>
<div className="camera-div">
  <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="logo">
    <input required name="logo" id="logo" type="file" accept="image/*" className="d-none" onChange={handleLogoChange} />
    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
      
      <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
    </svg>
  </label>
</div>

<div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#b2b2b2', width: '50%',  borderTopRightRadius: '0 !important', borderBottomRightRadius: '0 !important' }}>

{logoImage ?(

<div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
  <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(1000%)'}} src={!logochanged?logoImage: URL.createObjectURL(logoImage)} alt="Logo Preview" />
</div>
):
<svg xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">

<path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
</svg>
}
 

</div>

<div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#f8f8f9', width: '50%',  borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0 !important' }}>
{logoImage ?(
<div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
 <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(0%)'}} src={!logochanged?logoImage:URL.createObjectURL(logoImage)} alt="Logo Preview" />
</div>
):
  <svg style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">
    <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
  </svg>
}
</div>

</div>
<div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
<Button style={{marginRight:20}} onClick={()=>{ window.open(!logochanged?logoImage:URL.createObjectURL(logoImage), '_blank');}}>
  View Image
</Button>
</div>
</div>

<label className="form-label mt-5">Banner <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div>
<div className="bg-primary rounded d-flex p-2">
<svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
</svg>
<div style={{ width: '95%', color: 'white' }}>
  <p>The banner needs to match the following conditions:</p>
  <ol>
    <li>The width must be at least 1296px.</li>
    <li>The height must be at least 540px.</li>
  </ol>
</div>
</div>

<div className="mt-3 logodiv22" style={{ width: '90%',marginLeft:'5%',  backgroundColor: '#b2b2b2', position: 'relative' }}>
<div id="banner_cameradiv" className="camera-div" style={{zIndex:10}}>
  <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="banner">
    <input required name="banner" id="banner" type="file" accept="image/*" className="d-none" onChange={handlebannerChange} />
    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
      <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
    </svg>
  </label>
</div>

<div className="rounded d-flex justify-content-center align-items-center bannerdiv" style={{ backgroundColor: '#b2b2b2', width: '100%', height: '100%' }}>
{bannerImage &&(
<div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%',}}>
 <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(100%)'}} src={!bannerchanged?bannerImage: URL.createObjectURL(bannerImage)} alt="Logo Preview" />
</div>
)}
</div>
</div>


<div style={{display:'flex',marginTop:10,alignItems:'center',justifyContent:'flex-end'}}>
<Button style={{marginRight:20}} onClick={()=>{ window.open(!bannerchanged?bannerImage:URL.createObjectURL(bannerchanged), '_blank');}}>
  View Image
</Button>
</div>

</div>
<div className="bg-secondary rounded d-flex p-2 mt-4">
<svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

  <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
</svg>
<div style={{ width: '95%', color: 'white' }}>
  <p style={{ fontSize: '18px' }}>Check the appropriate boxes if any of the following options apply to your store</p>
  <p>At a later step, we'll ask you for documents that verify the options you checked. For more information, you can visit the Help Center.</p>
</div>
</div>
<label className="form-label mt-5" style={{ fontFamily: 'Poppins-SemiBold' }}>Legal documents</label>

<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">

<input name="commercial_license" className="form-check-input" type="checkbox" id="flexCheckDefault1" value={commercialLicense} checked={commercialLicense}  onChange={() => setCommercialLicense(!commercialLicense)} />
<label className="form-check-label" htmlFor="flexCheckDefault1">
Commercial license for each branch.
</label>
</div>
</div>

<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">


<input name="receipt" className="form-check-input" type="checkbox" id="flexCheckDefault2" value={receipt} checked={receipt}  onChange={() => setReceipt(!receipt)} />
<label className="form-check-label" htmlFor="flexCheckDefault2">
Physical sales receipt
</label>
</div>
</div>

<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">
<input name="sanitary" className="form-check-input" type="checkbox" id="flexCheckDefault3"  value={sanitary} checked={sanitary}  onChange={() => setSanitary(!sanitary)}/>
<label className="form-check-label" htmlFor="flexCheckDefault3">
Sanitary authorization permit for each branch. <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
</label>
</div>
</div>

<div className="border p-3">
<div className="form-check">
<input name="alcohol" className="form-check-input" type="checkbox" id="flexCheckDefault4" value={alcohol} checked={alcohol}  onChange={() => setAlcohol(!alcohol)} />
<label className="form-check-label" htmlFor="flexCheckDefault4">
Alcohol license  <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
</label>
</div>
</div>

<label className="form-label mt-3" style={{ fontFamily: 'Poppins-SemiBold' }}>Payment information</label>

<div className="border p-3">
<div className="form-check">
<input name="pay_personal" className="form-check-input" type="checkbox" id="flexCheckDefault5" value={payPersonal} checked={payPersonal}    onChange={() => setPayPersonal(!payPersonal)}  />
<label className="form-check-label" htmlFor="flexCheckDefault5">
I want to receive payments in my personal account <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
</label>
</div>
</div>

<label className="form-label mt-3" style={{ fontFamily: 'Poppins-SemiBold' }}>Other specifications about my store</label>

<div className="border p-3" style={{ borderBottom: '0 !important' }}>
<div className="form-check">
<input name="own_bags" className="form-check-input" type="checkbox" id="flexCheckDefault6" value={ownBags} checked={ownBags}  onChange={() => setOwnBags(!ownBags)} />
<label className="form-check-label" htmlFor="flexCheckDefault6">
Has own bags <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
</label>
</div>
</div>

<div className="border p-3">
<div className="form-check">
<input name="return_policy" className="form-check-input" type="checkbox" id="flexCheckDefault7" value={returnPolicy} checked={returnPolicy}      onChange={() => setReturnPolicy(!returnPolicy)} />
<label className="form-check-label" htmlFor="flexCheckDefault7">
Has return policy <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
</label>
</div>
</div>  
</>
<Button onClick={handleSubmit} style={{marginTop:10}}>Save</Button>
{conf==0?
<>
<Button onClick={()=>{setShowConfirmModal(true)}} style={{marginLeft:10,marginTop:10,color:'white',backgroundColor:'green',border:'1px solid green'}}>Accpet</Button>
<Button onClick={()=>setShow1(true)} style={{marginLeft:10,marginTop:10,color:'white',backgroundColor:'red',border:'1px solid red'}}>Reject</Button>
  
</>
:conf==1?
<Button onClick={()=>setShow1(true)} style={{marginLeft:10,marginTop:10,color:'white',backgroundColor:'red',border:'1px solid red'}}>Reject</Button>
:conf==2?
<Button onClick={()=>{setShowConfirmModal(true)}} style={{marginLeft:10,marginTop:10,color:'white',backgroundColor:'green',border:'1px solid green'}}>Accpet</Button>
:null
}
            </>
            :
            isFinancial?
            <>
            <Modal  scrollable dialogClassName="custom-modal" centered show={showmodal} onHide={handleCloser}>
       <Modal.Header style={{height:50,alignItems:'center'}}>
      
        <h4 style={{ fontFamily: "Poppins-Regular",fontSize:18,margin:0 }}>Settlement {paymentsData.num}</h4>
        <div style={{display:'flex'}}>
   
          <button onClick={()=>setshowmodal(false)} data-bs-dismiss="modal" style={{borderColor:'#cecece',color:'#24292e',minHeight:34,padding:'0px 7px',backgroundColor:'transparent',border:'2px solid #cecece',borderRadius:7}}>Close</button>
        </div>
  
       </Modal.Header>
    <div className="modal-content" style={{border:'none'}}>
      <div className='downloadpayments'>
      <div className="d-flex align-items-center" style={{width:'70%',marginLeft:10}}>
      <div style={{cursor:'pointer',width:'auto',minWidth:'40%',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:35}} className="form-control">

       <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
        <input
         value={searchQuery}
         onChange={handleSearchChange}
          type="text"
          id="search-order"
          name="search"
          placeholder="Search by order number"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}

          // className="form-control input-order"
        />
        {searchQuery!=''?   <svg onClick={()=>setSearchQuery('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
</div>

        
          </div>
          
          <div className='testbtn' style={{marginRight:10,width:'30%',display:'flex',alignItems:'center',justifyContent:'flex-end',}}>
                                        <a download target="_blank" href={`${awsUrl}/payments/merchants/${paymentsData.file}`} className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ cursor: "pointer",}}>
                                            <svg style={{marginRight:5}} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="red" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                        </a>
        </div>
        <div className="downloadd align-items-center" style={{marginRight:10}}>
                                        <a download target="_blank" href={`${awsUrl}/payments/merchants/${paymentsData.file}`} className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "red",maxWidth:130, cursor: "pointer",}}>
                                            <svg style={{marginRight:5}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="white" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            <p className="text-white ms-1 m-0" style={{ fontSize: "13px", }}>Download</p>
                                        </a>
        </div>
      </div>

      <div className="modal-body" style={{ backgroundColor: "rgb(242, 242, 242)" }}>
        <div className="pb-5">
        <p className="font-weight-bold titlepayment" style={{ fontFamily: "Poppins-Regular" }}>General information</p>
        <div className='titlepayment' style={{ backgroundColor: "white", padding: "5px 10px", borderRadius: "10px",}}>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0">Issue date</p>
              <p className="m-0">{formatDate(paymentsData.date)} at {convertTo12HourFormat(paymentsData.date.substring(11, 16) )}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pay-detail-contx">
              <p className="m-0 font-weight-bold">Period</p>
              <p className="m-0 font-weight-bold">{formatDate(paymentsData.from)} to {formatDate(paymentsData.to)}</p>
            </div>
            
          </div>
     
          <p className="font-weight-bold titlepayment" style={{  fontFamily: "Poppins-Regular" }}>Settlement details</p>
          <div className='titlepayment'style={{ backgroundColor: "white", padding: "5px 10px", borderRadius: "10px" }}>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0">Sales</p>
              <p className="m-0">${paymentsData.prix}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0 text-muted">Commission of TrakiTrak</p>
              <p className="m-0 text-muted">-${paymentsData.com}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0 text-muted">Tax</p>
              <p className="m-0 text-muted">-${paymentsData.tax}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pay-detail-contx">
              <p className="m-0 font-weight-bold">net pay</p>
              <p className="m-0 font-weight-bold">${paymentsData.final}</p>
            </div>
          </div>
          <p className="font-weight-bold titlepayment" style={{  fontFamily: "Poppins-Regular" }}>Orders ({orders.length})</p>
          <div className="row g-3 orderpayment" style={{ margin: "0px 0px 0px" }}>
            {filteredOrders.map((order) => {
              const total = order.checkoutbranch.productstotal;
              const comtotal = parseFloat((total * (paymentsData.comiss / 100)).toFixed(2));
              const tax = parseFloat((comtotal * (paymentsData.taxperc / 100)).toFixed(2));
              const finaltotal = parseFloat((total - comtotal - tax).toFixed(2));

              return (
                <PaymentDetails
                order={order}
    total={total}
    comtotal={comtotal}
    finaltotal={finaltotal}
    tax={tax}
    />
              );
            })}
          </div>
        </div>
      </div>
    </div>
 

       </Modal>
       
             <Modal backdrop="static" scrollable dialogClassName="custom-modal" centered show={paymentdetails} onHide={handleClose3}>
       <Modal.Header>
        <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                         <div style={{display:'flex',alignItems:'center'}}>
                          <p style={{marginLeft:10}}>Payment settings</p>
                         </div>
                        <div style={{display:'flex',alignItems:'center'}}>
                        {/* <button  onClick={()=>EditPayment(billid)} type="submit" className="btn buttonx-outline">Save</button> */}

<button  style={{marginLeft:10}} onClick={()=>{setPaymnentDetails(false)
setChanged(false)}} type="submit" className="btn buttonx-outline">Close</button>

                        </div>
        </div>
       </Modal.Header>
       <Modal.Body>
        
       <div className="modal-body">


  <p style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BRANCHES</p>
  <p style={{ fontSize: '14px' }}>Orders placed in these branches will be billed and paid using the following information</p>

  <div className="border rounded mt-3">
  <>
  {
changed?
<>

{/* {branchess?.map((branch, i) => {
         

  return(
<div   key={i} className="branches-modal-item">
<h2 className="m-0" style={{ fontSize: '20px' }}><span className="branch-title-span" style={{ fontFamily: 'Poppins-Regular' }}>{branch.name}</span></h2>
<div className="bill-field">
  <strong className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>Address:</strong>
  <span className="bill-field-value">{branch.address}</span>
</div>
</div>
)})} */}
</>:
<>

{branchesss?.map((branch, i) => {
         

  return(
<div   key={i} className="branches-modal-item">
<h2 className="m-0" style={{ fontSize: '20px' }}><span className="branch-title-span" style={{ fontFamily: 'Poppins-Regular' }}>{branch.name}</span></h2>
<div className="bill-field">
  <strong className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>Address:</strong>
  <span className="bill-field-value">{branch.address}</span>
</div>
</div>
)})}
</>
  }
  </>
    <div className="branches-modal-item d-flex justify-content-end">
      <button 
      // onClick={()=>{
      //   setBranchess(branchesss)
      //   setBranches([...branches,...branchesss])
      //   setBranchModal(true)}}
         type="button" className="btn buttonx text-white btn-sm">Select branches</button>
    </div>
  </div>

  <p className="mt-4" style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BILLING INFORMATION</p>
  <div className="border rounded mt-3">
    <div className="branches-modal-item">
      <div className="d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Business/Company name</p>
        <p>{billinfoo ? billinfoo.busname : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Activity</p>
        <p>{billinfoo ? billinfoo.activit : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Address</p>
        <p>{billinfoo ? `${billinfoo.street}, ${billinfoo.unit}${billinfoo.numsuite ? ', ' + billinfoo.numsuite : ''}, ${billinfoo.city}` : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>RUT</p>
        <p>{billinfoo ? billinfoo.comprat : ''}</p>
      </div>
    </div>
    <div className="branches-modal-item d-flex justify-content-end">
      <button onClick={()=>setBill(true)} type="button" className="btn buttonx text-white btn-sm">Select billing information</button>
    </div>
  </div>

  <p className="mt-4" style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BANK ACCOUNT</p>

    <div className="border rounded mt-3">
    <div className="branches-modal-item">
      <div className="d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>{bankinfoo ? bankinfoo?.acctyp : ''}</p>
        <p>{bankinfoo ? 'Nº ' + bankinfoo?.accnum : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Bank</p>
        <p>{bankinfoo ? bankinfoo.bank?.name : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Account holder</p>
        <p>{bankinfoo ? bankinfoo?.name : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>RUT</p>
        <p>{bankinfoo ? bankinfoo?.idnum : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Email</p>
        <p>{bankinfoo ? bankinfoo?.email : ''}</p>
      </div>
    </div>
    <div className="branches-modal-item d-flex justify-content-end">
      <button onClick={()=>setBankss(true)} type="button" className="btn buttonx text-white btn-sm">Select bank account</button>
    </div>
  </div>

</div>
       </Modal.Body>
       </Modal>
       <Modal dialogClassName="custom-modal" centered show={showModal} onHide={handleClosee}>
       
       <Modal.Body>
       <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
  <div className="modal-content">
    <div className="modal-header">
      <h4 style={{ fontFamily: 'Poppins-SemiBold' }}>Billing information</h4>
      <div>
        {/* <a onClick={()=>navigate(`/billinginformation/edit/${modalData.id}`)}  className="btn btn-danger" style={{ marginRight: '5px' }}>
          Edit
        </a> */}
        <button onClick={()=>{setShowModal(false)}} data-bs-dismiss="modal" className="btn btn-primary">
          Close
        </button>
      </div>
    </div>
    <div className="modal-body" style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
      <div className="p-3">
        <p className="mb-4">GENERAL INFORMATION</p>
        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Business/Company Name</p>
          <p style={{ fontSize: '15px' }}>{modalDataa.name}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>ID Number</p>
          <p style={{ fontSize: '15px' }}>{modalDataa.rut}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Activity</p>
          <p style={{ fontSize: '15px' }}>{modalDataa.activity}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Street</p>
          <p style={{ fontSize: '15px' }}>{modalDataa.street}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Number</p>
          <p style={{ fontSize: '15px' }}>{modalDataa.unit}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Suite/Unit</p>
          <p style={{ fontSize: '15px' }}>{modalDataa.numsuite}</p>
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>City</p>
          <p style={{ fontSize: '15px' }}>{modalDataa.city}</p>
        </div>
      </div>
    </div>
  </div>
</div>
       </Modal.Body>
     
     </Modal>
     <Modal dialogClassName="custom-modal" centered show={showModal1} onHide={handleCloseee}>
       
       <Modal.Body>
       <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
 <div className="modal-content">
   <div className="modal-header">
     <h4 style={{ fontFamily: 'Poppins-SemiBold' }}>Bank account</h4>
     <div>
    
       <button onClick={()=>{setShowModal1(false)}} data-bs-dismiss="modal" className="btn btn-primary">
         Close
       </button>
     </div>
   </div>
   <div className="modal-body" style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
     <div className="p-3">
       <p className="mb-4">GENERAL INFORMATION</p>
       <div className="mb-4 d-flex align-items-center justify-content-between">
         <p style={{ fontFamily: 'Poppins-Regular' }}>{modalDataaa.acctype}</p>
         <p style={{ fontSize: '15px' }}>Nº {modalDataaa.accnum}</p>
       </div>

       <div className="mb-4 d-flex align-items-center justify-content-between">
         <p style={{ fontFamily: 'Poppins-Regular' }}>Bank</p>
         <p style={{ fontSize: '15px' }}>{modalDataaa.bank}</p>
       </div>

       <div className="mb-4 d-flex align-items-center justify-content-between">
         <p style={{ fontFamily: 'Poppins-Regular' }}>Account holder</p>
         <p style={{ fontSize: '15px' }}>{modalDataaa.name}</p>
       </div>

       <div className=" mb-4 d-flex align-items-center justify-content-between">
         <p style={{ fontFamily: 'Poppins-Regular' }}>ID Number</p>
         <p style={{ fontSize: '15px' }}>{modalDataaa.rut}</p>
       </div>

       <div className="d-flex align-items-center justify-content-between">
         <p style={{ fontFamily: 'Poppins-Regular' }}>Email</p>
         <p style={{ fontSize: '15px' }}>{modalDataaa.email}</p>
       </div>
     </div>
   </div>
 </div>
</div>
       </Modal.Body>
     
     </Modal>

        <div className="tab-navigation">
        <div
      style={{marginLeft:'5%'}}
        className={`tab ${activeTab === 'Settlements' ? 'active' : 'inactive'}`}
        onClick={() => {
          fetchpayments()
          setBanksLoading(true)
          setPaymentsLoading(true)
          handleTabClick('Settlements')}}
      >
        Settlements
      </div>
      <div
      style={{marginLeft:'5%'}}
        className={`tab ${activeTab === 'paymentSettings' ? 'active' : 'inactive'}`}
        onClick={() => {GetpaymentSettings()
          setBanksLoading(true)
          setPaymentsLoading(true)
          handleTabClick('paymentSettings')}}
      >
        Payment settings
      </div>
      <div
        className={`tab ${activeTab === 'paymentInformation' ? 'active' : 'inactive'}`}
        onClick={() => {handleTabClick('paymentInformation')
        GetpaymentInfo()
        setBanksLoading(true)
          setPaymentssLoading(true)
        }}
      >
        Payment information
      </div>
      <div
        className={`tab ${activeTab === 'bankAccounts' ? 'active' : 'inactive'}`}
        onClick={() => {handleTabClick('bankAccounts')
        GetBanksAccounts()
        setPaymentsLoading(true)
        setPaymentssLoading(true)
      }}
      >
        Bank accounts
      </div>
    </div>
    <>

    {activeTab=='paymentSettings'?
     <div className="mt-3">
  {paymentssloading?
    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

     <div className="spinner-border" role="status">
     <span className="visually-hidden">Loading...</span>
   </div>
   </div>
   :
<>
     {paymentsettings.map((bill, i) => (
       <div
         key={i}
         onClick={() => {
          // //setBillid(bill.id)
          // //console.log(bill.branches)
          // //setBranchess(bill.branches.map(i=>i.branch))
          //  setBranchesss(bill.branches.map(i=>i.branch))
          //  //console.log([...branches, ...bill.branches.map(i=>i.branch)])
          //  setBillInfoo(bill.billinfo)
          //  setBbillinfoid(bill.billinfoid)
          //  setbankinfoo(bill.bankaccount)
          //  setBankaccountid(bill.bankaccountid)
  
  
            setPaymnentDetails(true)
         }}
         className="account-container mb-3 rounded showmodal"
         data-show-modal="myModal"
       >
         <div className="account-content">
           <div className="billing-title">
             <strong>Branches</strong>
             <ul className="billing-title-list">
               {bill.branches.map((branch, index) => (
                 <li key={index} className="rounded" style={{ marginLeft: index === 0 ? '0px' : '4px' }}>
                   {branch.branch.name}
                 </li>
               ))}
             </ul>
           </div>
   
           <div className="billing-fields">
             <div className="bill-field billing-field">
               <b className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>
                 Billing information:
               </b>
               <span className="bill-field-value">
                 {bill.billinfo.busname} - {bill.billinfo.street}, {bill.billinfo.unit}
                 {bill.billinfo.numsuite ? ', ' + bill.billinfo.numsuite : ''}, {bill.billinfo.city}
               </span>
             </div>
             <div className="bill-field billing-field">
               <b className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>
                 Bank account
               </b>
               <span className="bill-field-value">
                 {bill.bankaccount.acctyp} - {bill.bankaccount.accnum}
               </span>
             </div>
           </div>
         </div>
       </div>
     ))}
     </>}
   </div>
   :activeTab=='paymentInformation'?
   <>
    {paymentsloading?
    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

     <div className="spinner-border" role="status">
     <span className="visually-hidden">Loading...</span>
   </div>
   </div>
   :
<>
     {billInfo.length==0?
     
     <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No results</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Your recents Billing Information will appear here.</p>
         </div>
 :

        <div className="bank-accounts-container mt-3">
      {billInfo.map((account, i) => (
        <div
          onClick={() => {
            setModalDataa({
              id: account.id,
              rut: account.comprat,
              name: account.busname,
              activity: account.activit,
              street: account.street,
              unit: account.unit,
              numsuite: account.numsuite,
              city: account.city,
            });
            setShowModal(true)
          }}
          className="account-container mb-3 rounded"
          data-bs-toggle="modal"
          data-bs-target="#payModal"
          key={i}
        >
          <div className="account-content">
            <h5 style={{ fontSize: '18px', fontFamily: 'Poppins-Regular' }}>{account.busname}</h5>
            <div className="d-flex justify-content-between align-items-center">
              <div className="acc-flex">
                <strong className="acc-title">Activity</strong>
                <span className="acc-flex-detail">{account.activit}</span>
              </div>

              <div className="acc-flex">
                <strong className="acc-title">Address</strong>
                <span className="acc-flex-detail">
                  {account.street}, {account.unit}
                  {account.numsuite ? ', ' + account.numsuite : ''}, {account.city}
                </span>
              </div>

              <div className="acc-flex">
                <strong className="acc-title">ID Number</strong>
                <span className="acc-flex-detail">{account.comprat}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div> 
    
  }
  </>
}
   </>
   :activeTab=='bankAccounts'?
   <>
     {banksLoading?
    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

     <div className="spinner-border" role="status">
     <span className="visually-hidden">Loading...</span>
   </div>
   </div>
   :
<>
   {bankAccounts.length==0?
<div
   
  style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No results</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Your recents Bank account will appear here.</p>
         </div>
:
    <div className="bank-accounts-container mt-3">
      {bankAccounts.map((account, i) => (
        <div
          onClick={() => {
            setModalDataaa({
              email: account.email,
              rut: account.idnum,
              name: account.name,
              bank: account.bank.name,
              accnum: account.accnum,
              acctype: account.acctyp,
              id: account.id,
            });
            setShowModal1(true)
          }}
          className="account-container mb-3 rounded"
          data-bs-toggle="modal"
          data-bs-target="#payModal"
          key={i}
        >
          <div className="account-content">
            <h5 style={{ fontSize: '18px', fontFamily: 'Poppins-Regular' }}>
              {account.acctyp} Nº {account.accnum}
            </h5>
            <div className="d-flex justify-content-between align-items-center">
              <div className="acc-flex">
                <strong className="acc-title">Account holder</strong>
                <span className="acc-flex-detail">{account.name}</span>
              </div>

              <div className="acc-flex">
                <strong className="acc-title">Bank</strong>
                <span className="acc-flex-detail">{account.bank.name}</span>
              </div>

              <div className="acc-flex">
                <strong className="acc-title">ID Number</strong>
                <span className="acc-flex-detail">{account.idnum}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
}
</>
}
   </>
   :
   activeTab=='Settlements'?
   <>
   {!loaderrr ? (

<div style={{width:'100%',height:300,justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
  </div>
      ):
      (
        <>
        {payments.length>0?
        <div style={{marginBottom:15}}>
         {payments.map((payment, i) => (
        <div
          onClick={() => {
            setPaymentsData({
              from:payment.from,
              to:payment.to,
              num: payments.length-i,
              date: payment.dater,
              prix: payment.prix,
              com: payment.comtotal,
              tax: payment.tax,
              final: payment.finaltotal,
              comiss: payment.comiss,
              file: payment.filer,
              taxperc:payment.taxperc
            });
            getPaymentOrder(payment.id)
            setshowmodal(true)
            //setOrders(payment.settlchecks);
          }}
          className="payment-cont mt-3 px-3 py-2 d-flex align-items-center justify-content-between"
          style={{ cursor: 'pointer',borderRadius:9,height:95 }}
          data-bs-toggle="modal"
          data-bs-target="#payModal"
          key={i}
        >
          <div>
            <p className="m-0" style={{ fontSize: '18px', fontFamily: 'Poppins-SemiBold' }}>
            Settlement #{payments.length-i}
            </p>
            <div className="d-flex align-items-center" style={{marginTop:3}}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="12.000000pt" height="12.000000pt" viewBox="0 0 256.000000 256.000000"
                preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M604 2548 c-31 -15 -44 -50 -44 -122 l0 -64 -153 -3 c-149 -4 -154
                -5 -219 -37 -76 -37 -120 -82 -157 -162 l-26 -55 0 -925 0 -925 26 -55 c37
                -80 81 -125 157 -162 l67 -33 1025 0 1025 0 55 26 c80 37 125 81 162 157 l33
                67 0 925 0 925 -33 67 c-37 76 -83 120 -162 157 -50 23 -69 26 -207 29 l-153
                4 0 64 c0 76 -13 109 -51 125 -22 9 -36 9 -58 0 -38 -16 -51 -49 -51 -126 l0
                -65 -560 0 -560 0 0 65 c0 75 -13 108 -47 124 -29 13 -41 13 -69 -1z m1793
                -1568 l3 -695 -23 -40 c-12 -22 -40 -50 -62 -62 l-40 -23 -995 0 -995 0 -40
                23 c-22 13 -49 40 -62 62 l-23 40 0 691 c0 380 3 694 7 697 3 4 506 6 1117 5
                l1111 -3 2 -695z"/>
                </g>
            </svg>
              <p className="text-muted" style={{ margin: '0px 0px 0px 10px' }}>
                 {formatDate((payment.dater).substring(0,10))}
                
              </p>
            </div>
          </div>
          <div className="h-100 d-flex justify-content-center align-items-center">
            <p className="m-0">Amount: ${payment.finaltotal}</p>
          </div>
        </div>
      ))}
        </div>
        :
        <>
         <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
         <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M832 4940 c-237 -62 -422 -259 -462 -490 -7 -46 -10 -646 -8 -2075
l3 -2010 21 -40 c46 -88 156 -137 251 -112 29 8 172 88 360 200 171 103 316
187 323 187 6 0 173 -97 372 -215 360 -215 361 -215 420 -215 32 0 74 7 94 15
19 8 185 105 370 216 222 133 342 199 354 196 11 -3 159 -90 331 -193 296
-179 314 -188 370 -192 80 -6 137 17 188 74 22 26 43 63 50 89 8 31 11 434 11
1399 l0 1355 298 3 297 3 57 27 c80 37 152 107 191 187 l32 66 0 520 0 520
-24 74 c-66 201 -236 357 -447 411 -75 20 -117 20 -1728 19 -1592 0 -1654 -1
-1724 -19z m2783 -317 c-5 -10 -18 -43 -29 -73 -21 -55 -21 -55 -26 -2001 l-5
-1947 -239 143 c-132 79 -260 149 -285 157 -57 16 -162 16 -217 0 -23 -7 -189
-100 -368 -207 l-325 -195 -293 176 c-161 97 -306 184 -323 194 -43 26 -131
50 -181 50 -97 0 -139 -18 -392 -170 l-247 -149 -3 1891 c-1 1209 1 1904 8
1927 24 91 100 170 193 202 49 17 132 18 1396 19 1275 0 1343 -1 1336 -17z
m659 -17 c60 -29 103 -73 138 -139 23 -42 23 -46 26 -511 3 -429 1 -470 -14
-487 -16 -18 -36 -19 -281 -19 l-263 0 0 493 c0 473 1 494 21 537 33 73 93
126 169 151 46 15 147 3 204 -25z"/>
<path d="M1295 3736 c-120 -53 -120 -228 -1 -286 43 -20 50 -21 724 -18 673 3
681 3 709 24 15 11 38 36 51 54 46 68 18 176 -58 219 -37 21 -43 21 -716 21
-541 -1 -685 -3 -709 -14z"/>
<path d="M1318 2888 c-50 -13 -104 -74 -113 -127 -9 -53 25 -126 74 -158 l34
-23 691 0 691 0 36 24 c97 66 91 210 -11 270 -34 21 -49 21 -700 23 -418 1
-679 -3 -702 -9z"/>
<path d="M1292 2003 c-127 -62 -110 -254 26 -293 25 -8 251 -10 709 -8 663 3
672 3 700 24 100 75 98 201 -6 271 l-34 23 -681 0 c-608 0 -685 -2 -714 -17z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No payments results found</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Please try again with a different date</p>
        </div>
        </>

        }
       
        </>
      )
        }
   </>
    :null}
    </>
            </>
            :
            isbranches?
                <>
                  <Modal dialogClassName="custom-modal" centered show={showww} onHide={handleCloseww}>
       <Modal.Header closeButton>
        <Modal.Title>{`${branchnamee} ${isopen?'Open Shifts':'Close Shifts'}`}</Modal.Title>
       </Modal.Header>
       <Modal.Body>
      <div>
     {!isopen&&(
      <>
         <label>Message</label>
         <input placeholder='Message' className='form-control' value={message} onChange={(e)=>setMessage(e.target.value)}/>
         </>  
     )}
     <Button disabled={!isopen?message==''?true:false:false}  style={{marginTop:10}} onClick={handleShifts}>Save</Button>
      </div>
       </Modal.Body>
     
     </Modal>
                <p style={{fontSize:19,fontWeight:'500'}}>{branches.length} Branches</p>
                  {branches.map(branch => (
                      <div
                      style={{cursor:'pointer',backgroundColor:branch.conf2!==1||branch.conf==2?'#f2f2f3':null}}
                      onClick={()=>navigate(`/store/branches/${branch.id}`)}
                   
                        className={`store-branch-container rounded mb-3 p-4 ${
                          branch.conf2 === 0 ? 'bg-gray-200' : ''
                        }`}
                        key={branch.id}
                      >
                      
                        <div className="d-flex justify-content-between mb-2">
                        <div style={{display:'flex',alignItems:'center'}}>
            <h5 style={{color:'#4a4a4a',fontSize:20}}>{branch.name} </h5>
            {
            branch.conf==2? <span style={{marginLeft:5,marginTop:7,color:'#888b90',fontSize:16}} dangerouslySetInnerHTML={{ __html: branch.reason }}></span>:
            branch.conf==0? <span style={{marginLeft:5,marginTop:-5,color:'#888b90',fontSize:16}} >Pending</span>:
            ''}
            </div>
            {/* <h5 style={{color:'#4a4a4a',fontSize:18}}>{branch.name}</h5>
            <h6 style={{color:branch.conf==1?'green':branch.conf==2?'red':'gray'}}>{branch.conf==1?'Accepted':branch.conf==2?'Rejected':'Pending'}</h6> */}
            <div style={{display:'flex'}}>
            <button
            style={{zIndex:10,marginRight:10}}
              className="branch-disable-btn"
              data-branchid={branch.id}
              data-conf2={branch.shifts_stopped}
              onClick={e => {
                e.stopPropagation();
                if(branch.shifts_stopped===1){
                setIsopen(true)
                }else{
                  setIsopen(false)
                }
                setBranchid(branch.id)
                setBranchname(branch.name)
                 setShowww(!showww);
        
              }}
            >
              {branch.shifts_stopped === 1 ? 'Open Shifts' : 'Close Shifts'}
            </button>
            <button
            style={{zIndex:10}}
              className="branch-disable-btn"
              data-branchid={branch.id}
              data-conf2={branch.conf2}
              onClick={e => {
                e.stopPropagation();
                if(branch.conf2===1){
                setEnable(false)
                }else{
                  setEnable(true)
                }
                setBranchid(branch.id)
                setBranchname(branch.name)
                 setShow(!show);
        
              }}
            >
              {branch.conf2 === 1 ? 'Disable branch' : 'Enable branch'}
            </button>
            </div>
          </div>
                        <div className='branchlisting'>
                
                          <div className="d-flex align-items-center listing">
                          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

              <g id="SVGRepo_bgCarrier" stroke-width="0"/>

              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

              <g id="SVGRepo_iconCarrier"> <g id="style=linear"> <g id="email"> <path id="vector" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#000000" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> <path id="vector_2" d="M18.7698 7.7688L13.2228 12.0551C12.5025 12.6116 11.4973 12.6116 10.777 12.0551L5.22998 7.7688" stroke="#000000" stroke-width="1.5" stroke-linecap="round"/> </g> </g> </g>

              </svg>
                            <p className="ms-1 branch-detail-text" style={{margin:0, fontSize: '13px' }}>
                              {branch.email}
                            </p>
                          </div>

                          <div className="d-flex align-items-center listing" >
                          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"/>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
              <g id="SVGRepo_iconCarrier"> <g id="Navigation / Map_Pin"> <g id="Vector"> <path d="M5 9.92285C5 14.7747 9.24448 18.7869 11.1232 20.3252C11.3921 20.5454 11.5281 20.6568 11.7287 20.7132C11.8849 20.7572 12.1148 20.7572 12.271 20.7132C12.472 20.6567 12.6071 20.5463 12.877 20.3254C14.7557 18.7871 18.9999 14.7751 18.9999 9.9233C18.9999 8.08718 18.2625 6.32605 16.9497 5.02772C15.637 3.72939 13.8566 3 12.0001 3C10.1436 3 8.36301 3.7295 7.05025 5.02783C5.7375 6.32616 5 8.08674 5 9.92285Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g> </g> </g>
              </svg>
                            <p className="ms-1 branch-detail-text" style={{margin:0, fontSize: '13px' }}>
                              {branch.address}
                            </p>
                          </div>

                          <div className="d-flex align-items-center listing2" >
                          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"/>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
              <g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.8284 6.75736C12.3807 6.75736 12.8284 7.20507 12.8284 7.75736V12.7245L16.3553 14.0653C16.8716 14.2615 17.131 14.8391 16.9347 15.3553C16.7385 15.8716 16.1609 16.131 15.6447 15.9347L11.4731 14.349C11.085 14.2014 10.8284 13.8294 10.8284 13.4142V7.75736C10.8284 7.20507 11.2761 6.75736 11.8284 6.75736Z" fill="#0F1729"/> </g>
              </svg>
                  
                  <DayContainer
                    day="M"
                    startTime={branch.monday1}
                    endTime={branch.monday2}
                    isClosed={branch.monday1 === '' && branch.monday2 === ''}
                  />


                  <DayContainer
                    day="T"
                    startTime={branch.tuesday1}
                    endTime={branch.tuesday2}
                    isClosed={branch.tuesday1 === '' && branch.tuesday2 === ''}
                  />


                  <DayContainer
                    day="W"
                    startTime={branch.wedneday1}
                    endTime={branch.wedneday2}
                    isClosed={branch.wedneday1 === '' && branch.wedneday2 === ''}
                  />


                  <DayContainer
                    day="T"
                    startTime={branch.thursday1}
                    endTime={branch.thursday2}
                    isClosed={branch.thursday1 === '' && branch.thursday2 === ''}
                  />


                  <DayContainer
                    day="F"
                    startTime={branch.friday1}
                    endTime={branch.friday2}
                    isClosed={branch.friday1 === '' && branch.friday2 === ''}
                  />


                  <DayContainer
                    day="S"
                    startTime={branch.saturday1}
                    endTime={branch.saturday2}
                    isClosed={branch.saturday1 === '' && branch.saturday2 === ''}
                  />


                  <DayContainer
                    day="S"
                    startTime={branch.sunday1}
                    endTime={branch.sunday2}
                    isClosed={branch.sunday1 === '' && branch.sunday2 === ''}
                  />

                  

                          </div>
                        </div>
                      </div>
                    ))}
                </>:
              isproducts?
              <>
              
              <p style={{fontSize:19,fontWeight:'500'}}>{count} Products</p>
              <main className="products-container">
                  <div className="prodfilter" style={{  marginTop:'1%',width:'96%',marginLeft:'2%'}}>
                  <div className="d-flex align-items-center" style={{width:'70%'}}>
              <div style={{cursor:'pointer',marginLeft:'0%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
            </g>
            </svg>

                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      id="search-product"
                      placeholder="Search Product"
                      style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
                      // className="form-control"
                    />
                {search!=''?   <svg onClick={()=>setSearch('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
            -491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
            -24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
            -209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
            44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
            196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
            480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
            -468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
            c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
            -50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
            330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
            325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
            -379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
            477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
            24 151 22 204 -5z"/>
            </g>
            </svg>:null}
                    </div>

            <div >
              <div onClick={() => setIsExpanded(!isExpanded)} className="form-control" style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 5px 0 5px',border:statuss=='ALL'?null:'2px solid #307afe',display:'flex',alignItems:'center',height:40}}>
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill={statuss=='ALL'?"#616161":'#307afe'}  stroke="none">
                <path d="M1283 4612 c-700 -262 -1273 -479 -1273 -482 0 -3 574 -220 1275
                -482 l1275 -478 477 179 478 179 -55 25 c-30 13 -245 102 -478 198 -398 163
                -422 175 -395 186 15 6 160 67 321 136 l293 125 478 -214 479 -215 476 178
                c262 98 476 180 476 183 0 4 -2503 944 -2545 956 -5 2 -583 -212 -1282 -474z"/>
                <path d="M2 2369 l3 -1441 1195 -448 1195 -448 3 1436 c1 790 -1 1439 -5 1442
                -5 4 -516 197 -1138 430 -621 233 -1158 434 -1193 446 l-62 24 2 -1441z m958
                -988 l315 -118 3 -171 c1 -95 1 -172 0 -172 -2 0 -145 53 -318 119 l-315 118
                -3 171 c-1 95 -1 172 0 172 2 0 145 -53 318 -119z"/>
                <path d="M4640 3630 l-475 -178 -5 -403 -5 -402 -315 -118 c-173 -66 -316
                -119 -317 -119 -2 0 -4 180 -5 399 l-3 399 -395 -148 -395 -148 -3 -1441 -2
                -1441 62 24 c35 12 572 213 1193 446 622 233 1133 426 1138 430 4 3 6 652 5
                1442 l-3 1436 -475 -178z"/>
                </g>
                </svg>
                <div style={{marginLeft:5,width:'100%',color:statuss=='ALL'?'black':'#307afe',padding:'0 4px 0 0'}}>{statuss=='ALL'?'Availability':statuss=='AVAILABLE'?'Available':
                statuss=='OUT_OF_SEASON'?'Out of season':statuss=='OUT_OF_STOCK'?'Out of stock':
                statuss=='UNAVAILABLE'?'Not available':null }</div>
              </div>
              {isExpanded? 


              <div ref={wrapperRef}  className='AvailabilityFilterList_containerList__be85l' >
              <div style={{display:'flex',flexDirection:'column',marginLeft:10}}>
                    <div onClick={()=>{
                      setStatus('AVAILABLE')
                  setIsExpanded(!isExpanded)
                  setPage(1)
                  }} style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
                    Available
                    </div>
                    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
                        <div onClick={()=>{      setIsExpanded(!isExpanded)
                        setStatus('OUT_OF_SEASON')
                        setPage(1)
                        }} style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
                    Out of season
                    </div>
                    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
                        <div onClick={()=>{
                                setIsExpanded(!isExpanded)
                          setStatus('OUT_OF_STOCK')
                          setPage(1)}
                          } style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
                    Out of stock
                    </div>
                    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
                        <div onClick={()=>{
                          setPage(1)
                                setIsExpanded(!isExpanded)
                          setStatus('UNAVAILABLE')}
                          } style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
                    Not available
                    </div>
                    <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
                    <div  onClick={() => {
                      if(statuss=='ALL'){
                        console.log('.')
                      }else{
                        setStatus('ALL')
                        setIsExpanded(!isExpanded)
                      }
              
                        }} style={{padding:'10px 0 5px 0',color:statuss=='ALL'?'gray':'red',cursor:'pointer'}}>
                    Clear selection
                    </div>
                  
              

                
              </div>

              </div>
              :null}
            
            </div>

            <div  style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">

            <svg xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  width="18.000000pt" height="18.000000pt" viewBox="0 0 512 512">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#616161" stroke="none">
            <path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
            -146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
            229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
            -310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
            734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
            l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
            <path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
            l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
            0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
            158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
            -456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
            -1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
            0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
            </g>
            </svg>

            <select value={branchid === '0' ? 'All Branches' : branchname}
              onChange={(e) => {
                if(e.target.value==0){
                  setBranchid(0);
                  setBranchName('All Branches');
                }else{
                  const selectedBranchName = e.target.value;
                  const selectedBranch = branches.find(branch => branch.name === selectedBranchName);
                  setBranchid(selectedBranch.id);
                  setBranchName(selectedBranch.name);
                }
              
              }}
              style={{marginLeft: '5%',width:branchid==0?120:150 ,border:'none',outline:'none'}}>
              <option value="0">All Branches</option>
              {branches.map((branch) => (
                  <option key={branch.id}>{branch.name}</option>
              ))}
            </select>

              
            </div>
                      
                        </div>
                  
                  </div>
                  <div className='d-flex align-items-center justify-content-between' style={{marginTop:10,width:'96%',marginLeft:'2%'}}>
                    <div>
                      <p>All Products</p>
                    </div>
            <div className='d-flex align-items-center'>
            <p id="pagination-text" className="mx-2" style={{ fontSize: '12px' }}>
                        {page === 1 ? 0 : (page - 1) * limit}-{page * limit <= count ? page * limit : count} of {count}
                      </p>
                      <div className="pagination-container py-2 border d-flex h-100">
                      <svg  onClick={() => {
                            if (page > 1) setPage(page - 1);
                          }} style={{ opacity: page > 1 ? '1' : '0.25' }} className="border-end" fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
              <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
            </svg>
                  
                    

            <svg  onClick={() => {
                            if (page < totalPages) setPage(page + 1);
                          }}   style={{ opacity: page < totalPages ? '1' : '0.25' }} fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)">
              <g id="SVGRepo_bgCarrier" strokeWidth="0" />
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
              <g id="SVGRepo_iconCarrier">
                <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
              </g>
            </svg>
                      </div>
            </div>
                  </div>
            {showAlerts()}

                  <div className={`main-products-container py-3 px-4 ${loading ? 'd-flex justify-content-center align-items-center main-products-containerx' : ''}`}>
                    {prodsLoading ? (
                    <div style={{width:'100%',height:'70vh',justifyContent:'center',alignItems:'center',display:'flex'}}>
                    <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                    </div>
                    </div>
                    ) : (
                    <>
                    {products.length==0?
                      <div style={{width:'100%',height:'70vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
            </g>
            </svg>
            <p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No search results found</p>
            <p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Please try again with a different search term</p>
                      </div>
                    :
                    products.map((product) => (
                      <ProdItem
                      fromStore={true}
                      fetch={fetchprods}
                        key={product.id} // Make sure to provide a unique key
                        setModalState={setModalState}
                        setModalInfo={setModalInfo}
                        setModalImgPrev={setModalImgPrev}
                        product={product}
                        id={id==product.id?id:null}
                        hasid={id==product.id?true:false}
                      />
                    ))
                    }
                    </>
                    
                        
                  
                    )}
                  
                  </div>
                </main>
              </>
              :
              isSettings?
              <>
                       {error && (
<div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
    <div className="d-flex align-items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
    </svg>
    <strong className="ms-1">{errors[0]}</strong>
    </div>
    <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
    </svg>
    </button>
</div>
)}
{conf==2&&
<>
<label className="form-label mt-4">Reason <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<Editor editorState={editorState} setEditorState={setEditorState} />

</>}
<div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Store Limiters</p>

        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40,marginRight:10}} onClick={()=>navigate(`/getstorelimiter/${storeid}`)}>Show</Button>

    
   </div>
   <div style={{width:'97%',marginLeft:'1.5%',height:50,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <p>Manage Store Cashier</p>

        <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40,marginRight:10}} onClick={()=>navigate(`/getstorecashier/${storeid}`)}>Show</Button>

    
   </div>
   <div>
   <label className="form-label mt-4">Store Code</label>
   <input required type="number" className="form-control" min={0} max={100} name="slogan" value={storecode==0?'......':storecode} 
  onChange={(e) => {
    const value = parseInt(e.target.value, 10);
    // Check if the value is a number and within the 0-100 range
      setStoreCode(value);
   
  }}
/>
<Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40,marginRight:10}} onClick={handleSaveCode}>Save</Button>

   </div>





<label className="form-label mt-4">Charge Of Service</label>
<input required type="number" className="form-control" min={0} max={100} name="slogan" value={chargeofservice} 
  onChange={(e) => {
    const value = parseInt(e.target.value, 10);
    // Check if the value is a number and within the 0-100 range
      setChargeofservice(value);
   
  }}
/>
<label className="form-label mt-4">Type</label>
<div >
<select required name="code" className="form-control" value={storeType} onChange={(e)=>{setStoreType(e.target.value)}} >
  <option value="1">Fulfillment</option>
  <option value="2">Independent</option>

</select>

</div>

<label className="form-label mt-4">position</label>
<div >
<select required name="code" className="form-control" value={position} onChange={(e)=>{setPosition(e.target.value)}} >
  <option value="banner">Banner</option>
  <option value="featured">Featured</option>
  <option value="none">None</option>
</select>

</div>
<label className="form-label mt-4">Priority</label>
<input required type="number" className="form-control" min={1} max={100} name="slogan" value={priority} 
  onChange={(e) => {
    const value = parseInt(e.target.value, 10);
    // Check if the value is a number and within the 0-100 range
      setpriority(value);
   
  }}
/>
<label className="form-label mt-4">category Priority</label>
<input required type="number" className="form-control" min={1} max={100} name="slogan" value={catpriority} 
  onChange={(e) => {
    const value = parseInt(e.target.value, 10);
    // Check if the value is a number and within the 0-100 range
      setcatpriority(value);
   
  }}
/>

<Button onClick={HandleEditSettings} style={{marginTop:10}}>Save</Button>

              </>:
             isPromotions?
             <>
             <p>promotions</p>
             </>:
               isOrder?
               <>
        <StoreOrder branches={branches} branchid={branchid} storeid={storeid}/>
               </>
            :isExchange?
              <ExchangeRate storid={id}/>
              :mainprom?
              <MainPromotions branches={branches} branchid={branchid} storid={id}/>
            :null
            }
        </div>

    </div>
    </>
}
        </>
  )
}
