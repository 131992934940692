import React , {useCallback,useEffect,useState,useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Alert, Button } from 'react-bootstrap';

export default function EditOrderFaq() {
  const textareaRef = useRef(null);
    const {id} = useParams();
    const navigate = useNavigate();
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [existing, setExisting] = useState(0);
    const [dropdowndata, setDrop] = useState([]);
    const [isparent, setisparent] = useState(false);
    const [questionid, setQuestionid] = useState();
    const [quest, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [type, setType] = useState(1);
    const [hasNoAnswer, setHasNoAnswer] = useState(false);
    const GetFaq = async ()=>{
        const {status, data, msg} = await fetchData(`/orderfaqs/${id}`);
        if(status){
            setQuestion(data.title)
            if(data.answer){
                setAnswer(data.answer)
                setHasNoAnswer(false)
            }else{
                setisparent(true)
                setHasNoAnswer(true)
            }
            
    if(data.questionid){
        setType(2)
        setExisting(2)
        setQuestionid(data.questionid)
    }else{
        setExisting(1)
    }
          
           
         }
    }
    const GetDropDown = async ()=>{
        const {status, data, msg} = await fetchData(`/orderfaqs/dropdown`);
        if(status){
            setDrop(data)
           // setQuestionid(data[0].id)
            // setData(data)
            //  setLoading(false)
         }
    }
    useEffect(()=>{
        GetDropDown()
        GetFaq()
    },[])
    const EditFaq = async () => {
        const {status, data, msg} = await sendData('/orderfaqs/add', JSON.stringify({
            type:existing==1?1:existing==2?2:null,
            id:id,
            question:quest,answer:hasNoAnswer?null:answer,questionid:existing==2?questionid:null}));
        if(status){
         navigate('/orders-faq')
        }else{
            setShowalert(true)
            setMsg(msg)
        }
      }
      const showAlerts = () =>{
        return (
          <>
      {showalert&&(
    <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
        {msg}
    </Alert>
      )}
         
        </>
        )
}
useEffect(() => {
  const textarea = textareaRef.current;
  if (textarea) {
    textarea.style.height = 'auto'; // Reset height to allow shrink if text is removed
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
}, [answer]); 
  return (
    <div style={{marginTop:'6%',marginLeft:'1%'}}>
          {showAlerts()}
           {existing==1?
                    <>
                     <label className="form-label mt-4">Question</label>
      <input required type="text" className="form-control"  name="slogan" value={quest} 
      onChange={(e) => {setQuestion(e.target.value)}}
      />
      {isparent?null:
      <>
        <div style={{display:'flex',alignItems:'center',marginTop:15}}>
        <input style={{marginRight:10}} required type="checkbox"   name="slogan" checked={hasNoAnswer} value={hasNoAnswer} 
        onChange={(e) => {setHasNoAnswer(!hasNoAnswer)}}
      />
      <p style={{marginLeft:10}} className='m-0'>This question has no answer, it has follow-up questions</p> 
        </div>
        <label className="form-label mt-4">Answer</label>
        <textarea    ref={textareaRef}  style={{width:'98%'}} disabled={hasNoAnswer?true:false}  required type="text" className="form-control"  name="slogan" value={answer} 
      onChange={(e) => {setAnswer(e.target.value)}}
      /> 
      </>
      } 
      <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={EditFaq}>Save</Button>

                    </>
                    :
                    existing==2?
                    <>
                            {/* <label style={{marginLeft:10}} className="form-label mt-4">Questions <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
<div style={{margin:'5px 10px 10px 10px'}} >
<select value={questionid} onChange={(e)=>setQuestionid(e.target.value)} required name="code" className="form-control"  >
{dropdowndata.map((item,i)=>(
      <option value={item.id}>{item.title}</option>
))}

</select>

</div> */}
                    <label className="form-label mt-4">Question</label>
     <input required type="text" className="form-control"  name="slogan" value={quest} 
     onChange={(e) => {setQuestion(e.target.value)}}
     />
        {isparent?null:
      <>
        <div style={{display:'flex',alignItems:'center',marginTop:15}}>
        <input style={{marginRight:10}} required type="checkbox"   name="slogan" checked={hasNoAnswer} value={hasNoAnswer} 
        onChange={(e) => {setHasNoAnswer(!hasNoAnswer)}}
      />
      <p style={{marginLeft:10}} className='m-0'>This question has no answer, it has follow-up questions</p> 
        </div>
        <label className="form-label mt-4">Answer</label>
      <textarea ref={textareaRef}  style={{width:'98%'}} disabled={hasNoAnswer?true:false}  required type="text" className="form-control"  name="slogan" value={answer} 
      onChange={(e) => {setAnswer(e.target.value)}}
      /> 
      </>
      }   
     <Button style={{marginTop:'1%',paddingLeft:40,paddingRight:40}} onClick={EditFaq}>Save</Button>

                   </>
                    :null
            }
    </div>
  )
}
