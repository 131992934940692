import React, {useState} from 'react'
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Time from './Time';
import { Button } from 'react-bootstrap';
import { sendData } from '../utils';
export default function AddShifts() {
    const [day, setDay] = useState('today');
    const [timePickers, setTimePickers] = useState([]);
    const addTimePicker = () => {
        setTimePickers([...timePickers, { from: '00:00', to: '00:00'}]);
    };
    const removeTimePicker = (index) => {
        const updatedTimePickers = [...timePickers];
        updatedTimePickers.splice(index, 1);
        setTimePickers(updatedTimePickers);
    };
    const setTime = (index, field, value) => {
        const updatedTimePickers = [...timePickers];
        updatedTimePickers[index][field] = value;
        setTimePickers(updatedTimePickers);
    };
    const addShift = async () => {
        const {status, data, msg} = await sendData('/shifts/add', JSON.stringify({day,data:timePickers}));
        if(status){
         
        }
      }
    
    // const toggleEnabled = (index, value) => {
    //     const updatedTimePickers = [...timePickers];
    //     updatedTimePickers[index].enabled = value;
    //     setTimePickers(updatedTimePickers);
    // };
  return (
    <div style={{paddingTop:'5%',width:'97%',marginLeft:'1.5%',overflow:'scroll',height:'98vh'}}>
 
            <div className="d-flex w-50 mt-4 justify-content-between align-items-center">
                    <label className="form-label">Day <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
                </div>
            <select required name="code" className="form-control" value={day} onChange={(e)=>{setDay(e.target.value)}} >
            <option value="today">Today</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="aftertomorrow">After Tomorrow</option>
            </select>
            

            <div style={{marginBottom:10}} className="d-flex w-100 mt-4 justify-content-between align-items-center">
                    <label className="form-label">Time <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
                    <Button onClick={addTimePicker} style={{ margin: 10 }}>Add</Button>

            </div>

            <div>
            {timePickers.map((timePicker, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px',width:'100vw' }}>
        <div style={{ marginRight: '10px',width:'34.5%' }}>
            <label>From:</label>
            <TimePicker
                onChange={(value) => setTime(index, 'from', value)}
                value={timePicker.from}
                clearIcon={null}
                disableClock={true}
                format='HH:mm'
            />
        </div>
        <div style={{ marginRight: '10px',width:'34.5%' }}>
            <label>To:</label>
            <TimePicker
                onChange={(value) => setTime(index, 'to', value)}
                value={timePicker.to}
                clearIcon={null}
                disableClock={true}
                format='HH:mm'
            />
        </div>
      
        <Button onClick={() => removeTimePicker(index)} style={{ marginLeft: '10px' }}>Remove</Button>
    </div>
))}

        </div>
        <Button disabled={timePickers.length==0?true:false} onClick={addShift}>Save</Button>
        




    </div>
  )
}
